import axios from "axios";
import {refreshToken} from "../../features/logins/loginSlice";

const _axiosPublic = axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND}`,
});
const _axiosPrivate = axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND}`,
});

//
export const setup = (store)=>{
    _axiosPrivate.interceptors.request.use(

        async (config) => {
            if(!config.withCredentials){
                config.withCredentials = true
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    _axiosPrivate.interceptors.response.use(function (response) {
        return response;
    }, async function (error) {
        let _config = JSON.parse(JSON.stringify(error.config))
        if(error.response.status ==401 &&  _config.url!="/authentication/refresh"){
            error.config._retry =true
            store.dispatch(refreshToken()).unwrap().then(result=>{
                return _axiosPrivate(error.config)
            }).catch(err=>{
                return Promise.reject(error);
            })

        }else{
            return Promise.reject(error);
        }

    });

}

export const axiosPublic = _axiosPublic
export const axiosPrivate = _axiosPrivate
