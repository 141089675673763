import React, {useEffect, useState} from 'react'
import GlobalHeaderStudent from "../../commons/components/headers/GlobalHeaderStudent";
import banner from '../../assets/images/bannerStudent.png'
import {useDispatch, useSelector} from "react-redux";
import {Navigation, Pagination, Scrollbar, A11y, Autoplay} from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import {useNavigate} from "react-router-dom";
import {listCourse} from "../courses/courseSlice";
import flagIcon from '../../assets/images/icons/flagIcon.svg'
import imageFooter from '../../assets/images/imgFooter.png'
import banner1 from '../../assets/images/banner/banner1.png'
import banner2 from '../../assets/images/banner/banner2.png'
import banner3 from '../../assets/images/banner/banner3.png'
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import CourseItem from "./AccountItem";
import Footer from "../../commons/components/footer/Footer";
import {getGradeMega} from "./DashboardStudentSlice";
import ChatGPT from "../chatGPT/ChatGPT";
import BannerCarousel from "../../commons/components/Carousel/BannerCarousel";
import { useTranslation } from 'react-i18next';
export default function DashboardStudent(){
    const {t, i18n} = useTranslation()
    const user =useSelector(state=>state.auth.user)
    const courses = useSelector(state=>state.courses.courses)
    const navigate = useNavigate()
    const [grades, setGrades]= useState([])
    const dispatch = useDispatch()
    // useEffect(()=>{
    //     if(user){
    //         dispatch(listCourse())
    //     }
    // },[user])

    useEffect(()=>{
        dispatch(getGradeMega()).unwrap().then(grades=>{
            setGrades(grades)
        })
    },[])



    function getColor(courseId){
        let color={}
        switch (courseId%12){
            case 0:
                color= {
                    bg1: "#10a0b6",
                    bg2:"#10a0b6"
                }
                break
            case 1:
                color= {
                    bg1: "#f39317",
                    bg2:"#f39317"
                }
                break
            case 2:
                color= {
                    bg1: "#42ae18",
                    bg2:"#42ae18"
                }
                break
            case 3:
                color= {
                    bg1: "#d74726",
                    bg2:"#d74726"
                }
                break
            case 4:
                color= {
                    bg1: "#00a1de",
                    bg2:"#00a1de"
                }
                break
            case 5:
                color= {
                    bg1: "#8d33aa",
                    bg2:"#8d33aa"
                }
                break
            case 6:
                color= {
                    bg1: "#00b971",
                    bg2:"#00b971"
                }
                break
            case 7:
                color= {
                    bg1: "#e9681b",
                    bg2:"#e9681b"
                }
                break
            case 8:
                color= {
                    bg1: "#0b863c",
                    bg2:"#0b863c"
                }
                break
            case 9:
                color= {
                    bg1: "#eabc00",
                    bg2:"#eabc00"
                }
                break
            case 10:
                color= {
                    bg1: "#7027a5",
                    bg2:"#7027a5"
                }
                break
            case 11:
                color= {
                    bg1: "#c83131",
                    bg2:"#c83131"
                }
                break
            case 12:
                color= {
                    bg1: "#147cc4",
                    bg2:"#147cc4"
                }
                break
            default:
                color= {
                    bg1: "#00A586",
                    bg2:"#008B71"
                }
                break

        }
        return color
    }

    return <div className={'w-screen bg-white min-h-screen'}>
        <GlobalHeaderStudent page={'hone'} />
        <div className={'w-full'}>
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                spaceBetween={50}
                slidesPerView={1}
                navigation
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log('slide change')}
                autoplay={true}
            >
                <SwiperSlide><img onClick={()=>{navigate('/license')}} className={'w-full'} src={banner1}/></SwiperSlide>
                <SwiperSlide><img onClick={()=>{navigate('/license')}} className={'w-full'} src={banner2}/> </SwiperSlide>
                <SwiperSlide><img onClick={()=>{navigate('/license')}} className={'w-full'} src={banner3} /></SwiperSlide>
                <SwiperSlide><img onClick={()=>{navigate('/license')}} className={'w-full'} src={banner} /></SwiperSlide>
            </Swiper>
        </div>
        <div className={' bg-white pt-5'}>
            <div className={'container  mx-auto   '}>
                <div className={' w-full grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 lg:grid-cols-4 gap-4 px-5 md:px-0'}>
                    {grades && grades.map((x,i)=><div  onClick={()=>{
                        window.open(`/courseMega/account/${x.id}`, "_self")
                    }} key={x.id} className={'border border-2 rounded-xl bg-white hover:cursor-pointer '} style={{borderColor: getColor(i).bg1}}>
                        <p className={'w-fit z-10 text-white relative'}>
                            <div className={' my-5 px-3 relative  z-20 '} style={{backgroundColor:getColor(i).bg1}}>
                                <span className={''}>{x.name}</span>
                            </div>
                            <svg className={'absolute right-[-8px] top-[-4px] z-10'} xmlns="http://www.w3.org/2000/svg" width="23.359" height="21.337" viewBox="0 0 23.359 21.337">
                                <path id="Path_10296" data-name="Path 10296" d="M50.439,98.344H73.8L66.034,87.675,73.8,77.007H50.439Z" transform="translate(-50.439 -77.007)" fill={getColor(i).bg2} />
                            </svg>
                        </p>
                        <CourseItem account={x} />
                    </div>)}
                </div>
            </div>

        </div>
        <div className={'z-50'}>
            <ChatGPT/>
        </div>
        <div className={'z-30'}>
            <Footer />
        </div>



    </div>
}
