import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {createSchoolAPI, deleteSchoolAPI, getAllSchoolAPI} from "./adminSchoolService";

const initState = {
    schools:[],
    schoolSelect:null,
    status: 'idle'
}

export const createSchool = createAsyncThunk('adminSchool/create', async(schoolName)=>{
    let school = await createSchoolAPI(schoolName)
    return school.data
})

export const getAllSchool = createAsyncThunk('adminSchool/getall',async ()=>{
    let schools = await getAllSchoolAPI()
    return schools.data
})

export const deleteSchool = createAsyncThunk('adminSchool/delete', async (schoolId)=>{
    let schoolDelete = await deleteSchoolAPI(schoolId)
    console.log(schoolDelete.data)
    return schoolDelete.data
})


export const adminSchoolSlice = createSlice({
    name:'adminSchool',
    initialState: initState,
    reducers:{},
    extraReducers:builder => {
        builder.addCase(createSchool.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(createSchool.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.schools = [...state.schools, action.payload]
        }).addCase(getAllSchool.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(getAllSchool.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.schools=[...action.payload]
        }).addCase(deleteSchool.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(deleteSchool.fulfilled, (state,action)=>{
            state.status = 'idle'
            let _schools = [...state.schools]
            console.log(action.payload)
            for(let i = 0; i< _schools.length; i++){
                if(_schools[i].id === action.payload.id){
                   _schools.splice(i, 1)
                    break
                }
            }
            state.schools=[..._schools]
        }).addCase(deleteSchool.rejected, (state,action)=>{
            state.stauts = 'idle'
            console.log(action.error.message)
        })
    }
})

export default adminSchoolSlice.reducer
