const {axiosPrivate} = require("../../commons/services/axiosService");
exports.getEnrollInfoAPI = (courseId)=>{
    return axiosPrivate({
        url: `enrollment/enrollInfo?courseId=${courseId}`,
        method: "GET",
    })
}
exports.getPlannerAPI = (startDate, endDate)=>{
    return axiosPrivate({
        url:`planner/plannersByDate?startDate=${startDate}&endDate=${endDate}`
    })
}
