import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {createSubject, deleteSubject, getSubjectInGrade} from "./AdminSubjectSlice";
import DropdownSimple from "../../../commons/components/dropdowns/DropdownSimple";
import SimpleButton from "../../../commons/components/buttons/SimpleButton";
import AddIcon from "../../../assets/images/icons/addIcon.svg";
import {toast} from "react-toastify";

export default function AdminSubject(){
    const dispatch = useDispatch()
    const grades = useSelector(state=> state.adminGrade.grades)
    const subjects = useSelector(state=>state.adminSubject.subjects)
    const [subjectName, setSubjectName]= useState('')
    const [gradeSelect, setGradeSelect] = useState(null)
    const [subjectSelect, setSubjectSelect] = useState(null)
    const subjectStatus = useSelector(state=>state.adminSubject.status)
    const handleChangeGrade = (grade)=>{
        setGradeSelect(grade)
    }
    const handleChangeSubject = (subject)=>{
        setSubjectSelect(subject)
    }
    useEffect(()=>{
        if(grades.length){
            setGradeSelect(grades[0])
        }else{
            setGradeSelect(null)
        }
    }, [grades])

    useEffect(()=>{
        if(gradeSelect){
            dispatch(getSubjectInGrade(gradeSelect.id))
        }
    },[gradeSelect])
    useEffect(()=>{
        if(subjects.length){
            setSubjectSelect(subjects[0])
        }else{
            setSubjectSelect(null)
        }
    }, [subjects])


    const handleCreateSubject = ()=>{
        dispatch(createSubject({
            name: subjectName,
            gradeId: gradeSelect.id
        })).unwrap().then(data=>{
            toast.success(`subject ${subjectName} have been created`)
        }).catch(err=>{
            toast.error(err.message)
        })
    }

    const handleDeleteSubject = ()=>{
        dispatch(deleteSubject(subjectSelect.id)).unwrap().then(data=>{
            toast.success(`subject ${subjectSelect.name} have been deleted`)
        }).catch(err=>{
            toast.error(err.message)
        })
    }

    return <div className={'border-b-2 py-3'}>
        <div >
            <span  className={'text-[24px] font-semibold  my-3'}>Create Subject</span>
        </div>
        <div>
            <div className={'flex items-center space-x-5'}>
                <span className={'font-semibold'}>Grade:</span> <DropdownSimple selectData={handleChangeGrade} listData={grades}/>
            </div>
        </div>

        <div className={'flex items-center space-x-5'}>
            <span>Subject:</span>
            <input className={'rounded-xl border px-2 py-2'}  value={subjectName} onChange={(e)=>{setSubjectName(e.target.value)}} />
            <SimpleButton
                disable={subjectStatus!=='idle'}
                name={'create subject'}
                icon={AddIcon}
                textSize={'text-[14px]'}
                textColor={'text-white'}
                bgColor={'bg-indigo-600'}
                handleClick={handleCreateSubject}
            />
        </div>

        <div >
            <span  className={'text-[24px] font-semibold  my-3'}>Delete Subject</span>
        </div>
        <div>
            <div className={'flex items-center space-x-5'}>
                <span className={'font-semibold'}>Group:</span>
                <DropdownSimple selectData={handleChangeSubject} listData={subjects}/>
                <SimpleButton
                    disable={subjectStatus!=='idle'}
                    name={'Delete subject'}
                    icon={AddIcon}
                    textSize={'text-[14px]'}
                    textColor={'text-white'}
                    bgColor={'bg-indigo-600'}
                    handleClick={handleDeleteSubject}
                />
            </div>
        </div>
    </div>
}
