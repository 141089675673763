import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getModuleItemSequenceAPI} from "../../../commons/services/moduleService";

const init = {
    itemSequence:null,
    status : 'idle'
}


export const getModuleItemSequence = createAsyncThunk('assignmentItem/moduleItemSequence', async(input)=>{
    let itemSequence = await getModuleItemSequenceAPI(input.courseId, input.assetId, input.assetType)
    return itemSequence.data
})

const AssignmentItemSlice = createSlice({
    name: 'assignmentItem',
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getModuleItemSequence.pending, (state,action)=>{
            state.status= 'loading'
        }).addCase(getModuleItemSequence.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(getModuleItemSequence.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.itemSequence = action.payload
        })
    }
})

export default AssignmentItemSlice.reducer
