import React, {useEffect, useState} from 'react'
import TrashIcon from "../../../assets/images/icons/trash.svg";
import {useDispatch, useSelector} from "react-redux";
import {concludeEnrollment} from "./EnrollmentSlice";
import { useTranslation } from 'react-i18next';

export default function PeopleItem({enrollment}){
    const dispatch = useDispatch()
    const status = useSelector(state=> state.enrollment.status)
    const [invited, setInvited] = useState(false)
    const {t, i18n} = useTranslation()
    const [role, setRole] = useState("teacher")
    const handleConcludeEnrollment= ()=>{
        dispatch(concludeEnrollment({
            courseId: enrollment.enrollments[0].course_id,
            enrollmentId: enrollment.enrollments[0].id
        })).unwrap().then(result=>{
            dispatch()
        })
    }

    useEffect(()=>{
        for(let enroll of enrollment.enrollments){
            if(enroll.enrollment_state == 'invited'){
                setInvited(true)
            }
            if(enroll.role=='StudentEnrollment'){
                setRole("student")
            }
        }
    },[enrollment])

    return <div key={enrollment.id} className={'py-2 border-b flex'}>
        <div className={'flex space-x-2 w-full'}>
            <div>
                {enrollment && enrollment.avatar_url && <img className={'w-[40px] h-[40px] rounded-full'} src={enrollment && enrollment.avatar_url ? enrollment.avatar_url:""}/>}
            </div>
            <div className={'flex  flex-col space-y-1'}>
                <h3 className={'text-[14px] text-black'}>{enrollment.name} {invited?<span className={'px-1 py-0.5 rounded text-white font-semibold bg-1D53E2 text-[10px]'}>{t('waitStatus')}</span>:<></>}</h3>
                <h5 className={'text-[12px] text-7A86A1'}>{t(role)} | {enrollment.sis_user_id}</h5>
            </div>
        </div>
        <div>
            <button  onClick={handleConcludeEnrollment}  className={'bg-F0F0F0 flex justify-center items-center px-3 space-x-3 h-[40px] rounded '}><img src={TrashIcon}/></button>
        </div>
    </div>
}
