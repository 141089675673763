import React,{useEffect, useState} from 'react'
import GlobalHeader from "../../commons/components/headers/GlobalHeader";
import TabListComponent from "../../commons/components/tabs/TabListComponent";
import TabComponent from "../../commons/components/tabs/TabComponent";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getCourseDetail} from "../courseDetail/CourseDetailSlice";
import {getAssignmentDetail, getGroupAssignment, updateAssignment} from "../assignmentDetail/AssignmentDetailSlice";
import AssignmentInfo from "../courseDetail/assignmentTab/AssignmentInfo";
import {toast} from "react-toastify";
import Footer from "../../commons/components/footer/Footer";
import TeacherCourseTabBar from "../../components/tabBar/TeacherCourseTabBar";
import CourseSubTab from "../../components/tabBar/CourseSubTab";
import { useTranslation } from 'react-i18next';
export default function AssignmentEdit(){
    const params = useParams()
    const dispatch = useDispatch()
    const {t, i18n} = useTranslation()
    const course = useSelector(state=>state.courseDetail.course)
    const [tabSelect2,setTabSelect2] = useState(1)
    const [tabSelect,setTabSelect] = useState('3')
    const [assignmentInfo, setAssignmentInfo] = useState({})
    const [listAssignTo, setListAssignTo] = useState([])
    const navigate=useNavigate()
    const assignment = useSelector(state=> state.assignmentDetail.assignment)

    useEffect(()=>{
        dispatch(getCourseDetail(params.courseId))
    },[params.courseId])

    useEffect(()=>{
        if(params.courseId && params.assignmentId){
            dispatch(getAssignmentDetail({
                courseId: params.courseId,
                assignmentId: params.assignmentId
            }))
        }
    },[params.courseId, params.assignmentId])
    useEffect(()=>{
        if(assignment){
            dispatch(getGroupAssignment({
                courseId: params.courseId,
                groupId: assignment.assignment_group_id
            })).unwrap().then(group=>{
                if(assignment.all_dates.length >0){
                    let listAssto = []
                    for(let dates of assignment.all_dates){
                       if(dates.set_type =='CourseSection'){
                           listAssto.push({
                               sectionSelects:[{
                                   label:dates.title,
                                   value:dates.set_id
                               }],
                               dueDate:  dates.due_at?new Date(dates.due_at):null,
                               startDate: dates.unlock_at?new Date(dates.unlock_at):null,
                               endDate: dates.lock_at? new Date(dates.lock_at):null
                           })
                       }else{
                           listAssto.push({
                               sectionSelects:[],
                               dueDate:  dates.due_at?new Date(dates.due_at):null,
                               startDate: dates.unlock_at?new Date(dates.unlock_at):null,
                               endDate: dates.lock_at? new Date(dates.lock_at):null
                           })
                       }
                    }
                    setListAssignTo(listAssto)
                }
                setAssignmentInfo({
                    name: assignment.name,
                    description: assignment.description,
                    points_possible: assignment.points_possible,
                    assignment_group_id: assignment.assignment_group_id,
                    submission_types:assignment.submission_types,
                    allowed_attempts:assignment.allowed_attempts,

                })
            })

        }

    },[assignment])



    const handleEditAssignment= ()=> {
        if (assignmentInfo.name == "") {
            toast.error(t("assignment.titleNameEmptyErrorMsg"))
            return
        }
        if (assignmentInfo.points_possible == undefined || assignmentInfo.points_possible <= 0) {
            toast.error(t("assignment.gradeNotSetEror"))
            return
        }
        if (assignmentInfo.submission_types == undefined || assignmentInfo.submission_types.length == 0) {

            toast.error(t("assignment.submissionTypeNotSet"))
            return
        }
        if (assignmentInfo.assignment_overrides.length > 0) {
            for (let override of assignmentInfo.assignment_overrides) {
                if (override.dueDate) {
                    if (override.startDate) {
                        if (new Date(override.dueDate).getTime() < new Date(override.startDate).getTime()) {
                            toast.error(t("assignment.dateError1"))
                            return
                        }
                    }
                    if (override.endDate) {
                        if (new Date(override.dueDate).getTime() > new Date(override.endDate).getTime()) {
                            toast.error(t("assignment.dateError2"))
                            return
                        }
                    }

                }
                if (override.startDate && override.endDate) {
                    if (new Date(override.startDate).getTime() > new Date(override.endDate).getTime()) {
                        toast.error(t("assignment.dateError3"))
                        return
                    }
                }
            }
        }
        dispatch(updateAssignment({
            courseId: course.id,
            assignmentInfo: assignmentInfo,
            assignmentId: assignment.id
        })).unwrap().then(assignment => {
            navigate(`/courses/${course.id}/assignments/${assignment.id}`)
        })
    }

    return <div>
        <GlobalHeader />
        <CourseSubTab course={course}/>
        <TeacherCourseTabBar tabSelect={tabSelect} />
        <div className={'container mx-auto py-3 min-h-[500px]'}>
            {course && tabSelect==3 && <div className={'flex w-full'}>
                {assignment && <div className={'py-2'}>
                    <TabListComponent >
                        <TabComponent name={t( "assignment.detailTitle")}
                                      isSelect={tabSelect2==1}
                                      handleClick={()=>{setTabSelect2(1)}}
                        />

                    </TabListComponent>
                    {tabSelect2 == 1 && assignmentInfo && <AssignmentInfo _listAssignTo={listAssignTo} isEdit={true} assignmentInfo={assignmentInfo} setAssignmentInfo={setAssignmentInfo}  course= {course} />}
                    <div className={'flex justify-end space-x-2'}>
                        <button className={'h-[40px] rounded bg-F0F0F0 min-w-[100px] text-[14px]'}
                                onClick={()=>{
                                    window.open(`/courses/${course.id}/assignments`, '_self')
                                }}
                        >{t("cancelBtn")}</button>
                        <button onClick={handleEditAssignment} className={'h-[40px] rounded bg-316AFF min-w-[100px] text-[14px] text-white'}>{t("saveBtn")}</button>
                    </div>
                </div>}

            </div>}

        </div>
        <Footer />
    </div>
}
