import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    concludeEnrollmentAPI,
    inviteUserAPI,
    listAllEnrollmentSectionAPI,
    listEnrollInvitedAPI, replyInviteAPI
} from "./EnrollmentService";

const initState={
    status: 'idle',
    enrollments:[],
    enrollInvited:[]
}


export const inviteUser=createAsyncThunk('enrollment/inviteUser',async(input)=>{
    let enrollments = await inviteUserAPI(input.emails, input.limit, input.type, input.sectionId)
    return enrollments.data
})

export const listAllEnrollInSection= createAsyncThunk('enrollment/section', async ({sectionId, courseId})=>{
    let enrollments = await listAllEnrollmentSectionAPI(courseId,sectionId)
    return enrollments.data
})

export const concludeEnrollment = createAsyncThunk('enrollment/conclude', async(input)=>{
    let enroll= await concludeEnrollmentAPI(input.courseId,input.enrollmentId)
    return enroll.data
})

export const listEnrollInvited=createAsyncThunk('enrollment/listInvited', async()=>{
    let enrolls = await listEnrollInvitedAPI()
    return enrolls.data
})
export const replyInvite = createAsyncThunk('enrollment/replyInvite', async({courseId, enrollId, type})=>{
    let response = await replyInviteAPI(courseId, enrollId, type)
    return response.data
})


const enrollmentSlice = createSlice({
    name:'enrollment',
    initialState: initState,
    reducers:{
        removeEnrollInvite:(state,action)=>{
            let inviteEnroll = [...state.enrollInvited]
            for(let i=0; i< inviteEnroll.length; i++){
                if(inviteEnroll[i].id == action.payload){
                    inviteEnroll.splice(i, 1)
                    break
                }
            }
            state.enrollInvited= [...inviteEnroll]
        }
    },
    extraReducers:builder => {
        builder.addCase(inviteUser.pending,(state,action)=>{
            state.status = 'loading'
        }).addCase(inviteUser.fulfilled, (state,action)=>{
            state.status = 'idle'
        }).addCase(inviteUser.rejected, (state, action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(listAllEnrollInSection.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(listAllEnrollInSection.fulfilled, (state,action)=>{
            state.staus = 'idle'
            state.enrollments = action.payload
        }).addCase(listAllEnrollInSection.rejected, (state,action)=>{
            state.staus = 'idle'
            console.log(action.error)
        }).addCase(concludeEnrollment.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(concludeEnrollment.fulfilled, (state,action)=>{
            state.status = 'idle'
            let _enrolls = [...state.enrollments]
            for(let i =0; i< _enrolls.length; i++){
                console.log(action.payload.user_id)
                if(_enrolls[i].id === action.payload.user_id){
                    _enrolls.splice(i, 1)
                }
            }
            state.enrollments = [..._enrolls]
        }).addCase(concludeEnrollment.rejected,(state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        } ).addCase(listEnrollInvited.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(listEnrollInvited.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.enrollInvited = action.payload
        }).addCase(listEnrollInvited.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(replyInvite.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(replyInvite.fulfilled, (state,action)=>{
            state.status = 'idle'
        }).addCase(replyInvite.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        })
    }
})

export const {removeEnrollInvite} = enrollmentSlice.actions
export default enrollmentSlice.reducer
