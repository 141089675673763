import React, {useEffect, useState} from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {getUserInfo, refreshToken} from "./features/logins/loginSlice";
import Loading from "./features/loading/Loading";

export const PrivateRoute= ({component:Component, ...rest})=>{
    const user = useSelector(state=>state.auth.user)
    const [isAuth,setIsAuth] = useState(false)
    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(getUserInfo()).unwrap().then(data=>{
            setLoading(false)
            if(data.user){
                setIsAuth(true)
            }else {
                setIsAuth(false)
            }
        }).catch(err=>{
            dispatch(refreshToken()).unwrap().then(data=>{
                setLoading(false)
                if(data){
                    console.log(data)
                    setIsAuth(true)
                }else{
                    setIsAuth(false)
                }
            }).catch(err=>{
                setLoading(false)
                setIsAuth(false)
            })
        })
    }, [] )

        if(loading){
            return <Loading/>
        }else{
            if(isAuth) {
                return <Outlet/>
            }else {
                return <Navigate to={'/login'}/>
            }

        }

}
