import axios from 'axios'
import {axiosPrivate} from "../../commons/services/axiosService";


export const getCourseDetailAPI =(courseId)=> {
    return axiosPrivate({
        url: `course/${courseId}`,
        method:'GET'
    })
}

export const getCourseSettingAPI=(courseId)=>{
    return axiosPrivate({
        url:`course/courseSetting/${courseId}`,
        method:"GET"
    })
}

export const publicCourseAPI=(courseId)=>{
    return axiosPrivate({
        url:`course/publicCourse`,
        method:'PUT',
        data:{
            id: courseId
        }
    })
}

export const updateCourseStateAPI=(courseId, event)=>{
    return axiosPrivate({
        url:`course/updateCourseState`,
        method:'PUT',
        data:{
            id: courseId,
            event: event
        }
    })
}

export const createImageUploadUrlAPI = (courseId, name)=>{
    return axiosPrivate({
        url:`courseFile/createUrl`,
        method:'POST',
        data:{
            courseId: courseId,
            name:name
        }
    })
}

export const updateCourseAPI = (dataUpdate)=>{
    return axiosPrivate({
        url:`course/updateCourse`,
        method:'PUT',
        data:{
            updateData:dataUpdate
        }
    })
}


export const uploadFileAPI = (params, file, url)=>{
    let formData = new FormData()
    formData.append('Filename', params.Filename)
    formData.append('Policy', params.Policy)
    formData.append('acl', params.acl)
    formData.append('content-type', params['content-type'])
    formData.append('key', params.key)
    formData.append('success_action_redirect', params.success_action_redirect)
    formData.append('x-amz-algorithm', params['x-amz-algorithm'])
    formData.append('x-amz-credential', params['x-amz-credential'])
    formData.append('x-amz-date', params['x-amz-date'])
    formData.append('x-amz-signature', params['x-amz-signature'])
    formData.append('file', file)
    return axios.post(url, formData, {
        headers:{
            'Content-Type': 'multipart/form-data'
        }
    })
}

