import React, {useEffect, useState, useRef  } from 'react'
import DropdownSimple2 from "../../../commons/components/dropdowns/DropdownSimple2";
import SimpleCheckbox from "../../../commons/components/checkbox/SimpleCheckbox";
import AssignTo from "./AssignTo";
import {useDispatch, useSelector} from "react-redux";
import {listSectionInCourse} from "../peopleTab/SectionSlice";
import {getAssignmentGroups} from "./AssignmentGroupSlice";
import {toast} from "react-toastify";
import RCE from "../../editor/RCE";
import { useTranslation } from 'react-i18next';

export default function AssignmentInfo({course, assignmentInfo, setAssignmentInfo, isEdit, _listAssignTo}){
    const decriptionRef = useRef(null);
    const [assName, setAssName] = useState('')
    const [assDescription, setAssDescription] = useState('')
    const [groupSelect, setGroupSelect] = useState()
    const [points, setPoints] =useState('')
    const [submissionType, setSubmissionType] = useState()
    const groups = useSelector(state=>state.assignmentGroup.groups)

    const assignmentGroup = useSelector(state=> state.assignmentDetail.assignmentGroup)
    const [listGroup, setListGroup] = useState([])
    const [numberSubmit, setNumberSubmit] = useState(-1)
    const [onlineSubmissionType, setOnlineSubmissionType] = useState([])
    const listSections  = useSelector(state =>state.section.sections)
    const [listAssignTo,setListAssignTo] = useState([{}])
    const dispatch = useDispatch()
    const {t, i18n} = useTranslation()
    const [submissionTypes, setSubmissionTypes] = useState([
        {name:'online', value:'online'},
        {name: 'megaMark', value: 'external_tool'}
    ])


    useEffect(()=>{
        if(submissionType && submissionType.value=='external_tool'){
            setOnlineSubmissionType(['external_tool'])
        }
        if (submissionType && submissionType.value=='online'){
            setOnlineSubmissionType([])
        }
    },[submissionType])

    useEffect(()=>{
        if(assignmentInfo){
            setAssName(assignmentInfo.name)
            setAssDescription(assignmentInfo.description)
            setPoints(assignmentInfo.points_possible)
            setNumberSubmit(assignmentInfo.allowed_attempts)
        }

    },[assignmentInfo])
    useEffect(()=>{
        setOnlineSubmissionType(assignmentInfo && assignmentInfo.submission_types?assignmentInfo.submission_types:[])
    },[])

    useEffect(()=>{
        if(_listAssignTo && _listAssignTo.length >0){
            setListAssignTo(_listAssignTo)
        }
    },[_listAssignTo])


    useEffect(()=>{
        if(assignmentGroup){
            setGroupSelect({
                name: assignmentGroup.name,
                value: assignmentGroup.id
            })
        }
    },[assignmentGroup])

    useEffect(()=>{
        dispatch(getAssignmentGroups(course.id))
    },[])
    useEffect(()=>{
        if(submissionTypes){
            setSubmissionType(submissionTypes[0])
        }

    },[submissionTypes])

    useEffect(()=>{

            if(groups){
                let listGroup = []
                for(let _group of groups){
                    listGroup.push({
                        name: _group.name,
                        value: _group.id
                    })
                }
                setListGroup(listGroup)

            }
    },[groups])

    useEffect(()=>{
        if(listGroup && listGroup.length > 0){
            setGroupSelect(listGroup[0])
        }
    },[listGroup])

    useEffect(()=>{
        dispatch(listSectionInCourse(course.id))
    },[course])


    useEffect(()=>{
        setAssignmentInfo({...assignmentInfo, name: assName})
    },[assName])

    useEffect(()=>{
        setAssignmentInfo({...assignmentInfo, description: assDescription})
    }, [assDescription])

    useEffect(()=>{
        setAssignmentInfo({...assignmentInfo, points_possible: points})
    },[points])

    useEffect(()=>{
        if(groupSelect){
            setAssignmentInfo({...assignmentInfo, assignment_group_id: groupSelect.value})
        }

    },[groupSelect])

    useEffect(()=>{

        setAssignmentInfo({...assignmentInfo, submission_types: onlineSubmissionType})
    },[onlineSubmissionType])

    useEffect(()=>{
      setAssignmentInfo({...assignmentInfo, allowed_attempts:numberSubmit})
    },[numberSubmit])

    useEffect(()=>{
        setAssignmentInfo({...assignmentInfo, assignment_overrides: listAssignTo})
    },[listAssignTo])

    const handleOnlineSubmissionType= (type)=>{
        if(onlineSubmissionType.includes(type)){
            let _list = []
            for( let  i =0; i< onlineSubmissionType.length; i++){
                if(onlineSubmissionType[i] != type){
                    _list.push(onlineSubmissionType[i])
                }
            }
            setOnlineSubmissionType(_list)
        }else{
            let _list = [...onlineSubmissionType]
            _list.push(type)
            setOnlineSubmissionType(_list)
        }
    }

    const handleRemoveAssignTo = (index)=>{
        let assignTos = [...listAssignTo]
        if(assignTos.length > index && assignTos.length !=1){
            assignTos.splice(index,1)
            setListAssignTo(assignTos)
        }

    }

    const handleAddNewAssignto = ()=>{
        let _assignTo = [...listAssignTo]
        for(let asTo of _assignTo){
            if(asTo.sectionSelects == null){
                toast.error(t("assignment.assignToError"))
                return
            }
        }
        setListAssignTo([...listAssignTo, {}])
    }

    const getContentData= ()=>{
        setAssDescription(decriptionRef.current.getContent())
    }

    return <div className={'py-5'}>
        <div className={'pb-5'}>
            <span className={'text-[14px]'}>{t("assignment.AssignmentName")}</span>
        </div>
        <input
            value={assName}
            onChange={(e)=>{setAssName(e.target.value)}}
            className={'bg-F0F0F0 rounded text-[14px] h-[40px] w-full px-2'} />
        <div className={'py-5'}>
            <span>{t("assignment.assignmentDescripion")}</span>
        </div>
        <RCE editorRef={decriptionRef} onBlur={getContentData} content={assDescription}/>
        <div className={'py-5'}>
            <span className={'text-[14px]'}>{t('Grade')}</span>
        </div>
        <input
            value={points}
            onChange={event => {setPoints(event.target.value)}}
            className={'text-[14px] px-2 h-[40px] rounded bg-F0F0F0'}
            type={"number"}/>
        <div className={'py-5 flex space-x-2'}>
            <div>
                <div className={'py-5'}><span className={'text-[14px]'}>{t("assignment.groupAss")}</span></div>
                <DropdownSimple2 background={'bg-F0F0F0'} selectData={setGroupSelect} listData={listGroup} disable={isEdit} />
            </div>
            <div>
                <div className={'py-5'}><span className={'text-[14px]'}>{t("assignment.submissionType")}</span></div>
                <DropdownSimple2 background={'bg-F0F0F0'} selectData={setSubmissionType} listData={submissionTypes} disable={isEdit} />
            </div>
            <div>
                <div className={'py-5'}><span className={'text-[14px]'}>{t( "assignment.timeAttempt")}</span></div>
                <input
                    onChange={event => {setNumberSubmit(event.target.value)}}
                    value={numberSubmit} type={"number"} className={'text-[14px] h-[40px] px-2 py-2 rounded bg-F0F0F0'} />
            </div>

        </div>
        {submissionType && onlineSubmissionType && submissionType.value=='online' && <div>
            <div className={'py-5'}><span className={'text-[14px]'}>{t("assignment.onlineSumissionType")}</span></div>
            <div className={'flex-col space-y-2'}>
                <SimpleCheckbox
                    checked={onlineSubmissionType.includes('online_text_entry')}
                    text={t( "assingment.text_entry")}
                    value={'online_text_entry'}
                    click={(type)=>{handleOnlineSubmissionType(type)}} />
                <SimpleCheckbox
                    checked={onlineSubmissionType.includes('online_url')}
                    text={t("assignment.web_url")}
                    value={'online_url'}
                    click={(type)=>{handleOnlineSubmissionType(type)}} />
            </div>
        </div>}
        <div className={'py-5 max-w-2xl '}>
            {listAssignTo && listAssignTo.map((x,i)=> <div>
                <AssignTo listAssignTo={listAssignTo} setListAssignTo={setListAssignTo} index={i} handleRemoveAssignTo={handleRemoveAssignTo} assignTo={x} listSection={listSections} _selectOptions={ x.sectionSelects}  />
                </div>)}
            <button onClick={()=>{
                handleAddNewAssignto()
            }
            } className={'h-[40px] mt-3 w-full rounded text-[12px] bg-F0F0F0'}>{t('AddBtn')}</button>
        </div>
    </div>
}
