import React, {useEffect, useState} from 'react'
import DropdownSimple2 from "../../../commons/components/dropdowns/DropdownSimple2";
import TabListComponent from "../../../commons/components/tabs/TabListComponent";
import TabComponent from "../../../commons/components/tabs/TabComponent";
import AssignmentInfo from "./AssignmentInfo";
import {toast, useToast} from "react-toastify";
import {useDispatch} from "react-redux";
import {createAssignment} from "./AssignmentSlice";
import {useNavigate} from "react-router-dom";
import QuizInfo from "../../quizInfo/QuizInfo";
import CreateQuestion from "../../createQuestions/CreateQuestion";
import {createQuiz, createQuizQuestions, editQuiz} from "../../quizInfo/QuizSlice";
import {getAssignmentAPI} from "../../assignmentDetail/AssignmentDetailService";
import {getAssignmentDetail} from "../../assignmentDetail/AssignmentDetailSlice";
import { useTranslation } from 'react-i18next';

export default function CreateAssignment ({course, assignmentGroups}){
    const [listbt, setListbt] = useState([
        {name: 'Assignment', value :"assignment"},
        {name:"Quiz", value:"quiz"}
    ])
    let navigate = useNavigate()
    const listGroup = []
    const dispatch = useDispatch()
    const [btSelect, setBtSelect] = useState( {name: 'Assignment', value :"assignment"})
    const [tabSelect,setTabSelect] = useState(1)
    const [assignmentInfo, setAssignmentInfo] = useState({})
    const [quizInfo, setQuizInfo] = useState({})
    const [quizQuestions, setQuizQuestions] = useState([])
    const [loading,setLoading] = useState(false)
    const {t, i18n} = useTranslation()
    const handleCreateAssignment= ()=>{
        if(assignmentInfo.name ==""){
            toast.error(t('assignment.titleNameEmptyErrorMsg'))
            return
        }
        if(assignmentInfo.points_possible== undefined || assignmentInfo.points_possible <=0){
            toast.error(t("assignment.gradeNotSetEror"))
            return
        }
        if(assignmentInfo.submission_types==undefined|| assignmentInfo.submission_types.length==0){

            toast.error(t("assignment.submissionTypeNotSet"))
            return
        }
        setLoading(true)
        if(assignmentInfo.assignment_overrides.length > 0){
            for(let override of assignmentInfo.assignment_overrides){
                if(override.dueDate){
                    if(override.startDate){
                        if(new Date(override.dueDate).getTime() < new Date(override.startDate).getTime()){
                            setLoading(false)
                            toast.error(t("assignment.dateError1"))
                            return
                        }
                    }
                    if(override.endDate){
                        if(new Date(override.dueDate).getTime() > new Date(override.endDate).getTime()){
                            setLoading(false)
                            toast.error(t('assignment.dateError2'))
                            return
                        }
                    }

                }
                if(override.startDate && override.endDate){
                    if(new Date(override.startDate).getTime() > new Date(override.endDate).getTime()){
                        setLoading(false)
                        toast.error(t("assignment.dateError3"))
                        return
                    }
                }
            }
        }
        dispatch(createAssignment({
            courseId: course.id,
            assignment: assignmentInfo
        })).unwrap().then(assignment=>{
            setLoading(false)
            navigate(`/courses/${course.id}/assignments/${assignment.id}`)
        })

    }

    const handleCreateQuiz=()=>{
        if(quizInfo.title == ""){
            toast.error(t('assignment.titleNameEmptyErrorMsg'))
            return
        }
        let poinsts = 0
        setLoading(true)
        for(let q of quizQuestions){
            poinsts += parseFloat(q.points_possible)
        }
        let assignmentInfo_ ={
            name: quizInfo.title,
            submission_types: ['online_quiz'],
            assignment_overrides: quizInfo.assignment_overrides,
            allowed_attempts: quizInfo.allowed_attempts,
            description: quizInfo.description,
            points_possible: poinsts

        }
        if(assignmentInfo_.assignment_overrides && assignmentInfo_.assignment_overrides.length > 0){
            for(let override of assignmentInfo_.assignment_overrides){
                if(override.dueDate){
                    if(override.startDate){
                        if(new Date(override.dueDate).getTime() < new Date(override.startDate).getTime()){
                            setLoading(false)
                            toast.error(t("assignment.dateError1"))
                            return
                        }
                    }
                    if(override.endDate){
                        if(new Date(override.dueDate).getTime() > new Date(override.endDate).getTime()){
                            setLoading(false)
                            toast.error(t("assignment.dateError2"))
                            return
                        }
                    }

                }
                if(override.startDate && override.endDate){
                    if(new Date(override.startDate).getTime() > new Date(override.endDate).getTime()){
                        setLoading(false)
                        toast.error(t("assignment.dateError3"))
                        return
                    }
                }
            }
        }
        dispatch(createAssignment({
            courseId: course.id,
            assignment: assignmentInfo_
        })).unwrap().then(assignment=>{
            dispatch(getAssignmentDetail({
                courseId: course.id,
                assignmentId: assignment.id
            })).unwrap().then(ass=>{
                dispatch(createQuizQuestions({
                    courseId: course.id,
                    quizId: ass.quiz_id,
                    questions: quizQuestions
                })).unwrap().then(questions=>{
                    dispatch(editQuiz({
                        courseId: course.id,
                        quizId: ass.quiz_id,
                        quiz: quizInfo
                    })).unwrap().then(quiz=>{
                        setLoading(false)
                        window.open(`/courses/${course.id}/quizzes/${quiz.id}`, '_self')
                    })
                })
            })
        })

    }


    return <div className={'py-2'}>
        <div>
            <div className={'pb-2'}>
                <span className={'text-14px'}>{t("assignment.assType")}</span>
            </div>
            <DropdownSimple2  listData={listbt} selectData={setBtSelect} background={'bg-F0F0F0'} />
        </div>
        <div className={'container mx-auto'}>
            <TabListComponent >
                <TabComponent name={t("assignment.detailTitle")}
                              isSelect={tabSelect==1}
                              handleClick={()=>{setTabSelect(1)}}
                />
                {btSelect.value=='quiz' &&<TabComponent name={t("assignment.questions")}
                              isSelect={tabSelect==2}
                              handleClick={()=>{setTabSelect(2)}}
                />}
            </TabListComponent>
        </div>

        {tabSelect == 1 && btSelect.value == 'assignment' && <AssignmentInfo assignmentInfo={assignmentInfo} setAssignmentInfo={setAssignmentInfo}  course= {course} />}
        {tabSelect==1 && btSelect.value=='quiz' && <QuizInfo quizInfo={quizInfo} setQuizInfo={setQuizInfo} course={course} />}
        {tabSelect==2 && btSelect.value=='quiz' && <CreateQuestion  quizQuestions={quizQuestions} setQuizQuestions={setQuizQuestions} course={course} />}
        <div className={'flex justify-end space-x-2'}>
            <button onClick={()=>{
            window.open(`/courses/${course.id}/assignments`, '_self')
            }} className={'h-[40px] rounded bg-F0F0F0 min-w-[100px] text-[14px]'}>{t('cancelBtn')}</button>
            <button disabled={loading} onClick={()=>{
                if(btSelect.value=='assignment'){
                    handleCreateAssignment()
                }else{
                    handleCreateQuiz()
                }

            }} className={'h-[40px] rounded bg-316AFF min-w-[100px] text-[14px] text-white'}>
                {loading? <div
                    className="inline-block h-4 w-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status">
                      <span
                          className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                      >Loading...</span
                      >
                </div>:<span>{t('createBtn')}</span>}
            </button>
        </div>
    </div>
}
