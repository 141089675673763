import React, {useState, useEffect, useRef} from 'react'
import GlobalHeader from "../../commons/components/headers/GlobalHeader";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import RCE from "../editor/RCE";
import { showPage, updatePage} from "./PageSlice";
import checkIcon from '../../assets/images/icons/checkIcon.svg'
import unCheckIcon from '../../assets/images/icons/unCheckIcon.svg'
import {toast, ToastContainer} from "react-toastify";
import TeacherCourseTabBar from "../../components/tabBar/TeacherCourseTabBar";
import CourseSubTab from "../../components/tabBar/CourseSubTab";
import { useTranslation } from 'react-i18next';

export default function EditPage(){
    const {t, i18n} = useTranslation()
    const params = useParams()
    const dispatch = useDispatch()
    const course = useSelector(state=>state.courseDetail.course)
    const [tabSelect,setTabSelect] = useState('2')
    const navigator = useNavigate()
    const editorRef = useRef(null);
    const [content, setContent] = useState("")
    const [title, setTitle] = useState('')
    const [isFontPage, setIsFontPage] = useState(false)
    const pageDetail  = useSelector(state=>state.page.pageDetail)
    useEffect(()=>{
        if(params.courseId && params.pageId){
            dispatch(showPage({
                courseId: params.courseId,
                pageId: params.pageId
            }))
        }
    },[params.courseId, params.pageId])

    useEffect(()=>{
        if(pageDetail){
            setTitle(pageDetail.title)
            setContent(pageDetail.body)
            setIsFontPage(pageDetail.front_page)
        }
    },[pageDetail])


    const handleSaveEditPage=()=>{
        if(title==""){
            return toast.error(t("page.titleEmptyMsg"))
        }
        dispatch(updatePage({
            title: title,
            courseId: params.courseId,
            content: content,
            pageId: pageDetail.page_id,
            isFontPage: isFontPage
        })).unwrap().then(page=>{
            navigator(`/courses/${params.courseId}/pages`)
        })
    }

    const handleCancel=()=>{
        navigator(`/courses/${params.courseId}/pages`)
    }

    const getContentData= ()=>{
        let _content = editorRef.current.getContent()
        setContent(_content)
    }
    return <div>
        <GlobalHeader />
        <CourseSubTab course={course}/>
        <TeacherCourseTabBar tabSelect={tabSelect} />
        <div className={'container mx-auto'}>
            <h3 className={'text-[14px] py-3'}>{t("page.pageTitle")}</h3>
            <input value={title} onChange={(e)=>{setTitle(e.target.value)}} className={'bg-F0F0F0 h-[40px] w-full rounded focus:outline-none px-3'} />
            <h3 className={'text-[14px] py-3'}>{ t("page.content")}</h3>
            <RCE editorRef={editorRef} onBlur={getContentData} content={content}/>
            <div className={'py-3 flex justify-start space-x-3'}>
                <button onClick={()=>{setIsFontPage(!isFontPage)}}>
                    <img src={isFontPage?checkIcon:unCheckIcon}/>
                </button>
                <span>{t("page.setFontPage")}</span>
            </div>
            <div className={'py-3 flex justify-end space-x-2'}>
                <button
                    onClick={handleCancel}
                    className={'min-w-[100px] rounded text-[14px] bg-F0F0F0 py-2 px-3'}>{t('cancelBtn')}</button>
                <button className={'min-w-[100px] rounded text-[14px] text-white bg-316AFF py-2 px-3'}
                        onClick={handleSaveEditPage}
                >{t('saveBtn')}</button>
            </div>
        </div>
        <ToastContainer/>
    </div>
}
