import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {createGroupAssignmentAPI, getAssignmentGroupsAPI, getAssignmentInGroupAPI} from "./assignmentGroupService";
import {courseSlice} from "../../courses/courseSlice";
import {changeAssignmentStateAPI, delAssignmentAPI} from "./AssignmentService";
import {createAssignment} from "./AssignmentSlice";


const initState = {
    groups: [],
    status: 'idle'
}


export const getAssignmentGroups=  createAsyncThunk('assignment/getAssignmentGroup', async(courseId)=>{
    let groups = await getAssignmentGroupsAPI(courseId)
    return groups.data
})

export const createGroupAssignment = createAsyncThunk('assingment/createGroup', async(input)=>{
    let group = await createGroupAssignmentAPI(input.courseId, input.groupName)
    return group.data
})

export const getAssignmentInGroup = createAsyncThunk('assignment/getAssignmentInGroup', async(input)=>{
    let assignments = await getAssignmentInGroupAPI(input.courseId, input.groupId)
    return {
        group: input.groupId,
        assignments: assignments.data
    }
})

export const changeAssignmentState= (createAsyncThunk('assignment/changeAssignmentState', async({courseId, assignmentId, published})=>{
    let assignment = await changeAssignmentStateAPI(assignmentId, courseId, published)
    return assignment.data
}))

export const delAssignment =createAsyncThunk('assignment/delAssignment', async({courseId, assignmentId})=>{
    let ass = await delAssignmentAPI(assignmentId, courseId)
    return ass.data
})


const assignmentGroup = createSlice({
    name:'assignmentGroup',
    initialState: initState,
    reducers: {},
    extraReducers:builder => {
        builder.addCase(getAssignmentGroups.pending,(state,action)=>{
            state.status = 'pending'
        }).addCase(getAssignmentGroups.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.groups = action.payload
        }).addCase(getAssignmentGroups.rejected, (state,action)=>{
            state.status= 'idle'
            console.log(action.error)
        }).addCase(getAssignmentInGroup.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(getAssignmentInGroup.fulfilled, (state,action)=>{
            state.status = 'idle'
            let _groups = [...state.groups]
            for(let i =0; i<_groups.length; i++){
                if(_groups[i].id == action.payload.group){
                    _groups[i].assignments = action.payload.assignments
                }
            }
            state.groups = _groups
        }).addCase(getAssignmentInGroup.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(createGroupAssignment.pending,(state,action)=>{
            state.status = 'loading'
        }).addCase(createGroupAssignment.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(createGroupAssignment.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.groups = [...state.groups, action.payload]
        }).addCase(changeAssignmentState.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(changeAssignmentState.fulfilled, (state,action)=>{
            state.status = 'idle'
            let _groups = [...state.groups]
            for(let i =0; i<_groups.length; i++){
                if(_groups[i].id == action.payload.assignment_group_id){
                    if(_groups[i].assignments){
                        for(let j = 0; j< _groups[i].assignments.length; j++){
                            if(_groups[i].assignments[j].id== action.payload.id){
                                _groups[i].assignments[j]= action.payload
                            }
                        }
                    }

                }
            }
            state.groups= [..._groups]
        }).addCase(changeAssignmentState.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(delAssignment.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(delAssignment.fulfilled, (state,action)=>{
            state.status = 'idle'
            let _groups = [...state.groups]
            for(let i =0; i<_groups.length; i++){
                if(_groups[i].id == action.payload.assignment_group_id){
                    for(let j = 0; j< _groups[i].assignments.length; j++){
                        if(_groups[i].assignments[j].id== action.payload.id){
                            _groups[i].assignments.splice(j, 1)
                            break
                        }
                    }
                }
            }
            state.groups= [..._groups]
        }).addCase(delAssignment.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        })
    }
})

export default assignmentGroup.reducer
