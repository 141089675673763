import React, {useEffect, useState} from 'react'
import AddIcon from "../../../assets/images/icons/add1.svg";
import TrashIcon from "../../../assets/images/icons/trash.svg";
import {deleteSection} from "./SectionSlice";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {listAllEnrollInSection} from "./EnrollmentSlice";
import PeopleItem from "./PeopleItem";
import SearchIcon from '../../../assets/images/icons/search_icon.svg'
import { useTranslation } from 'react-i18next';
export default function ListPeople({section, openModal, setSectionSelect}){
    const dispatch = useDispatch()
    const {t, i18n} = useTranslation()
    const enrolls = useSelector(state=>state.enrollment.enrollments)
    const [searchText, setSearchText] = useState("")
    const [enrollments, setEnrollments] = useState([])
    const handleDelete=(id)=>{
        dispatch(deleteSection(id)).unwrap().catch(err=>{
            toast.error(t('candeleteSectionMessage'))
        })
    }

    const handleSearch = (text)=>{
        setSearchText(text)
        let list = []
        for(let enroll of enrolls){
            if(enroll.name.toLowerCase().includes(text)){
                list.push(enroll)
            }
        }
        setEnrollments(list)
    }

    useEffect(()=>{
        setEnrollments(enrolls)
        setSearchText("")
    },[enrolls])




    const handleOpenModal=(section)=>{
        openModal()
        setSectionSelect(section)
    }

    useEffect(()=>{
        if(section){
            dispatch(listAllEnrollInSection({
                courseId: section.course_id,
                sectionId: section.id
            }))
        }

    },[section])

    return <div>
        <div className={'flex mt-2 mb-5'}>
            <h1 className={'text-[18px] font-semibold text-black w-full'}>{section.name}</h1>
            <div className={'flex space-x-2'}>
                <button onClick={()=>{handleOpenModal(section)}} className={'bg-F0F0F0 flex justify-center items-center px-3 space-x-3 h-[40px] rounded w-[200px]'}><img src={AddIcon}/><span className={'text-393939 text-[14px]'}>{t('addPeopleBtn')}</span></button>
                <button onClick={()=>{handleDelete(section.id)}} className={'bg-F0F0F0 flex justify-center items-center px-3 space-x-3 h-[40px] rounded '}><img src={TrashIcon}/></button>
            </div>
        </div>
        <div className={'mb-5'}>
            <div className={'bg-F0F0F0 w-1/4 px-3 h-[40px] rounded flex space-x-2'}>
                <img src={SearchIcon} className={'w-[20px]'}/>
                <input value={searchText} onChange={(e)=>{handleSearch(e.target.value)}} className={'bg-F0F0F0 text-[14px] w-full focus:outline-none'}  placeholder={t('listPeopleStdName')}/>
            </div>
        </div>
        <div>
            {enrollments && enrollments.map(x=><PeopleItem enrollment={x}/>)}
        </div>
    </div>
}
