import React from 'react';
import './App.css';
import '@tremor/react/dist/esm/tremor.css';
import { Route, Routes} from "react-router-dom";
import Login from "./features/logins/Login";
import {PrivateRoute} from "./PrivateRoute";
import Dashboard from "./features/dashboard/Dashboard";
import SelectView from "./features/selectView/SelectView";
import Courses from "./features/courses/Courses";
import Admin from "./features/admin/Admin";
import CourseDetail from "./features/courseDetail/CourseDetail";
import AssignmentDetail from "./features/assignmentDetail/AssignmentDetail";
import AssignmentEdit from "./features/AssignmentEdit/AssignmentEdit";
import QuizDetail from "./features/quizDetail/QuizDetail";
import QuizEdit from "./features/quizEdit/QuizEdit";
import ModuleTab from "./features/moduleTab/ModuleTab";
import Assignment from "./features/AssignmentTab/Assignment";
import Setting from "./features/settingTab/Setting";
import People from "./features/PeopleTab/People";
import PageTab from "./features/pageTab/PageTab";
import GradeTab from "./features/gradeTab/GradeTab";
import CreatePage from "./features/pageTab/CreatePage";
import EditPage from "./features/pageTab/EditPage";
import {useSelector} from "react-redux";
import DashboardStudent from "./features/dashboardStudent/DashboardStudent";
import CourseStudent from "./features/courseStudent/CourseStudent";
import CourseMega from "./features/courseMega/CourseMega";
import CourseDetailStudent from "./features/courseDetailStudent/CourseDetailStudent";
import ModuleTabStudent from "./features/moduleTabStudent/ModuleTabStudent";
import PageDetailStudent from "./features/pageDetailStudent/PageDetailStudent";
import QuizDetailStudent from "./features/quizDetailStudent/QuizDetailStudent";
import AssignmentStudent from "./features/AssignmentTabStudent/AssignmentStudent";
import GradeStudent from "./features/gradeStudent/GradeStudent";
import ReportStudent from "./features/reportStudent/ReportStudent";
import LicensePage from "./features/license/LicensePage";
import VnPayReturn from "./features/vnPayReturn/VnPayReturn";
import {Oauth2Google} from "./features/oauth2Google/Oauth2Google";
import CourseReportStudent from "./features/courseReportStudent/CourseReportStudent";
import AssignmentDetailStudent from "./features/AssignmentDetailStudent";
import SpeedGrade from "./features/SpeedGrade";
import MGECallback from "./features/mgeCallback/MGECallback";
import MgeLogOut from "./features/mgeLogOut/MgeLogOut";
import AutoLoginMeagEdu from './features/logins/AutoLoginMeagEdu';

function App() {
    const user =useSelector(state=>state.auth.user)
  return (
     <Routes>
         <Route path='/' element={<PrivateRoute/>}>
             <Route  path="/" element={user && user.viewOption==1?<Dashboard/>:user&& user.viewOption==3?<DashboardStudent />:<SelectView/>}/>
             <Route path={'/changeview'} element={<SelectView />}/>
             <Route path={'/courses'} element={user && user.viewOption ==1? <Courses />:<CourseStudent />}/>
             <Route path={'/admin'} element={<Admin />}/>
             <Route path={'/courses/:courseId'} element={user && user.viewOption==1?<CourseDetail/>:<CourseDetailStudent />} />
             <Route path={'/courses/:courseId/modules'} element={user && user.viewOption==1?<ModuleTab/>:<ModuleTabStudent/>} />
             <Route path={'/courses/:courseId/assignments'} element={user && user.viewOption==1?<Assignment/>:<AssignmentStudent />} />
             <Route path={'/courses/:courseId/report'} element={<CourseReportStudent />}/>
             <Route path={'/courses/:courseId/settings'} element={<Setting/>} />
             <Route path={'/courses/:courseId/peoples'} element={<People/>} />
             <Route path={'/courses/:courseId/pages'} element={<PageTab/>} />
             <Route path={'/courses/:courseId/pages/:pageId'} element={<ModuleTabStudent/>} />
             <Route path={'/courses/:courseId/pages/create'} element={<CreatePage />} />
             <Route path={'/courses/:courseId/pages/:pageId/edit'} element={<EditPage />} />
             <Route path={'/courses/:courseId/grades'} element={user && user.viewOption==1? <GradeTab/>: <GradeStudent />} />
             <Route path={'/courses/:courseId/assignments/:assignmentId'} element={user && user.viewOption==1?<AssignmentDetail />:<AssignmentStudent/>} />
             <Route path={'/courses/:courseId/quizzes/:quizId'} element={user && user.viewOption==1?<QuizDetail />:<AssignmentStudent/>} />
             <Route path={'/courses/:courseId/quizzes/:quizId/edit'} element={<QuizEdit />} />
             <Route path={'/courses/:courseId/assignments/:assignmentId/edit'} element={<AssignmentEdit />} />
             <Route path={'/courses/:courseId/assignments/:assignmentId/speedGrade'} element={<SpeedGrade/>}/>
             <Route path={'/courseMega/account/:accountId'} element={<CourseMega />}/>
             <Route path={'/report'} element={<ReportStudent />} />
             <Route path={'/license'} element={user && <LicensePage />} />
             <Route path={'/vnpayReturn'} element={<VnPayReturn />}/>
             <Route path={'/sso-mge-logout'} element={<MgeLogOut />} />
         </Route>
         <Route path={'/login'} element={<Login />}/>
         <Route path={'/sso-mge-callback'} element={<MGECallback />} />
         <Route path={'/login/:oauth2'} element={<Login/>}/>
         <Route path='/megaEduLogin' element={<AutoLoginMeagEdu/>} />
         <Route path={'/oauth2google'} element={<Oauth2Google />} />
     </Routes>
  );
}

export default App;
