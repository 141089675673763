import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {createSchool, deleteSchool} from "./AdminSchoolSlice";
import {toast} from "react-toastify";
import SimpleButton from "../../../commons/components/buttons/SimpleButton";
import AddIcon from "../../../assets/images/icons/addIcon.svg";
import DropdownSimple from "../../../commons/components/dropdowns/DropdownSimple";

export default function AdminSchool(){
    const [schoolName, setSchoolName] = useState("")
    const schools = useSelector(state=>state.adminSchool.schools)
    const schoolStatus = useSelector(state=> state.adminSchool.status)
    const [schoolSelect, setSchoolSelect]= useState(null)
    const changeOrder = (order)=>{
        setSchoolSelect(order)
    }
    const dispatch = useDispatch()


    const handleCreateSchool = ()=>{
        dispatch(createSchool(schoolName)).unwrap().then(data=>{
            setSchoolName('')
            toast.success(` school ${schoolName} have been created!`)
        }).catch(err=>{
            toast.error(err.message)
        })
    }

    const handleDeleteSchool = ()=>{
        dispatch(deleteSchool(schoolSelect.id)).unwrap().then(data=>{
            toast.success(`school ${schoolSelect.name} have been deleted`)
        }).catch(err=>{
            toast.error(err.message)
        })
    }

    useEffect(()=>{
        if(schools.length >0){
            setSchoolSelect(schools[0])
        }else{
            setSchoolSelect(null)
        }
    },[schools])

    return <div className={'mb-3 border-b-2 pb-3'}>
        <div>
            <span className={'text-[24px] font-semibold  my-3'}>Create School</span>
        </div>
        <div className={'flex space-x-3 items-center'}>
            <span className={'pr-2 font-semibold'}>School Name:</span>
            <input className={'rounded-xl border px-2 py-2'} value={schoolName} onChange={e=>{setSchoolName(e.target.value)}} />
            <SimpleButton
                disable={schoolStatus!=='idle'}
                name={'create school'}
                icon={AddIcon}
                textSize={'text-[14px]'}
                textColor={'text-white'}
                bgColor={'bg-indigo-600'}
                handleClick={handleCreateSchool}
            />
        </div>

        <div >
            <span  className={'text-[24px] font-semibold  my-3'}>Delete School</span>
        </div>
        <div>
            <div className={'flex items-center space-x-5'}>
                <span className={'font-semibold'}>School:</span>
                <DropdownSimple selectData={changeOrder} listData={schools}/>
                <SimpleButton
                    disable={schoolStatus!=='idle'}
                    name={'Delete School'}
                    icon={AddIcon}
                    textSize={'text-[14px]'}
                    textColor={'text-white'}
                    bgColor={'bg-indigo-600'}
                    handleClick={handleDeleteSchool}
                />
            </div>
        </div>
    </div>
}
