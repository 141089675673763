import React, {useEffect, useState} from 'react'
import AddIcon from '../../../assets/images/icons/addIcon.svg'
import {useDispatch, useSelector} from "react-redux";
import {createSection, listSectionInCourse} from "./SectionSlice";
import SectionItem from "./SectionItem";
import {toast, ToastContainer} from 'react-toastify';
import SimpleModal from "../../../commons/modals/SimpleModal";
import AddPeopleForm from "./AddPeopleForm";
import ListPeople from "./ListPeople";
import { useTranslation } from 'react-i18next';
export default function PeopleTab({course}){
    const [sectionName, setSectionName] = useState('')
    const sections = useSelector(state =>state.section.sections)
    const dispatch = useDispatch()
    const status = useSelector(state=>state.section.status)
    const [openModalAddStudent, setOpenModalAddStudent] = useState(false)
    const [sectionSelect, setSectionSelect] = useState(null)
    const [viewListStudent,setViewListStudent] = useState(false)
    const handleCloseModal=()=>{
        setOpenModalAddStudent(false)
    }
    useEffect(()=>{
        if(course){
            dispatch(listSectionInCourse(course.id))
        }

    },[course])
    useEffect(()=>{

        if(!openModalAddStudent){
            dispatch(listSectionInCourse(course.id))
        }

    },[openModalAddStudent])
    const handleCreateSection = ()=>{
        dispatch(createSection({
            courseId: course.id,
            name: sectionName
        })).unwrap().then(data=>{
            setSectionName('')
        })
    }
    const handleOpenModal = ()=>{
        setOpenModalAddStudent(true)
    }

    const handleViewListStudent=(section)=>{
        setSectionSelect(section)
        setViewListStudent(true)
    }

    const {t, i18n} = useTranslation()

    return <div>
        {viewListStudent===false && <div>
            <div className={'flex space-x-2 items-center py-2'}>
                <input className={'focus: outline-none bg-F0F0F0 rounded h-[40px] w-[240px] px-3 '}
                       placeholder={t('peopleTab.className')}
                       value={sectionName} onChange={(e)=>{setSectionName(e.target.value)}} />
                <button onClick={handleCreateSection} disabled={status==='loading'} className={'bg-316AFF flex justify-center items-center px-3 space-x-3 h-[40px] rounded'}><img src={AddIcon}/><span className={'text-white text-[14px]'}>{t('peopleTab.addNewClassBtn')}</span></button>
            </div>
            <div>
                {sections && sections.map(x=><SectionItem openViewListPeople={handleViewListStudent} openModal={handleOpenModal} section={x} setSectionSelect={setSectionSelect}/>)}
            </div>
        </div>}

        {viewListStudent && <ListPeople section={sectionSelect}  openModal={handleOpenModal} setSectionSelect={setSectionSelect}/>}

        <SimpleModal title={'Thêm thành viên'} isOpen={openModalAddStudent} closeModal={handleCloseModal}>
            <AddPeopleForm course={course} section={sectionSelect} handleClose={handleCloseModal}/>
        </SimpleModal>

        <ToastContainer />
    </div>
}
