import React, {useRef} from 'react'
import RCE from "../editor/RCE";

export default function CreateQuestionText({setQuestionContent, index, content}){
    const editorRef = useRef(null);
    const getContentData= ()=>{
        console.log(editorRef.current.getContent())
         setQuestionContent(index,editorRef.current.getContent())
    }
    return <div className={'py-2'}>
        <RCE editorRef={editorRef} onBlur={getContentData} content={content}/>
    </div>
}
