import React, {useEffect, useState} from 'react'
import DropdownSimple from "../../../commons/components/dropdowns/DropdownSimple";
import SimpleButton from "../../../commons/components/buttons/SimpleButton";
import AddIcon from "../../../assets/images/icons/addIcon.svg";
import {useDispatch, useSelector} from "react-redux";
import {createGrade, deleteGrade, getGradeInGroup} from "./AdminGradeSlice";
import {toast} from "react-toastify";
import {getGradeInGroupAPI} from "./AdminGradeService";

export default function AdminGrade(){
    const [gradeName,setGradeName] = useState('')
    const gradeStatus = useSelector(state=>state.adminGrade.status)
    const groups = useSelector(state=>state.adminGroup.groups)
    const grades = useSelector(state=>state.adminGrade.grades)
    const [groupSelect, setGroupSelect] = useState(null)
    const [gradeSelect, setGradeSelect] = useState(null)
    const handleChangeGroup = (group)=>{
        setGroupSelect(group)
    }
    const handleChangeGrade = (grade)=>{
        setGradeSelect(grade)
    }
    const dispatch = useDispatch()
    const handleCreateGrade = ()=>{
        console.log(groupSelect)
        dispatch(createGrade({
            name: gradeName,
            groupId: groupSelect.id
        })).unwrap().then(data=>{
            toast.success(`grade ${gradeName} have been created`)
            setGradeName('')
        }).catch(err=>{
            toast.error(err.message)
        })
    }

    const handleDeleteGrade = ()=>{
        dispatch(deleteGrade(gradeSelect.id)).unwrap().then(data=>{
            toast.success(`grade ${gradeSelect.name} have been deleted`)
        }).catch(err=>{
            toast.error(err.message)
        })
    }

    useEffect(()=>{
        if(groups.length){
            setGroupSelect(groups[0])
        }else{
            setGroupSelect(null)
        }
    },[groups])

    useEffect(()=>{
        if(groupSelect){
            dispatch(getGradeInGroup(groupSelect.id))
        }
    }, [groupSelect])

    useEffect(()=>{
        if(grades.length){
            setGradeSelect(grades[0])
        }else{
            setGradeSelect(null)
        }
    },[grades])



    return <div className={'border-b-2 py-3'}>
        <div >
            <span  className={'text-[24px] font-semibold  my-3'}>Create Grade</span>
        </div>
        <div>
            <div className={'flex items-center space-x-5'}>
                <span className={'font-semibold'}>Group:</span> <DropdownSimple selectData={handleChangeGroup} listData={groups}/>
            </div>
        </div>

        <div className={'flex items-center space-x-5'}>
            <span>Grade:</span>
            <input className={'rounded-xl border px-2 py-2'}  value={gradeName} onChange={(e)=>{setGradeName(e.target.value)}} />
            <SimpleButton
                disable={gradeStatus!=='idle'}
                name={'create grade'}
                icon={AddIcon}
                textSize={'text-[14px]'}
                textColor={'text-white'}
                bgColor={'bg-indigo-600'}
                handleClick={handleCreateGrade}
            />
        </div>
        <div>
            <span  className={'text-[24px] font-semibold  my-3'}>Delete Grade</span>
        </div>
        <div>
            <div className={'flex items-center space-x-5'}>
                <span className={'font-semibold'}>Grade:</span>
                <DropdownSimple selectData={handleChangeGrade} listData={grades}/>
                <SimpleButton
                    disable={gradeStatus!=='idle'}
                    name={'Delete grade'}
                    icon={AddIcon}
                    textSize={'text-[14px]'}
                    textColor={'text-white'}
                    bgColor={'bg-indigo-600'}
                    handleClick={handleDeleteGrade}
                />
            </div>
        </div>
    </div>
}
