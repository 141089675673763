import React, {useEffect, useState} from 'react'
import {useDispatch} from "react-redux";
import {getAllSubmission} from "./gradeSlice";
import {getSubString} from "../../commons/services/utilService";

function AssignmentGrade({assignment, submissions}){
    const [grade,setGrade] = useState("-")
    useEffect(()=>{
        if(submissions){
            for(let submission of submissions){
                if(submission.assignment_id== assignment.id){
                    setGrade(submission.grade?submission.grade:"-")
                }
            }
        }
    },[])

    return <>{grade}</>
}

export default function StudentGrade({student, courseId, assignments}){

    const dispatch = useDispatch()

    useEffect(()=>{
        console.log(student)
        dispatch(getAllSubmission({
            studentIds: [student.id],
            courseId: courseId
        }))
    },[])

    return <tr>
        <td className={'border text-[14px] text-4B4DA4'}>{getSubString(64,student.name)}</td>
        {assignments && assignments.map(x=><td className={'border text-[14px] text-center w-[300px]'}>
                <AssignmentGrade assignment={x} submissions={student.submissions} />
            </td>)}
    </tr>
}
