import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {getModules} from "../moduleTab/ModuleSlice";
import ModuleItem from "./ModuleItem";
import {getCoursesInAccount} from "./DashboardStudentSlice";
import { useTranslation } from 'react-i18next';



export default  function CourseItem({account}){
    const {t, i18n} = useTranslation()


    const dispatch = useDispatch()
    const [courses,setCourses]= useState([])
    useEffect(()=>{
           dispatch(getCoursesInAccount(account.id)).unwrap().then(data=>{
               setCourses(data)
           })
    },[account])
    return <div className={'p-5'}>

        <div>
            {courses &&  courses.map((course,idx)=><div>
                {idx <3 && <ModuleItem  course={course} />}
            </div>)}
            {courses && courses.length > 3 && <span className={'text-[14px] '}>... +{courses.length -3} {t('course')}</span>}
        </div>
    </div>
}
