import { configureStore } from '@reduxjs/toolkit';
import loginReducer from '../features/logins/loginSlice';
import adminSchoolReducer from '../features/admin/school/AdminSchoolSlice'
import adminGroupReducer from '../features/admin/group/AdminGroupSlice'
import adminGradeReducer from '../features/admin/grade/AdminGradeSlice'
import adminSubjectReducer from '../features/admin/subject/AdminSubjectSlice'
import courseSchoolReducer from '../features/courses/SchoolSlice'
import courseGroupReducer from '../features/courses/GroupSlice'
import courseGradeReducer from '../features/courses/GradeSlice'
import courseSubjectReducer from '../features/courses/SubjectSlice'
import courseReducer from '../features/courses/courseSlice'
import courseDetailReducer from '../features/courseDetail/CourseDetailSlice'
import sectionReducer from '../features/courseDetail/peopleTab/SectionSlice'
import enrollmentReducer from '../features/courseDetail/peopleTab/EnrollmentSlice'
import assignmentGroupReducer from '../features/courseDetail/assignmentTab/AssignmentGroupSlice'
import assignmentReducer from '../features/courseDetail/assignmentTab/AssignmentSlice'
import assignmentDetailReducer from '../features/assignmentDetail/AssignmentDetailSlice'
import quizReducer from '../features/quizInfo/QuizSlice'
import quizDetailReducer from '../features/quizDetail/QuizDetailSlice'
import moduleReducer from '../features/moduleTab/ModuleSlice'
import moduleAssignmentReducer from '../features/moduleTab/ModuleAssignmentSlice'
import pageReducer from '../features/pageTab/PageSlice'
import GradeReducer from '../features/gradeTab/gradeSlice'
import DashboardReducer from '../features/dashboard/DashboardSlice'
import courseStudentReducer from '../features/courseStudent/CourseStudentSlice'
import dashboardStudentReducer from '../features/dashboardStudent/DashboardStudentSlice'
import courseMegaReducer from '../features/courseMega/CourseStudentSlice'
import pageDetailStudentReducer from '../features/pageDetailStudent/PageDetailStudentSlice'
import quizDetailStudentReducer from "../features/quizDetailStudent/QuizDetailStudentSlice";
import reportStudentReducer from '../features/reportStudent/ReportStudentSlice'
import chatGPTReducer from '../features/chatGPT/ChatGPTSlice'
import licenseReducer from '../features/license/LicenseSlice'
import toolReducer from '../components/toolLMS/toolSlice'
import courseReportStudentReducer from '../features/courseReportStudent/CourseReportStudentSlice'
import SpeedGraderReducer from '../features/SpeedGrade/SpeedGraderSlice'
import AssignmentDetailStudentReducer from '../features/AssignmentDetailStudent/AssignmentDetailStudentSlice'
export const store = configureStore({
  reducer: {
    auth: loginReducer,
    adminSchool:adminSchoolReducer,
    adminGroup:adminGroupReducer,
    adminGrade:adminGradeReducer,
    adminSubject:adminSubjectReducer,
    courseSchool:courseSchoolReducer,
    courseGroup: courseGroupReducer,
    courseGrade:courseGradeReducer,
    courseSubject:courseSubjectReducer,
    courses:courseReducer,
    courseDetail: courseDetailReducer,
    section:sectionReducer,
    enrollment: enrollmentReducer,
    assignmentGroup: assignmentGroupReducer,
    assignment: assignmentReducer,
    assignmentDetail: assignmentDetailReducer,
    quiz: quizReducer,
    quizDetail: quizDetailReducer,
    module: moduleReducer,
    moduleAssignment: moduleAssignmentReducer,
    page:pageReducer,
    grade:GradeReducer,
    dashboard: DashboardReducer,
    courseStudent: courseStudentReducer,
    dashboardStudent:dashboardStudentReducer,
    courseMega: courseMegaReducer,
    pageDetailStudent:pageDetailStudentReducer,
    quizDetailStudent:quizDetailStudentReducer,
    reportStudent: reportStudentReducer,
    chatGPT:chatGPTReducer,
    license: licenseReducer,
    tool:toolReducer,
    courseReportStudent: courseReportStudentReducer,
    speedGrader: SpeedGraderReducer,
    assDetailStd: AssignmentDetailStudentReducer
  },
});
