import React, {useEffect, useState} from 'react'
import { useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Disclosure} from "@headlessui/react";
import {ChevronUpIcon} from "@heroicons/react/solid";
import { createModuleItem, deleteModule, getModules} from "./ModuleSlice";
import {getCourseDetail} from "../courseDetail/CourseDetailSlice";
import GlobalHeaderStudent from "../../commons/components/headers/GlobalHeaderStudent";
import Footer from "../../commons/components/footer/Footer";
import ListModueItemsTab from "./listModueItemsTab";
import {getPageDetail} from "../pageDetailStudent/PageDetailStudentSlice";
import {getQuizById} from "../quizDetailStudent/QuizDetailStudentSlice";
import {getPageSessionTokenAPI} from "../../services/authService";
import {getAssignmentDetail} from "../assignmentDetail/AssignmentDetailSlice";
import SpeedGradeIcon from "../../assets/images/icons/speed-grader.svg";
import TakingAssignment from "../../components/TakingAssignment";
import SpeedGradeStudent from "../SpeedGradeStudent";
import {getAssSubmissionStudent} from "../AssignmentDetailStudent/AssignmentDetailStudentSlice";
import closeBtn from '../../assets/images/icons/close.svg'
import StudentCourseTabBar from "../../components/tabBar/StudentCourseTabBar";
import CourseSubTab from "../../components/tabBar/CourseSubTab";
import { useTranslation } from 'react-i18next';
export default function ModuleTabStudent(){
    const {t,i18n} =useTranslation()
    const params = useParams()
    const dispatch = useDispatch()
    const course = useSelector(state=>state.courseDetail.course)
    const modules = useSelector(state=>state.module.modules)
    const [tabSelect,setTabSelect] = useState('1')
    const [isAddItemModal, setIsAddItemModal] = useState(false)
    const [itemTypeSelect,setItemTypeSelect] = useState(null)
    const [itemSelect,setItemSelect] = useState(null)
    const page = useSelector(state=>state.pageDetailStudent.page)
    const quiz = useSelector(state=> state.quizDetailStudent.quiz)
    const [quizUri, setQuizUri] = useState(null)
    const assignment = useSelector(state=> state.assignmentDetail.assignment)
    const [isTaking, setIsTaking] = useState(false)
    const [isViewSpeedGrade,setIsViewSpeedGrade] = useState(false)
    const [isSubmit,setIsSubmit] = useState(false)
    const submission = useSelector(state=>state.assDetailStd.submission)
    const [submissionSelect, setSubmissionSelect] = useState(null)
    const [showListModule,setShowListModule] = useState(true)
    const [isHove,setIsHove] = useState(false)
    useEffect(()=>{
        dispatch(getCourseDetail(params.courseId))
    },[params.courseId])

    useEffect(()=>{
        dispatch(getModules(params.courseId))
    },[params.courseId])

    const handleDeleteModule= (moduleId)=>{
        dispatch(deleteModule({
            courseId: params.courseId,
            moduleId: moduleId
        }))
    }


    const handleAddItem=(item, moduleId)=>{
        console.log(item, moduleId)
        dispatch(createModuleItem({
            courseId: params.courseId,
            moduleId: moduleId,
            item: item
        })).unwrap().then(data=>{
            setIsAddItemModal(false)
        })
    }

    useEffect(()=>{
        switch (itemTypeSelect){
            case "Page":
                if(itemSelect){
                    dispatch(getPageDetail({
                        pageId: itemSelect,
                        courseId: course.id
                    }))
                }
                break
            case "Quiz":
                if(itemSelect){
                    dispatch(getQuizById({
                        courseId: params.courseId,
                        quizId: itemSelect
                    }))
                }
                break
            case "Assignment":
                if(itemSelect){
                    dispatch(getAssignmentDetail({
                        courseId: params.courseId,
                        assignmentId: itemSelect
                    }))
                }
                break
        }
        if(window.innerWidth< 640){
            setShowListModule(false)
        }

    },[itemSelect,  itemTypeSelect])

    useEffect(()=>{
        if(quiz){
            getPageSessionTokenAPI(quiz.mobile_url).then(pageUrl => {
                console.log(pageUrl.data)
                setQuizUri(pageUrl.data.session_url + "&persist_headless=1")
            }).catch(err => {
                console.log(err)
            })
        }
    },[quiz])

    useEffect(()=>{
        if(!isTaking){
            dispatch(getAssSubmissionStudent({
                courseId: params.courseId,
                assignmentId: params.assignmentId
            }))
        }

    },[assignment, isTaking])

    useEffect(()=>{
        if(submission && assignment){
            if(assignment.allowed_attempts==-1 || submission.submission_history.length < assignment.allowed_attempts){
                setIsSubmit(true)
            }
        }
    },[submission, assignment])

    const handleCancelSubmit=()=>{
        setIsTaking(false)
    }

    return <div className={'h-screen'}>
        <div className={'z-50'}>
            <GlobalHeaderStudent />
        </div>

        <CourseSubTab  course={course}/>
        <StudentCourseTabBar tabSelect={tabSelect} />
        <div className={'z-30 flex flex-col'}>
            {!showListModule && <div  onClick={()=>{setShowListModule(true)}}  onMouseEnter={()=>{setIsHove(true)}} onMouseLeave={()=>{setIsHove(false)}} className={'hover:cursor-pointer absolute flex space-x-5 justify-start  items-center top-1/4 left-0 border   p-2 bg-indigo-100 opacity-60 hover:bg-indigo-300 z-20'}>
                <h3 className={`border-b-302574 font-semibold uppercase text-[18px]  ${isHove?"":"hidden"}`}>{t("module.courseContent")}</h3>
                <ChevronUpIcon
                    className={`${
                        'rotate-90 transform'
                    } h-10 w-10 text-black`}
                />
            </div>}
            <div className={'container mx-auto grid grid-cols-4 relative gap-1 h-full flex-1 overflow-y-scroll scrollbar-hide min-h-[500px] z-10 '}>

                {course && tabSelect==1 && <>
                    <div className={`absolute md:relative md:grid-cols-1 h-full max-h-screen flex-1 overflow-y-scroll scrollbar-hide bg-white  my-2  ${showListModule?"":'hidden'}`}>
                        <div className={'flex justify-between py-3 bg-white'}>
                            <h3 className={`text-302574 underline uppercase font-semibold text-[18px] `}>{t("module.courseContent")}</h3>
                            <button
                                onClick={()=>{setShowListModule(false); setIsHove(false)}}
                            >
                                <img src={closeBtn}/>
                            </button>
                        </div>
                        {modules && modules.map(x=><div className=" w-full  rounded-lg  bg-white mb-2  ">
                            <Disclosure defaultOpen={true}>
                                {({ open }) => (
                                    <div className={'border-b-302574 border-b-2 bg-f6f8fa'}>
                                        <div className={'flex justify-between w-full py-1'}>
                                            <Disclosure.Button className={`${!open?'rounded-lg':'rounded-t-lg'} flex w-full item-center justify-between   py-2 text-left text-sm font-medium   focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75`}>
                                                <div className={'flex px-3 md:px-0 justify-start space-x-3'}>
                                                    <ChevronUpIcon
                                                        className={`${
                                                            open ? 'rotate-180 transform' : ''
                                                        } h-5 w-5 text-black`}
                                                    />
                                                    <span className={'text-[18px] font-semibold text-302574'}>{x.name}</span>
                                                </div>
                                            </Disclosure.Button>

                                        </div>

                                        <Disclosure.Panel className="  text-sm text-302574 px-3 md:px-0 ">
                                            <ListModueItemsTab setItemSelect={setItemSelect} setItemTypeSelect={setItemTypeSelect} itemSelect={itemSelect} module={x} course={course}/>
                                        </Disclosure.Panel>
                                    </div>
                                )}
                            </Disclosure>

                        </div>)}
                    </div>
                    <div className={` h-full overflow-y-scroll flex-1 scrollbar-hide ${showListModule?"col-span-3":"col-span-4"}`}>
                        {itemTypeSelect =='Page' && <>
                            {page && <div className={'pb-5 px-2'}>
                                <h3 className={'uppercase my-2  py-3 px-2 rounded-xl border-2 text-302574 text-[16px] font-semibold '}>{page.title}</h3>
                                <div className={'w-full   '}>
                                    <div  className={'w-full py-5 px-2 rounded-xl '}  dangerouslySetInnerHTML={{__html: page.body}}></div>
                                </div>
                            </div>}
                        </>}
                        {itemTypeSelect =='Quiz' && <>
                            {quiz && quizUri && <div className={'pb-5 px-2'}>
                                <h3 className={'uppercase my-2  py-3 px-2 rounded-xl border-2 text-302574 text-[16px] font-semibold '}>{quiz.title}</h3>
                                <div  className={'w-full py-5 px-2 rounded-xl h-screen '} >
                                    <iframe src={quizUri} className={'w-full h-full border-0'} ></iframe>
                                </div>
                            </div>}
                        </>}
                        {itemTypeSelect=='Assignment' && <>
                            {assignment && <div className={'grid-cols-12 grid w-full'}>
                                <div className={'col-span-12 md:col-span-10 border-r px-2 '}>
                                    <div>
                                        <p className={'text-[24px] font-semibold py-5'}>{assignment.name}</p>

                                        {isViewSpeedGrade == false  && <>
                                            <div dangerouslySetInnerHTML={{__html:assignment.description}} className={'text-[14px] py-5 bg-F0F0F0 px-3 rounded mb-5'}></div>
                                            {!isTaking && <div className={'grid grid-cols-2 gap-2 sm:w-full md:w-1/2'}>
                                                <span className={'text-[14px] font-semibold'}>{t('Grade')}:</span>
                                                <span className={'text-[14px]'}>{assignment.points_possible}</span>
                                                <span className={'text-[14px] font-semibold'}>{t("assignment.timeAttempt")}:</span>
                                                <span className={'text-[14px]'}>{assignment.allowed_attempts==-1?'Không giới hạn':assignment.allowed_attempts}</span>
                                                <span className={'text-[14px] font-semibold'}>{t("assignment.submissionType")}:</span>
                                                <span className={'text-[14px]'}>{assignment.submission_types.join()=='on_paper'?'Mega Marking':assignment.submission_types.join()}</span>

                                            </div>}
                                            {!isTaking &&<div className={'w-full border rounded-2xl mt-5'}>
                                                <table className={'w-full'}>
                                                    <thead className={''}>
                                                    <tr className={'text-left bg-E9EDF8 text-[14px]  '}>
                                                        <th className={'py-3 px-2 rounded-tl-2xl '}>{t("assignTo.dueDate")}</th>
                                                        <th className={'py-3 px-2'}>{t("assignTo.assignTo")}</th>
                                                        <th className={'py-3 px-2'}>{t("assignTo.avaiableDate")}</th>
                                                        <th className={'py-3 px-2 rounded-tr-2xl'}>{t("assignTo.utilDate")}</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {assignment.all_dates?<>
                                                        {assignment.all_dates.map(x=><tr className={''}>
                                                            <td className={'text-[14px] py-3 px-2'}>{x.due_at? `${new Date(x.due_at).toLocaleDateString()}-${new Date(x.due_at).toLocaleTimeString()}`:"-"}</td>
                                                            <td className={'text-[14px] py-3 px-2'}>{x.title? x.title:'-'}</td>
                                                            <td className={'text-[14px] py-3 px-2'}>{x.unlock_at? `${new Date(x.unlock_at).toLocaleDateString()}-${new Date(x.unlock_at).toLocaleTimeString()}`:"-"}</td>
                                                            <td className={'text-[14px] py-3 px-2'}>{x.lock_at? `${new Date(x.lock_at).toLocaleDateString()}-${new Date(x.lock_at).toLocaleTimeString()}`:"-"}</td>
                                                        </tr>)}
                                                    </>:<>

                                                    </>}
                                                    </tbody>
                                                </table>
                                            </div>}
                                            {!isTaking &&<div className={'w-full  mt-5 justify-center flex'}>
                                                {isSubmit ? <button disabled={!isSubmit} onClick={()=>{
                                                    setIsTaking(true)
                                                }
                                                } className={'border rounded text-white text-[14px] bg-indigo-600 px-3 py-2 '}>{t("assingment.Take")}</button>:<span className={'text-[14px] text-red-600'}>{t("assignment.cannotTake")}</span>}
                                            </div>}
                                            {isTaking && <TakingAssignment courseId={params.courseId} assignment={assignment} cancelSubmit={handleCancelSubmit}/>}
                                        </>}
                                        {isViewSpeedGrade && <SpeedGradeStudent submission={submissionSelect} assignmentId={params.assignmentId} courseId={params.courseId}/>}
                                    </div>
                                </div>
                                <div className={'col-span-12 md:col-span-2 px-2'}>
                                    <div className={'flex space-x-3 py-3 text-[14px]'}>
                                        <img  src={SpeedGradeIcon}/>
                                        <span>{t( "assignment.speedGrade")}</span>
                                    </div>
                                    <div>
                                        <span className={'font-semibold text-[14px]'}>{t("assignment.status")}:</span>
                                        <span className={'font-semibold text-[14px] text-red-600'}> {submission && submission.workflow_state? submission.workflow_state:""}</span>

                                    </div>
                                    <div>
                                        <span className={'font-semibold text-[14px]'}>{t('Grade')}:</span>
                                        <span className={'font-semibold text-[14px] text-red-600'}> {submission && submission.entered_score? submission.entered_score:t("assignment.notGrade")}</span>
                                    </div>
                                    <div>
                                        <div>
                                            <span className={'font-semibold text-[14px]'}>{t("speedGrade.listSubmission")}</span>
                                        </div>
                                        {submission && submission.submission_history && submission.submission_history.map(x=><div>
                                            <button onClick={()=>{
                                                setIsViewSpeedGrade(true)
                                                setSubmissionSelect(x)
                                            }} className={'underline text-indigo-600 text-[14px] px-3 py-2 text-left'}>{new Date(x.submitted_at).toLocaleDateString()}- {new Date(x.submitted_at).toLocaleTimeString()}</button>
                                        </div>)}
                                    </div>
                                </div>
                            </div>}
                        </>}

                    </div>

                </>}

            </div>
        </div>

        <Footer/>


    </div>
}
