import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    getCourseSettingAPI,
    getCourseDetailAPI,
    publicCourseAPI,
    createImageUploadUrlAPI,
    uploadFileAPI, updateCourseStateAPI, updateCourseAPI
} from "./CourseDetailService";


const initState = {
    course:null,
    courseSetting:null,
    status:'idle'
}

export const getCourseDetail = createAsyncThunk('courseDetail/getcourseDetail',async(courseId)=>{
    let course = await getCourseDetailAPI(courseId)
    return course.data
})

export const getCourseSetting=createAsyncThunk('courseDetail/getCourseSetting', async(courseId)=>{
    let courseSetting = await getCourseSettingAPI(courseId)
    return courseSetting.data
})

export const publicCourse = createAsyncThunk('courseDetail/publicCourse', async(courseId)=>{
    let course = await publicCourseAPI(courseId)
    return course.data
})

export const updateCourseState = createAsyncThunk('courseDetail/updateCourseState', async(input)=>{
    let course = await updateCourseStateAPI(input.courseId, input.event)
    return input.event
})

export const createUploadUrl = createAsyncThunk(`courseDetail/createUploadUrl`, async(input)=>{
    let url = await createImageUploadUrlAPI(input.courseId, input.name)
    return url.data
})

export const uploadImgFile = createAsyncThunk('courseDetail/uploadFile', async(input)=>{
    let file = await uploadFileAPI(input.params, input.file, input.url)
    return file.data
})

export const updateCourse = createAsyncThunk('courseDetail/updateCourse', async(input)=>{
    let result = await updateCourseAPI(input)
    return result.data
})

export const CourseDetailSlice = createSlice({
    name:'courseDetail',
    initialState: initState,
    reducers:{},
    extraReducers:builder => {
        builder.addCase(getCourseDetail.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(getCourseDetail.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.course = action.payload
        }).addCase(getCourseDetail.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error.message)
        }).addCase(getCourseSetting.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(getCourseSetting.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.courseSetting=action.payload
        }).addCase(getCourseSetting.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error.message)
        }).addCase(publicCourse.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(publicCourse.fulfilled, (state,action)=>{
            state.status = 'idle'
            let _course = {...state.course}
            _course.workflow_state='available'
            state.course = {..._course}
        }).addCase(publicCourse.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error.message)
        }).addCase(updateCourseState.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(updateCourseState.fulfilled, (state,action)=>{
            state.status = 'idle'
            let _course = {...state.course}
            switch (action.payload){
                case 'conclude':
                    _course.workflow_state = 'completed'
                    break
                case 'offer':
                    _course.workflow_state = 'available'
                    break;
                case 'claim':
                    _course.workflow_state = 'claim'
                    break
            }
            state.course = {..._course}
        }).addCase(updateCourseState.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error.message)
        }).addCase(createUploadUrl.pending, (state, action)=>{
            state.status = 'loading'
        }).addCase(createUploadUrl.fulfilled, (state,action)=>{
            state.status = 'idle'
        }).addCase(createUploadUrl.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error.message)
        }).addCase(uploadImgFile.pending, (state,action)=> {
            state.status = 'loading'
        }).addCase(uploadImgFile.fulfilled, (state,action)=>{
            state.status = 'idle'
        }).addCase(uploadImgFile.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error.message)
        }).addCase(updateCourse.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(updateCourse.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error.message)
        }).addCase(updateCourse.fulfilled, (state,action)=>{
            state.status = 'idle'
        })
    }
})

export default CourseDetailSlice.reducer
