import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    createModuleAPI, createModuleItemAPI,
    deleteModuleAPI,
    deleteModuleItemAPI,
    editModuleAPI,
    getModuleItemsAPI,
    getModulesAPI
} from "./ModuleService";
import {courseSlice} from "../courses/courseSlice";

const initState ={
    modules: [],
    status:'idle'
}


export const getModules = createAsyncThunk('module/listmodule', async(courseId)=>{
    let modules = await getModulesAPI(courseId)
    return modules.data
})

export const getModulesItems = createAsyncThunk('module/getModuleItems', async (input)=>{
    let moduleItems = await getModuleItemsAPI(input.courseId, input.moduleId)
    return {
        moduleId: input.moduleId,
        moduleItems: moduleItems.data
    }
})

export const deleteModule = createAsyncThunk('module/delete', async(input)=>{
    await deleteModuleAPI(input.courseId, input.moduleId)
    return input.moduleId
})


export const createModule = createAsyncThunk('module/create', async(input)=>{
    let module =  await  createModuleAPI(input.courseId, input.moduleName)
    return module.data
})
export const editModule = createAsyncThunk('module/edit', async (input)=>{
    let module = await editModuleAPI(input.courseId,  input.moduleName, input.moduleId)
    return module.data
})

export const deleteItem = createAsyncThunk('module/deleteItem', async(input)=>{
    await deleteModuleItemAPI(input.courseId, input.moduleId, input.itemId)
    return {
        moduleId: input.moduleId,
        itemId: input.itemId
    }
})

export const createModuleItem = createAsyncThunk('module/createModuleItem', async(input)=>{
    let item = await createModuleItemAPI(input.courseId, input.moduleId, input.item)
    return {
        moduleId: input.moduleId,
        item: item.data
    }
})

const moduleSlice = createSlice({
    name:'module',
    initialState: initState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getModules.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(getModules.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.modules = action.payload
        }).addCase(getModules.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(getModulesItems.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(getModulesItems.fulfilled, (state,action)=>{
            state.status = 'idle'
            let _modules = [...state.modules]
            for(let i = 0; i< _modules.length; i++){
                if(_modules[i].id == action.payload.moduleId){
                    _modules[i].items = action.payload.moduleItems
                }
            }
            state.modules= [..._modules]
        }).addCase(getModulesItems.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(deleteModule.pending,(state,action)=>{
            state.status = 'pending'
        }).addCase(deleteModule.fulfilled, (state,action)=>{
            state.status = 'idle'
            let _modules = [...state.modules]
            for( let i = 0; i< _modules.length; i++){
                if(_modules[i].id==action.payload){
                    _modules.splice(i,1)
                    break
                }
            }
            state.modules = [..._modules]
        }).addCase(deleteModule.rejected, (state,action)=>{
            state.status= 'idle'
            console.log(action.error)
        }).addCase(createModule.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(createModule.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.modules = [...state.modules, action.payload]
        }).addCase(createModule.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(editModule.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(editModule.fulfilled, (state,action)=>{
            let _modules = [...state.modules]
            for(let i =0; i< _modules.length; i++){
                if(_modules[i].id == action.payload.id){
                    _modules[i].name = action.payload.name
                }
            }
            state.modules = [..._modules]
            state.status = 'idle'
        }).addCase(editModule.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(deleteItem.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(deleteItem.fulfilled, (state,action)=>{
            let _modules = [...state.modules]
            for(let i = 0; i< _modules.length; i++){
                if(_modules[i].id == action.payload.moduleId && _modules[i].items && _modules[i].items.length > 0){
                    for( let j = 0; j< _modules[i].items.length; j++){
                        if(_modules[i].items[j].id == action.payload.itemId){
                            _modules[i].items.splice(j, 1)
                            break
                        }
                    }
                }
            }
            state.modules =[..._modules]
            state.status = 'idle'
        }).addCase(deleteItem.rejected, (state, action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(createModuleItem.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(createModuleItem.fulfilled, (state,action)=>{
            state.status = 'idle'
            let _modules = [...state.modules]
            for(let i =0; i< _modules.length; i++){
                if(_modules[i].id == action.payload.moduleId){
                 if(_modules[i].items){
                     _modules[i].items.push(action.payload.item)
                 }else{
                     _modules[i].items= [action.payload.item]
                 }
                }
            }
            state.modules = [..._modules]
        }).addCase(createModuleItem.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        })
    }
})

export default moduleSlice.reducer
