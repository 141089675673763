import {axiosPrivate} from "../../../commons/services/axiosService";


export const createSchoolAPI=(schoolName)=>{
    return axiosPrivate({
        url:'/school/create',
        method:'POST',
        data:{
            name:schoolName
        },
        withCredentials:true,
        headers: {
            'Content-Type': 'application/json'
        },
    })

}


export const getAllSchoolAPI = ()=>{
    return axiosPrivate({
        url:'/school',
        method:"GET",
        withCredentials:true,
        headers:{
            'Content-Type': 'application/json'
        }
    })
}

export const deleteSchoolAPI = (schoolId)=>{
    return axiosPrivate({
        url:`/school/${schoolId}`,
        method:"DELETE"
    })
}
