import Loadable from 'react-loadable'
function Loading(){
    return <div>

    </div>
}
export default Loadable({
    // The import below tells webpack to
    // separate this code into another bundle
    loader: ()=>import('./AssignmentDetailStudent'),
    loading: Loading
})
