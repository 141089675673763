import React, {useRef} from 'react'
import CalendarIcon from '../../../assets/images/icons/calendarIcon.svg'
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";

export default function DatePickerComponent({handleSelect, dateSelect}){
    const refComp = useRef(null);
    const handle_click = ()=>{
        refComp.current.flatpickr.open()
    }
    return <div className={'flex bg-F0F0F0 px-2 py-3 rounded-xl space-x-2'}>
        <img src={CalendarIcon} className={'hover:cursor-pointer'} onClick={handle_click}/>
        <Flatpickr
            ref={refComp}
            className={'bg-F0F0F0 w-full text-[14px] focus:outline-none'}
            enableTime={false}
            value={dateSelect}
            onChange={([date]) => {
                handleSelect(date)
            }}
        />
    </div>
}
