import React, {useEffect} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import DropdownSimple2 from "../../commons/components/dropdowns/DropdownSimple2";
import {useDispatch, useSelector} from "react-redux";
import {getAllAssignment} from "./ModuleAssignmentSlice";
import {toast} from "react-toastify";
import {getAllListPage} from "../pageTab/PageSlice";
import { useTranslation } from 'react-i18next';


export default function AddItemDialog({isOpen, closeModal,courseId,module, addItemToModule}) {
    const {t,i18n} = useTranslation()
    const [listType, setListType] = useState([
        {name: 'Bài tập', value :"assignment"},
        {name:"học liệu", value:"page"}
    ])



    const assignments = useSelector(state=>state.moduleAssignment.assignments)
    const pages = useSelector(state=> state.page.pages)
    const [typeSelect, setTypeSelect] = useState( {name: t("modules.asigntment"), value :"assignment"})
    const [assignmentSelect, setAssignmentSelect] = useState(null)
    const [pageSelect, setPageSelect] = useState(null)
    const dispatch = useDispatch()
    const [listAss, setListAss] = useState([])
    const [listPage, setListPage] = useState([])
    useEffect(()=>{
        if(typeSelect.value=='assignment'){
            dispatch(getAllAssignment(courseId))
        }
        else if(typeSelect.value='page'){
            dispatch(getAllListPage(courseId))
        }
    }, [typeSelect])

    useEffect(()=>{
        let list = []
        for(let ass of assignments){
            list.push({
                name: ass.name,
                value: ass
            })
        }
        setListAss(list)
    },[assignments])

    useEffect(()=>{
        let list = []
        for(let page of pages){
            list.push({
                name: page.title,
                value: page
            })
        }
        setListPage(list)
    },[pages])




    const handleCreateItem=()=>{
        if(typeSelect.value=='assignment'){
            if(assignmentSelect== null){
                toast.error(t( "modules.notSelectAsssignmentError"))
            }else{
                let item = {
                    title: assignmentSelect.value.name,
                    type:'Assignment',
                    content_id: assignmentSelect.value.id,
                    completion_requirement: {
                        type:'must_submit'
                    }
                }
                addItemToModule(item, module.id)
            }
        }else{
            if(pageSelect== null){
                toast.error(t("modules.pageNotSelectError"))
            }else{
                let item = {
                    title: pageSelect.value.title,
                    type:'Page',
                    page_url: pageSelect.value.url,
                    completion_requirement: {
                        type:'must_view'
                    }
                }
                addItemToModule(item, module.id)
            }
        }
    }

    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform  rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg  leading-6 text-black text-center font-semibold "
                                    >
                                        {t("modules.createItemTitle")}
                                    </Dialog.Title>
                                    <div className={'mt-2'}>
                                        <DropdownSimple2  listData={listType} selectData={setTypeSelect} background={'bg-F0F0F0'} />
                                    </div>
                                    {typeSelect.value == 'assignment' &&  <div className={'mt-2'}>
                                        <DropdownSimple2  listData={listAss} selectData={setAssignmentSelect} background={'bg-F0F0F0'} />
                                    </div>}
                                    {typeSelect.value == 'page' &&  <div className={'mt-2'}>
                                        <DropdownSimple2  listData={listPage} selectData={setPageSelect} background={'bg-F0F0F0'} />
                                    </div>}


                                    <div className="mt-4">
                                        <button
                                            type="button"
                                            className=" inline-flex justify-center rounded border border-transparent bg-6149CD w-full text-white  px-4 py-2 text-sm font-medium hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={()=>{handleCreateItem()}}

                                        >
                                            {t("modules.createModuletbtn")}
                                        </button>
                                        <button
                                            type="button"
                                            className=" inline-flex mt-3 justify-center rounded border border-transparent  w-full text-black  px-4 py-2 text-sm font-medium hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={closeModal}
                                        >
                                            {t("cancelBtn")}
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
