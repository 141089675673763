import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getEnrollInfoAPI, getPlannerAPI} from "./CourseStudentService";

const initState = {
    enrollInfo:null,
    status: 'idle',
    planners:[]
}

export const getEnrollInfo = createAsyncThunk(`courseStudent/enrollInfo`, async courseId=>{
    let enroll = await getEnrollInfoAPI(courseId)
    return enroll.data
})

export const getPlannerByDate=createAsyncThunk('courseStudent/plannerByDate', async({startDate,endDate})=>{
    let planners = await getPlannerAPI(startDate,endDate)
    return planners.data
})

const CourseStudentSlice = createSlice({
    name: 'courseStudent',
    initialState: initState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getEnrollInfo.pending, (state, action)=>{
            state.status = 'pending'
        }).addCase(getEnrollInfo.rejected,(state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(getEnrollInfo.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.enrollInfo = action.payload
        }).addCase(getPlannerByDate.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(getPlannerByDate.rejected,(state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(getPlannerByDate.fulfilled,(state,action)=>{
            state.status = 'idle'
            state.planners = action.payload
        })
    }
})

export default CourseStudentSlice.reducer
