import React, {useEffect} from 'react'
import GlobalHeaderStudent from "../../commons/components/headers/GlobalHeaderStudent";
import ArrowDowIcon from "../../assets/images/icons/arrowdow.svg";
import {useDispatch, useSelector} from "react-redux";
import {createVnpayUrl, getAllLicense} from "./LicenseSlice";
import CurrencyFormat from 'react-currency-format';
import Footer from "../../commons/components/footer/Footer";
import { useTranslation } from 'react-i18next';

export default function LicensePage(){

    const dispatch = useDispatch()
    const {t, i18n} = useTranslation()
    const licenses = useSelector(state=> state.license.licenses)
    useEffect(()=>{
        dispatch(getAllLicense())
    },[])

    const handleGetPaymentUrl=(license)=>{
        dispatch(createVnpayUrl(license.id)).unwrap().then(url=>{
            window.open(url, "_self")
        })
    }

    return <>
        <GlobalHeaderStudent />
        <div className={'border-b-2'}>
            <div className={'container mx-auto'}>
                <div className={'py-3 flex space-x-3 items-center'}>
                    <span onClick={()=>{window.open('/', '_self')}} className={'text-7A86A1 text-[14px] hover:cursor-pointer'}>{t('home')} </span>
                    <span><img src={ArrowDowIcon} alt={'arrow'}/></span>
                    <span className={'text-7A86A1 text-[14px]'}>{t('license')} </span>
                </div>
            </div>
        </div>
        <div className={'container mx-auto'}>
            <div className={'grid grid-cols-12 gap-5 mx-2 lg:mx-56 my-5'}>
                {licenses.map(license=><div className={'col-span-12 md:col-span-6 border rounded-xl px-3 py-5 shadow bg-F0F0F0'} key={license.id}>
                    <h3 className={'w-full text-center font-bold text-[24px]'}>{license.name}</h3>
                    <div className={'text-[14px] font-normal text-gray-400 px-5 py-10'}>
                        <ul className={'list-disc '}>
                            <li className={'leading-[50px]'}>{t('lisence.Desc1')}</li>
                            <li className={' leading-[50px]'}>{t('lisence.Desc2')}</li>
                            <li  className={' leading-[50px]'}>{t('lisence.Desc3')}</li>
                            <li  className={' leading-[50px]'}>{t('lisence.Price')}: <span className={'text-red-600 font-medium'}><CurrencyFormat value={license.price} displayType={'text'} thousandSeparator={true} /> VND</span> / {license.month} {t('month')}</li>
                        </ul>
                    </div>
                    <div className={'flex justify-center'}>

                        <button
                            onClick={()=>{handleGetPaymentUrl(license)}}
                            className={'text-white bg-6149CD rounded-lg px-3 py-2 text-[18px] font-bold'}>
                            {t('lisence.PayBtn')}
                        </button>
                    </div>
                </div>)}

            </div>
        </div>
        <Footer/>
    </>
}
