import React from 'react'
import homeIcon from '../../../assets/images/icons/dashboardIcon2.svg'
import courseIcon from '../../../assets/images/icons/courseIcon2.svg'
import gradeIcon from '../../../assets/images/icons/gradeIcon.svg'
import scheduleIcon from '../../../assets/images/icons/ScheduleIcon.svg'
import projectIcon from '../../../assets/images/icons/projectIcon.svg'
import moveLeftIcon from '../../../assets/images/icons/move-left.svg'
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {logOut} from "../../../features/logins/loginSlice";
import userManager from "../../../config/configSSO";
import { useTranslation } from 'react-i18next'
export default function SideBar({userInfo}){
    const navigate = useNavigate()
    const {t, i18n} = useTranslation()

    const dispatch = useDispatch()
    const handleLogout= ()=>{
        dispatch(logOut()).unwrap().then(res=>{
            userManager.getUser().then(user=>{
                if(user){
                    userManager.signoutRedirect();
                }
               else{
                    window.open('/login','_self')
                }
            }).catch(err=>{
                window.open('/login','_self')
            })

        })
    }
    return <ul className="flex flex-col list-none lg:flex-row lg:space-x-8 lg:mt-0 lg:text-sm lg:font-medium w-full ">
        <li className={'  mx-1  mb-5 w-2/3  rounded-3xl '}>
            <a href="/"
               className={' '}
               aria-current="page">
                            <span className={'flex items-center text-black font-semibold hover:text-fontcolor1'}>
                                <div className={' p-3  rounded-full mx-3 my-1'}>
                                    <img alt={'home'} src={homeIcon} className={''} />
                                </div>
                                {t('home')}
                            </span>
            </a>
        </li>
        <li className={'mx-1  mb-5 w-2/3  rounded-3xl '}>
            <a href="/courses"
               className={' '}
               aria-current="page">
                            <span className={'flex text-black items-center font-semibold hover:text-fontcolor1'}>
                                <div className={' p-3  rounded-full mx-3 my-1'}>
                                    <img alt={'course'} src={projectIcon} className={''} />
                                </div>
                                {t('course')}
                            </span>
            </a>
        </li>
        <li className={'  mx-1  mb-5 w-2/3  rounded-3xl '}>
            <a href="/report"
               className={' '}
               aria-current="page">
                            <span className={'flex text-black items-center  font-semibold hover:text-fontcolor1'}>
                                <div className={' p-3  rounded-full mx-3 my-1'}>
                                    <img alt={'calendar'} src={courseIcon} className={''} />
                                </div>
                                {t('Báo cáo')}
                            </span>
            </a>
        </li>
        <li className={'  mx-5  mb-5 w-2/3  rounded-3xl '}>
            <button
                onClick={handleLogout}
               className={' '}
               aria-current="page">
                            <span className={'flex text-black items-center font-semibold hover:text-fontcolor1'}>
                                <div className={' p-3  rounded-full mx-3 my-1'}>
                                    <img alt={'logout'} src={moveLeftIcon} className={''} />
                                </div>
                               {t('logout')}
                            </span>
            </button>
        </li>

    </ul>
}
