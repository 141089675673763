import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getCoursePageDetailAPI} from "../../services/pageService";

const initState={
    status:'idle',
    page: null
}

export const getPageDetail= createAsyncThunk('pageDetailStudent/getDetail',async ({courseId, pageId})=>{
    let page = await getCoursePageDetailAPI(courseId, pageId)
    return page.data
})

const pageDetailStudent= createSlice({
    name:'pageDetailStudent',
    initialState: initState,
    reducers:{},
    extraReducers: builder => {
        builder.addCase(getPageDetail.pending, (state,action)=>{
            state.status ='pending'
        }).addCase(getPageDetail.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(getPageDetail.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.page = action.payload
        })
    }
})

export default pageDetailStudent.reducer
