import React, { useTransition } from 'react'
import ArrowDowIcon from "../../assets/images/icons/arrowdow.svg";
import { useTranslation } from 'react-i18next';

export default function CourseSubTab({course}){
    const {t,  i18n} = useTranslation()
    return <div className={'border-b-2'}>
        <div className={'container mx-auto'}>
            <div className={'py-3 flex space-x-3 items-center'}>
                <span onClick={()=>{window.open('/', '_self')}} className={'text-7A86A1 text-[14px] hover:cursor-pointer'}>{t('home')} </span>
                <span><img src={ArrowDowIcon} alt={'arrow'}/></span>
                <span onClick={()=>{window.open('/courses', '_self')}} className={'text-7A86A1 text-[14px] hover:cursor-pointer'}>{t('course')}</span>
                <span><img src={ArrowDowIcon} alt={'arrow'}/></span>
                <span className={'text-7A86A1 text-[14px]'}>{course?course.name:''}</span>
            </div>
        </div>
    </div>
}
