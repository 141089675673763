import React, {useEffect, useState} from 'react'
import clockIcon from '../../assets/images/icons/clock.svg'
import {useDispatch} from "react-redux";
import {getEnrollInfo} from "./CourseStudentSlice";
import {getCourseActionNumber} from "../dashboardStudent/DashboardStudentSlice";
import SimpleModal from "../../commons/modals/SimpleModal";
import {enrollToMegaCourse} from "./CourseMegaSlice";
import {useNavigate} from "react-router-dom";
import { useTranslation } from 'react-i18next';
export default function CourseInfo({course}) {
    const {t, i18n} = useTranslation()
    const navigate = useNavigate()
    const randomColor=(process)=> {
        if(process< 100){
            return '#FF7439'
        }else{
            return "#09BD3C"
        }
    }
    const [isConfirm, setIsConfirm] = useState(false)
    const [enrollInfo, setEnrollInfo] = useState()
    const [progress, setProgress] = useState()
    const [process, setProcess] = useState(0)
    const [actionNumber, setActionNumber] = useState(0)
    const dispatch = useDispatch()


    useEffect(()=>{
        if(course && course.course_progress){setProgress(course.course_progress)}
    },[course])
    useEffect(()=>{
        if(course){
            dispatch(getEnrollInfo(course.id)).unwrap().then(enrollInfo=>{
                if(enrollInfo.length){
                    for(let enroll of enrollInfo){
                        if(enroll.role=='StudentEnrollment'){
                            setEnrollInfo(enroll)
                            break
                        }
                    }
                }

            })
        }

    },[course])



    useEffect(()=>{
        dispatch(getCourseActionNumber(course.id)).unwrap().then(actionNumber=>{
            setActionNumber(actionNumber)
        })
    },[ course])

    const handleCloseConfirm = ()=>{
        setIsConfirm(false)
    }

    const handleEnroll= ()=>{
        dispatch(enrollToMegaCourse(course.id)).unwrap().then(enroll=>{
            console.log(enroll)
            setIsConfirm(false)
            navigate(`/courses/${course.id}`)
        })
    }

    return <div className={'w-full'}>
        <div>
            <div >
                <div className={'flex pt-2 md:pt-0 md:hidden text-7A86A1'}>
                    <img src={clockIcon} />
                    {enrollInfo && enrollInfo.last_activity_at && <span>{t("course.lastAccess")} {new Date(enrollInfo.last_activity_at).toLocaleDateString()} {new Date(enrollInfo.last_activity_at).toLocaleTimeString()}</span>}
                </div>
                <div className="w-full bg-white rounded-full h-2.5 mt-4 ">
                    <div  className="h-2.5 rounded-full" style={{width:`${100}%`, background: randomColor(0)}}></div>
                </div>

                <div className={'flex flex-col md:flex-row justify-between text-[14px] py-2 text-7A86A1'}>
                    <div className={'hidden md:flex'}>
                        <img src={clockIcon} />
                        {enrollInfo && enrollInfo.last_activity_at && <span>{t("course.lastAccess")} {new Date(enrollInfo.last_activity_at).toLocaleDateString()} {new Date(enrollInfo.last_activity_at).toLocaleTimeString()}</span>}
                    </div>
                    <div className={'flex space-x-2'}>
                        <span>{actionNumber} {t("dashboar.moduleAction")}</span>
                        <span></span>
                    </div>
                </div>
                {enrollInfo ? <div className={'w-full flex justify-start md:justify-end'}>
                  <button
                      onClick={()=>{navigate(`/courses/${course.id}`)}}
                      className={'rounded-xl bg-6149CD text-white py-2 px-3 tr-font-semibold '}>{t("course.accesstoCourse")}</button>
                </div>:<div className={'w-full flex justify-end'}><button
                    onClick={()=>{
                        setIsConfirm(true)
                        }
                    }
                    className={'rounded-xl bg-009A17 text-white py-2 px-3 tr-font-semibold '}>{t("course.register")}</button></div>}
            </div>
        </div>
        <SimpleModal  isOpen={isConfirm} title={'Xác nhận tham gia'} closeModal={handleCloseConfirm}>
            <div className={'py-5 w-full align-center'}>
                <span className={'text-[18px] '}>{t("course.registermessage1")} {course.name} {t("course.registermessage2")}?</span>
            </div>
            <div className={'flex justify-center  space-x-3'}>
                <button
                    onClick={handleEnroll}
                    className={'bg-6149CD tr-font-semibold text-white  py-2 px-3 rounded-xl  min-w-[70px]'}>{t("yesBtn")}</button>
                <button onClick={handleCloseConfirm} className={'bg-009A17 tr-font-semibold text-white  py-2 px-3 rounded-xl  min-w-[70px]'}>{t("noBtn")}</button>
            </div>
        </SimpleModal>
    </div>
}
