import React, {useEffect} from 'react'
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {logOut} from "../logins/loginSlice";

export default function MgeLogOut(){
    const dispatch = useDispatch()
    const navigate = useNavigate()
    useEffect(()=>{
        dispatch(logOut()).unwrap().then(res=>{
            navigate('/login')
        })
    })
    return <div>

    </div>
}
