import React, {useEffect} from 'react'
import defaultCard from '../../assets/images/logos/logo2.svg'
import {useNavigate} from "react-router-dom";
export default function CourseCard({course}){
    const navigate = useNavigate()
    const handleClick=(courseId)=>{
        navigate(`/courses/${courseId}`)
    }
    return <div onClick={()=>{handleClick(course.id)}} className="max-w-sm rounded-2xl overflow-hidden shadow-lg hover:cursor-pointer" key={course.id} >
        <img className="w-full h-[176px]" src={course.image_download_url?course.image_download_url:defaultCard} alt="course image"/>
            <div className="px-6 py-4">
                <div className="font-semibold text-[14px] mb-2">{course.name}</div>
            </div>
    </div>
}
