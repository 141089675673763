import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {createSectionAPI, deleteSectionAPI, listSectionInCourseAPI, updateSectionAPI} from "./SectionService";
import {courseSlice} from "../../courses/courseSlice";


const initState = {
    sections:[],
    status: 'idle'
}


export const listSectionInCourse = createAsyncThunk('section/listSection', async(courseId)=>{
    let sections = await listSectionInCourseAPI(courseId)
    return sections.data
})

export const updateSection = createAsyncThunk('section/update', async(input)=>{
    let section = await updateSectionAPI(input.sectionId,input.name)
    return section.data
})

export const createSection = createAsyncThunk('section/create', async(input)=>{
    let section = await createSectionAPI(input.courseId, input.name)
    return section.data
})

export const deleteSection = createAsyncThunk('section/delete', async(sectionId)=>{
    let result= await deleteSectionAPI(sectionId)
    return sectionId
})

const sectionSlice = createSlice({
    name: 'section',
    initialState:initState,
    reducers:{},
    extraReducers: builder => {
        builder.addCase(listSectionInCourse.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(listSectionInCourse.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.sections = action.payload
        }).addCase(listSectionInCourse.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(updateSection.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(updateSection.fulfilled, (state,action)=>{
            state.status = 'idle'
            let _sections = [...state.sections]
            for(let i=0; i< _sections.length; i++){
                if(_sections[i].id == action.payload.id){
                    _sections[i].name = action.payload.name
                    break
                }
            }
            state.sections = [..._sections]
        }).addCase(updateSection.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(createSection.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(createSection.fulfilled,(state,action)=>{
            state.status = 'idle'
            state.sections = [...state.sections, action.payload]
        }).addCase(createSection.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(deleteSection.pending, (state, action)=>{
            state.status = 'loading'
        }).addCase(deleteSection.fulfilled, (state,action)=>{
            state.status = 'idle'
            let _sections = [...state.sections]
            for(let i =0; i< _sections.length; i++){
                if(_sections[i].id == action.payload){
                    _sections.splice(i, 1)
                    break
                }
            }
            state.sections = [..._sections]
        }).addCase(deleteSection.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        })
    }
})

export default sectionSlice.reducer
