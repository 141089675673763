import {axiosPrivate} from "./axiosService";

export const getGroupBySchoolIdAPI = (schoolId)=>{
    return axiosPrivate({
        url:`/group/groupbyschool/${schoolId}`,
        method:"GET",
        withCredentials:true,
        headers:{
            'Content-Type':'application/json'
        }
    })
}
