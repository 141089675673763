import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {createSubjectAPI, deleteSubjectAPI} from "./adminSubjectService";
import {getSubjectInGradeAPI} from "../../../commons/services/subjectService";

const initState= {
    subjects:[],
    status:'idle'
}

export const createSubject = createAsyncThunk('adminSubject/create',async(input)=>{
    let subject =  await createSubjectAPI(input.name, input.gradeId)
    return subject.data
})
export const getSubjectInGrade = createAsyncThunk('adminSubject/getSubjectInGrade', async(gradeId)=>{
    let subjects = await getSubjectInGradeAPI(gradeId)
    return subjects.data
})

export const deleteSubject = createAsyncThunk('adminSubject/delete', async(subjectId)=>{
    await deleteSubjectAPI(subjectId)
    return subjectId
})

export const adminSubject = createSlice({
    name:'adminSubject',
    initialState: initState,
    reducers:{},
    extraReducers: builder => {
        builder.addCase(createSubject.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(createSubject.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.subjects = [...state.subjects, action.payload]
        }).addCase(createSubject.rejected, (state, action)=>{
            state.status = 'idle'
            console.log(action.error.message)
        }).addCase(getSubjectInGrade.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(getSubjectInGrade.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.subjects =[...action.payload]
        }).addCase(getSubjectInGrade.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error.message)
        }).addCase(deleteSubject.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(deleteSubject.fulfilled, (state,action)=>{
            state.status = 'idle'
            let _subjects = [...state.subjects]
            for(let i =0; i< _subjects.length; i++){
                if(_subjects[i].id === action.payload){
                    _subjects.splice(i, 1)
                    break
                }
            }
            state.subjects = [..._subjects]
        }).addCase(deleteSubject.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error.message)
        })
    }
})

export default adminSubject.reducer
