import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getEnrollInfoByCourseAPI} from "../../services/enrollService";
import {getCourseReportSummaryAPI} from "../../services/courseService";

const initState = {
    status: 'idle',
    enrollInfo: null,
    courseSummary: null
}

export const getEnrollInfo = createAsyncThunk('courseReportStd/enrollInfo', async(courseId)=>{
    let enroll = await getEnrollInfoByCourseAPI(courseId)
    return enroll.data
})

export const getCourseReportSummary = createAsyncThunk('courseReportStd/reportSummary', async(courseId)=>{
    let summary = await getCourseReportSummaryAPI(courseId)
    return summary.data
})

const CourseReportStudentSlice = createSlice({
    name:'courseReportStudent',
    initialState: initState,
    reducers:{},
    extraReducers:builder => {
        builder.addCase(getEnrollInfo.pending,(state,action)=>{
            state.status = 'pending'
        }).addCase(getEnrollInfo.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(getEnrollInfo.fulfilled, (state,action)=>{
            state.status = 'idle'
            if(action.payload && action.payload.length >0){
                state.enrollInfo= action.payload[0]
            }
        }).addCase(getCourseReportSummary.pending,(state,action)=>{
            state.status = 'pending'
        }).addCase(getCourseReportSummary.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(getCourseReportSummary.fulfilled, (state,action)=>{
            state.courseSummary=action.payload
        })
    }
})

export default CourseReportStudentSlice.reducer
