import React, {useEffect, useState} from 'react'
import {useDispatch} from "react-redux";
import AssignmentItem from "./AssignmentItem";

export default function AssignmentItems({assignments, courseId}){
    const dispatch = useDispatch()

    return <div >
        {assignments && assignments.map(x=><AssignmentItem assignment={x} courseId={courseId}/>)    }
    </div>

}


