import React, {useState,useEffect} from 'react'
import GlobalHeaderStudent from "../../commons/components/headers/GlobalHeaderStudent";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getCourseDetail} from "../courseDetail/CourseDetailSlice";
import StudentCourseTabBar from "../../components/tabBar/StudentCourseTabBar";
import CourseSubTab from "../../components/tabBar/CourseSubTab";

export default function CourseDetailStudent(){
    const params = useParams()
    const dispatch = useDispatch()
    const course = useSelector(state=>state.courseDetail.course)
    const [tabSelect,setTabSelect] = useState('1')
    const navigate = useNavigate()
    useEffect(()=>{
        dispatch(getCourseDetail(params.courseId))
    },[params.courseId])
    useEffect(()=>{
        navigate(`/courses/${params.courseId}/modules`)
    })
    return <div className={'w-screen bg-white min-h-screen'}>
        <GlobalHeaderStudent page={'course'} />
        <CourseSubTab course={course} />
        <StudentCourseTabBar tabSelect={tabSelect} />
    </div>
}
