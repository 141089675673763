import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getCourseDetail} from "../courseDetail/CourseDetailSlice";
import GlobalHeaderStudent from "../../commons/components/headers/GlobalHeaderStudent";
import Footer from "../../commons/components/footer/Footer";
import {getAssignmentGroups, getAssignmentInGroup} from "../courseDetail/assignmentTab/AssignmentGroupSlice";
import closeBtn from "../../assets/images/icons/close.svg";
import AssignmentItemViewWithList from "../courseDetail/assignmentTab/AssignmentItemViewWithList";
import {getQuizById} from "../quizDetailStudent/QuizDetailStudentSlice";
import {getAssignmentDetail} from "../assignmentDetail/AssignmentDetailSlice";
import TakingAssignment from "../../components/TakingAssignment";
import SpeedGradeStudent from "../SpeedGradeStudent";
import SpeedGradeIcon from "../../assets/images/icons/speed-grader.svg";
import {getPageSessionTokenAPI} from "../../services/authService";
import {getAssSubmissionStudent} from "../AssignmentDetailStudent/AssignmentDetailStudentSlice";
import {ChevronUpIcon} from "@heroicons/react/solid";
import {Disclosure} from "@headlessui/react";
import StudentCourseTabBar from "../../components/tabBar/StudentCourseTabBar";
import CourseSubTab from "../../components/tabBar/CourseSubTab";
import closeIcon from "../../assets/images/icons/close.svg";
import SimpleModal from "../../commons/modals/SimpleModal";
export default function AssignmentStudent(){
    const params = useParams()
    const dispatch = useDispatch()
    const assignmentGroups = useSelector(state=>state.assignmentGroup.groups)
    const course = useSelector(state=>state.courseDetail.course)
    const [tabSelect,setTabSelect] = useState('3')
    const navigator = useNavigate()
    const [showListModule,setShowListModule] = useState(true)
    const [isHove,setIsHove] = useState(false)
    const [itemTypeSelect,setItemTypeSelect] = useState(null)
    const [itemSelect,setItemSelect] = useState(null)
    const quiz = useSelector(state=> state.quizDetailStudent.quiz)
    const [quizUri, setQuizUri] = useState(null)
    const assignment = useSelector(state=> state.assignmentDetail.assignment)
    const [isTaking, setIsTaking] = useState(false)
    const [isViewSpeedGrade,setIsViewSpeedGrade] = useState(false)
    const [isSubmit,setIsSubmit] = useState(false)
    const submission = useSelector(state=>state.assDetailStd.submission)
    const [submissionSelect, setSubmissionSelect] = useState(null)
    const [noLicenseShow, setNolicenseShow] = useState(false)
    useEffect(()=>{
        dispatch(getCourseDetail(params.courseId))
    },[params.courseId])


    useEffect(()=>{
        if(course){
            dispatch(getAssignmentGroups(params.courseId)).unwrap().then(groups=>{
                for(let group of groups){
                    dispatch(getAssignmentInGroup({
                        courseId: params.courseId,
                        groupId: group.id
                    }))
                }
            })
        }
    },[course])


    useEffect(()=>{
        // eslint-disable-next-line default-case
        switch (itemTypeSelect){
            case "Quiz":
                if(itemSelect){
                    dispatch(getQuizById({
                        courseId: params.courseId,
                        quizId: itemSelect
                    }))
                }
                break
            case "Assignment":
                if(itemSelect){
                    dispatch(getAssignmentDetail({
                        courseId: params.courseId,
                        assignmentId: itemSelect
                    }))
                }
                break
        }
        if(window.innerWidth< 640){
            setShowListModule(false)
            setIsHove(false)
        }

    },[itemSelect,  itemTypeSelect])


    useEffect(()=>{
        if(quiz ){
            if(!quiz.block){
                getPageSessionTokenAPI(quiz.mobile_url).then(pageUrl => {
                    console.log(pageUrl.data)
                    setQuizUri(pageUrl.data.session_url + "&persist_headless=1")
                }).catch(err => {
                    console.log(err)
                })
            }else{
                setQuizUri(null)
                setNolicenseShow(true)
            }

        }
    },[quiz])
    useEffect(()=>{
        if(assignment && assignment.block){
            setNolicenseShow(true)
        }
    },[assignment])

    useEffect(()=>{
        if(!isTaking){
            dispatch(getAssSubmissionStudent({
                courseId: params.courseId,
                assignmentId: params.assignmentId
            }))
        }

    },[assignment, isTaking])

    useEffect(()=>{
        if(submission && assignment){
            if(assignment.allowed_attempts==-1 || submission.submission_history.length < assignment.allowed_attempts){
                setIsSubmit(true)
            }
        }
    },[submission, assignment])

    const handleCancelSubmit=()=>{
        setIsTaking(false)
    }

    const handleCloseNoLicensePopup=()=>{
        setNolicenseShow(false)
    }
    const moveToLicense=()=>{
        window.open('/license', '_self')
    }

    return <div className={'h-screen'}>
        <div className={'z-50'}>
            <GlobalHeaderStudent />
        </div>
        <CourseSubTab course={course} />
        <StudentCourseTabBar tabSelect={tabSelect} />
        <div className={'z-40 flex flex-col'}>
            {!showListModule && <div  onClick={()=>{setShowListModule(true)}}  onMouseEnter={()=>{setIsHove(true)}} onMouseLeave={()=>{setIsHove(false)}} className={'hover:cursor-pointer absolute flex space-x-5 justify-start  items-center top-1/4 left-0 border   p-2 bg-indigo-100 opacity-60 hover:bg-indigo-300 z-20'}>
                <h3 className={`border-b-302574 font-semibold uppercase text-[18px]  ${isHove?"":"hidden"}`}>Danh sách bài tập</h3>
                <ChevronUpIcon
                    className={`${
                        'rotate-90 transform'
                    } h-10 w-10 text-black`}
                />
            </div>}
            <div className={'container mx-auto  grid grid-cols-4 relative gap-1 h-full flex-1 overflow-y-scroll scrollbar-hide min-h-[500px] z-10'}>
                {/*{course && tabSelect==3 && <AssignmentTab course={course}/>}*/}
                <div className={`absolute  md:relative md:grid-cols-1 h-full flex-1 max-h-screen overflow-y-scroll scrollbar-hide bg-white  my-2  ${showListModule?"":'hidden'}`}>
                    <div className={'flex justify-between py-3 bg-white'}>
                        <h3 className={`text-302574 underline uppercase font-semibold text-[18px] `}>Danh sách bài tập</h3>
                        <button
                            onClick={()=>{setShowListModule(false); setIsHove(false)}}
                        >
                            <img src={closeBtn}/>
                        </button>
                    </div>
                    {assignmentGroups && assignmentGroups.length && assignmentGroups.map(x=><div className=" w-full  rounded-lg  bg-white mb-2  ">
                            <Disclosure defaultOpen={true}>
                                {({ open }) => (
                                    <div className={'border-b-302574 border-b-2 bg-f6f8fa'}>
                                        <div className={'flex justify-between w-full py-1'}>
                                            <Disclosure.Button className={`${!open?'rounded-lg':'rounded-t-lg'} flex w-full item-center justify-between   py-2 text-left text-sm font-medium   focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75`}>
                                                <div className={'flex px-3 md:px-0 justify-start space-x-3'}>
                                                    <ChevronUpIcon
                                                        className={`${
                                                            open ? 'rotate-180 transform' : ''
                                                        } h-5 w-5 text-black`}
                                                    />
                                                    <span className={'text-[18px] font-semibold text-302574'}>{x.name}</span>
                                                </div>
                                            </Disclosure.Button>

                                        </div>

                                        <Disclosure.Panel className="  text-sm text-302574 px-3 md:px-0 ">
                                            {x.assignments && x.assignments.map(assignment=><AssignmentItemViewWithList assignmentGroup={x} assignment={assignment} courseId={course.id} setItemTypeSelect={setItemTypeSelect} setItemSelect={setItemSelect} itemSelect={itemSelect}/>)    }
                                            {/*<ListModueItemsTab setItemSelect={setItemSelect} setItemTypeSelect={setItemTypeSelect} itemSelect={itemSelect} module={x} course={course}/>*/}
                                        </Disclosure.Panel>
                                    </div>
                                )}
                            </Disclosure>

                        </div>

                    )}
                </div>
                <div className={` h-full overflow-y-scroll flex-1 scrollbar-hide ${showListModule?"col-span-3":"col-span-4"}`}>
                    {itemTypeSelect =='Quiz' && <>
                        {quiz && quizUri && <div className={'pb-5 px-2'}>
                            <h3 className={'uppercase my-2  py-3 px-2 rounded-xl border-2 text-302574 text-[16px] font-semibold '}>{quiz.title}</h3>
                            <div  className={'w-full py-5 px-2 rounded-xl h-screen '} >
                                <iframe src={quizUri} className={'w-full h-full border-0'} ></iframe>
                            </div>
                        </div>}
                    </>}
                    {itemTypeSelect=='Assignment' && <>
                        {assignment && !assignment.block && <div className={'grid-cols-12 grid w-full'}>
                            <div className={'col-span-12 md:col-span-10 border-r px-2 '}>
                                <div>
                                    <p className={'text-[24px] font-semibold py-5'}>{assignment.name}</p>

                                    {isViewSpeedGrade == false  && <>
                                        <div dangerouslySetInnerHTML={{__html:assignment.description}} className={'text-[14px] py-5 bg-F0F0F0 px-3 rounded mb-5'}></div>
                                        {!isTaking && <div className={'grid grid-cols-2 gap-2 sm:w-full md:w-1/2'}>
                                            <span className={'text-[14px] font-semibold'}>Điểm:</span>
                                            <span className={'text-[14px]'}>{assignment.points_possible}</span>
                                            <span className={'text-[14px] font-semibold'}>Số lần làm:</span>
                                            <span className={'text-[14px]'}>{assignment.allowed_attempts==-1?'Không giới hạn':assignment.allowed_attempts}</span>
                                            <span className={'text-[14px] font-semibold'}>Dạng nộp:</span>
                                            <span className={'text-[14px]'}>{assignment.submission_types.join()=='on_paper'?'Mega Marking':assignment.submission_types.join()}</span>

                                        </div>}
                                        {!isTaking &&<div className={'w-full border rounded-2xl mt-5'}>
                                            <table className={'w-full'}>
                                                <thead className={''}>
                                                <tr className={'text-left bg-E9EDF8 text-[14px]  '}>
                                                    <th className={'py-3 px-2 rounded-tl-2xl '}>Đến hạn</th>
                                                    <th className={'py-3 px-2'}>Cho</th>
                                                    <th className={'py-3 px-2'}>Có sẵn từ</th>
                                                    <th className={'py-3 px-2 rounded-tr-2xl'}>Cho đến</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {assignment.all_dates?<>
                                                    {assignment.all_dates.map(x=><tr className={''}>
                                                        <td className={'text-[14px] py-3 px-2'}>{x.due_at? `${new Date(x.due_at).toLocaleDateString()}-${new Date(x.due_at).toLocaleTimeString()}`:"-"}</td>
                                                        <td className={'text-[14px] py-3 px-2'}>{x.title? x.title:'-'}</td>
                                                        <td className={'text-[14px] py-3 px-2'}>{x.unlock_at? `${new Date(x.unlock_at).toLocaleDateString()}-${new Date(x.unlock_at).toLocaleTimeString()}`:"-"}</td>
                                                        <td className={'text-[14px] py-3 px-2'}>{x.lock_at? `${new Date(x.lock_at).toLocaleDateString()}-${new Date(x.lock_at).toLocaleTimeString()}`:"-"}</td>
                                                    </tr>)}
                                                </>:<>

                                                </>}
                                                </tbody>
                                            </table>
                                        </div>}
                                        {!isTaking &&<div className={'w-full  mt-5 justify-center flex'}>
                                            {isSubmit ? <button disabled={!isSubmit} onClick={()=>{
                                                setIsTaking(true)
                                            }
                                            } className={'border rounded text-white text-[14px] bg-indigo-600 px-3 py-2 '}>Làm bài</button>:<span className={'text-[14px] text-red-600'}>Bạn không thể làm bài tập này nữa</span>}
                                        </div>}
                                        {isTaking && <TakingAssignment courseId={params.courseId} assignment={assignment} cancelSubmit={handleCancelSubmit}/>}
                                    </>}
                                    {isViewSpeedGrade && <SpeedGradeStudent submission={submissionSelect} assignmentId={params.assignmentId} courseId={params.courseId}/>}
                                </div>
                            </div>
                            <div className={'col-span-12 md:col-span-2 px-2'}>
                                <div className={'flex space-x-3 py-3 text-[14px]'}>
                                    <img  src={SpeedGradeIcon}/>
                                    <span>Chấm điểm</span>
                                </div>
                                <div>
                                    <span className={'font-semibold text-[14px]'}>Trạng thái:</span>
                                    <span className={'font-semibold text-[14px] text-red-600'}> {submission && submission.workflow_state? submission.workflow_state:""}</span>

                                </div>
                                <div>
                                    <span className={'font-semibold text-[14px]'}>Điểm:</span>
                                    <span className={'font-semibold text-[14px] text-red-600'}> {submission && submission.entered_score? submission.entered_score:"Chưa chấm"}</span>
                                </div>
                                <div>
                                    <div>
                                        <span className={'font-semibold text-[14px]'}>Danh sách bài nộp</span>
                                    </div>
                                    {submission && submission.submission_history && submission.submission_history.map(x=><div>
                                        <button onClick={()=>{
                                            setIsViewSpeedGrade(true)
                                            setSubmissionSelect(x)
                                        }} className={'underline text-indigo-600 text-[14px] px-3 py-2 text-left'}>{new Date(x.submitted_at).toLocaleDateString()}- {new Date(x.submitted_at).toLocaleTimeString()}</button>
                                    </div>)}
                                </div>
                            </div>
                        </div>}
                    </>}

                </div>

            </div>
        </div>
        <div className={ ''}>
            <SimpleModal  isOpen={noLicenseShow} closeModal={handleCloseNoLicensePopup}>
                <div>
                    <div className={'flex w-full justify-end'}><button onClick={handleCloseNoLicensePopup}><img  alt={'close'} src={closeIcon}/></button></div>
                    <div className={'py-3'}>{noLicenseShow &&<span className={'text-[18px]'}>Bạn cần trở thành thành viên Premium mới có thể thực hiện tính năng </span>}</div>
                    <div className={'w-full flex justify-center'}>
                        <button  onClick={moveToLicense} className={'py-2 px-3 rounded-lg bg-6149CD text-white'}>Mua gói Premium</button>
                    </div>
                </div>
            </SimpleModal>
        </div>

        <Footer/>


    </div>
}
