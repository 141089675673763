import {axiosPrivate} from "../../../commons/services/axiosService";

export const createAdminGradeAPI =(name, groupId)=>{
    return axiosPrivate({
        url: '/grade/create',
        method: 'POST',
        data: {
            grade: {
                name: name
            },
            groupId: groupId
        },
    })
}


export const deleteGradeAPI = (gradeId)=>{
    return axiosPrivate({
        url:`grade/${gradeId}`,
        method:'DELETE'
    })
}
