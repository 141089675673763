import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {getStudentSummary} from "./ReportStudentSlice";
import GlobalHeaderStudent from "../../commons/components/headers/GlobalHeaderStudent";
import ArrowDowIcon from "../../assets/images/icons/arrowdow.svg";
import Footer from "../../commons/components/footer/Footer";
import clockIcon from '../../assets/images/icons/clock.svg'
import PieChartCompoment from "../dashboard/PieChartCompoment";
import BarChartComponent from "../../components/charts/BarChartComponent";
import {getSubString} from "../../commons/services/utilService";
import { useTranslation } from 'react-i18next';
export default function ReportStudent(){
    const {t, i18n} = useTranslation()
    const randomColor=(process)=> {
        if(process< 100){
            return '#FF7439'
        }else{
            return "#09BD3C"
        }
    }

    const dispatch = useDispatch()
    const user =useSelector(state=>state.auth.user)
    const summary = useSelector(state=> state.reportStudent.summary)
    const requirement_count = useSelector(state=> state.reportStudent.requirement_count)
    const requirement_completed_count = useSelector(state=>state.reportStudent.requirement_completed_count)
    const totalAssignment = useSelector(state=>state.reportStudent.totalAssignment)
    const totalAssignmentSummited = useSelector(state=>state.reportStudent.totalAssignmentSummited)
    const activityTimes=  useSelector(state=> state.reportStudent.activityTimes)
    const [lastActivity, setLastActivity] = useState()
    const [totalActivityTime, setTotalActivityTime] = useState(0)
    const [dataLearningTime,setDataLearningTime] = useState([])
    const [gradeData,setGradeData] = useState([])
    useEffect(()=>{
       dispatch(getStudentSummary())
    },[])
    useEffect(()=>{
        let total =0
        for(let at of activityTimes){
            total += parseInt(at.total_activity_time)
        }
        if(total){
            let hours = (total/3600).toFixed(0)
            let minutes = ((total % 3600)/60).toFixed(0)
            setTotalActivityTime(`${hours}:${minutes}hr`)
        }else{
            setTotalActivityTime('-')
        }
        if(activityTimes.length >5){
            let tmps = [...activityTimes]
            tmps.sort((a,b)=>{
                return parseInt(b.total_activity_time) - parseInt(a.total_activity_time)
            })
            let otherTime = 0
            for(let i=5; i< tmps.length; i++){
                otherTime+= parseInt(tmps[i].total_activity_time)
            }
            setDataLearningTime([{
                name: getSubString(32,tmps[0].name),
                value: parseInt(tmps[0].total_activity_time)
            },
                {
                    name: getSubString(32,tmps[1].name),
                    value: parseInt(tmps[1].total_activity_time)
                },
                {
                    name: getSubString(32,tmps[2].name),
                    value: parseInt(tmps[2].total_activity_time)
                },
                {
                    name: getSubString(32,tmps[3].name),
                    value: parseInt(tmps[3].total_activity_time)
                },
                {
                    name: getSubString(32,tmps[4].name),
                    value: parseInt(tmps[4].total_activity_time)
                },
                {
                    name: 'Khác',
                    value: otherTime
                },
            ])

        }else{
            let lt= []
            activityTimes.map(at=>{
                lt.push({
                    name: at.name.length >16? at.name.substring(0,13)+'...': at.name,
                    value: at.total_activity_time
                })
            })
            setDataLearningTime(lt)

        }
    },[activityTimes])

    useEffect(()=>{

        let data = []
        if(summary){
            let last_active = null
            for(let temp of summary){
                if(temp.status =='fulfilled'){
                    if(last_active == null){
                        last_active = new Date(temp.value.user.enrollments[0].last_activity_at)
                    }else{
                        let tempTime =  new Date(temp.value.user.enrollments[0].last_activity_at)
                        if(last_active.getTime() < tempTime.getTime()){
                            last_active = tempTime
                        }
                    }
                    data.push({
                        name: temp.value.course.name,
                        current_score: temp.value.user.enrollments[0].grades.current_score?temp.value.user.enrollments[0].grades.current_score:0,
                        current_grade : temp.value.user.enrollments[0].grades.current_grade?temp.value.user.enrollments[0].grades.current_grade:0
                    })
                }
            }
            setLastActivity(last_active)

            if(data.length > 5){
                data.sort((a, b)=>{
                    if (b.current_score > a.current_score) return 1
                    else return -1
                })
                let new_data = []
                for(let i =0; i<5; i++){
                    if(data[i].name.length > 16){
                        data[i].name = data[i].name.substring(0, 16)+"..."
                    }
                    new_data.push(data[i])
                }
                let avg_current_score = 0
                let avg_current_grade =0
                let count = 0
                for(let i=5; i< data.length; i++){
                    avg_current_grade+= data.current_grade
                    avg_current_score = data.current_score
                    count+=1
                }
                avg_current_grade = avg_current_grade?(avg_current_grade/count).toFixed(2):0
                avg_current_score = avg_current_score?(avg_current_score/count).toFixed(2):0
                new_data.push({
                    name: 'other',
                    current_score: avg_current_score,
                    avg_current_grade: avg_current_grade
                })

                data= new_data
            }


            setGradeData(data)

        }
    },[summary])



    return <div className={'w-screen bg-white min-h-screen'}>
        <GlobalHeaderStudent page={'report'} />
        <div className={'border-b-2'}>
            <div className={'container mx-auto'}>
                <div className={'py-3 flex space-x-3 items-center'}>
                    <span onClick={()=>{window.open('/', '_self')}} className={'text-7A86A1 text-[14px] hover:cursor-pointer'}>Trang chủ </span>
                    <span><img src={ArrowDowIcon} alt={'arrow'}/></span>
                    <span className={'text-7A86A1 text-[14px]'}>{t("tabBar.report")} </span>
                </div>
            </div>
        </div>
        <div className={'container mx-auto shadow m-5 pb-10 pt-5 px-5'}>
            <div className={'flex flex-col md:flex-row space-x-0 md:space-x-3'}>
                <div className={'border-r-2  px-0 py-3 md:py-0 md:px-5'}>
                    <h3 className={'text-[18px] font-semibold mb-5'}>{t("learningReport")}</h3>
                    {lastActivity &&  <div className={'flex space-x-3 text-[14px] text-7A86A1'}>
                        <img src={clockIcon} />
                        <span>{t("report.lastActivityTime")}{lastActivity.toLocaleString()}</span>
                    </div>}
                    <div>
                        <div className="w-full bg-fef5f3 rounded-full h-2.5 mt-4 ">

                            <div  className="h-2.5 rounded-full" style={{width:`${requirement_completed_count/requirement_count}%`, background: randomColor(requirement_completed_count/requirement_count)}}></div>

                        </div>
                        <div className={'flex justify-between text-[14px] py-2 text-7A86A1'}>

                            <div className={'flex space-x-2'}>
                                <span>{t("course.completionRate")}</span>
                                <span> {requirement_completed_count} / {requirement_count} ({(requirement_completed_count/requirement_count*100).toFixed(2)}%)</span>
                            </div>
                        </div>
                    </div>

                </div>
                <div className={'flex-1 '}>
                    <h3 className={'text-[18px] font-semibold mb-5'}>{t("report.learingStatistic")}</h3>
                    <div className={'flex flex-col md:flex-row justify-between  space-x-0 md:space-x-3'}>
                        <div className={' px-0 md:px-5'}>
                            <h4 className={'text-[14px] text-7A86A1'}>{t("report.assignmentComplete")}</h4>
                            <span className={'text-[24px] text-4B4DA4'}>{totalAssignmentSummited} / {totalAssignment}</span>
                        </div>
                        <div className={' px-0 md:px-5'}>
                            <h4 className={'text-[14px] text-7A86A1'}>{t("report.totalLearningTime")}</h4>
                            <span className={'text-[24px] text-4B4DA4'}>{totalActivityTime}</span>
                        </div>
                        <div className={' px-0 md:px-5'}>
                            <h4 className={'text-[14px] text-7A86A1'}>{t("report.numberCourseEnroll")}</h4>
                            <span className={'text-[24px] text-4B4DA4'}>{activityTimes.length}</span>
                        </div>

                    </div>
                </div>

            </div>
            <div className={'grid grid-cols-12  my-0 md:my-5 pt-10'}>
                <div className={'px-0 md:px-5 col-span-12 md:col-span-6 '}>
                    <h3 className={'text-[18px] font-semibold mb-5'}>{t("report.courseLearningTime")}</h3>
                    <div className={'w-full h-[300px]'}>
                        <PieChartCompoment data={dataLearningTime}/>
                    </div>
                </div>
                <div className={'px-0 md:px-5  col-span-6 md:col-span-6'}>
                    <h3 className={'text-[18px] font-semibold mb-5'}>{t("dashboard.avgGrade")}</h3>
                    <div className={'w-full h-[300px]'}>
                        <BarChartComponent data={gradeData} dataKey1={'current_score'} dataKey2={'current_grade'} />
                    </div>
                </div>
            </div>

        </div>

        <Footer/>
    </div>
}
