import {axiosPrivate} from "../../commons/services/axiosService";

export const createCourseAPI=(name, subjectId)=>{
    return axiosPrivate({
        url: `course/create`,
        method:'POST',
        data:{
            name: name,
            subjectId:subjectId
        }
    })
}


export const getListCourseAPI=()=>{
    return axiosPrivate({
        url:'course/listCourse',
        method:'GET'
    })
}

export const getCourseAvgProgressAPI = (courseId)=>{
    return axiosPrivate({
        url: `course/${courseId}/avgProgress`,
        method:'GET'
    })
}
