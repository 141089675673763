import {axiosPrivate} from "../../commons/services/axiosService";

export const getAllStudentInCourseAPI = (courseId)=>{
    return axiosPrivate({
        method:"GET",
        url:`enrollment/listAllInCourse?courseId=${courseId}`
    })
}

export const getListSubmissionAPI= (courseId, studentIds)=>{
    let url = `submissions/studentSubmission?courseId=${courseId}`
    for(let id of studentIds){
        url+=`&studentIds[]=${id}`
    }

    return axiosPrivate({
        method: 'GET',
        url:url
    })
}
