import React, {useState} from 'react'
import chatIcon from '../../assets/images/icons/chatIcon.jpeg'
import botIcon from '../../assets/images/icons/botIcon.png'
import SimpleModal from "../../commons/modals/SimpleModal";
import {useDispatch, useSelector} from "react-redux";
import {sendMessage} from "./ChatGPTSlice";
import dotIcon from '../../assets/images/icons/3dot.png'
import avatarIcon from '../../assets/images/avatars/account.png'
import Simple2Modal from "../../commons/modals/Simple2Modal";
export default function ChatGPT(){
    const [chatOpen,setChatOpen] = useState(false)
    const [chatInput,setChatInput] = useState("")
    const [chatConversations,setChatConversation] = useState([])
    const chatStatus = useSelector(state=> state.chatGPT.status)

    const dispatch = useDispatch()
    const handleCloseChat = ()=>{
        setChatOpen(false)
    }
    const handleSendChat=()=>{

        setChatConversation(prevState => [...prevState, {
            user:'me',
            message: chatInput
        }])

        dispatch(sendMessage(chatInput)).unwrap().then(result=>{
            setChatConversation(prevState => [...prevState, {
                user:'bot',
                message: result.botMessage
            }])
        })
        setChatInput("")
    }
    return <div  className={'z-50'}>
        <div className={' fixed bottom-[50px] right-[50px] z-50'}>
            <button
                onClick={()=>{setChatOpen(true)}}
                className={'animate-bounce '}>
                <img className={'w-[70px] h-[70px]'} src={botIcon}/>
            </button>
        </div>
        <Simple2Modal closeModal={handleCloseChat} isOpen={chatOpen} icon={botIcon} title={'Gia sư MegaSchool'} >
            <div className={'flex justify-between flex-col'}>
                <div className={' flex flex-col-reverse  h-[400px] min-h-[400px] overflow-y-scroll scrollbar-hide'}>
                    <div className={' '}>
                        {chatConversations.map(chat=><div className={` my-3  flex ${chat.user=='me'?'flex-row-reverse ':'justify-start'}`}>
                           <img src={chat.user=='me'?avatarIcon:botIcon} className={'w-[30px] h-[30px] m-2'} /> <span className={`py-1 text-[14px] ${chat.user=='me'?'bg-F0F0F0':'bg-71C16A'} px-2 rounded`}>{chat.message}</span>
                        </div>)}
                        {chatStatus =='pending' &&<div className={'my-3  flex justify-start'}>
                            <img src={chatIcon} className={'w-[30px] h-[30px] m-2'} />
                            <img src={dotIcon} className={'w-[30px] m-2 animate-bounce'}/>
                        </div>}
                    </div>

                </div>
                <div className={'flex space-x-3  pt-2 border-t'}>
                    <input
                        value={chatInput}
                        onChange={(e)=>{setChatInput(e.target.value)}}
                        className={'flex-1 border py-1 px-2 rounded-md text-[14px] text-7A86A1'}/>
                    <button
                        disabled={chatStatus=='pending'}
                        onClick={handleSendChat}
                        className={`${chatStatus=='pending'?"bg-indigo-300":"bg-indigo-600"} rounded-md text-white px-3 py-2`}>
                        Send
                    </button>
                </div>
            </div>

        </Simple2Modal>
    </div>
}
