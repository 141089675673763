import {axiosPrivate} from "../../../commons/services/axiosService";

export const getAssignmentGroupsAPI =(courseId)=>{
    return axiosPrivate({
        url:`/assignment/assignmentGroups/${courseId}`,
        method:"GET",
    })
}

export const getAssignmentInGroupAPI=(courseId, groupId)=>{
    return axiosPrivate({
        url:`assignment/assignmentInGroup?courseId=${courseId}&groupId=${groupId}`,
        method:"GET"
    })
}

export const createGroupAssignmentAPI = (courseId, groupName)=>{
    return axiosPrivate({
        url:`assignment/createGroup`,
        method:"POST",
        data:{
            courseId: courseId,
            groupName: groupName
        }
    })
}
