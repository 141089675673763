import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getDefaultSchoolAPI} from "./SchoolService";

const initState = {
    schools:[],
    status: 'idle'
}

export const getDefaultSchool = createAsyncThunk('courseSchool/getDefaultSchool',async()=>{
    let schools = await getDefaultSchoolAPI()
    return schools.data
})
export const CourseSchoolSlice= createSlice({
    name:'courseSchool',
    initialState: initState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getDefaultSchool.pending,(state,action)=>{
            state.status = 'loading'
        }).addCase(getDefaultSchool.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.schools = action.payload
        })
    }
})


export default CourseSchoolSlice.reducer
