import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {enrollToMegaCourseAPI} from "../../services/enrollService";

const initState = {
    status: 'idle'
}

export const enrollToMegaCourse = createAsyncThunk('courseMega/enrollStudent', async(courseId)=>{
    let enrollInfo =await enrollToMegaCourseAPI(courseId)
    return enrollInfo.data
})

const CourseMegaSlice = createSlice({
    name: 'courseMega',
    initialState: initState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(enrollToMegaCourse.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(enrollToMegaCourse.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(enrollToMegaCourse.fulfilled, (state,action)=>{
            state.status = 'idle'
            console.log(action.payload)
        })
    }
})

export default CourseMegaSlice.reducer
