import React from 'react'
import LogoIcon from "../../assets/images/logos/logo3.svg";
import BannerIcon from "../../assets/images/banner.png";
import {useDispatch} from "react-redux";
import {setViewOption} from "../logins/loginSlice";
import {useNavigate} from 'react-router-dom'
import bgLogin from "../../assets/images/bgLogin.png";
import { useTranslation } from 'react-i18next';

export default function SelectView(){
    const navigator = useNavigate()
    const {t, i18n} = useTranslation()
    const dispatch = useDispatch()
    const handleChangeViewOption= (viewOption)=>{
        dispatch(setViewOption(viewOption))
            .unwrap().then((data)=>{navigator('/')}).catch(err=>{
                console.log(err)
        })
    }

    return <div className={'flex flex-col justify-center items-center h-screen space-y-5 '} style={{backgroundImage:`url(${bgLogin})`, backgroundRepeat:"no-repeat", backgroundSize: "cover" }}>
        <img src={LogoIcon}/>
        <h1 className={'text-[14px] md:text-[20px] font-normal'}>{t("selectRole.selectRole")}</h1>
        <div className={'p-2 bg-6E48C3 rounded-3xl'}>
            <button
                className={' flex items-center justify-center space-x-5 rounded-3xl text-white border-dashed border-2 border-sky-500 bg-6E48C3 p-[10px] w-[280px]   md:w-[300px]' }
                onClick={()=>{handleChangeViewOption(1)}}>
                {t("selectRole.teacher")}
            </button>
        </div>
        <div className={'rounded-3xl p-2 bg-6E48C3'}>
            <button
                className={' flex items-center justify-center space-x-5 rounded-3xl text-white border-dashed border-2 border-sky-500 bg-6E48C3 p-[10px] w-[280px]   md:w-[300px]' }
                onClick={()=>{handleChangeViewOption(3)}}>
               {t("selectRole.student")}
            </button>
        </div>

        {/*<button*/}
        {/*    className={'rounded-3xl text-white ' +*/}
        {/*        'bg-gradient-to-r bg-316AFF p-[22px] md:h-[65px] w-full md:w-[432px]' }*/}

        {/*    onClick={()=>{handleChangeViewOption(2)}}>*/}
        {/*    Tôi là phụ huynh*/}
        {/*</button>*/}

    </div>
}
