import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {getModuleItemSequence} from "./AssignmentItemSlice";
import AssignmentIcon from '../../../assets/images/icons/assignment.svg'
import MoreIcon from '../../../assets/images/icons/moreIcon.svg'
import publishIcon from '../../../assets/images/icons/publish.svg'
import unPublishIcon from '../../../assets/images/icons/unpublish.svg'
import QuizIcon from '../../../assets/images/icons/quiz.svg'
import {Link, useNavigate} from "react-router-dom";
import closeIcon from "../../../assets/images/icons/close.svg";
import SimpleModal from "../../../commons/modals/SimpleModal";
import assignmentIcon from "../../../assets/images/icons/assignment.svg";
import pageIcon from "../../../assets/images/icons/document.svg";
import {checkCourseIsMegaContentAPI} from "../../../services/courseService";
import {changeAssignmentStateAPI} from "./AssignmentService";
import {changeAssignmentState, delAssignment} from "./AssignmentGroupSlice";
import trashIcon from '../../../assets/images/icons/trash.svg'
import {getSubString} from "../../../commons/services/utilService";
import {toast} from "react-toastify";
import { useTranslation } from 'react-i18next';

export default function AssignmentItem({assignment, courseId}){
    const dispatch = useDispatch()
    const [module, setModule] = useState(null)
    const user =useSelector(state=>state.auth.user)
    const [noLicenseShow, setNolicenseShow] = useState(false)
    const navigate= useNavigate()
    const [modulePosition, setModulePosition] = useState(0)
    const {t, i18n} = useTranslation()

    useEffect(()=>{
        if(courseId && assignment && user.viewOption!=1 ){
            dispatch(getModuleItemSequence({
                courseId: courseId,
                assetType:assignment.submission_types.includes('online_quiz')?'Quiz':'AssignmentStudent',
                assetId: assignment.submission_types.includes('online_quiz')?assignment.quiz_id:assignment.id
            })).unwrap().then(itemSequence=>{
                if(itemSequence){
                    let _modules = ""
                    for(let i =0; i< itemSequence.modules.length; i++){
                        if(itemSequence.modules[i].position > modulePosition){
                            setModulePosition(itemSequence.modules[i].position)
                        }
                        _modules +=`${itemSequence.modules[i].name}`
                        if(i +1 != itemSequence.modules.length){
                            _modules+=', '
                        }
                    }
                    setModule(_modules)
                }
            })
        }


    },[assignment, courseId, user])


    const handleCloseNoLicensePopup=()=>{
        setNolicenseShow(false)
    }

    const moveToLicense=()=>{
        window.open('/license', '_self')
    }

    const handleClick=(assignment)=>{
        checkCourseIsMegaContentAPI(courseId).then(data=>{
            if(data.data.result){
                if(user.viewOption==1 || module==null || modulePosition <= process.env.REACT_APP_NUMBER_MODULE_FREE){
                    if(assignment.quiz_id){
                        navigate(`/courses/${courseId}/quizzes/${assignment.quiz_id}`)
                    }else{
                        navigate(`/courses/${courseId}/assignments/${assignment.id}`)
                    }
                }else{
                    if(user && user.premiumEndDate){
                        let endDate = new Date(user.premiumEndDate).getTime()
                        if(new Date().getTime() <endDate){
                            if(assignment.quiz_id){
                                navigate(`/courses/${courseId}/quizzes/${assignment.quiz_id}`)
                            }else{
                                navigate(`/courses/${courseId}/assignments/${assignment.id}`)
                            }
                        }else{
                            setNolicenseShow(true)
                        }
                    }else{
                        setNolicenseShow(true)
                    }

                }
            }else{
                if(assignment.quiz_id){
                    navigate(`/courses/${courseId}/quizzes/${assignment.quiz_id}`)
                }else{
                    navigate(`/courses/${courseId}/assignments/${assignment.id}`)
                }
            }
        })

    }

    const handleChangeStateAssignment=()=>{
        dispatch(changeAssignmentState({
            assignmentId: assignment.id,
            courseId: courseId,
            published: !assignment.published
        })).unwrap().catch(err=>{
            toast.error(t('assignment.cannotunpublicmessage'))
        })
    }

    const handleDelAss=()=>{
        dispatch(delAssignment({
            courseId: courseId,
            assignmentId: assignment.id
        }))
    }



    return <div className={'flex py-2 items-start border-b rounded hover:bg-F0F0F0'}>
        <div className={'px-2'}>{assignment && !assignment.quiz_id ? <img src={AssignmentIcon} />:<img src={QuizIcon} />}</div>
        <div className={'flex flex-grow flex-col space-y-2'}>
            {assignment && <div onClick={()=>{handleClick(assignment)}}><span className={'text-black text-[14px] hover:cursor-pointer'}>{getSubString(128,assignment.name)}</span></div>}
            <div className={`flex ${module?'justify-between':'justify-start'} text-[12px] text-7A86A1 max-w-sm`}>
                {module  && <div><span>{module}</span></div>}
                <div><span>{assignment.points_possible} {t('grade')}</span></div>
            </div>
        </div>
        { user && user.viewOption == 1 && <div className={'flex justify-end space-x-2'}>
            <img onClick={handleChangeStateAssignment} className={'hover:cursor-pointer '} src={assignment.published? publishIcon:unPublishIcon}/>
            <img onClick={handleDelAss} className={'hover:cursor-pointer'} src={trashIcon} />
        </div>}
        <SimpleModal  isOpen={noLicenseShow} closeModal={handleCloseNoLicensePopup}>
            <div>
                <div className={'flex w-full justify-end'}><button onClick={handleCloseNoLicensePopup}><img src={closeIcon}/></button></div>
                <div className={'py-3'}>{noLicenseShow &&<span className={'text-[18px]'}>{t('requirePremiumMsg')}</span>}</div>
                <div className={'w-full flex justify-center'}>
                    <button  onClick={moveToLicense} className={'py-2 px-3 rounded-lg bg-6149CD text-white'}>{t('profile.buyLicense')}</button>
                </div>
            </div>
        </SimpleModal>
    </div>
}
