import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getGroupBySchoolIdAPI} from "../../commons/services/groupService";

const initState = {
    groups:[],
    status:'idle'
}

export const getGroupsInSchool = createAsyncThunk('crourseGroup/getGroupsInSchool', async(schoolId)=>{
    let groups = await getGroupBySchoolIdAPI(schoolId)
    return groups.data
})

const CourseGroupSlice = createSlice({
    name:'courseGroup',
    initialState: initState,
    reducers:{},
    extraReducers:builder => {
        builder.addCase(getGroupsInSchool.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(getGroupsInSchool.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.groups = action.payload
        }).addCase(getGroupsInSchool.rejected,(state,action)=>{
            state.status = 'idle'
            console.log(action.error.message)
        })
    }
})

export default CourseGroupSlice.reducer
