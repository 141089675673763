import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getCourseDetail} from "../courseDetail/CourseDetailSlice";
import {getAllStudent} from "./gradeSlice";
import {getAllAssignment} from "../moduleTab/ModuleAssignmentSlice";
import GlobalHeaderStudent from "../../commons/components/headers/GlobalHeaderStudent";
import Footer from "../../commons/components/footer/Footer";
import {getSubString} from "../../commons/services/utilService";
import StudentCourseTabBar from "../../components/tabBar/StudentCourseTabBar";
import CourseSubTab from "../../components/tabBar/CourseSubTab";
import { useTranslation } from 'react-i18next';
export default function GradeStudent(){
    const {t, i18n} = useTranslation()
    const params = useParams()
    const dispatch = useDispatch()
    const course = useSelector(state=>state.courseDetail.course)
    const [tabSelect,setTabSelect] = useState('4')
    const students = useSelector(state=>state.grade.students)
    const assignments = useSelector(state=>state.moduleAssignment.assignments)
    const navigator = useNavigate()
    useEffect(()=>{
        dispatch(getCourseDetail(params.courseId))
    },[params.courseId])

    useEffect(()=>{
        dispatch(getAllStudent(params.courseId))
    },[params.courseId])

    useEffect(()=>{
        dispatch(getAllAssignment(params.courseId))
    }, [params.courseId])

    const handleNavigateToAssignment=(assignment)=>{
        if(assignment.quiz_id){
            navigator(`/courses/${params.courseId}/quizzes/${assignment.quiz_id}`)
        }else{
           //todo
        }
    }

    return <div>
        <GlobalHeaderStudent />
        <CourseSubTab course={course}/>
        <StudentCourseTabBar tabSelect={tabSelect} />
        <div className={'container mx-auto'}>
            {course && tabSelect==4 && <div className={'w-full overflow-x-scroll py-5 '}>
                <table className={'w-full'}>
                    <thead  className={'w-full'}>
                        <tr className={'w-full'}>
                            <th className={'min-w-[200px] border text-[14px] bg-E9EDF8 py-2'}>{t("assignment.AssignmentName")}</th>
                            <th className={'min-w-[200px] border text-[14px] bg-E9EDF8 py-2'}>{t("assignment.status")}</th>
                            <th className={'min-w-[200px] border text-[14px] bg-E9EDF8 py-2'}>{t("Grade")}</th>
                        </tr>
                    </thead>
                    <tbody>
                    {assignments && assignments.map(assignment=><tr className={'border-b'}>
                        <td  className={'text-left font-semibold text-[14px] px-2 py-2 border hover:text-4B4DA4 hover:cursor-pointer'}>
                            <span onClick={()=>{handleNavigateToAssignment(assignment)}}>{getSubString(64,assignment.name)}</span>
                        </td>
                        <td className={'text-right text-[14px] border py-2 px-2 '}>{assignment.submission && assignment.submission.workflow_state=='graded'?"Hoàn Thành":'Chưa hoàn thành'}</td>
                        <td className={'text-right text-[14px] py-2 border px-2'}>{assignment.submission && assignment.submission.score?assignment.submission.score:0} / {assignment.points_possible}</td>
                    </tr>)}
                    </tbody>
                </table>

            </div>}
        </div>
        <Footer/>


    </div>
}
