import {axiosPrivate} from "../../commons/services/axiosService";


export const getQuizByIdAPI =(courseId, quizId)=>{
    return axiosPrivate({
        method:'GET',
        url:`quizzes/detail?courseId=${courseId}&quizId=${quizId}`
    })
}

export const getListQuizQuestionAPI = (courseId, quizId)=>{
    return axiosPrivate({
        method: "GET",
        url:`quizQuestion/list?courseId=${courseId}&quizId=${quizId}`
    })
}

export const updateQuestionAPI = (courseId, quizId, questionId, question)=>{
    return axiosPrivate({
        method:"PUT",
        url:'quizQuestion/update',
        data:{
            courseId: courseId,
            quizId: quizId,
            questionId: questionId,
            question: question
        }
    })
}


export const getAllQuizSubmissionsAPI= (courseId, quizId)=>{
    return axiosPrivate({
        method:"GET",
        url:`quizSubmission/allSubmissions?courseId=${courseId}&quizId=${quizId}`
    })
}

export const getSubmissionHistoryAPI=(submissionId, version)=>{
    return axiosPrivate({
        method:"GET",
        url:`quizSubmission/submissionHistory?submissionId=${submissionId}&version=${version}`
    })
}
