import {axiosPrivate, axiosPublic} from "../../commons/services/axiosService";

export const signInAPI= (accessToken)=>{
    return axiosPublic({
        url:'/google-authentication',
        method:'POST',
        withCredentials:true,
        data:{
            token: accessToken
        },
        headers: {
            'Content-Type': 'application/json'
        },
    })
}

export const authenticationAPI = ()=>{
    return axiosPrivate.get('/authentication',{
        withCredentials:true,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}


export const mgeAuthenticationAPI = (access_token)=>{
    return axiosPrivate.post('/authentication/mge-log-in',{
        access_token: access_token
    },{
        withCredentials:true,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

export const refreshTokenAPI = ()=>{
    return axiosPrivate({
        url:'/authentication/refresh',
        method:'GET',
    })
}


export const setViewOptionAPI = (viewOption)=>{
    return axiosPrivate({
        url:'/user/update-viewoption',
        method:'POST',
        withCredentials:true,
        data:{
            viewOption:viewOption
        },
        headers:{
            'Content-Type': 'application/json'
        }
    })
}


export const logOutAPI = ()=>{
    return axiosPrivate({
        url:'/authentication/log-out',
        method:"POST"
    })
}
