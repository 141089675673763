import React, {useEffect, useState} from 'react'
import GlobalHeader from "../../commons/components/headers/GlobalHeader";
import { useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getCourseDetail} from "../courseDetail/CourseDetailSlice";
import AssignmentTab from "../courseDetail/assignmentTab/AssignmentTab";
import Footer from "../../commons/components/footer/Footer";
import TeacherCourseTabBar from "../../components/tabBar/TeacherCourseTabBar";
import CourseSubTab from "../../components/tabBar/CourseSubTab";
export default function Assignment(){
    const params = useParams()
    const dispatch = useDispatch()
    const course = useSelector(state=>state.courseDetail.course)
    const [tabSelect,setTabSelect] = useState('3')
    useEffect(()=>{
        dispatch(getCourseDetail(params.courseId))
    },[params.courseId])
    return <div>
        <GlobalHeader />
        <CourseSubTab  course={course}/>
        <TeacherCourseTabBar tabSelect={tabSelect} />
        <div className={'container mx-auto  min-h-[500px]'}>
            {course && tabSelect==3 && <AssignmentTab course={course}/>}
        </div>
        <Footer />

    </div>
}
