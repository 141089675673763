import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import { Fragment } from 'react'
import { Menu } from '@headlessui/react'
import publicIcon from '../../assets/images/icons/publish.svg'
import unpublicIcon from '../../assets/images/icons/unpublish.svg'
import {deleteItem, getModulesItems} from "./ModuleSlice";
import iconMore from '../../assets/images/icons/icon_more.svg'
import trashIcon from '../../assets/images/icons/transh2.svg'
import * as PropTypes from "prop-types";
import pageIcon from '../../assets/images/icons/document.svg'
import quizIcon from '../../assets/images/icons/quiz.svg'
import assignmentIcon from '../../assets/images/icons/assignment.svg'
import {useNavigate} from "react-router-dom";
import SimpleModal from "../../commons/modals/SimpleModal";
import closeIcon from "../../assets/images/icons/close.svg";
import {checkCourseIsMegaContentAPI} from "../../services/courseService";
import {getSubString} from "../../commons/services/utilService";
import { useTranslation } from 'react-i18next';
function Transition(props) {
    return null;
}



Transition.propTypes = {
    as: PropTypes.func,
    leave: PropTypes.string,
    enterFrom: PropTypes.string,
    leaveTo: PropTypes.string,
    enter: PropTypes.string,
    enterTo: PropTypes.string,
    leaveFrom: PropTypes.string
};
export default function ListModueItemsTab({module, course, setItemTypeSelect,setItemSelect, itemSelect}){
    const {t, i18n} = useTranslation()
    const user =useSelector(state=>state.auth.user)
    const navigate= useNavigate()
    const dispatch = useDispatch()
    const [items, setItems] = useState([])
    const [noLicenseShow, setNolicenseShow] = useState(false)
    const [block,setBlock] = useState(true)
    const modules= useSelector(state=> state.module.modules)

    useEffect(()=>{
        if(module && course ){
            dispatch(getModulesItems({
                courseId: course.id,
                moduleId: module.id
            }))

        }

    },[])

    useEffect(()=>{
        if( course && module){
            if(!course.block){
                setBlock(false)
            }else{
                if(parseInt(course.blockNumber) > module.position){
                    setBlock(false)
                }else{
                    setBlock(true)
                }
            }
        }
    },[course, module])

    useEffect(()=>{
        if(modules && module){
            let firstModule = modules[0]
            for( let m of modules){
                if(m.position <= firstModule.position){
                    firstModule= m
                }
                if(m.id == module.id && m.items){
                    setItems(m.items)
                }
            }
            if(firstModule && firstModule.items && firstModule.items.length >0){
                for(let item of firstModule.items){
                    if(item.type!= "SubHeader"){
                        setItemTypeSelect(item.type)
                        if(item.type!="Page"){
                            setItemSelect(item.content_id)
                        }else{
                            setItemSelect(item.page_url)
                        }
                        break
                    }
                }
            }
        }

    },[modules])

    const handleDeleteItem = (itemId)=>{
        dispatch(deleteItem({
            courseId: course.id,
            moduleId: module.id,
            itemId: itemId
        }))
    }

    const getIcon = (item)=>{
        let icon = null
        switch (item.type){
            case 'Assignment':
                icon = assignmentIcon
                break
            case 'Quiz':
                icon = quizIcon
                break
            case 'Page':
                icon= pageIcon
                break
            default:
                icon= pageIcon
                break
        }
        return icon
    }

    const handleClickItem=(item)=>{
        if(block){
            setNolicenseShow(true)
            return
        }
        let type = null
        switch (item.type){
            case 'Assignment':
                setItemTypeSelect('Assignment')
                setItemSelect(item.content_id)
                break
            case 'Quiz':
                setItemTypeSelect('Quiz')
                setItemSelect(item.content_id)
                break
            case 'Page':
                setItemTypeSelect('Page')
                setItemSelect(item.page_url)
                break
            default:
                type= pageIcon
                setItemSelect(null)
                setItemTypeSelect(null)
                break
        }

    }


    const handleCloseNoLicensePopup=()=>{
        setNolicenseShow(false)
    }

    const moveToLicense=()=>{
        window.open('/license', '_self')
    }


    return <div className={'bg-white w-full bg-gray-50'} >
        {items && items.map(x=><div className={`hover:bg-indigo-100 ${block?"text-ADE5F2": "text-302574"} border-b-2 border-b-gray-300 py-2 px-3 flex items-start space-x-5 justify-between ${x.content_id == itemSelect || x.page_url == itemSelect?"bg-indigo-100":''}`}>
            <div>
                {x.type=='SubHeader'?<h3 className={'font-semibold text-left text-[18px]'}>{getSubString(128,x.title)}</h3>:<button onClick={()=>{handleClickItem(x)}} className={`flex text-[16px] text-left  space-x-2 ${block?"hover:text-ADE5F2":"hover:text-indigo-600"} hover:cursor-pointer`}><img src={getIcon(x)} /><span>{getSubString(128,x.title)}</span></button>}
            </div>
            {x.completion_requirement && <div className={'relative w-8  items-center text-end flex space-x-5 justify-end '} >
                {/*<span>{x.completion_requirement && x.completion_requirement.completed?'Hoàn thành':'chưa hoàn thành'} </span>*/}
                {x.completion_requirement && x.completion_requirement.completed ? <div className={'group flex relative'}>
                    <img src={publicIcon} className={'w-4 h-4'}/>
                    <span className="group-hover:opacity-100 transition-opacity bg-indigo-300 px-1 top-0 text-sm text-gray-100 rounded-md absolute left-1/2
    -translate-x-1/2 translate-y-full opacity-0  mx-auto">{t("done")}</span>
                </div>: <div className={'group flex relative'}>
                    <img className={'w-4 h-4 z-10'} src={unpublicIcon}/>
                    <span className="group-hover:opacity-100 transition-opacity bg-indigo-300 px-1 top-0 text-sm text-gray-100 rounded-md absolute left-0 -translate-x-full  opacity-0 mx-auto">{t("notDone")}</span>
                </div>
                }
            </div>}
        </div>)}
        <SimpleModal  isOpen={noLicenseShow} closeModal={handleCloseNoLicensePopup}>
            <div>
                <div className={'flex w-full justify-end'}><button onClick={handleCloseNoLicensePopup}><img src={closeIcon}/></button></div>
                <div className={'py-3'}>{noLicenseShow &&<span className={'text-[18px]'}>{t("requirePremiumMsg")} </span>}</div>
                <div className={'w-full flex justify-center'}>
                    <button  onClick={moveToLicense} className={'py-2 px-3 rounded-lg bg-6149CD text-white'}>{t("profile.buyLicense")}</button>
                </div>
            </div>
        </SimpleModal>
    </div>
}
