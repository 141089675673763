import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {createCourseAPI, getCourseAvgProgressAPI, getListCourseAPI} from "./courseService";

const initState = {
    courses: [],
    status: 'idle'
}

export const createNewCourse = createAsyncThunk('coursesSlice/create', async(input)=>{
    let course =await createCourseAPI(input.name, input.subjectId)
    return course.data

})

export const listCourse = createAsyncThunk('courseSlice/listCourse', async()=>{
    let courses= await getListCourseAPI()
    return courses.data
})

export const getCourseAvgProgress = createAsyncThunk('courseSlice/courseAgvProgress', async(courseId)=>{
    let courseAvg = await getCourseAvgProgressAPI(courseId)
    return {
        avg: courseAvg.data,
        courseId: courseId
    }
})

export const courseSlice = createSlice({
    name:'coursesSlice',
    initialState: initState,
    reducers:[],
    extraReducers: builder => {
        builder.addCase(createNewCourse.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(createNewCourse.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.courses = [...state.courses, action.payload]
        }).addCase(createNewCourse.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error.message)
        }).addCase(listCourse.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(listCourse.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.courses = [...action.payload]
        }).addCase(listCourse.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error.message)
        }).addCase(getCourseAvgProgress.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(getCourseAvgProgress.rejected, (state,action)=>{
            state.status= 'idle'
            console.log(action.error)
        }).addCase(getCourseAvgProgress.fulfilled, (state,action)=>{
            state.status = 'idle'
            let _courses = [...state.courses]
            for(let i = 0; i< _courses.length; i++){
                if(_courses[i].id == action.payload.courseId){
                    _courses[i].avgProgress = action.payload.avgProgress
                }
            }
            state.courses = [..._courses]
        })
    }
})

export default courseSlice.reducer
