import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {createGroupAPI, deleteGroupAPI} from "./adminGroupService";
import {getGroupBySchoolIdAPI} from "../../../commons/services/groupService";
const initState = {
    groups:[],
    state:'idle'
}


export const createGroup=createAsyncThunk('adminGroup/create',async(input)=>{
    let group = await createGroupAPI(input.groupName, input.schoolId)
    return group.data
})

export const getGroupInSchool = createAsyncThunk('adminGroup/groupsinschool', async(schoolId)=>{

    let groups = await getGroupBySchoolIdAPI(schoolId)
    return groups.data
})

export const deleteGroup = createAsyncThunk('adminGroup/deletegroup', async(groupId)=>{
    await deleteGroupAPI(groupId)
    return groupId
})

export const adminGroup = createSlice({
    name:'adminGroup',
    initialState:initState,
    reducers:{

    },
    extraReducers:builder => {
        builder.addCase(createGroup.pending,(state,action)=>{
            state.status= 'loading'
        }).addCase(createGroup.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.groups=[...state.groups, action.payload]
        }).addCase(getGroupInSchool.pending,(state,action)=>{
            state.status = 'loading'
        }).addCase(getGroupInSchool.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.groups= [...action.payload]
        }).addCase(getGroupInSchool.rejected, (state,action)=>{
            console.log(action.error)
            state.status = 'idle'
        }).addCase(deleteGroup.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(deleteGroup.fulfilled, (state,action)=>{
            state.status = 'idle'
            let _groups = [...state.groups]
            for(let i = 0; i<_groups.length;i++){
                if(_groups[i].id === action.payload){
                    _groups.splice(i, 1)
                    break
                }
            }
            state.groups = [..._groups]
        }).addCase(deleteGroup.rejected, (state,action)=>{
            state.status = "idle"
            console.log(action.error.message)
        })
    }
})

export default adminGroup.reducer
