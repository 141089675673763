import React from 'react'
import imageFooter from "../../../assets/images/imgFooter.png";
import logo from '../../../assets/images/logos/logo2.svg'
import facebookIcon from '../../../assets/images/icons/facebook-2.svg'
import youtubeIcon from '../../../assets/images/icons/youtube-3.svg'
import googleStore from '../../../assets/images/google_store.png'
import appleStore from '../../../assets/images/appleStore.png'
import logoSaleNoti from '../../../assets/images/logoSaleNoti.png'
import footer1 from '../../../assets/images/footer1.png'
import footer2 from '../../../assets/images/footer2.png'
import { useTranslation } from 'react-i18next';
export default function Footer(){
    const {t, i18n} = useTranslation()
    return <div className={' bg-ADE5F2 mt-3'}>
        <div className={'mx-auto container py-3 font-light px-3 z-10 relative'}>
            <div className={'grid grid-cols-12 gap-5'}>
                <div className={'col-span-12 md:col-span-4'}>
                    <div>
                        <img src={logo} className={'max-w-[200px]'} />
                    </div>
                    <div className={'text-[14px] mt-5'}>
                        <span className={'font-semibold text-[12px] md:text-[14px]'}>{t('footer.addressTitle')}:</span><span className={'text-[12px] md:text-[14px]'}> {t('footer.address')}</span>
                    </div>
                    <div className={'grid grid-cols-12 mt-5'}>
                        <div className={'col-span-5 md:col-span-6'}>
                            <span className={'font-semibold text-[12px] md:text-[14px]'}>Hotline:</span><span className={'text-[12px] md:text-[14px]'}> 1900 234 582</span>
                        </div>
                        <div className={'col-span-7 md:col-span-6'}>
                            <span className={'font-semibold text-[12px] md:text-[14px]'}>Email:</span><span className={'text-[12px] md:text-[14px]'}> info@megaschool.edu.vn</span>
                        </div>
                    </div>
                    <div className={'w-full text-[12px] md:text-[14px] mt-5'}>
                        <span className={'text-707070'}>©Copyright 2023 All rights reserved.</span>
                    </div>
                </div>
                <div className={'col-span-12 md:col-span-2'}>

                </div>
                <div className={'col-span-12 md:col-span-6'}>
                    <div className={'grid grid-cols-12 gap-5'}>
                        <div className={'col-span-7 md:col-span-4'}>
                            <h5 className={'font-semibold mt-5 text-[12px] md:text-[14px]'}>{t('footer.custommerService')}</h5>
                            <p className={'mt-3 text-[12px] md:text-[14px]'}>{t('footer.paymentMenthod')}</p>
                            <p className={'mt-3 text-[12px] md:text-[14px]'}>{t('RefundPolicy')}</p>
                        </div>
                        <div className={'col-span-5 md:col-span-4'}>
                            <h5 className={'font-semibold mt-5 text-[12px] md:text-[14px]'}>{t('footer.aboutUs')}</h5>
                            <p className={'mt-3 text-[12px] md:text-[14px]'}>{t('footer.introduction}')}</p>
                            <p className={'mt-3 text-[12px] md:text-[14px]'}>{t('footer.contactUs')}</p>
                        </div>
                        <div className={'col-span-12 md:col-span-4'}>
                            <h5 className={'font-semibold mt-5 text-[12px] md:text-[14px]'}>{t('footer.ConnectToUs')}</h5>
                            <div className={'mt-3 flex space-x-5'}>
                                <a href={'https://www.facebook.com/megaschool.edu.vn'} target={'_blank'}><img src={facebookIcon} /></a>
                                <img src={youtubeIcon}/>
                            </div>
                        </div>
                    </div>
                    <div className={'mt-9'}>
                        <h5 className={'text-[12px] md:text-[14px] font-semibold'}>TẢI ỨNG DỤNG</h5>
                        <div className={'grid grid-cols-12 gap-5  '}>

                            <div className={'col-span-12 md:col-span-8'}>

                                <div className={'flex space-x-3 mt-3'}>
                                    <img src={googleStore} />
                                    <img src={appleStore} />
                                </div>
                            </div>
                            <div className={'col-span-4'}>
                                <img src={logoSaleNoti}/>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
        <div className={'relative bg-8C603B h-[20px] z-0'}>
            <img   className={' max-w-[200px] md:w-fit absolute bottom-0 right-3 md:right-[50vw] z-0'
            } src={footer1}/>
            <img   className={'hidden md:block max-w-[200px] md:w-fit absolute bottom-0 left-5 z-0'
            } src={footer2}/>
        </div>
    </div>

}
