import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import {
    add,
    eachDayOfInterval,
    endOfMonth,
    format,
    getDay,
    isEqual,
    isSameDay,
    isSameMonth,
    isToday,
    parse,
    parseISO,
    startOfToday,
} from 'date-fns'
import {Fragment, useEffect, useState} from 'react'
import PlannerBadger from "./PlannerBadger";
// import PlannerBadger from "./PlannerBadger";
// import {listPlannerAPI} from "../../services/TimeTableService";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Calendar({selectedDay, changeSelectedDay, today,isToDo, children, currentMonth, setCurrentMonth, offSelectDate}) {
    // let today = startOfToday()
    // let [selectedDay, setSelectedDay] = useState(today)
    let firstDayCurrentMonth = parse(currentMonth, 'MMM-yyyy', new Date())

    let days = eachDayOfInterval({
        start: firstDayCurrentMonth,
        end: endOfMonth(firstDayCurrentMonth),
    })

    function previousMonth() {
        let firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 })
        setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'))
    }

    function nextMonth() {
        let firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 })
        setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'))
    }
    // useEffect(()=>{
    //     if(isToDo){
    //         let firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 })
    //         listPlannerAPI(new Date(firstDayCurrentMonth).toISOString(), new Date(firstDayNextMonth).toISOString()).then(res=>{
    //
    //         })
    //     }
    // }, [isToDo, currentMonth])
    return (
        <div className="pt-4 w-full">
            <div className="w-full px-3">
                <div className="mx-5">
                    <div className="flex items-center w-full">
                        <button
                            type="button"
                            onClick={previousMonth}
                            className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500 bg-bgcolor8 hover:bg-gray-200  rounded-full text-bgcolor7"
                        >
                            <span className="sr-only">Previous month</span>
                            <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
                        </button>
                        <div className={'flex-auto font-semibold  w-full text-gray-900'}>
                            <h2 className="mx-auto text-center">
                                {format(firstDayCurrentMonth, 'MMMM yyyy')}
                            </h2>
                        </div>

                        <button
                            onClick={nextMonth}
                            type="button"
                            className="-my-1.5 -mr-1.5 ml-2 flex flex-none bg-bgcolor8 hover:bg-gray-200  rounded-full items-center justify-center p-1.5 text-bgcolor7 hover:text-gray-500"
                        >
                            <span className="sr-only">Next month</span>
                            <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="grid grid-cols-7 mt-2 text-xs leading-6 text-center text-gray-500">
                        <div>S</div>
                        <div>M</div>
                        <div>T</div>
                        <div>W</div>
                        <div>T</div>
                        <div>F</div>
                        <div>S</div>
                    </div>
                    <div className="grid grid-cols-7 mt-2 text-sm">
                        {days.map((day, dayIdx) => (
                            <div
                                key={day.toString()}
                                className={classNames(
                                    dayIdx === 0 && colStartClasses[getDay(day)],
                                    'py-1.5 relative inline-block'
                                )}
                            >
                                <button
                                    type="button"
                                    onClick={() => changeSelectedDay(day)}
                                    className={classNames(
                                        isEqual(day, selectedDay) && 'text-white',
                                        !isEqual(day, selectedDay) &&
                                        isToday(day) &&
                                        'text-yellow-500',
                                        !isEqual(day, selectedDay) &&
                                        !isToday(day) &&
                                        isSameMonth(day, firstDayCurrentMonth) &&
                                        'text-gray-900',
                                        !isEqual(day, selectedDay) &&
                                        !isToday(day) &&
                                        !isSameMonth(day, firstDayCurrentMonth) &&
                                        'text-gray-400',
                                        isEqual(day, selectedDay) && isToday(day) && 'bg-71C16A',
                                        isEqual(day, selectedDay) &&
                                        !isToday(day) &&
                                        'bg-6149CD',
                                        !isEqual(day, selectedDay) && 'hover:bg-gray-200',
                                        'mx-auto flex h-8 w-8 items-center  justify-center font-semibold rounded-md'
                                    )}
                                >
                                    <time dateTime={format(day, 'yyyy-MM-dd')}>
                                        {format(day, 'd')}
                                    </time>
                                </button>
                                <PlannerBadger day={day} />
                            </div>
                        ))}
                    </div>
                    <div>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}


let colStartClasses = [
    '',
    'col-start-2',
    'col-start-3',
    'col-start-4',
    'col-start-5',
    'col-start-6',
    'col-start-7',
]
