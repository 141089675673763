import {axiosPrivate} from "../../commons/services/axiosService";

export const getSectionProgressAPI=(sectionId, courseId)=>{
    return axiosPrivate({
        method:"GET",
        url:`section/${sectionId}/course/${courseId}/avgProgress`
    })
}

export const countAssignmentPublishAPI = (sectionId, courseId)=>{
    return axiosPrivate({
        method:'GET',
        url:`assignment/course/${courseId}/section/${sectionId}/countPublic`
    })
}

export const countSubmittionAPI=(courseId, sectionId)=>{
    return axiosPrivate({
        method: "GET",
        url:`submissions/course/${courseId}/section/${sectionId}/submitionCount`
    })
}
