import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getAssignmentAPI, getGroupAssignmentAPI, updateAssignmentAPI} from "./AssignmentDetailService";
import {createAssignment} from "../courseDetail/assignmentTab/AssignmentSlice";
import {courseSlice} from "../courses/courseSlice";

const initState = {
    assignment:null,
    status:'idle',
    assignmentGroup:null
}

export const getAssignmentDetail = createAsyncThunk('assignmentDetail/getAssignemnt', async(input)=>{
    let assignment = await getAssignmentAPI(input.courseId, input.assignmentId)
    return assignment.data
})

export const getGroupAssignment = createAsyncThunk('assignmentDetail/getGroupAssignment', async(input)=>{
    let group = await getGroupAssignmentAPI(input.courseId, input.groupId)
    return group.data
})

export const updateAssignment = createAsyncThunk('assignmentDetail/updateAssignment', async(input)=>{
    let assignment = await updateAssignmentAPI(input.courseId, input.assignmentInfo, input.assignmentId)
    return assignment.data
})

const assignmentDetailSlice = createSlice({
    name:'assignmentSlice',
    initialState: initState,
    reducers:{
        updateAssState:(state,action)=>{
            let _assignment = state.assignment
            _assignment.published= action.payload
            state.assignment= _assignment
        }
    },
    extraReducers:builder => {
        builder.addCase(getAssignmentDetail.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(getAssignmentDetail.rejected, (state,action)=>{
            state.status = 'ide'
            console.log(action.error)
        }).addCase(getAssignmentDetail.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.assignment = action.payload
        }).addCase(getGroupAssignment.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(getGroupAssignment.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.assignmentGroup = action.payload
        }).addCase(getGroupAssignment.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(updateAssignment.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(updateAssignment.fulfilled, (state,action)=>{
            state.status ='idle'
            state.assignment=action.payload
        }).addCase(updateAssignment.rejected, (state,action)=>{
            state.status ='idle'
            console.log(action.error)
        })
    }
})

export const {updateAssState} = assignmentDetailSlice.actions
export default assignmentDetailSlice.reducer
