const {axiosPrivate} = require("../../../commons/services/axiosService");


export const createAssignmentAPI =(assignmentInfo, courseId)=>{
    if(assignmentInfo.assignment_overrides.length > 0){
        let assignment_overrides = []
        for(let i =0; i< assignmentInfo.assignment_overrides.length; i++){

            if(assignmentInfo.assignment_overrides[i].sectionSelects!= null && assignmentInfo.assignment_overrides[i].sectionSelects.length > 0){
               console.log(assignmentInfo.assignment_overrides[i])
                for(let section of assignmentInfo.assignment_overrides[i].sectionSelects){
                    assignment_overrides.push({
                        course_section_id: section.value,
                        due_at: assignmentInfo.assignment_overrides[i].dueDate,
                        unlock_at:assignmentInfo.assignment_overrides[i].startDate,
                        lock_at:assignmentInfo.assignment_overrides[i].endDate
                    })
                }
            }else{
                if(assignmentInfo.assignment_overrides[i].startDate){
                    assignmentInfo.unlock_at = assignmentInfo.assignment_overrides[i].startDate
                }
                if(assignmentInfo.assignment_overrides[i].endDate){
                    assignmentInfo.lock_at=assignmentInfo.assignment_overrides[i].endDate
                }
                if(assignmentInfo.assignment_overrides[i].dueDate){
                    assignmentInfo.due_at=assignmentInfo.assignment_overrides[i].dueDate
                }
            }
        }
        assignmentInfo.assignment_overrides = assignment_overrides
    }
    return axiosPrivate({
        url: 'assignment/createAssignment',
        method: 'POST',
        data: {
            courseId: courseId,
            assignment: assignmentInfo
        }
    })
}

export const changeAssignmentStateAPI=(assignmentId, courseId, published)=>{
    return axiosPrivate({
        url: `assignment/course/${courseId}/assignment/${assignmentId}`,
        method:'PUT',
        data:{
            published: published
        }
    })
}


export const delAssignmentAPI= (assignmentId, courseId)=>{
    return axiosPrivate({
        url:`assignment/course/${courseId}/ass/${assignmentId}`,
        method:"DELETE"
    })
}
