import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {createAdminGradeAPI, deleteGradeAPI} from "./AdminGradeService";
import {getGradeInGroupAPI} from "../../../commons/services/gradeService";

const initialState = {
    grades:[],
    gradeSelect:null,
    status:'idle'
}

export const createGrade = createAsyncThunk('adminGrade/create', async(input)=>{
    let grade = await createAdminGradeAPI(input.name,input.groupId )
    return grade.data
})

export const getGradeInGroup = createAsyncThunk('adminGrade/getGradeInGroup', async(groupId)=>{
    let grades = await getGradeInGroupAPI(groupId)
    return grades.data
})

export const deleteGrade = createAsyncThunk('adminGrade/deleteGrade', async(gradeId)=>{
    await deleteGradeAPI(gradeId)
    return gradeId
})

export const adminGrade = createSlice({
    name:'adminGrade',
    initialState:initialState,
    reducers:{},
    extraReducers:builder => {
        builder.addCase(createGrade.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(createGrade.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error.message)
        }).addCase(createGrade.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.grades = [...state.grades, action.payload]
        }).addCase(getGradeInGroup.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(getGradeInGroup.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.grades = [...action.payload]
        }).addCase(getGradeInGroup.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error.message)
        }).addCase(deleteGrade.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(deleteGrade.fulfilled,(state,action)=>{
            state.status = 'idle'
            let _grades = [...state.grades]
            for(let i = 0; i< _grades.length; i++){
                if(_grades[i].id == action.payload){
                    _grades.splice(i, 1)
                    break
                }
            }
            state.grades = [..._grades]
        } ).addCase(deleteGrade.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error.message)
        })
    }
})

export default adminGrade.reducer
