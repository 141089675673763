import {axiosPrivate} from "../commons/services/axiosService";

export const createVnpUrlAPI=(licenseId)=>{
    return axiosPrivate({
        method:'POST',
        url:'/payment/createVnpUrl',
        data:{
            licenseId: licenseId,
            returnUrl: process.env.REACT_APP_VNP_RETURN_URL
        }
    })
}

export const verifyPaymentAPI=(params)=>{
return axiosPrivate({
    method:"GET",
    url:`/payment/checkVnpReturn?${params}`
})
}
