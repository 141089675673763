import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/solid'

export default function DisclosureSimple({title, children}) {
    return (
        <div className="w-full ">
            <div className=" w-full  rounded-lg  bg-white mb-2 border">
                <Disclosure defaultOpen={true}>
                    {({ open }) => (
                        <>
                            <Disclosure.Button className={`${!open?'rounded-lg':'rounded-t-lg'} flex w-full justify-start  bg-F0F0F0 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75`}>
                                <ChevronUpIcon
                                    className={`${
                                        open ? 'rotate-180 transform' : ''
                                    } h-5 w-5 text-purple-500`}
                                />
                                <span>{title}</span>

                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 ">
                                {children}
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>

            </div>
        </div>
    )
}
