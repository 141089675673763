import React, {useEffect, useState} from 'react'
import GlobalHeader from "../../commons/components/headers/GlobalHeader";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getCourseDetail} from "../courseDetail/CourseDetailSlice";
import {getAllStudent} from "./gradeSlice";
import StudentGrade from "./StudentGrade";
import {getAllAssignment} from "../moduleTab/ModuleAssignmentSlice";
import Footer from "../../commons/components/footer/Footer";
import {getSubString} from "../../commons/services/utilService";
import TeacherCourseTabBar from "../../components/tabBar/TeacherCourseTabBar";
import CourseSubTab from "../../components/tabBar/CourseSubTab";
import { useTranslation } from 'react-i18next';
export default function GradeTab(){
    const params = useParams()
    const dispatch = useDispatch()
    const course = useSelector(state=>state.courseDetail.course)
    const [tabSelect,setTabSelect] = useState('4')
    const students = useSelector(state=>state.grade.students)
    const assignments = useSelector(state=>state.moduleAssignment.assignments)

    const checkStudent = (student)=>{
        if(student && student.enrollments.length>0){
            for(let i=0; i< student.enrollments.length; i++){
                if(student.enrollments[i].role=="TeacherEnrollment"){
                    return true
                }
            }
            return false
        }else{
            return false
        }
    }

    useEffect(()=>{
        dispatch(getCourseDetail(params.courseId))
    },[params.courseId])

    useEffect(()=>{
        dispatch(getAllStudent(params.courseId))
    },[params.courseId])

    useEffect(()=>{
        dispatch(getAllAssignment(params.courseId))
    }, [params.courseId])

    const {t, i18n} = useTranslation()
    return <div>
        <GlobalHeader />
        <CourseSubTab course={course} />
        <TeacherCourseTabBar  tabSelect={tabSelect}/>
        <div className={'container mx-auto min-h-[500px]'}>
            {course && tabSelect==4 && <div className={'w-full overflow-x-scroll py-3 '}>
                <table className={'w-fit'}>
                    <thead  className={'w-fit'}>
                        <tr className={'w-fit'}>
                            <th className={'min-w-[200px] border text-[14px] align-bottom  bg-E9EDF8'}>{t('stdName')}</th>
                            {assignments && assignments.map(x=><th className={'min-w-[200px] align-top bg-E9EDF8 border text-[14px]'}>
                                <p  className={'text-7A86A1 align-top font-normal text-[14px]'}>{getSubString(20,x.name)}</p>
                                <span>{t('gradeTab.perGradePosible')}  {x.points_possible}</span>
                            </th>)}
                        </tr>
                    </thead>
                    <tbody>
                    {students && students.map(x=><>{checkStudent(x)?<></>:<StudentGrade assignments={assignments} student={x}  courseId={params.courseId}/>}</>)}
                    </tbody>
                </table>

            </div>}
        </div>
        <Footer />


    </div>
}
