import React, {useEffect, useState} from 'react'
import GlobalHeader from "../../commons/components/headers/GlobalHeader";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getCourseDetail} from "./CourseDetailSlice";
import ArrowDowIcon from "../../assets/images/icons/arrowdow.svg";
import TabListComponent from "../../commons/components/tabs/TabListComponent";
import TabComponent from "../../commons/components/tabs/TabComponent";
import CourseSettingTab from "./settingTab/CourseSettingTab";
import PeopleTab from "./peopleTab/PeopleTab";
import AssignmentTab from "./assignmentTab/AssignmentTab";
import Footer from "../../commons/components/footer/Footer";
import TeacherCourseTabBar from "../../components/tabBar/TeacherCourseTabBar";
import CourseSubTab from "../../components/tabBar/CourseSubTab";
export default function CourseDetail(){
    const params = useParams()
    const dispatch = useDispatch()
    const course = useSelector(state=>state.courseDetail.course)
    const [tabSelect,setTabSelect] = useState('1')
    const navigate = useNavigate()
    useEffect(()=>{
        dispatch(getCourseDetail(params.courseId))
    },[params.courseId])
    useEffect(()=>{
        navigate(`/courses/${params.courseId}/modules`)
    })
    return <div>
        <GlobalHeader />
        <CourseSubTab course={course} />
        <TeacherCourseTabBar tabSelect={tabSelect} />
        <div className={'container mx-auto min-h-[500px]'}>
            {course && tabSelect==5 && <CourseSettingTab course={course} />}
            {course && tabSelect==6 && <PeopleTab course={course}/>}
            {course && tabSelect==3 && <AssignmentTab course={course}/>}
        </div>
    </div>
}
