import React from 'react'
import CourseSkeleton from "./CourseSkeleton";

export default function ListCourseSkeleton({title}){
    return <div >
        <div className={'py-5'}>
            <span className={'text-[18px] font-semibold text-7A86A1'}>{title}</span>
        </div>
        <div className={'grid grid-cols-12  md:grid-cols-4 gap-x-2 gap-y-4'}>
            <CourseSkeleton />
            <CourseSkeleton/>
            <CourseSkeleton />
        </div>

    </div>
}
