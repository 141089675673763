import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import ArrowDowIcon from '../../assets/images/icons/arrowdow.svg'
import AddIcon from '../../assets/images/icons/addIcon.svg'
import GlobalHeader from "../../commons/components/headers/GlobalHeader";
import SimpleButton from "../../commons/components/buttons/SimpleButton";
import SimpleModal from "../../commons/modals/SimpleModal";
import CreateCourseForm from "./CreateCourseForm";
import {listCourse} from "./courseSlice";
import ListCourse from "./ListCourse";
import ListCourseSkeleton from "./ListCourseSkeleton";
import Footer from "../../commons/components/footer/Footer";
import InviteEnroll from "../../components/InviteEnroll";
import {listEnrollInvited} from "../courseDetail/peopleTab/EnrollmentSlice";
import { useTranslation } from 'react-i18next';

export default function Courses(){
    const [isOpenCreateForm, setIsOpenCreateForm] = useState(false)
    const [publishCourse, setPublishCourse] = useState([])
    const [unPublishCourse, setUnPublishCourse] = useState([])
    const courses = useSelector(state=>state.courses.courses)
    const courseStatus = useSelector(state=> state.courses.status)
    const user =useSelector(state=>state.auth.user)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {t, i18n} =useTranslation()
    const enrollInvited = useSelector(state=> state.enrollment.enrollInvited)
    useEffect(()=>{
        if(user && user.viewOption == null){
        navigate('/changeview')
    }
    },[user, navigate])

    useEffect(()=>{
        if(user){
            dispatch(listCourse())
        }
    },[user])
    useEffect(()=>{
        if(courses.length){
            let publics=[]
            let unPublics=[]
            for(let course of courses){
                if(course.workflow_state=='available'){
                    publics.push(course)
                }else if(course.workflow_state=='unpublished'){
                    unPublics.push(course)
                }
            }
            setPublishCourse(publics)
            setUnPublishCourse(unPublics)
        }else{
            setUnPublishCourse([])
            setPublishCourse([])
        }
    },[courses])
    const handleOpenCreateForm=()=>{
        setIsOpenCreateForm(true)
    }
    const handleCloseCreateForm=()=>{
        setIsOpenCreateForm(false)
    }

    useEffect(()=>{
        dispatch(listEnrollInvited())
    },[])

    return <div className={'w-screen bg-white min-h-screen'}>
        <GlobalHeader page={'courses'} />
        <div className={'border-b-2'}>
            <div className={'container mx-auto'}>
                <div className={'py-3 flex space-x-3 items-center'}>
                    <span onClick={()=>{window.open('/', '_self')}} className={'text-7A86A1 text-[14px] hover:cursor-pointer'}>{t('home')} </span>
                    <span><img src={ArrowDowIcon} alt={'arrow'}/></span>
                    <span onClick={()=>{window.open('/courses', '_self')}} className={'text-7A86A1 text-[14px] hover:cursor-pointer'}>{t('course')} </span>
                </div>
            </div>
        </div>

        <div className={'container mx-auto  min-h-[500px] px-2'} >
            {enrollInvited && enrollInvited.map(x=><InviteEnroll enrollment={x}/>)}
            <div className={'w-full rounded-3xl py-[20px] bg-white'}>
                <SimpleButton
                    name={t('course.createCourseBtn')}
                    icon={AddIcon}
                    bgColor={'bg-6149CD'}
                    textColor={'text-white'}
                    textSize={'text-[14px]'}
                    handleClick={handleOpenCreateForm}
                />
            </div>
            {courseStatus != 'idle'?<div>
                    <ListCourseSkeleton title={t('dashboard.publicCourse')}/>
                    <ListCourseSkeleton title={t("dashbaord.unpublishCourse")}/>
            </div>:<div>
                <ListCourse title={t('dashboard.publicCourse')} listCourse={publishCourse}/>
                <ListCourse title={t("dashbaord.unpublishCourse")} listCourse={unPublishCourse}/>
            </div>}

        </div>
        <Footer />
            <SimpleModal closeModal={handleCloseCreateForm} isOpen={isOpenCreateForm}>
                <CreateCourseForm  handleClose={handleCloseCreateForm} />
            </SimpleModal>

    </div>
}
