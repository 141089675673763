import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getAllSubmissionAPI, submitSpeedGradeAPI} from "../../services/SubmissionService";

const initState = {
    status: 'idle',
    submissions:[]
}

export const getAllSubmission = createAsyncThunk('speedGrader/getAllAssignment',async({courseId, assignmentId})=>{
    let submissions = await getAllSubmissionAPI(assignmentId, courseId)
    return submissions.data
})

export const speedGradeSubmit= createAsyncThunk('speedGrader/submitSpeedGrade',async({
    megaMarkData,
    courseId,
    assignmentId,
    submissionId,
    grade,
    studentId,
    attempt,
    submissionType
                                                                                     })=>{
    let gradeResult = await submitSpeedGradeAPI(courseId, assignmentId, submissionId, grade, studentId, megaMarkData, attempt, submissionType)
    return gradeResult.data
})


const SpeedGraderSlice = createSlice({
    name: 'speedGrader',
    initialState: initState,
    reducers:{},
    extraReducers:builder => {
        builder.addCase(getAllSubmission.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(getAllSubmission.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.submissions= action.payload
        }).addCase(getAllSubmission.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(speedGradeSubmit.pending, (state,action)=>{
            state.status= 'pending'
        }).addCase(speedGradeSubmit.fulfilled, (state,action)=>{
            state.status = 'idle'
        }).addCase(speedGradeSubmit.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        })
    }
})



export default SpeedGraderSlice.reducer
