import {axiosPrivate} from "../../commons/services/axiosService";

export const getModulesAPI=(courseId)=>{
    return axiosPrivate({
        method:'GET',
        url:`canvasModule/listModuleInCourse?courseId=${courseId}`
    })
}

export const getModuleItemsAPI = (courseId, moduleId)=>{
    return axiosPrivate({
        method:'GET',
        url:`canvasModule/listModuleItems?courseId=${courseId}&moduleId=${moduleId}`
    })
}

export const deleteModuleAPI = (courseId, moduleId)=>{
    return axiosPrivate({
        method:'DELETE',
        url:`canvasModule/deleteModule?courseId=${courseId}&moduleId=${moduleId}`
    })
}

export const createModuleAPI = (courseId, moduleName)=>{
    return axiosPrivate({
        method:'POST',
        url:`canvasModule/createNewModule`,
        data:{
            moduleName: moduleName,
            courseId: courseId
        }
    })
}

export const editModuleAPI = (courseId, moduleName, moduleId)=>{
    return axiosPrivate({
        method:"PUT",
        url:'canvasModule/editModule',
        data:{
            moduleName: moduleName,
            courseId: courseId,
            moduleId: moduleId
        }
    })
}

export const deleteModuleItemAPI = (courseId, moduleId, itemId)=>{
    return axiosPrivate({
        method:'DELETE',
        url:`canvasModule/deleteItem?courseId=${courseId}&moduleId=${moduleId}&itemId=${itemId}`,

    })
}

export const createModuleItemAPI = (courseId, moduleId,item)=>{
    return axiosPrivate({
        method:'POST',
        url:`canvasModule/createItem`,
        data:{
            courseId: courseId,
            moduleId: moduleId,
            item: item
        }
    })
}

export const changeModuleStateAPI =(courseId, moduleId, published)=>{
    return axiosPrivate({
        method:"PUT",
        url: `canvasModule/changeModuleState`,
        data: {
            courseId: courseId,
            moduleId: moduleId,
            published: published
        }
    })
}

export const changeModuleItemStateAPI=(courseId, moduleId, itemId, published)=>{
    return axiosPrivate({
        method: "PUT",
        url: `canvasModule/changeModuleItemState`,
        data:{
            courseId: courseId,
            moduleId: moduleId,
            published: published,
            itemId: itemId
        }
    })
}
