import {axiosPrivate} from "../../../commons/services/axiosService";

export const inviteUserAPI=(emails, limit,type ,sectionId)=>{
    return axiosPrivate({
        url: `enrollment/invite`,
        method:'POST',
        data:{
            emails:emails,
            limit:limit,
            type:type,
            sectionId:sectionId
        }
    })
}

export const listAllEnrollmentSectionAPI = (courseId,sectionId)=>{
    return axiosPrivate({
        url: `enrollment/course/${courseId}/section/${sectionId}/listAll`,
        method:"GET"
    })
}


export const concludeEnrollmentAPI = (courseId, enrollmentId)=>{
    return axiosPrivate({
        url:`enrollment/conclude?courseId=${courseId}&enrollmentId=${enrollmentId}`,
        method:'DELETE'
    })
}

export const listEnrollInvitedAPI = ()=>{
    return axiosPrivate({
        url:`enrollment/listEnrollInvited`,
        method:'GET'
    })
}

export const replyInviteAPI=(courseId, enrollId, type)=>{
    return axiosPrivate({
        url:`enrollment/replyInvite`,
        method:"POST",
        data:{
            courseId: courseId,
            enrollId: enrollId,
            type:type
        }
    })
}
