import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {sendMessageAPI} from "../../services/chatGPTService";


const initState = {
    state: 'idle'
}


export const sendMessage =createAsyncThunk('chat/sendMessage',async(msg)=>{
    let response = await sendMessageAPI(msg)
    return response.data
})

const chatGPTSlice = createSlice({
    name:'chatGPT',
    initialState: initState,
    reducers:{},
    extraReducers:builder => {
        builder.addCase(sendMessage.pending,(state,action)=>{
            state.status = 'pending'
        }).addCase(sendMessage.fulfilled, (state,action)=>{
            console.log(action.payload)
            state.status='idle'
        }).addCase(sendMessage.rejected, (state,action)=>{
            console.log(action.error)
            state.status='idle'
        })
    }
})

export default chatGPTSlice.reducer
