import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    changeQuizStateAPI,
    getListQuizQuestionAPI,
    getQuizByIdAPI,
    updateListQuestionsAPI,
    updateQuestionAPI
} from "./QuizDetailService";

const initState={
    quiz: null,
    status: 'idle',
    questions:[]
}
export const getQuizById = createAsyncThunk('quizDetail/getQuizById', async(input)=>{
    let quiz = await getQuizByIdAPI(input.courseId, input.quizId)
    return quiz.data
})

export const getListQuestion = createAsyncThunk('quizDetail/listQuestion', async(input)=>{
    let question = await getListQuizQuestionAPI(input.courseId, input.quizId)
    return question.data
})

export const updateQuestion = createAsyncThunk('quizDetail/updateQuestion', async (input)=>{
    let question = await updateQuestionAPI(input.courseId, input.quizId, input.questionId, input.question)
    return question.data
})

export const updateListQuestion = createAsyncThunk('quizDetail/updateListQuestion', async({courseId, quizId, questions})=>{
    let result = await updateListQuestionsAPI(courseId, quizId, questions)
    return result.data
})


export const changeQuizState=createAsyncThunk('quizDetail/changeQuizState',async({
    courseId, quizId, publish
                                                                                 })=>{
    let quiz = await changeQuizStateAPI(courseId, quizId, publish)
    return quiz.data
})


const QuizDetailSlice = createSlice({
    name: 'quizDetail',
    initialState: initState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getQuizById.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(getQuizById.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.quiz = action.payload
        }).addCase(getQuizById.rejected, (state,action)=>{
            state.status = 'dile'
            console.log(action.error)
        }).addCase(getListQuestion.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(getListQuestion.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(getListQuestion.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.questions= action.payload
        }).addCase(updateQuestion.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(updateQuestion.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(updateQuestion.fulfilled, (state,action)=>{
            state.status = 'idle'
        }).addCase(updateListQuestion.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(updateListQuestion.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(updateListQuestion.fulfilled, (state,action)=>{
            state.status = 'idle'
        }).addCase(changeQuizState.pending, (state,action)=>{
            state.status ='pending'
        }).addCase(changeQuizState.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.quiz = action.payload
        }).addCase(changeQuizState.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        })
    }
})

export default QuizDetailSlice.reducer
