import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  authenticationAPI,
  logOutAPI,
  mgeAuthenticationAPI,
  refreshTokenAPI,
  setViewOptionAPI,
  signInAPI
} from "./loginService";

const initialState = {
  user: null,
  status: 'idle',
};

export const googleAuthentication = createAsyncThunk('login/google-authentication', async(accessToken)=>{
  let response = await signInAPI(accessToken)
  return response.data
})

export const getUserInfo = createAsyncThunk('login/getuser', async()=>{
  let response = await authenticationAPI()
  return {
    user: response.data
  }
})

export const mgeAuth=createAsyncThunk('login/mgeLogin', async(accessToken)=>{
  let response = await mgeAuthenticationAPI(accessToken)
  return {
    user: response.data
  }
})

export const refreshToken = createAsyncThunk('login/refresh', async()=>{
  let response = await refreshTokenAPI()
  return response.data
})


export const setViewOption = createAsyncThunk('login/setviewoption', async (viewOption)=>{
  await setViewOptionAPI(viewOption)
  return viewOption
})

export const logOut = createAsyncThunk('login/log-out', async()=>{
  let result = await logOutAPI()
  return result.data
})

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(googleAuthentication.pending, (state, action)=>{
      state.status = 'loading'
    }).addCase(googleAuthentication.fulfilled, (state,action)=>{
      state.status = 'idle'
      state.user = action.payload
    }).addCase(googleAuthentication.rejected,(state,action)=>{
      state.status = 'idle'
      state.user = null
    }).addCase(refreshToken.pending, (state,action)=>{
      state.status ='loading'
    }).addCase(refreshToken.fulfilled, (state,action)=>{
      state.status = 'idle'
      state.user = action.payload
    }).addCase(getUserInfo.pending, (state,action)=>{
      state.status = 'loading'
    }).addCase(getUserInfo.fulfilled, (state,action)=>{
      state.status = 'idle'
      state.user = action.payload.user
    }).addCase(getUserInfo.rejected, (state,action)=>{
      state.status = 'idle'
      // state.user = null
    }).addCase(setViewOption.pending, (state,action)=>{
      state.status = 'loading'
    }).addCase(setViewOption.fulfilled, (state,action)=>{
      state.staus = 'idle'
      let _user = {...state.user}
      _user.viewOption = action.payload
      state.user = {..._user}
    }).addCase(setViewOption.rejected, (state,action)=>{
      console.log(action.error)
      state.status = 'idle'
    }).addCase(logOut.pending, (state,action)=>{
      state.status= 'loading'
    }).addCase(logOut.fulfilled, (state,action)=>{
      state.status = 'idle'
    }).addCase(logOut.rejected, (state,action)=>{
      state.status = 'idle'
    }).addCase(mgeAuth.pending, (state, action)=>{
      state.status = 'loading'
    }).addCase(mgeAuth.fulfilled, (state,action)=>{
      state.status = 'idle'
      console.log(action.payload)
      state.user = action.payload
    }).addCase(mgeAuth.rejected,(state,action)=>{
      state.status = 'idle'
      state.user = null
    })
  },
});


export default loginSlice.reducer;
