import React, {Fragment} from 'react'
import avatarDefault from "../../../assets/images/avatars/account.png";
import { Menu, Transition } from '@headlessui/react'
import UserIcon from '../../../assets/images/icons/user.svg'
import settingIcon from '../../../assets/images/icons/settings.svg'
import moveLeftIcon from '../../../assets/images/icons/move-left.svg'
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {logOut} from "../../../features/logins/loginSlice";
import userManager from "../../../config/configSSO";
import { useTranslation } from 'react-i18next';
export default function MenuDropdownUser({openPopup}){

    const navigate = useNavigate()
    const {t, i18n} = useTranslation()

    const dispatch = useDispatch()
    const handleLogout= ()=>{
        // userManager.signoutRedirect();
        dispatch(logOut()).unwrap().then(res=>{
            userManager.getUser().then(user=>{
                if(user){
                    userManager.signoutRedirect();
                }
                else{
                    window.open('/login','_self')
                }
            }).catch(err=>{
                window.open('/login','_self')
            })

        })
    }

    return <div className="">
        <Menu as="div" className="relative inline-block text-left" style={{'zIndex':100}}>
            <div>
                <Menu.Button className="inline-flex w-full justify-center rounded-md  px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                    <img alt={'avatar'} src={avatarDefault} className={'w-[50px] h-[45px]'}/>
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute z-100 right-0 mt-2 w-[300px] origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="px-1 py-1 z-100 ">
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    onClick={()=>{openPopup()}}
                                    className={`${
                                        active ? 'bg-violet-500 text-white z-100' : 'text-7A86A1'
                                    } group flex w-full items-center rounded-md px-2 py-2 text-[16px] uppercase`}
                                >
                                    <img src={UserIcon} className={'px-2'} />
                                    {t('profileTitle')}
                                </button>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    onClick={()=>{window.location.href='/changeview'}}
                                    className={`${
                                        active ? 'bg-violet-500 text-white' : 'text-7A86A1'
                                    } group flex w-full items-center rounded-md px-2 py-2 text-[16px] uppercase`}
                                >
                                    <img src={UserIcon} className={'px-2'} />
                                    {t('changeRole')}
                                </button>
                            )}
                        </Menu.Item>
                        {/*<Menu.Item>*/}
                        {/*    {({ active }) => (*/}
                        {/*        <button*/}
                        {/*            className={`${*/}
                        {/*                active ? 'bg-violet-500 text-white' : 'text-7A86A1'*/}
                        {/*            } group flex w-full items-center rounded-md px-2 py-2 text-[16px] z-100 uppercase`}*/}
                        {/*        >*/}
                        {/*            <img src={settingIcon} className={'px-2'} />*/}
                        {/*            Cài đặt*/}
                        {/*        </button>*/}
                        {/*    )}*/}
                        {/*</Menu.Item>*/}
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    onClick={handleLogout}
                                    className={`${
                                        active ? 'bg-violet-500 text-white' : 'text-7A86A1'
                                    } group flex w-full items-center rounded-md px-2 py-2 text-[16px] uppercase`}
                                >
                                    <img src={moveLeftIcon} className={'px-2'} />
                                   {t('logout')}
                                </button>
                            )}
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    </div>
}
