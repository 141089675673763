import React from 'react'
import {axiosPrivate} from "../commons/services/axiosService";

export const createQuizSubmissionAPI = (courseId, quizId)=>{
    return axiosPrivate({
        url: `quizSubmission/create`,
        method:"POST",
        data:{
            quizId: quizId,
            courseId: courseId
        }
    })
}

export const getQuestionAPI = (submissionId)=>{
    return axiosPrivate({
        method: "GET",
        url:`quizSubmission/${submissionId}/submissionId`
    })
}

export const answerQuestionAPI =(submissionId, attempt, validateToken, quiz_question)=>{
    return axiosPrivate({
        method: 'POST',
        url: `quizSubmission/${submissionId}/answerQuestion`,
        data:{
            attempt:attempt,
            validateToken: validateToken,
            quiz_question: quiz_question
        }
    })
}

export const completeSubmitAPI = (submissionId, attempt, validateToken, courseId, quizId)=>{
    return axiosPrivate({
        method: 'POST',
        url: `quizSubmission/${submissionId}/complete`,
        data:{
            attempt: attempt,
            validateToken: validateToken,
            courseId: courseId,
            quizId: quizId
        }
    })
}
