import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getStudentSummaryAPI} from "../../services/reportService";

const initState={
    status: 'idle',
    summary: null,
    requirement_count: 0,
    requirement_completed_count:0,
    totalAssignment: 0,
    totalAssignmentSummited: 0,
    activityTimes: []
}

export const getStudentSummary = createAsyncThunk('reportStudent/studentSummary', async()=>{
    let summary = await  getStudentSummaryAPI()
    return summary.data
})

const reportStudentSlice = createSlice({
    name:'reportStudent',
    initialState: initState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getStudentSummary.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(getStudentSummary.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.summary = action.payload.courseSummary
            state.requirement_count = action.payload.requirement_count
            state.requirement_completed_count = action.payload.requirement_completed_count
            let _ass = action.payload.assignmentSubmitReport
            let totalAss = 0
            let totalAssUnSub = 0
            for(let info of _ass){
                totalAss+=parseInt(info.count)
                if(info.workflow_state=='unsubmitted'){
                    totalAssUnSub = parseInt(info.count)
                }
            }
            state.totalAssignment = totalAss
            state.totalAssignmentSummited = totalAss-totalAssUnSub
            state.activityTimes = action.payload.totalActivityTimes

        }).addCase(getStudentSummary.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        })

    }
})

export default reportStudentSlice.reducer
