import { Dialog, Transition } from '@headlessui/react'
import {Fragment, useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'

export default function EditModuleDialog({isOpen, closeModal, editModule, module}) {

    const {t, i18n} = useTranslation()

    const [moduleName, setModuleName] = useState("")

    useEffect(()=>{
        if(module){
            setModuleName(module.name)
        }
    },[module])

    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg  leading-6 text-black text-center font-semibold "
                                    >
                                        {t("modules.editTitle")}
                                    </Dialog.Title>
                                    <div className={'mt-2'}>
                                        <input
                                            className={'bg-F0F0F0  w-full h-[40px] rounded px-2'}

                                            value={moduleName} onChange={e=>{setModuleName(e.target.value)}} />
                                    </div>


                                    <div className="mt-4">
                                        <button
                                            type="button"
                                            className=" inline-flex justify-center rounded border border-transparent bg-6149CD w-full text-white  px-4 py-2 text-sm font-medium hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={()=>{editModule(moduleName, module)}}

                                        >
                                           {t("modules.editTitle")}
                                        </button>
                                        <button
                                            type="button"
                                            className=" inline-flex mt-3 justify-center rounded border border-transparent  w-full text-black  px-4 py-2 text-sm font-medium hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={closeModal}
                                        >
                                            {t("cancelBtn")}
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
