import React, {useCallback, useEffect, useRef, useState} from 'react'
import LogoIcon from '../../assets/images/logos/logo3.svg'
import BannerIcon from '../../assets/images/banner.png'
// import GoogleLogin from "react-google-login";
import bgLogin from '../../assets/images/bgLogin.png'
import {useDispatch, useSelector} from "react-redux";
import {googleAuthentication, mgeAuth, refreshToken} from "./loginSlice";
import arrowRight from '../../assets/images/icons/arrowdow.svg'
import {useNavigate, useParams} from "react-router-dom";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { gapi } from 'gapi-script';
//gapi.load("client:auth2", () => { gapi.client.init({ clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID, plugin_name: "chat", }); });

import {GoogleLogin, useGoogleOneTapLogin} from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';
import userManager from "../../config/configSSO";
import { useTranslation } from 'react-i18next';
import { Switch } from '@headlessui/react';


export default function Login(){
    const [language, setLanguage] = useState({name:"Việt Nam", id:"vn"})
    const {t, i18n} = useTranslation()
    useEffect(()=>{
        if(i18n.language !='vn'){
            setLanguage({name: 'English', id:'en'})
        }else{
            setLanguage({name: 'Việt Nam', id:'vn'})
        }

    },[])
    const changeLangauge = (lng) => {
        if(lng){
            i18n.changeLanguage("vn");
            setLanguage({name: 'Việt Nam', id:'vn'})
        }else{
            i18n.changeLanguage("en");
            setLanguage({name: 'English', id:'en'})
        }
       
    }
const [flag,setFlag] = useState(false)

    const handleLogin = useGoogleLogin({
        onSuccess: tokenResponse => handleLoginSuccess(tokenResponse),
    })

    const user = useSelector(state=>state.auth.user)
    let ref = useRef()
    const params= useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    const handleLoginSuccess = (response)=>{
        if('access_token' in response){
            const accessToken = response.access_token
            dispatch(googleAuthentication(accessToken)).unwrap().then(data=>{
                navigate('/')
            }).catch(err=>{
                toast.error(err.message)
            })
        }else{
            toast.error('not found access token')
        }
    }

    const handleLoginMGE = ()=>{
        userManager.getUser().then(user => {
            if (user) {
                console.log(user)
                dispatch(mgeAuth(user.access_token)).unwrap().then(user=>{
                    navigate('/')
                }).catch(err=>{
                    userManager.signinRedirect();
                })
            } else {
                userManager.signinRedirect();
            }

        }).catch(err=>{
            console.log(err)
        })
    }



    useEffect(()=>{
        if(params.oauth2 && document && document!==null){
          
            dispatch(refreshToken()).unwrap().then(res=>{
                navigate('/')
            }).catch(err=>{
                document.getElementById('btnlogin').click()
            })
        }else{
            dispatch(refreshToken()).unwrap().then(res=>{
                navigate('/')
            })
        }

    },[params, document, user])




    return <div className={'flex justify-center items-center flex-col h-screen space-y-10  '}  style={{backgroundImage:`url(${bgLogin})`, backgroundRepeat:"no-repeat", backgroundSize: "cover" }}>
        <div className={'flex flex-col justify-center items-center'}>
            <img className={'w-[100px] md:w-[300px]'} src={LogoIcon} alt={'logo'}/>
            <p className={'text-[14px] md:text-[20px]'}>{t("LMS")}</p>
        </div>
        <div className={'bg-6E48C3 p-2 rounded-3xl'}>
            <button
                id={'btnlogin'}
                ref={ref}
                className={' flex items-center justify-center space-x-5 rounded-3xl text-white border-dashed border-2 border-sky-500 bg-6E48C3 p-[10px] w-[280px]   md:w-[300px]' }
                onClick={()=>{handleLogin()}} >
                <span>{t("googleLogin")}</span>
                <img src={arrowRight} className={'rounded-full p-1 w-5 h-5 bg-indigo-50'}/>
            </button>
        </div>

        <div className={'bg-6E48C3 p-2 rounded-3xl'}>
            <button
                id={'btnlogin2'}
                ref={ref}
                className={' flex items-center justify-center space-x-5 rounded-3xl text-white border-dashed border-2 border-sky-500 bg-6E48C3 p-[10px] w-[280px]   md:w-[300px]' }
                 onClick={()=>{handleLoginMGE()}} >
                <span>{t("HCMLogin")}</span>
               <img src={arrowRight} className={'rounded-full p-1 w-5 h-5 bg-indigo-50'}/>
           </button>
        </div>
        <div className={'text-[14px] font-semibold text-7A86A1 flex space-x-2'}>
                        <Switch
                            checked={language.id=="vn"}
                            onChange={changeLangauge}
                            className={`${
                                language.id=="vn" ? 'bg-blue-600' : 'bg-gray-200'
                            } relative inline-flex h-6 w-11 items-center rounded-full`}
                            >
                            <span className="sr-only">VN</span>
                            <span
                                className={`${
                                    language.id=="vn" ? 'translate-x-6' : 'translate-x-1'
                                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                            />
                            </Switch>
                            <span>{language.name}</span>

                        </div>

        <ToastContainer  position="top-center"
                         autoClose={5000}
                         hideProgressBar={false}
                         newestOnTop={false}
                         closeOnClick
                         rtl={false}
                         pauseOnFocusLoss
                         draggable
                         pauseOnHover
                         theme="light"/>
    </div>
}
