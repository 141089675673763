import React, {useState, useEffect, useRef} from 'react'
import DropdownSimple2 from "../../commons/components/dropdowns/DropdownSimple2";
import {useDispatch, useSelector} from "react-redux";
import {getAssignmentGroups} from "../courseDetail/assignmentTab/AssignmentGroupSlice";
import SimpleCheckbox from "../../commons/components/checkbox/SimpleCheckbox";
import AssignTo from "../courseDetail/assignmentTab/AssignTo";
import {toast} from "react-toastify";
import {listSectionInCourse} from "../courseDetail/peopleTab/SectionSlice";
import RCE from "../editor/RCE";
import { useTranslation } from 'react-i18next';

export default function QuizInfo({course, isEdit,_listAssignTo, quizInfo, setQuizInfo}){
    const decriptionRef = useRef(null);
    const listSections  = useSelector(state =>state.section.sections)
    const [quizName, setQuizName] = useState("")
    const [quizDesc, setQuizDesc] = useState("")
    const [groupSelect, setGroupSelect] = useState()
    const [listGroup, setListGroup] = useState([])
    const groups = useSelector(state=>state.assignmentGroup.groups)
    const [swapQuest, setSwapQuest] = useState(false)
    const [limitTime, setLimitTime] = useState(false)
    const [timeLimit, setTimeLimit] = useState(null)
    const [numberAttempt, setNumberAttempt] = useState(-1)
    const [multiAttempt, setMultiAttempt] = useState(false)
    const [listAssignTo,setListAssignTo] = useState([{}])
    const dispatch = useDispatch()
    const {t, i18n} = useTranslation()



    useEffect(()=>{
        if(quizName){
            setQuizInfo({...quizInfo, title: quizName})
        }
    },[quizName])
    useEffect(()=>{
        if(quizDesc){
            setQuizInfo({...quizInfo, description: quizDesc})
        }
    },[quizDesc])


    useEffect(()=>{
        setQuizInfo({...quizInfo,time_limit: timeLimit })
    },[timeLimit])

    useEffect(()=>{
        setQuizInfo({...quizInfo,shuffle_answers: swapQuest })
    },[swapQuest])

    useEffect(()=>{
        setQuizInfo({...quizInfo, allowed_attempts: numberAttempt })
    },[numberAttempt])


    useEffect(()=>{
        setQuizInfo({...quizInfo, assignment_overrides: listAssignTo})
    },[listAssignTo])


    useEffect(()=>{
        if(groupSelect){
            setQuizInfo({...quizInfo, assignment_group_id: groupSelect.value})
        }

    },[groupSelect])

    useEffect(()=>{
        dispatch(getAssignmentGroups(course.id))
    },[])

    useEffect(()=>{
        if(quizInfo.title){
            setQuizName(quizInfo.title)
        }
        if(quizInfo.description){
            setQuizDesc(quizInfo.description)
        }
        setSwapQuest(quizInfo.shuffle_answers)
        if(quizInfo.time_limit){
            setTimeLimit(quizInfo.time_limit)
            setLimitTime(true)
        }
        if(quizInfo.allowed_attempts){
            setNumberAttempt(quizInfo.allowed_attempts)
            setMultiAttempt(true)
        }
    },[quizInfo])

    useEffect(()=>{

        if(groups){
            let listGroup = []
            for(let _group of groups){
                listGroup.push({
                    name: _group.name,
                    value: _group.id
                })
            }
            setListGroup(listGroup)

        }
    },[groups])

    useEffect(()=>{
        dispatch(listSectionInCourse(course.id))
    },[course])


    useEffect(()=>{
        if(_listAssignTo && _listAssignTo.length >0){
            setListAssignTo(_listAssignTo)
        }
    },[_listAssignTo])

    const handleRemoveAssignTo = (index)=>{
        let assignTos = [...listAssignTo]
        if(assignTos.length > index && assignTos.length !=1){
            assignTos.splice(index,1)
            setListAssignTo(assignTos)
        }

    }

    const handleAddNewAssignto = ()=>{
        let _assignTo = [...listAssignTo]
        for(let asTo of _assignTo){
            if(asTo.sectionSelects == null){
                toast.error(t("assignment.assignToError"))
                return
            }
        }
        setListAssignTo([...listAssignTo, {}])
    }

    const getContentData= ()=>{
        setQuizDesc(decriptionRef.current.getContent())
    }

    return <div className={'py-5'}>
        <div className={'pb-5'}>
            <span className={'text-[14px]'}>{t("assignment.AssignmentName")}</span>
        </div>
        <input
            value={quizName}
            onChange={(e)=>{setQuizName(e.target.value)}}
            className={'bg-F0F0F0 rounded text-[14px] h-[40px] w-full px-2'} />
        <div className={'py-5'}>
            <span>{t( "assignment.assignmentDescripion")}</span>
        </div>
        <RCE editorRef={decriptionRef} onBlur={getContentData} content={quizDesc}/>
        <div>
            <div className={'py-5'}><span className={'text-[14px]'}>{t("assignment.groupAss")}</span></div>
            <DropdownSimple2 background={'bg-F0F0F0'} selectData={setGroupSelect} listData={listGroup} disable={isEdit} />
        </div>
        <div className={'py-5'}>
            <span className={'font-semibold text-[14px]'}>
               {t("quiz.option")}
            </span>
        </div>
        <div className={'pb-5'}>
            <SimpleCheckbox  text={t("quiz.sufans")}
                             checked={swapQuest}
                             value={!swapQuest}
                             click={setSwapQuest}
            />
        </div>
        <div className={'pb-5 flex space-x-3 items-center '}>
            <div className={''}>
                <SimpleCheckbox text={t("quiz.timeLimit")}
                                checked={limitTime}
                                value={!limitTime}
                                click={setLimitTime}
                />
            </div>

            {limitTime && <div className={'flex space-x-3 items-center'}>
                <input type={"number"} value={timeLimit}
                        onChange={e=>{setTimeLimit(e.target.value)}}
                       className={'bg-F0F0F0 h-[40px] rounded-xl w-[80px] px-2 text-[14px]'}/>
                <span className={'text-[14px]'}>{t('minutes')}</span>
            </div>}
        </div>
        <div className={'pb-5 flex space-x-3 items-center'}>
            <div>
                <SimpleCheckbox
                    text={t("quiz.MutipleAttempt")}
                    checked={multiAttempt}
                    value={!multiAttempt}
                    click={setMultiAttempt}
                />
            </div>
            {multiAttempt &&  <div className={'flex space-x-3 items-center'}>
                <input type={"number"}
                       onChange={(event)=>{setNumberAttempt(event.target.value)}}
                       value={numberAttempt}  className={'bg-F0F0F0 h-[40px] rounded-xl w-[80px] px-2 text-[14px]'}/>

                <span className={'text-[14px]'}>{t("quiz.timeAttempt")}</span>
            </div>}
        </div>
        <div className={'py-5 max-w-2xl '}>
            {listAssignTo && listAssignTo.map((x,i)=> <div>
                <AssignTo listAssignTo={listAssignTo} setListAssignTo={setListAssignTo} index={i} handleRemoveAssignTo={handleRemoveAssignTo} assignTo={x} listSection={listSections} _selectOptions={ x.sectionSelects}  />
            </div>)}
            <button onClick={()=>{
                handleAddNewAssignto()
            }
            } className={'h-[40px] mt-3 w-full rounded text-[12px] bg-F0F0F0'}>{t("AddBtn")}</button>
        </div>

    </div>
}
