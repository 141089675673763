import React, {useEffect, useState} from 'react'
import Select from 'react-select';
import DateTimePickerSimple from "../../../commons/components/datetimes/DateTimePickerSimple";
import CloseIcon from '../../../assets/images/icons/close.svg'
import {toast} from "react-toastify";
import { useTranslation } from 'react-i18next';
export default function AssignTo({listAssignTo, setListAssignTo,assignTo, listSection, handleRemoveAssignTo, index, _selectOptions}){

    const [selections, setSelections] = useState([])
    const [selectionOption, setSelectionOption] = useState(null)
    const {t, i18n} = useTranslation()

    useEffect(()=>{
        if(_selectOptions && _selectOptions.length>0){
            setSelectionOption(_selectOptions)
        }
    },[_selectOptions])
    useEffect(()=>{
        let list = [...listAssignTo]
        list[index].sectionSelects = selectionOption
        setListAssignTo(list)
    },[selectionOption])
    useEffect(()=>{
        if(listSection.length){
            let list_selections = []
            for(let section of listSection){
                list_selections.push({
                    value: section.id,
                    label: section.name
                })
            }
            setSelections(list_selections)
        }
    },[listSection])

    let setStartDate=(startDate)=>{
        let list = [...listAssignTo]
        list[index].startDate = startDate
        setListAssignTo(list)
    }
    let setDueDate=(dueDate)=>{
        let list = [...listAssignTo]
        list[index].dueDate = dueDate
        setListAssignTo(list)
    }
    let setEndDate=(endDate)=>{
        let list = [...listAssignTo]
        list[index].endDate = endDate
        setListAssignTo(list)
    }
    return <div className={'border rounded px-5 py-3 my-2 max-w-2xl'}>
        <div className={'w-full flex justify-end'}>
            <img
                onClick={()=>{handleRemoveAssignTo(index)}}
                className={'hover:cursor-pointer'} src={CloseIcon}/>
        </div>
        <div>
            <div className={'py-5'}><span className={'text-[14px]'}>{t("assignTo.assignTo")}</span></div>
            <Select classNamePrefix={'bg-F0F0F0 rounded border-none'} className={'bg-F0F0F0 '} value={selectionOption} isMulti={true} onChange={setSelectionOption} options={selections} />
        </div>
        <div className={'py-5'}>
            <div>
                <div className={'py-5'}><span className={'text-[14px]'}>{t( "assignTo.dueDate")}</span></div>
                <DateTimePickerSimple dateTime={assignTo.dueDate} setDateTime={setDueDate} />
            </div>
        </div>
        <div className={'flex space-x-2'}>
            <div>
                <div className={'py-5'}><span className={'text-[14px]'}>{t("assignTo.avaiableDate")}</span></div>
                <DateTimePickerSimple dateTime={assignTo.startDate} setDateTime={setStartDate} />
            </div>
            <div>
                <div className={'py-5'}><span className={'text-[14px]'}>{t("assignTo.utilDate")}</span></div>
                <DateTimePickerSimple dateTime={assignTo.endDate} setDateTime={setEndDate} />
            </div>
        </div>
    </div>
}
