import React, {useEffect, useState} from 'react'
import {useDispatch} from "react-redux";
import {getModulesItems} from "../moduleTab/ModuleSlice";
import {getCourseActionNumber} from "./DashboardStudentSlice";
import { useTranslation } from 'react-i18next';


export default  function ModuleItem({ course}){
    const {t, i18n} = useTranslation()
    const dispatch = useDispatch()
    const [actionNumber, setActionNumber] = useState(0)
    useEffect(()=>{
        dispatch(getCourseActionNumber(course.id)).unwrap().then(actionNumber=>{
            setActionNumber(actionNumber)
        })
    },[ course])
    return <div className={'flex justify-between space-x-3'}>
        <div className={'text-black text-[14px]'}>{course.name.length > 25?course.name.substring(0,25)+"...":course.name }</div>
        <div className={'text-right text-[14px] text-4B4DA4'}>{actionNumber} {t( "dashboar.moduleAction")}</div>
    </div>
}

