import React, {useEffect, useState} from 'react'
import clockIcon from '../../assets/images/icons/clock.svg'
import {useDispatch} from "react-redux";
import {concludeEnroll, getEnrollInfo} from "./CourseStudentSlice";
import {useNavigate} from "react-router-dom";
import SimpleModal from "../../commons/modals/SimpleModal";
import {listCourse} from "../courses/courseSlice";
import { useTranslation } from 'react-i18next';
export default function CourseProgress({course}) {

    const {t, i18n} = useTranslation()
    const randomColor=(process)=> {
        if(process< 100){
            return '#FF7439'
        }else{
            return "#09BD3C"
        }
    }
    const [enrollInfo, setEnrollInfo] = useState()
    const [progress, setProgress] = useState()
    const [process, setProcess] = useState(0)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [isConfirm, setIsConfirm] = useState(false)

    const handleCloseConfirm=()=>{
        setIsConfirm(false)
    }

    useEffect(()=>{
        if(progress && progress.requirement_count){
                setProcess((progress.requirement_completed_count / progress.requirement_count * 100).toFixed(2))
        }
    },[progress])

    useEffect(()=>{
        if(course && course.course_progress){setProgress(course.course_progress)}
    },[course])
    useEffect(()=>{
        if(course){
            dispatch(getEnrollInfo(course.id)).unwrap().then(enrollInfo=>{
                if(enrollInfo.length){
                    for(let enroll of enrollInfo){
                        if(enroll.role=='StudentEnrollment'){
                            console.log(enroll)
                            setEnrollInfo(enroll)
                            break
                        }
                    }
                }

            })
        }

    },[course])

    const handleConclude=()=>{
        if(enrollInfo){
            dispatch(concludeEnroll({
                courseId: course.id,
                enrollId: enrollInfo.id
            })).unwrap().then(result=>{
                handleCloseConfirm()
                dispatch(listCourse())
            }).catch(err=>{
                console.log(err)
            })
        }else{
        }
    }

    return <div className={'w-full'}>
        {progress && progress.error?<div>
            <div className="w-full bg-white rounded-full h-2.5 mt-4 ">
                <div  className="h-2.5 rounded-full" style={{width:`${100}%`, background: randomColor(100)}}></div>
                <div className={'flex justify-between text-[14px] py-2 text-7A86A1'}>
                    <div className={'flex'}>
                        <img src={clockIcon} />
                        {enrollInfo && enrollInfo.last_activity_at && <span>{t("course.lastAccess")} {new Date(enrollInfo.last_activity_at).toLocaleDateString()} {new Date(enrollInfo.last_activity_at).toLocaleTimeString()}</span>}
                    </div>
                    <div className={'flex space-x-2'}>
                        <span>{t( "course.completionRate")}</span>
                        <span>{t( "course.dontHaveComplete")}</span>
                    </div>
                </div>
                <div className={'w-full flex space-x-3 justify-end'}>
                    <button
                        onClick={()=>{setIsConfirm(true)}}
                        className={'rounded-xl bg-BB3B00 text-white py-2 px-3 tr-font-semibold '}>{t("course.unernoll")}</button>
                    <button
                        onClick={()=>{navigate(`/courses/${course.id}`)}}
                        className={'rounded-xl bg-6149CD text-white py-2 px-3 tr-font-semibold '}>{t("course.accesstoCourse")}</button>
                </div>
            </div>
        </div>:<div className={'w-full'}>
            <div className={'flex md:hidden text-7A86A1 mt-2'}>
                <img src={clockIcon} />
                {enrollInfo && enrollInfo.last_activity_at && <span>{t("course.lastAccess")} {new Date(enrollInfo.last_activity_at).toLocaleDateString()} {new Date(enrollInfo.last_activity_at).toLocaleTimeString()}</span>}
            </div>
            <div className="w-full bg-white rounded-full h-2.5 mt-4 ">
                <div  className="h-2.5 rounded-full" style={{width:`${process}%`, background: randomColor(process)}}></div>
            </div>
            <div className={'flex justify-start md:justify-between text-[14px] py-2 text-7A86A1'}>
                <div className={'hidden md:flex'}>
                    <img src={clockIcon} />
                    {enrollInfo && enrollInfo.last_activity_at && <span>{t("course.lastAccess")} {new Date(enrollInfo.last_activity_at).toLocaleDateString()} {new Date(enrollInfo.last_activity_at).toLocaleTimeString()}</span>}
                </div>
                <div className={'flex space-x-2'}>
                    <span>{t( "course.completionRate")}</span>
                    {progress &&<span>{progress.requirement_completed_count}/{progress.requirement_count} ({process}%)</span>}
                </div>
            </div>
            <div className={'w-full flex justify-start md:justify-end space-x-3'}>
                <button
                    onClick={()=>{setIsConfirm(true)}}
                    className={'rounded-xl bg-BB3B00 text-white py-2 px-3 tr-font-semibold '}>{t("course.unernoll")}</button>
                <button
                    onClick={()=>{navigate(`/courses/${course.id}`)}}
                    className={'rounded-xl bg-6149CD text-white py-2 px-3 tr-font-semibold '}>{t("course.accesstoCourse")}</button>
            </div>
        </div>}

        <SimpleModal  isOpen={isConfirm} title={'Xác nhận kết thúc'} closeModal={handleCloseConfirm}>
            <div className={'py-5 w-full align-center'}>
                <span className={'text-[18px] '}>{t("course.registermessage1")} {course.name} {t("course.registermessage2")}?</span>
            </div>
            <div className={'flex justify-center  space-x-3'}>
                <button
                    onClick={handleConclude}
                    className={'bg-6149CD tr-font-semibold text-white  py-2 px-3 rounded-xl  min-w-[70px]'}>{t('yesBtn')}</button>
                <button onClick={handleCloseConfirm} className={'bg-009A17 tr-font-semibold text-white  py-2 px-3 rounded-xl  min-w-[70px]'}>{t('noBtn')}</button>
            </div>
        </SimpleModal>
    </div>
}
