import React from 'react'

export default function CourseSkeleton(){
    return <div className=" shadow rounded-md  max-w-sm w-full mx-auto">
        <div className="animate-pulse flex space-x-4">
            <div className="flex-1 space-y-6 py-1">
                <div className="h-[176px] bg-slate-700 rounded"></div>
                <div className="space-y-3">
                    <div className="h-2 bg-slate-700 rounded"></div>
                </div>
            </div>
        </div>
    </div>
}
