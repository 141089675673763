import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import { useEffect } from 'react'
import userManager from "../../config/configSSO";
import { mgeAuth} from "./loginSlice";
import {useNavigate, useParams} from "react-router-dom";
export default function AutoLoginMeagEdu(){
  const user = useSelector(state=>state.auth.user)
    const params= useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
  useEffect(()=>{
    userManager.getUser().then(user => {
      if (user) {
          console.log(user)
          dispatch(mgeAuth(user.access_token)).unwrap().then(user=>{
              navigate('/')
          }).catch(err=>{
              userManager.signinRedirect();
          })
      } else {
          userManager.signinRedirect();
      }

  }).catch(err=>{
      console.log(err)
  })
  },[])
  return <></>
}