import {axiosPrivate} from "../../commons/services/axiosService";


export const getQuizByIdAPI =(courseId, quizId)=>{
    return axiosPrivate({
        method:'GET',
        url:`quizzes/detail?courseId=${courseId}&quizId=${quizId}`
    })
}

export const getListQuizQuestionAPI = (courseId, quizId)=>{
    return axiosPrivate({
        method: "GET",
        url:`quizQuestion/list?courseId=${courseId}&quizId=${quizId}`
    })
}

export const updateQuestionAPI = (courseId, quizId, questionId, question)=>{
    return axiosPrivate({
        method:"PUT",
        url:'quizQuestion/update',
        data:{
            courseId: courseId,
            quizId: quizId,
            questionId: questionId,
            question: question
        }
    })
}

export const updateListQuestionsAPI=(courseId, quizId, questions)=>{
    return axiosPrivate({
        method: "PUT",
        url:'quizQuestion/updateListQuestion',
        data:{
            courseId: courseId,
            quizId: quizId,
            questions: questions
        }
    })
}


export const changeQuizStateAPI=(courseId, quizId, publish)=>{
    return axiosPrivate({
        method:"PUT",
        url: 'quizzes/changeQuizState',
        data:{
            courseId: courseId,
            quizId: quizId,
            publish: publish
        }
    })
}
