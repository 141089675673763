import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getGradeInGroupAPI} from "../../commons/services/gradeService";


const initState = {
    grades:[],
    status: 'idle'
}

export const getGradeInGroup = createAsyncThunk('courseGrade/getGradeInGroup', async(groupId)=>{
    let grades = await getGradeInGroupAPI(groupId)
    return grades.data
})

const CourseGradeSlice = createSlice({
    name:'courseGrade',
    initialState:initState,
    reducers:{},
    extraReducers:builder => {
        builder.addCase(getGradeInGroup.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(getGradeInGroup.fulfilled, (state,action)=>{
            state.status ='idle'
            state.grades = action.payload
        }).addCase(getGradeInGroup.rejected, (state, action)=>{
            state.status = 'idle'
            console.log(action.error.message)
        })
    }
})

export default CourseGradeSlice.reducer
