import React, {useEffect} from 'react';
import { Editor } from '@tinymce/tinymce-react';
import {uploadService} from "./uploadService";
import {data} from "autoprefixer";

export default function RCE({editorRef, onBlur, content}) {

    const image_upload_handler_callback = async (blobInfo, success, failure) => {


        const formData = new FormData();
        formData.append('file', blobInfo.blob(), blobInfo.filename());
        try{
            let result = await uploadService(formData)
            success(result.data.url)

        }catch (err){
            failure(err.messages)
        }
    };

    return (
        <>

            <Editor
                onBlur={onBlur}
                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue={content}
                init={{
                    skin:"newskin",
                    images_upload_handler: image_upload_handler_callback,
                    height: 300,
                    menubar: true,
                    plugins: [
                        'eqneditor advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar: 'undo redo |  eqneditor link image | formatselect | ' +
                        'bold italic backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                    content_style: `
                      body {
                          background: #ecf3f5;
                      }`
                }}
            />
        </>
    );
}
