import {axiosPrivate} from "../commons/services/axiosService";

export const enrollToMegaCourseAPI = (courseId)=>{
    return axiosPrivate({
        method:'POST',
        url:'enrollment/enrollStudentInMega',
        data:{
            courseId: courseId
        }
    })
}

export const getEnrollInfoByCourseAPI = (courseId)=>{
    return axiosPrivate({
        method:"GET",
        url:`enrollment/enrollInfo?courseId=${courseId}`
    })
}
