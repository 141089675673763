
import React, {useEffect, useState} from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend, ResponsiveContainer, Cell
} from "recharts";
const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', 'red', 'pink'];
const data = [
    {
        name: "Page A",
        uv: 4000,
        pv: 2400,
        amt: 2400
    },
    {
        name: "Page B",
        uv: 3000,
        pv: 1398,
        amt: 2210
    },
    {
        name: "Page C",
        uv: 2000,
        pv: 9800,
        amt: 2290
    },
    {
        name: "Page D",
        uv: 2780,
        pv: 3908,
        amt: 2000
    },
    {
        name: "Page E",
        uv: 1890,
        pv: 4800,
        amt: 2181
    },
    {
        name: "Page F",
        uv: 2390,
        pv: 3800,
        amt: 2500
    },
    {
        name: "Page G",
        uv: 3490,
        pv: 4300,
        amt: 2100
    }
];

export default function BarChartComponent({data, dataKey1, dataKey2}) {
    const [heigh, setHeigh] = useState(1)
    useEffect(()=>{
        setHeigh(data.length *100)
    },[])
    return (
        <ResponsiveContainer width="100%" height="100%">
        <BarChart
            layout="vertical"
            width={500}
            height={800}
            data={data}
            margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis  axisLine={false} type="number"  />
            <YAxis yAxisId={0} type="category"
                   axisLine={false}
                   tickLine={false} dataKey="name" width={150}/>
            <Tooltip />
            <Legend />
            <Bar dataKey={dataKey1}   fill="#8884d8" label={{ position: 'right' }} name={'điểm trung bình'} >
                {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index % 5]}   />
                ))}
            </Bar>
            {/*<Bar dataKey={dataKey2} fill="#82ca9d" />*/}
        </BarChart>
        </ResponsiveContainer>
    );
}
