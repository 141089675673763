import {axiosPrivate} from "../commons/services/axiosService";

export const getPageSessionTokenAPI=(uri)=>{
    return axiosPrivate({
        method: "POST",
        url: 'authentication/getPageSessionToken',
        data:{
            uri: uri
        }
    })
}
