import React, {useState, useEffect} from 'react'
import LogoIcon from '../../../assets/images/logos/logo2.svg'
import homeIcon from '../../../assets/images/icons/dashboardIcon2.svg'
import courseIcon from '../../../assets/images/icons/courseIcon2.svg'
import gradeIcon from '../../../assets/images/icons/gradeIcon.svg'
import scheduleIcon from '../../../assets/images/icons/ScheduleIcon.svg'
import projectIcon from '../../../assets/images/icons/projectIcon.svg'
import notifyIcon from '../../../assets/images/icons/notifyIcon.svg'
import avatarDefault from '../../../assets/images/avatars/account.png'
import menuIcon from '../../../assets/images/icons/menu.svg'
import MenuItem from "./MenuItem";
import {Link, useNavigate} from "react-router-dom";
import MenuDropdownUser from "./MenuDropdownUser";
import SideBar from "./SideBar";
import closeIcon from "../../../assets/images/icons/close.svg";
import SimpleModal from "../../modals/SimpleModal";
import {useSelector} from "react-redux";
import Simple2Modal from "../../modals/Simple2Modal";
import MenuDropdownNotify from "./MenuDropdownNotify";
import { useTranslation } from 'react-i18next'
import { Switch } from '@headlessui/react'

export default function GlobalHeaderStudent({page}){
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState({name:"Việt Nam", id:"vn"})
    useEffect(()=>{
        if(i18n.language !='vn'){
            setLanguage({name: 'English', id:'en'})
        }else{
            setLanguage({name: 'Việt Nam', id:'vn'})
        }

    },[])
    const changeLangauge = (lng) => {
        if(lng){
            i18n.changeLanguage("vn");
            setLanguage({name: 'Việt Nam', id:'vn'})
        }else{
            i18n.changeLanguage("en");
            setLanguage({name: 'English', id:'en'})
        }
       
    }

    const [clickMenu, setClickMenu] = useState(false)
    const [profilePopup, setProfilePopup] = useState(false)
    const navigate = useNavigate()
    const handleOpenPopup=()=>{setProfilePopup(true)}
    const [notifyPopup, setNotifyPopup] = useState(false)
    const handleOpenNofity=()=>{setNotifyPopup(true)}
    const handleClosePopup=()=>{
        setProfilePopup(false)
    }

    const user =useSelector(state=>state.auth.user)

    return <div className={'flex w-full h-[64px] justify-between items-center px-0 md:px-2 border-b bg-white z-auto w-full'}>

        <div className={`${clickMenu?"flex md:hidden ":"hidden"} absolute top-[64px] justify-between  z-50 items-start pt-[20px] w-full h-screen max-h-fit backdrop-blur-lg`}
             id="mobile-menu">
            <SideBar />
        </div>

        <div className={'md:w-1/6 px-2'}>
           <Link to={"/"}><img  src={LogoIcon} alt={'logo'}/></Link>
        </div>
        <div  className={'md:w-3/6 hidden md:flex h-full space-x-5 justify-center text-26276E'}>
            <MenuItem icon={homeIcon} name={t("home")} isActive={page=='home'} url={'/'}/>
            <MenuItem icon={projectIcon} name={t("course")} isActive={page=='courses'} url={'/courses'}/>
            <MenuItem icon={courseIcon} name={t("report")} isActive={page=='report'} url={'/report'}/>
            {/*<MenuItem icon={gradeIcon} name={'Điểm'} isActive={page=='grades'} url={'/grades'}/>*/}
            {/*<MenuItem icon={scheduleIcon} name={'Lịch'} isActive={page=='schedule'} url={'/schedule'}/>*/}

        </div>
        <div className={'md:w-2/6 hidden md:flex items-center justify-end space-x-8'}>
            {/*<button>*/}
            {/*    <img src={notifyIcon} alt={'notify'}/>*/}
            {/*</button>*/}
            <MenuDropdownNotify />
            <MenuDropdownUser openPopup={handleOpenPopup} />
        </div>
        <div>
            <button onClick={()=>{setClickMenu(!clickMenu)}} className={'flex md:hidden items-center  justify-end space-x-8 px-2'}>
                <img src={menuIcon}/>
            </button>

        </div>

        <SimpleModal  isOpen={profilePopup} closeModal={handleClosePopup}>
            {user &&<div className={''}>
                <div className={'py-3'}>
                <div className={'flex justify-center w-full '}>
                        <h3 className={'font-bold text-[24px] pb-10 '}>{t('profileTitle')}</h3>
                    </div>
                    <div className={'grid-cols-12 grid py-2 border-b'}>
                        <div className={'col-span-6 text-[14px] font-semibold text-7A86A1'}>{t('profile.UserFullName')}</div>
                        <div className={'col-span-6 text-[14px] font-semibold text-7A86A1'}>{user.name}</div>
                    </div>
                    <div className={'grid-cols-12 grid py-2 border-b'}>
                        <div className={'col-span-6 text-[14px] font-semibold text-7A86A1'}>{t('profile.UserEmail')}</div>
                        <div className={'col-span-6 text-[14px] font-semibold text-7A86A1'}>{user.email}</div>
                    </div>
                    <div className={'grid-cols-12 grid py-2 border-b'}>
                        <div className={'col-span-6 text-[14px] font-semibold text-7A86A1'}>{t('profile.UserLicense')}</div>
                        <div className={'col-span-6 text-[14px] font-semibold text-7A86A1'}>{user.premiumEndDate && new Date(user.premiumEndDate).getTime() > new Date().getTime()?"Premium":"Basic"}</div>
                    </div>
                    <div className={'grid-cols-12 grid py-2 border-b'}>
                        <div className={'col-span-6 text-[14px] font-semibold text-7A86A1'}>{t("profile.userLanguage")}</div>
                        <div className={'col-span-6 text-[14px] font-semibold text-7A86A1 flex space-x-2'}>
                        <Switch
                            checked={language.id=="vn"}
                            onChange={changeLangauge}
                            className={`${
                                language.id=="vn" ? 'bg-blue-600' : 'bg-gray-200'
                            } relative inline-flex h-6 w-11 items-center rounded-full`}
                            >
                            <span className="sr-only">VN</span>
                            <span
                                className={`${
                                    language.id=="vn" ? 'translate-x-6' : 'translate-x-1'
                                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                            />
                            </Switch>
                            <span>{language.name}</span>

                        </div>
                    </div>
                    {
                        user.premiumEndDate && new Date(user.premiumEndDate).getTime() > new Date().getTime()?<div className={'grid-cols-12 grid py-2 border-b'}>
                            <div className={'col-span-6 text-[14px] font-semibold text-7A86A1'}>{t('profile.expiredDate')}</div>
                            <div className={'col-span-6 text-[14px] font-semibold text-7A86A1'}>{user.premiumEndDate }</div>
                        </div>:<></>
                    }
                </div>
                <div className={'w-full flex justify-center pt-5'}>
                <button onClick={()=>{navigate('/license')}}  className={'py-2 px-3 rounded-lg bg-6149CD text-white'}>{t('profile.buyLicense')}</button>
                </div>
            </div>}
        </SimpleModal>

    </div>
}
