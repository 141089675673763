import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {createNewQuizApi, createQuizQuestionsAPI, editQuizAPI} from "./QuizService";

const initstate= {
    status : 'idle',
    quiz:null,
    questions:[]
}

export const createQuiz=createAsyncThunk('quiz/createQuiz', async (input)=>{
    let quiz = await createNewQuizApi(input.courseId, input.quiz)
    return quiz.data
})

export const editQuiz = createAsyncThunk('quiz/edit', async(input)=>{
    let quiz = await editQuizAPI(input.courseId, input.quizId, input.quiz)
    return quiz.data
})

export const createQuizQuestions = createAsyncThunk('quiz/createQuizQuestion', async (input)=>{
    let questions = await createQuizQuestionsAPI(input.courseId,input.quizId, input.questions)
    return questions.data
})

const quizSlice = createSlice({
    name: 'quiz',
    initialState: initstate,
    reducers:{},
    extraReducers: builder => {
        builder.addCase(createQuiz.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(createQuiz.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.quiz= action.payload
        }).addCase(createQuiz.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(editQuiz.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(editQuiz.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.quiz = action.payload
        }).addCase(editQuiz.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(createQuizQuestions.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(createQuizQuestions.fulfilled, (state,action)=>{
            state.questions =action.payload
            state.status = 'idle'
        }).addCase(createQuizQuestions.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        })
    }
})
export default quizSlice.reducer
