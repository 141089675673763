import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {createAssignment} from "../courseDetail/assignmentTab/AssignmentSlice";
import {getAllStudentInCourseAPI, getListSubmissionAPI} from "./GradeSerivce";

const initState= {
    students: []
}

export const getAllStudent=createAsyncThunk('grade/allstudent',async (courseId)=>{
    let students = await getAllStudentInCourseAPI(courseId)
    return students.data
})

export const getAllSubmission = createAsyncThunk('grade/submissioon', async ({courseId, studentIds})=>{
    let submissions = await getListSubmissionAPI(courseId, studentIds)
    console.log(submissions.data)
    return submissions.data
})

const gradeSlice = createSlice({
    name:'gradeSlice',
    initialState: initState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getAllStudent.pending, (state,action)=>{
                state.status = 'pending'
            }
        ).addCase(getAllStudent.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(getAllStudent.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.students = action.payload
        }).addCase(getAllSubmission.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(getAllSubmission.fulfilled, (state,action)=>{
            state.status = 'idle'
            let _students = [...state.students]
            for(let i =0; i< _students.length; i++){
                    for(let student of action.payload){
                        console.log(student)
                        if(student.user_id == _students[i].id){
                            if(_students[i].submissions){
                                for(let submission of student.submissions){
                                    _students[i].submissions.push(submission)
                                }
                            }else{
                                _students[i].submissions = [...student.submissions]
                            }
                        }
                    }

            }
            state.students =[..._students]
        }).addCase(getAllSubmission.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        })
    }
})

export  default  gradeSlice.reducer
