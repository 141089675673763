import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getAllLicenseAPI} from "../../services/licenseService";
import {createVnpUrlAPI, verifyPaymentAPI} from "../../services/paymentService";

const initState={
    licenses:[],
    status: 'idle'
}

export const getAllLicense=createAsyncThunk("license/all",async()=>{
    let licenses = await getAllLicenseAPI()
    return licenses.data
})

export const createVnpayUrl= createAsyncThunk('license/createVnpayUrl', async(licenseId)=>{
    let url = await createVnpUrlAPI(licenseId)
    return url.data
})

export const verifyPaymentReturn = createAsyncThunk('license/verifyPaymentReturn', async(params)=>{
    let paymentReturn = await verifyPaymentAPI(params)
    return paymentReturn.data
})

const LicenseSlice = createSlice({
    name: 'license',
    initialState: initState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getAllLicense.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(getAllLicense.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.licenses = action.payload
        }).addCase(getAllLicense.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(createVnpayUrl.pending, (state,action)=>{
            state.status = 'idle'
        }).addCase(createVnpayUrl.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(createVnpayUrl.fulfilled, (state,action)=>{
            state.status = 'idle'
        }).addCase(verifyPaymentReturn.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(verifyPaymentReturn.fulfilled, (state,action)=>{
            state.satatus = 'idle'
        }).addCase(verifyPaymentReturn.rejected, (state,action)=>{
            state.status = 'idle'
        })
    }
})


export default LicenseSlice.reducer
