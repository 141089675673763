import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {countAssignmentPublishAPI, countSubmittionAPI, getSectionProgressAPI} from "./DashboardService";

const initState = {
    sectionProgress:null,
    status : 'idle',
    numberPublishAssignment: null,
    submission: null
}

export const getSectionProgress=createAsyncThunk('dashboard/sectionProgress', async({courseId, sectionId})=>{
    let sectionProgress = await getSectionProgressAPI(sectionId, courseId)
    return sectionProgress.data
})

export const getCountPublishAssignment = createAsyncThunk('dashboard/countPublishAssignment', async({courseId, sectionId})=>{
    let  assignmentPublish = await countAssignmentPublishAPI(sectionId, courseId)
    return assignmentPublish.data
})

export const countSubmittion= createAsyncThunk('dashboard/countSubmittion', async({courseId, sectionId})=>{
    let submission = await countSubmittionAPI(courseId, sectionId)
    return submission.data
})

const dashboardSlice = createSlice({
    name:'dashboard',
    initialState: initState,
    reducers:{},
    extraReducers:builder => {
        builder.addCase(getSectionProgress.pending,(state,action)=>{
            state.status = 'loading'
        }).addCase(getSectionProgress.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(getSectionProgress.fulfilled, (state,action)=>{
            state.status = "idle"
            state.sectionProgress = action.payload
        }).addCase(getCountPublishAssignment.pending, (state,action)=>{
            state.status ='loading'
        }).addCase(getCountPublishAssignment.rejected,(state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(getCountPublishAssignment.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.numberPublishAssignment = action.payload
        }).addCase(countSubmittion.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(countSubmittion.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(countSubmittion.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.submission = action.payload
        })
    }
})

export default dashboardSlice.reducer
