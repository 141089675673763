import React, {useEffect, useState} from 'react'
import {useParams, useSearchParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {verifyPaymentReturn} from "../license/LicenseSlice";
import GlobalHeaderStudent from "../../commons/components/headers/GlobalHeaderStudent";
import ErrorIcon from '../../assets/images/icons/errorIcon.png'
import SuccessIcon from '../../assets/images/icons/successIcon.png'
import loadingIcon from '../../assets/images/icons/loadIcon.png'
import Footer from "../../commons/components/footer/Footer";
import { useTranslation } from 'react-i18next';
export default function VnPayReturn(){
    const {t, i18n} = useTranslation()
    const params = useParams()
    const [searchParams, setSearchParams] = useSearchParams();
    const [status,setStatus] = useState('loading')
    const dispatch = useDispatch()
    useEffect(()=>{
        let search = window.location.search;
        console.log(search)
        dispatch(verifyPaymentReturn(search)).unwrap().then(data=>{
            setStatus('done')
        }).catch(err=>{
            setStatus('error')
            console.log(err)
        })
    },[])
    return <div>
          <GlobalHeaderStudent />
        <div className={'container mx-auto'}>
            <div className={'flex justify-center'}>
                { status=='loading' && <div className={'flex flex-col justify-center items-center my-5 px-52 py-5 rounded-xl border'}>
                    <img src={loadingIcon} className={'w-[100px]'}/>
                    <div className={'py-5'}>
                        <span className={'text-[20px] text-7A86A1'}>{t("loading")}...</span>
                    </div>
                </div>}
                { status=='error' && <div className={'flex flex-col justify-center items-center my-5 px-52 py-5 rounded-xl border'}>
                    <img src={ErrorIcon} className={'w-[100px]'}/>
                    <div className={'py-5'}>
                        <span className={'text-[20px] text-7A86A1'}>{t("payment.faild")}.</span>
                    </div>
                </div>}
                { status=='done' && <div className={'flex flex-col justify-center items-center my-5 px-52 py-5 rounded-xl border'}>
                    <img src={SuccessIcon} className={'w-[100px]'}/>
                    <div className={'py-5'}>
                        <span className={'text-[20px] text-7A86A1'}>{t("payment.success")}.</span>
                    </div>
                </div>}
            </div>

        </div>
        <Footer/>
    </div>
}
