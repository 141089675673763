import React, {useEffect, useState, useTransition} from 'react'
import AddIcon from '../../assets/images/icons/addIcon.svg'
import DropdownSimple2 from "../../commons/components/dropdowns/DropdownSimple2";
import CreateQuestionText from "./CreateQuestionText";
import MutilpleChoice from "./MutilpleChoice";
import EditIcon from '../../assets/images/icons/edit.svg'
import {useDispatch} from "react-redux";
import {updateQuestion} from "../quizDetail/QuizDetailSlice";
import {createQuizQuestions} from "../quizInfo/QuizSlice";
import Simple2Modal from "../../commons/modals/Simple2Modal";
import {generateQuestionAPI} from "../../services/chatGPTService";
import readXlsxFile from 'read-excel-file'
import ShortAnswerQuestion from "./ShortAnswerQuestion";
import TrueFalseQuestion from "./TrueFalseQuestion";
import MutilpleAnswerQuestion from "./MutilpleAnswerQuestion";
import { useTranslation } from 'react-i18next';
export default function CreateQuestion({quizQuestions, setQuizQuestions, course, quiz}){
    const [questions, setQuestions] = useState([])
    const [questionTypes, setQuestionTypes] = useState([
        {name: 'multiple choice question', value: 'multiple_choice_question'},
        // {name:'essay_question', value:'essay_question'},
        {name: 'short answer question', value: 'short_answer_question'}, 
        {name: 'multiple answers question', value: 'multiple_answers_question'},
        {name: 'true false question', value: 'true_false_question'},
    ])
    const [openCreateQuestionAutoModal, setOpenCreateQuestionAutoModal] = useState(false)
    const [openUploadQuestions, setOpenUploadQuestions] = useState(false)
    const [subject,setSubject] = useState("")
    const [loading, setLoading] = useState(false)
    const [fileQuestionSelect, setFileQuestionSelect] = useState(false)
    const dispatch = useDispatch()
    const {t, i18n} = useTranslation()
    const [fileUpload,setFileUpload] = useState(null)
    const handleCreateNewQuestion =()=>{
        setQuestions([...questions, {
            question_text:"",
            question_type:"multiple_choice_question",
            points_possible:1,
            correct_comments:"",
            incorrect_comments:"",
            text_after_answers:"",
            answers:[{
                "answer_text":"",
                "answer_weight":100
            },{
                "answer_text":"",
                "answer_weight":0
            },{
                "answer_text":"",
                "answer_weight":0
            },{
                "answer_text":"",
                "answer_weight":0
            }],
            edit:true
        }])
    }

    const disableEdit=(id)=>{
        let _questions = [...questions]
        _questions[id].edit = false
        setQuestions(_questions)
        if(_questions[id].id){
            dispatch(updateQuestion({
                courseId: course.id,
                quizId: quiz.id,
                questionId: _questions[id].id,
                question: _questions[id]
            }))
        }else{
            if(quiz && quiz.id){
                dispatch(createQuizQuestions({
                    courseId: course.id,
                    quizId: quiz.id,
                    questions: [_questions[id]]
                }))
            }
        }
    }

    useEffect(()=>{
        setQuestions(quizQuestions)
    },[])

    useEffect(()=>{
        setQuizQuestions(questions)
    },[questions])

    useEffect(()=>{
        if(!openCreateQuestionAutoModal){
            setSubject("")
            setLoading(false)
        }
    },[openCreateQuestionAutoModal])

    const handleSelectQuestionType  = (index, data)=>{
        if(data){
            let _questions = [...questions]
            _questions[index].question_type = data.value
            switch (_questions[index].question_type){
                case 'multiple_choice_question':
                case 'multiple_answers_question':
                    _questions[index].question_type='multiple_answers_question'
                    _questions[index].answers=[
                        {
                            "answer_text":"",
                            "answer_weight":100
                        },{
                            "answer_text":"",
                            "answer_weight":0
                        },{
                            "answer_text":"",
                            "answer_weight":0
                        },{
                            "answer_text":"",
                            "answer_weight":0
                        }
                    ]
                    break;
                case 'essay_question':
                    _questions[index].question_type='essay_question'
                    _questions[index].answers=[]
                    break;
                case 'short_answer_question':
                    _questions[index].question_type='short_answer_question'
                    _questions[index].answers=[
                        {
                            "answer_text":"",
                            "answer_weight":100
                        }
                    ]
                    break;
                case 'true_false_question':
                    _questions[index].question_type='true_false_question'
                    _questions[index].answers=[
                        {
                            "answer_text":"True",
                            "answer_weight":100
                        },
                        {
                            "answer_text":"False",
                            "answer_weight":0
                        }
                    ]
                    break
                case "multiple_answers_question":
                    _questions[index].question_type='multiple_answers_question'
                    _questions[index].answers=[
                        {
                            "answer_text":"",
                            "answer_weight":50
                        },
                        {
                            "answer_text":"",
                            "answer_weight":50
                        },
                        {
                            "answer_text":"",
                            "answer_weight":0
                        },
                        {
                            "answer_text":"",
                            "answer_weight":0
                        },

                    ]
                    break
                default:
                    _questions[index].answers=[]
                    break
            }
            setQuestions(_questions)
        }

    }

    const handleSaveAnswer = (index, answers)=>{
        let _questions = [...questions]
        _questions[index].answers = answers
        setQuestions(_questions)
    }

    const handleSaveQuestion = (index, content)=>{
        let _questions = [...questions]
        _questions[index].question_text = content
        setQuestions(_questions)
    }

    const handleChangePoint = (index, point)=>{
        let _questions = [...questions]
        console.log(_questions)
        _questions[index].points_possible = point
        setQuestions(_questions)
    }

    const handleOpenEdit = (index)=>{
        let _questions = [...questions]
        _questions[index]["edit"] = true
        setQuestions(_questions)
    }


    const handleCreateAuto=()=>{
        setLoading(true)
        generateQuestionAPI(subject, 10).then(_questions=>{
            let ListQuestion = []
                for(let _question of _questions.data){
                    ListQuestion.push( {
                        question_text:_question.question,
                        question_type:"multiple_choice_question",
                        points_possible:1,
                        correct_comments:"",
                        incorrect_comments:"",
                        text_after_answers:"",
                        answers:[{
                            "answer_text":_question.correct_answer,
                            "answer_weight":100
                        },{
                            "answer_text":_question.incorrect_answer1,
                            "answer_weight":0
                        },{
                            "answer_text":_question.incorrect_answer2,
                            "answer_weight":0
                        },{
                            "answer_text":_question.incorrect_answer3,
                            "answer_weight":0
                        }],
                        edit:false
                    })

                }
                setQuestions([...questions, ...ListQuestion])


            setLoading(false)
            setSubject("")
            setOpenCreateQuestionAutoModal(false)
        }).catch(err=>{
            console.log(err)
            setLoading(false)
        })
    }
    const handleCloseModalCreateAuto =()=>{
        setOpenCreateQuestionAutoModal(false)
    }

    const handleCloseModalUploadQuestion=()=>{
        setOpenUploadQuestions(false)
    }

    const handleDeleteQuestion=(index)=>{
        let _questions= [...questions]
        if(_questions[index].id){
            _questions[index].isDelete=true
            _questions[index].edit = false
        }else{
            _questions.splice(index,1)
        }
        setQuestions([..._questions])
    }


    const handleSelectFile=(files)=>{
        if(files.length>0){
            setFileUpload(files[0])
        }

    }
    const handleUpload=()=>{
        if(fileUpload){
            readXlsxFile(fileUpload).then((rows)=>{
                console.log(rows)
                let ListQuestion = []
                for (let i =2; i< rows.length;i++){
                    if(rows[i][1]== 'Multiple Choice'){
                        let question = rows[i][0]
                        if(rows[i][9] != ""){
                            question = `<p>${question}</br><img src="${rows[i][9]}" /></p>`
                        }
                        let answers =[]
                        if(rows[i][2] != ""){
                            answers.push({
                                "answer_text":rows[i][2],
                                "answer_weight":0
                            })
                        }
                        if(rows[i][3] != ""){
                            answers.push({
                                "answer_text":rows[i][3],
                                "answer_weight":0
                            })
                        }
                        if(rows[i][4] != ""){
                            answers.push({
                                "answer_text":rows[i][4],
                                "answer_weight":0
                            })
                        }
                        if(rows[i][5] != ""){
                            answers.push({
                                "answer_text":rows[i][5],
                                "answer_weight":0
                            })
                        }
                        if(rows[i][6] != ""){
                            answers.push({
                                "answer_text":rows[i][6],
                                "answer_weight":0
                            })
                        }

                        let correct_answerIndex = parseInt(rows[i][7])-1
                        answers[correct_answerIndex].answer_weight = 100

                        ListQuestion.push( {
                            question_text:question,
                            question_type:"multiple_choice_question",
                            points_possible:1,
                            correct_comments:"",
                            incorrect_comments:"",
                            text_after_answers:"",
                            answers:answers,
                            edit:false
                        })
                    }
                }
                setQuestions([...questions, ...ListQuestion])
                setOpenUploadQuestions(false)
            })
        }
    }

    return <div>
        <div className={'py-5'}>
            {questions && questions.map((x,i)=><div className={`${x.isDelete?"":"border py-3 rounded-lg my-3"}`}>
                {x.isDelete?<></>:<>
                    <div className={'border-b px-3 flex justify-between py-1 items-center'}>
                        <div className={'flex space-x-3 items-center'}>
                            <h3 className={'text-18px font-semibold'}>{t('assignment.questions')} {i+1}</h3>
                            <DropdownSimple2
                                disable={false}
                                listData={questionTypes}
                                valueSelected={x.question_type}
                                selectData={handleSelectQuestionType}
                                background={'bg-F0F0F0'}
                                index={i}  />
                        </div>
                        <div className={'flex space-x-3 items-center'}>
                            <span className={'text-[14px]'}>{t('Grade')}:</span>
                            <input value={x.points_possible} onChange={e=>{
                                handleChangePoint(i, e.target.value)
                            }} type={"number"} className={'h-[40px] w-[80px] px-3 rounded bg-F0F0F0'} />
                            {!x.edit && <button onClick={()=>{handleOpenEdit(i)}}  ><img src={EditIcon}/></button>}
                        </div>

                    </div>
                    {x.edit && <div className={'px-3 py-3'}>
                        <div>
                            <p className={'text-[14px] font-semibold'}>{t('assignment.questions')}:</p>
                            <CreateQuestionText index={i} setQuestionContent={handleSaveQuestion} content={x.question_text} />
                        </div>
                        <div>

                            <div>
                                {x.question_type =='multiple_choice_question' && <MutilpleChoice handleSaveAnswer={handleSaveAnswer} answers={x.answers} index={i} />}
                                {x.question_type =='short_answer_question' && <ShortAnswerQuestion handleSaveAnswer={handleSaveAnswer} answers={x.answers} index={i} />}
                                {x.question_type =='true_false_question' && <TrueFalseQuestion handleSaveAnswer={handleSaveAnswer} answers={x.answers} index={i} />}
                                {x.question_type =='multiple_answers_question' && <MutilpleAnswerQuestion handleSaveAnswer={handleSaveAnswer} answers={x.answers} index={i} />}
                            </div>
                        </div>
                        <div className={'w-full justify-end flex pt-5 space-x-3'}>
                            <button
                                onClick={()=>{handleDeleteQuestion(i)}}
                                className={'  text-[14px] underline min-w-[70px] px-3 py-2 rounded border'}>{t('removeBtn')}</button>
                            <button
                                onClick={()=>{disableEdit(i)}}
                                className={'bg-4B4DA4 rounded  text-[14px] text-white px-3 py-2'}>{t('updateBtn')}</button>
                        </div>

                    </div>}
                    {!x.edit && <div className={' p-3'} dangerouslySetInnerHTML={{__html: x.question_text}}></div>}
                </>}


            </div>)}
        </div>
        <div className={'flex space-x-3 py-3'}>
            <button
                onClick={handleCreateNewQuestion}
                className={'flex space-x-2 rounded-lg  bg-4B4DA4 h-[40px] px-5 items-center'}>
                <img src={AddIcon} alt={'add button'} />
                <span className={'text-[14px] text-white'}> {t('assignment.questions')}</span>
            </button>
            <button
                onClick={()=>{setOpenCreateQuestionAutoModal(true)}}
                className={'flex space-x-2 rounded-lg  bg-4B4DA4 h-[40px] px-5 items-center'}>
                <img src={AddIcon} alt={'add button'} />
                <span className={'text-[14px] text-white'}> {t("quiz.autoCreateQuestion")}</span>
            </button>
            <button
                onClick={()=>{setOpenUploadQuestions(true)}}
                className={'flex space-x-2 rounded-lg  bg-4B4DA4 h-[40px] px-5 items-center'}>
                <img src={AddIcon} alt={'add button'} />
                <span className={'text-[14px] text-white'}> {t("quiz.uploadQuestion")}</span>
            </button>

        </div>
        <Simple2Modal isOpen={openUploadQuestions} maxWidth={'max-w-xl'} closeModal={handleCloseModalUploadQuestion}>
            <div className={'py-3'}>
                <div className={'flex justify-between'}>
                    <h3 className={'text-[18px] font-semibold '}>{t("chooseFile")}:</h3>
                    <a href={"/sample.xlsx"} className={'text-indigo-600 underline text-[12px]'}>{t( "downloadFileTemp")}</a>
                </div>
                <input
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={(e)=>{handleSelectFile(e.target.files)}}
                    className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
                    type="file"
                    id="formFile"/>
            </div>
            <div className={'flex justify-end space-x-3'}>
                <button onClick={handleCloseModalUploadQuestion} className={'underline text-[14px]  px-3 py-2 border rounded'}>{t('cancelBtn')}</button>
                <button onClick={handleUpload} className={'px-3 py-2 rounded text-white bg-indigo-600 text-[14px]'}>{t("uploadBtn")}</button>
            </div>
        </Simple2Modal>
        <Simple2Modal isOpen={openCreateQuestionAutoModal}
                      maxWidth={'max-w-xl'}
                      closeModal={handleCloseModalCreateAuto}
        >
            <div>
                <h3 className={'text-[18px] font-semibold '}>{t( "quiz.typeSubject")}:</h3>
                <input value={subject} onChange={e=>{setSubject(e.target.value)}}
                       className={'border rounded px-3 py-2 focus:outline-none w-full '} />

            </div>
            <div className={'flex justify-end space-x-3 py-3'}>
                <button
                    onClick={handleCloseModalCreateAuto}
                    className={'underline text-[14px] min-w-[70px]'}>{t('cancelBtn')}</button>
                <button
                    onClick={handleCreateAuto}
                    className={'px-3 py-2 rounded bg-indigo-600 text-white text-[14px] min-w-[70px]'}>{loading? <div
                    className="inline-block h-4 w-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status">
                      <span
                          className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                      >{t('loading')}...</span
                      >
                </div>:<span>{t('createBtn')}</span>}</button>
            </div>
        </Simple2Modal>
    </div>
}
