import React, {useEffect, useState} from 'react'
import GlobalHeader from "../../commons/components/headers/GlobalHeader";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Disclosure, Menu} from "@headlessui/react";
import {ChevronUpIcon} from "@heroicons/react/solid";
import {changeModuleState, createModule, createModuleItem, deleteModule, editModule, getModules} from "./ModuleSlice";
import {getCourseDetail} from "../courseDetail/CourseDetailSlice";
import addIcon from '../../assets/images/icons/icon_new.svg'
import addIcon2 from '../../assets/images/icons/addIcon.svg'
import IconMore from '../../assets/images/icons/icon_more.svg'
import ListModuleItems from "./ListModuleItems";
import trashIcon from "../../assets/images/icons/transh2.svg";
import editIcon from '../../assets/images/icons/edit.svg'
import CreateModuleDialog from "./CreateModuleDialog";
import {toast} from "react-toastify";
import EditModuleDialog from "./EditModuleDialog";
import AddItemDialog from "./AddItemDialog";
import publicIcon from '../../assets/images/icons/publish.svg'
import unpublicIcon from '../../assets/images/icons/unpublish.svg'
import Footer from "../../commons/components/footer/Footer";
import TeacherCourseTabBar from "../../components/tabBar/TeacherCourseTabBar";
import CourseSubTab from "../../components/tabBar/CourseSubTab";
import { useTranslation } from 'react-i18next';
export default function ModuleTab(){
    const {t, i18n} = useTranslation()
    const params = useParams()
    const dispatch = useDispatch()
    const course = useSelector(state=>state.courseDetail.course)
    const modules = useSelector(state=>state.module.modules)
    const [isOpenCreateModal, setIsOpenCreateModal] = useState(false)
    const [tabSelect,setTabSelect] = useState('1')
    const [isEditModal, setIsEditModal] = useState(false)
    const [isAddItemModal, setIsAddItemModal] = useState(false)

    const [moduleEdit, setModuleEdit] = useState(null)
    useEffect(()=>{
        dispatch(getCourseDetail(params.courseId))
    },[params.courseId])

    useEffect(()=>{
        dispatch(getModules(params.courseId))
    },[params.courseId])

    const handleDeleteModule= (moduleId)=>{
        dispatch(deleteModule({
            courseId: params.courseId,
            moduleId: moduleId
        }))
    }

    let handleEditModule= (moduleName, module)=>{
        if(moduleName== ""){
            toast.error(t("modules.moduleNameError"))
            return
        }
        dispatch(editModule({
            courseId: params.courseId,
            moduleId: module.id,
            moduleName: moduleName
        })).unwrap().then(module=>{
            setIsEditModal(false)
        })
    }

    const handleCloseModal = ()=>{
        setIsOpenCreateModal(false)
    }

    const handleCloseEditModal = ()=>{
        setIsEditModal(false)
    }

    const handleCloseAdItemModal =()=>{
        setIsAddItemModal(false)
    }


    const handleCreateModule= (moduleName)=>{
        if(moduleName== ""){
            toast.error(t("modules.moduleNameError"))
            return
        }
        dispatch(createModule({
            moduleName: moduleName,
            courseId: params.courseId
        })).unwrap().then(module=>{
            setIsOpenCreateModal(false)
        })
    }

    const handleAddItem=(item, moduleId)=>{
        console.log(item, moduleId)
        dispatch(createModuleItem({
            courseId: params.courseId,
            moduleId: moduleId,
            item: item
        })).unwrap().then(data=>{
            setIsAddItemModal(false)
        })
    }

    const handleChangeModuleState=(moduleId, published)=>{
        dispatch(changeModuleState({
            courseId: params.courseId,
            moduleId: moduleId,
            published: published
        }))
    }

    return <div>
        <GlobalHeader />
        <CourseSubTab  course={course} />
        <TeacherCourseTabBar tabSelect={tabSelect} />
        <div className={'container mx-auto min-h-[500px]'}>
            {course && tabSelect==1 && <div>
                <div className={'py-3'}>
                    <button onClick={()=>{setIsOpenCreateModal(true)}} className={'bg-4B4DA4 rounded text-white flex items-center space-x-3 px-3 py-2'}><img src={addIcon2}/><span>{t("modules.module")}</span></button>
                </div>
                {modules && modules.map(x=><div className=" w-full  rounded-lg  bg-white mb-2 ">
                    <Disclosure>
                        {({ open }) => (
                            <>
                                <div className={'flex justify-between w-full py-1'}>
                                    <Disclosure.Button className={`${!open?'rounded-lg':'rounded-t-lg'} flex w-full item-center justify-between  bg-white  py-2 text-left text-sm font-medium   focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75`}>
                                        <div className={'flex justify-start space-x-3'}>
                                            <ChevronUpIcon
                                                className={`${
                                                    open ? 'rotate-180 transform' : ''
                                                } h-5 w-5 text-black`}
                                            />
                                            <span className={'text-[18px] font-semibold text-black'}>{x.name}</span>
                                        </div>
                                    </Disclosure.Button>
                                    <div className={'flex space-x-2 relative'}>
                                        <button
                                            onClick={()=>{
                                                handleChangeModuleState(x.id, !x.published)
                                            }}
                                            className={'rounded bg-F0F0F0 px-3'}><img src={x.published?publicIcon:unpublicIcon}/></button>
                                        <button
                                            onClick={()=>{
                                                setModuleEdit(x)
                                                setIsAddItemModal(true)

                                            }}
                                            className={'rounded bg-F0F0F0 px-3'}><img src={addIcon}/></button>
                                        <Menu  >
                                            <Menu.Button className={'bg-F0F0F0 px-4'}><img src={IconMore} /></Menu.Button>

                                            <Menu.Items className={"absolute min-w-[80px] rounded  right-0 top-[40px] z-50 bg-white"}>
                                                <div className={'px-1 py-1'}>
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <button
                                                                onClick={()=>{
                                                                    setModuleEdit(x)
                                                                    setIsEditModal(true)
                                                                }}
                                                                className={'border-b flex hover:text-4B4DA4 space-x-2 items-center w-full justify-between'}> <span>{t("editBtn")}</span>  <img src={editIcon}/></button>
                                                        )}
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <button
                                                                onClick={()=>{
                                                                    handleDeleteModule(x.id)
                                                                }}
                                                                className={'flex hover:text-4B4DA4 space-x-2 items-center w-full justify-between'}> <span>{t("removeBtn")}</span>  <img src={trashIcon}/></button>
                                                        )}
                                                    </Menu.Item>
                                                </div>

                                            </Menu.Items>

                                        </Menu>
                                    </div>
                                </div>

                                <Disclosure.Panel className="  text-sm text-gray-500 ">
                                    <ListModuleItems module={x} course={course} />
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>

                </div>)}
            </div>}

        </div>
        <Footer/>
        <CreateModuleDialog isOpen={isOpenCreateModal} closeModal={handleCloseModal} createModule={handleCreateModule} />
        <EditModuleDialog isOpen={isEditModal} closeModal={handleCloseEditModal} module={moduleEdit} editModule={handleEditModule}/>
        <AddItemDialog  courseId={params.courseId} isOpen={isAddItemModal} closeModal={handleCloseAdItemModal} module={moduleEdit} addItemToModule={handleAddItem} />
    </div>
}
