import axios from "axios";
import {axiosPrivate} from "../commons/services/axiosService";


export const sendMessageAPI=(message)=>{
    return axiosPrivate({
        method:"POST",
        url:`chat/api`,
        data:{
            msg: message
        }
    })
}

export const generateQuestionAPI=(subject, question)=>{
    return axiosPrivate({
        method:"POST",
        url:"chat/generateQuestion",
        data:{
            subject: subject,
            numberQuestion: 10
        }
    })
}
