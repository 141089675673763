import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import './index.css';
import {BrowserRouter} from "react-router-dom";
import { CookiesProvider } from 'react-cookie';
import {setup} from "./commons/services/axiosService";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { I18nextProvider } from 'react-i18next';
import i18n from './config/118n';
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <CookiesProvider>
          <Provider store={store}>
          <I18nextProvider  i18n={ i18n }>
          < BrowserRouter>
                  <App/>
            </BrowserRouter>
          </I18nextProvider>
          </Provider>
      </CookiesProvider>
    </GoogleOAuthProvider>

);

setup(store)
