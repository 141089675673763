import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {createAssignmentAPI} from "./AssignmentService";

const initState = {
    status: 'idle'
}


export const createAssignment = createAsyncThunk('createAssignment',async (input)=>{
    let assignment = await createAssignmentAPI(input.assignment, input.courseId)
    return assignment.data
})

const assignmentSlice = createSlice({
    name: 'assignment',
    initialState: initState,
    reducers: {},
    extraReducers:builder => {
        builder.addCase(createAssignment.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(createAssignment.fulfilled, (state,action)=>{
            state.status = 'idle'
        }).addCase(createAssignment.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        })
    }
})


export default assignmentSlice.reducer
