import React, {Fragment, useEffect, useState} from 'react'
import {Menu, Transition} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/solid";
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function DropdownSimple({listData, selectData, background}){
    const [dataSelect,setDataSelect] = useState()

    useEffect(()=>{
        if(listData && listData.length > 0){
            setDataSelect(listData[0])
        }else{
            setDataSelect(null)
        }
    },[listData])

    const onChange = (data)=>{
        selectData(data)
        setDataSelect(data)
    }
    return <div >
        <Menu as="div" className="relative inline-block text-left mb-2 border rounded-3xl h-fit  w-full">
            <div className={' min-w-full '} >
                <Menu.Button className={`inline-flex justify-start lg:justify-center w-full rounded-3xl px-7   py-3  text-sm font-light text-gray-700  focus:outline-none ${background} `}>
                    <div className={'flex justify-start w-full'}>
                        {dataSelect && <span className={""}>{dataSelect.name}</span>}
                    </div>

                    <div className={'flex'}>
                        <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5 text-indigo-700" aria-hidden="true" />
                    </div>
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="origin-top-righ  max-h-[300px] absolute right-0 mt-2 w-full lg:w-fit rounded-2xl z-50 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1 ">
                        {listData && listData.map(x=><Menu.Item >
                            {({ active }) => (
                                <button
                                    onClick={()=>{onChange(x)}}
                                    className={classNames(
                                        'block px-4 py-2 text-sm hover:text-indigo-600'
                                    )}
                                >
                                    {x.name}
                                </button>
                            )}
                        </Menu.Item>)}


                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    </div>
}
