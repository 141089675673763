import React, {Fragment} from 'react'
import avatarDefault from "../../../assets/images/avatars/account.png";
import { Menu, Transition } from '@headlessui/react'
import UserIcon from '../../../assets/images/icons/user.svg'
import notifyIcon from '../../../assets/images/icons/notifyIcon.svg'
import settingIcon from '../../../assets/images/icons/settings.svg'
import moveLeftIcon from '../../../assets/images/icons/move-left.svg'
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {logOut} from "../../../features/logins/loginSlice";
import { useTranslation } from 'react-i18next';
export default function MenuDropdownNotify({openPopup}){
    const {t, i18n} = useTranslation()


    return <div className="">
        <Menu as="div" className="relative inline-block text-left" style={{'zIndex':100}}>
            <div>
                <Menu.Button className="inline-flex w-full justify-center rounded-md  px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                    <img alt={'notify'} src={notifyIcon} />
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute z-100 right-0 mt-2 w-[300px] origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="px-1 py-1 z-100 ">
                        <Menu.Item>
                            {({ active }) => (
                                <span>{t('noNotifyMessage')}</span>
                            )}
                        </Menu.Item>

                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    </div>
}
