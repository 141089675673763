import React, {useEffect, useState} from 'react'
import GlobalHeader from "../../commons/components/headers/GlobalHeader";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getCourseDetail} from "../courseDetail/CourseDetailSlice";
import CourseSettingTab from "../courseDetail/settingTab/CourseSettingTab";
import PeopleTab from "../courseDetail/peopleTab/PeopleTab";
import Footer from "../../commons/components/footer/Footer";
import TeacherCourseTabBar from "../../components/tabBar/TeacherCourseTabBar";
import CourseSubTab from '../../components/tabBar/CourseSubTab';
export default function People(){
    const params = useParams()
    const dispatch = useDispatch()
    const course = useSelector(state=>state.courseDetail.course)
    const [tabSelect,setTabSelect] = useState('6')
    useEffect(()=>{
        dispatch(getCourseDetail(params.courseId))
    },[params.courseId])
    return <div>
        <GlobalHeader />
        {/* <CourseSettingTab course={course} /> */}
        <CourseSubTab  course={course}/>
        <TeacherCourseTabBar tabSelect={tabSelect} />
        <div className={'container mx-auto min-h-[500px]'}>
            {course && tabSelect==6 && <PeopleTab course={course}/>}
        </div>
        <Footer/>
    </div>
}
