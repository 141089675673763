import {useEffect} from "react";
import {GoogleLogin, useGoogleLogin} from "@react-oauth/google";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {googleAuthentication} from "../logins/loginSlice";
import {toast} from "react-toastify";

export function Oauth2Google(){
    const handleLogin = useGoogleLogin({
        ux_mode:"redirect",
        onSuccess: tokenResponse => handleLoginSuccess(tokenResponse),
        onError:errorResponse => console.log(errorResponse)
    });
    useEffect(()=>{
        console.log('jflkdajflkajflkjkl')
        handleLogin()
    },[])

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleLoginSuccess = (response)=>{
        if('access_token' in response){
            const accessToken = response.access_token
            dispatch(googleAuthentication(accessToken)).unwrap().then(data=>{
                navigate('/')
            }).catch(err=>{
                toast.error(err.message)
            })
        }else{
            toast.error('not found access token')
        }
    }
    return <div>fasdfa</div>
}
