import React,{useState, useEffect} from 'react'
import DropdownSimple from "../../../commons/components/dropdowns/DropdownSimple";
import SimpleButton from "../../../commons/components/buttons/SimpleButton";
import AddIcon from "../../../assets/images/icons/addIcon.svg";
import {useDispatch, useSelector} from "react-redux";
import {createGroup, deleteGroup, getGroupInSchool} from "./AdminGroupSlice";
import {toast} from "react-toastify";

export default function AdminGroup(){
    const [groupName,setGroupName] = useState("")

    const schools = useSelector(state=>state.adminSchool.schools)
    const groups = useSelector(state=>state.adminGroup.groups)
    const groupStatus = useSelector(state=>state.adminGroup.status)
    const [schoolSelect, setSchoolSelect]= useState(null)
    const [groupSelect, setGroupSelect] = useState(null)
    const changeOrder = (order)=>{
        setSchoolSelect(order)
    }
    const changeGroupSelect = (group)=>{
        setGroupSelect(group)
    }
    const dispatch = useDispatch()

    const handleCreateGroup= ()=>{
        dispatch(createGroup({
            groupName:groupName,
            schoolId: schoolSelect.id
        })).unwrap().then(group=>{
            setGroupName('')
            toast.success(` group ${groupName} have been created!`)
        }).catch(err=>{
            toast.error(err.message)
        })
    }

    const handleDeleteGroup =()=>{
        dispatch(deleteGroup(groupSelect.id)).unwrap().then(err=>{
            toast.success(`group ${groupSelect.name} have been deleted`)
        }).catch(err=>{
            toast.error(`${err.message}`)
        })
    }

    useEffect(()=>{
        if(schools.length >0){
            setSchoolSelect(schools[0])
        }else{
            setSchoolSelect(null)
        }
    },[schools])

    useEffect(()=>{
        if(schoolSelect){
            dispatch(getGroupInSchool(schoolSelect.id))
        }
    }, [schoolSelect])
    useEffect(()=>{
        if(groups.length){
            setGroupSelect(groups[0])
        }else{
            setGroupSelect(null)
        }
    },[groups])

    return <div className={'mb-3 border-b-2 pb-3'}>
            <div >
                <span  className={'text-[24px] font-semibold  my-3'}>Create Group</span>
            </div>
            <div>
                <div className={'flex items-center space-x-5'}>
                    <span className={'font-semibold'}>School:</span> <DropdownSimple selectData={changeOrder} listData={schools}/>
                </div>
            </div>

            <div className={'flex items-center space-x-5'}>
                <span>Group:</span>
                <input className={'rounded-xl border px-2 py-2'}  value={groupName} onChange={(e)=>{setGroupName(e.target.value)}} />
                <SimpleButton
                    disable={groupStatus!=='idle'}
                    name={'create group'}
                    icon={AddIcon}
                    textSize={'text-[14px]'}
                    textColor={'text-white'}
                    bgColor={'bg-indigo-600'}
                    handleClick={handleCreateGroup}
                />
            </div>

        <div >
            <span  className={'text-[24px] font-semibold  my-3'}>Delete Group</span>
        </div>
        <div>
            <div className={'flex items-center space-x-5'}>
                <span className={'font-semibold'}>Group:</span>
                <DropdownSimple selectData={changeGroupSelect} listData={groups}/>
                <SimpleButton
                    disable={groupStatus!=='idle'}
                    name={'Delete group'}
                    icon={AddIcon}
                    textSize={'text-[14px]'}
                    textColor={'text-white'}
                    bgColor={'bg-indigo-600'}
                    handleClick={handleDeleteGroup}
                />
            </div>
        </div>

        </div>


}
