import React from 'react'
import TabListComponent from "../../commons/components/tabs/TabListComponent";
import TabComponent from "../../commons/components/tabs/TabComponent";
import {useNavigate, useParams} from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function TeacherCourseTabBar({tabSelect}){
    let params = useParams()
    let navigate = useNavigate()
    const {t, i18n} = useTranslation()
    return <div className={'border-b-2'}>
        <div className={'container mx-auto'}>
            <TabListComponent >
                <TabComponent name={t('tabBar.module')}
                              isSelect={tabSelect==1}
                              handleClick={()=>{navigate(`/courses/${params.courseId}/modules`)}}
                />
                <TabComponent name={t('tabBar.page')}
                              isSelect={tabSelect==2}
                              handleClick={()=>{navigate(`/courses/${params.courseId}/pages`)}}
                />
                <TabComponent name={t('tabBar.assignment')}
                              isSelect={tabSelect==3}
                              handleClick={()=>{navigate(`/courses/${params.courseId}/assignments`)}}
                />
                <TabComponent name={t('tabBar.grade')}
                              isSelect={tabSelect==4}
                              handleClick={()=>{navigate(`/courses/${params.courseId}/grades`)}}
                />
                <TabComponent name={t('tabBar.setting')}
                              isSelect={tabSelect==5}
                              handleClick={()=>{navigate(`/courses/${params.courseId}/settings`)}}
                />
                <TabComponent name={t('tabBar.people')}
                              isSelect={tabSelect==6}
                              handleClick={()=>{navigate(`/courses/${params.courseId}/peoples`)}}
                />
            </TabListComponent>
        </div>
    </div>
}
