import React, {useState} from 'react'
import { useTranslation } from 'react-i18next';
import {useSelector} from "react-redux";

export default function CreateGroupAssignmentPopup({closeModal, createGroup}){
    const [groupName, setGroupName] = useState()
    const status = useSelector(state=>state.assignmentGroup.status)
    const {t, i18n} = useTranslation()
    return <div className={'pt-3'}>
        <span className={'text-[14px] text-black '}>{t("assignment.groupName")}</span>
        <input
            value={groupName}
            onChange={e=>{setGroupName(e.target.value)}}
            className={'bg-F0F0F0 rounded focus:outline-none my-3 p-2 h-[40px] w-full'}/>
        <div className={'space-x-2 justify-center flex'}>
            <button onClick={closeModal}  className={'rounded h-[40px] w-[100px] bg-F0F0F0 text-[14px]'}>
                {t('cancelBtn')}
            </button>
            <button disabled={status=='loading'} onClick={()=>{
                createGroup(groupName)
            }} className={'rounded h-[40px] w-[100px] bg-316AFF text-[14px] text-white'}>
                {t('saveBtn')}
            </button>
        </div>
    </div>
}
