import {axiosPrivate} from "../commons/services/axiosService";

export const getAllSubmissionAPI=(assignmentId,courseId)=>{
    return axiosPrivate({
        method:"GET",
        url:`submissions/getAllSubmission?courseId=${courseId}&assignmentId=${assignmentId}`
    })
}


export const submitSpeedGradeAPI=(courseId, assignmentId, submissionId, grade, studentId, megaMarkData, attempt, submissionType)=>{
    switch (submissionType){
        case 'basic_lti_launch':
            return axiosPrivate({
                method: "POST",
                url:`megaMark/speedGrade`,
                data:{
                    courseId: courseId,
                    assignmentId: assignmentId,
                    submissionId: submissionId,
                    grade: grade,
                    attempt:attempt,
                    studentId: studentId,
                    megaMarkData: megaMarkData
                }
            })
            break;
        case "online_text_entry":
            return axiosPrivate({
                method:"POST",
                url:`submissions/speedGrder`,
                data:{
                    courseId: courseId,
                    assignmentId: assignmentId,
                    submissionId: submissionId,
                    grade: grade,
                    attempt:attempt,
                    studentId: studentId
                }
            })
        break;
    }

}

export const getSubmissionByStudentAPI= (courseId, assignmentId)=>{
    return axiosPrivate({
        method: 'GET',
        url:`submissions/submissionDetail?courseId=${courseId}&assignmentId=${assignmentId}`
    })
}


export const submitAssignmentAPI = (courseId, assignmentId, submissionType, url, body)=>{
    return axiosPrivate(({
        method:"POST",
        url: `submissions/submissionAssignment`,
        data:{
            courseId: courseId,
            assignmentId: assignmentId,
            submissionType: submissionType,
            url: url,
            body: body
        }
    }))
}
