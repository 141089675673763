import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import { Fragment } from 'react'
import { Menu } from '@headlessui/react'
import publicIcon from '../../assets/images/icons/publish.svg'
import unpublicIcon from '../../assets/images/icons/unpublish.svg'
import {changeModuleItemState, deleteItem, getModulesItems} from "./ModuleSlice";
import iconMore from '../../assets/images/icons/icon_more.svg'
import trashIcon from '../../assets/images/icons/transh2.svg'
import * as PropTypes from "prop-types";
import pageIcon from '../../assets/images/icons/document.svg'
import quizIcon from '../../assets/images/icons/quiz.svg'
import assignmentIcon from '../../assets/images/icons/assignment.svg'
import {checkCourseIsMegaContentAPI} from "../../services/courseService";
import {getSubString} from "../../commons/services/utilService";
import { useTranslation } from 'react-i18next';
function Transition(props) {
    return null;
}



Transition.propTypes = {
    as: PropTypes.func,
    leave: PropTypes.string,
    enterFrom: PropTypes.string,
    leaveTo: PropTypes.string,
    enter: PropTypes.string,
    enterTo: PropTypes.string,
    leaveFrom: PropTypes.string
};
export default function ListModuleItems({module, course}){
    const {t, i18n} = useTranslation()

    const dispatch = useDispatch()
    const [items, setItems] = useState([])
    const modules= useSelector(state=> state.module.modules)
    useEffect(()=>{
        if(module && course ){
            dispatch(getModulesItems({
                courseId: course.id,
                moduleId: module.id
            }))
        }

    },[])
    useEffect(()=>{
        if(modules && module){
            for( let m of modules){
                if(m.id == module.id && m.items){
                        setItems(m.items)
                }
            }
        }
    },[modules])

    const handleDeleteItem = (itemId)=>{
        dispatch(deleteItem({
            courseId: course.id,
            moduleId: module.id,
            itemId: itemId
        }))
    }

    const getIcon = (item)=>{
        let icon = null
        switch (item.type){
            case 'Assignment':
                icon = assignmentIcon
                break
            case 'Quiz':
                icon = quizIcon
                break
            case 'Page':
                icon= pageIcon
                break
            default:
                icon= pageIcon
                break
        }
        return icon
    }

    const handleChangeModuleItemState=(itemId, published)=>{
        console.log(itemId, module.id, course.id, published)
       dispatch(changeModuleItemState({
           courseId: course.id,
           moduleId: module.id,
           itemId: itemId,
           published: published
       }))
    }


    const handleClickItem=(item)=>{
        switch (item.type){
            case 'Assignment':
                window.open(`/courses/${course.id}/assignments/${item.content_id}`, '_blank')
                break
            case 'Quiz':
                window.open(`/courses/${course.id}/quizzes/${item.content_id}`, '_blank')
                break
            case 'Page':
                window.open(`/courses/${course.id}/pages/${item.page_url}`, '_blank')
                break
            default:
                break
        }

    }


    return <div className={'bg-F0F0F0 w-full rounded'} >
        {items && items.map(x=><div className={'text-black py-2 px-3 flex justify-between'}>
            <div>
                {x.type=='SubHeader'?<h3 className={'font-semibold text-[14px]'}>{getSubString(128,x.title)}</h3>:<button onClick={()=>{handleClickItem(x)}} className={'flex  space-x-2 hover:text-4B4DA4 hover:cursor-pointer'}><img src={getIcon(x)} /><span>{getSubString(128,x.title)}</span></button>}
            </div>
            <div className={'relative  items-center text-end flex space-x-5 justify-end'} >
                {x.published ? <button
                    onClick={()=>{
                        handleChangeModuleItemState(x.id, !x.published)
                        }
                    }
                ><img src={publicIcon}/></button>:<button
                    onClick={()=>{
                        handleChangeModuleItemState(x.id, !x.published)
                    }}
                >
                    <img src={unpublicIcon}/>
                </button>}
                <Menu >
                    <Menu.Button><img src={iconMore} /></Menu.Button>

                        <Menu.Items className={"absolute min-w-[80px] rounded  right-0 top-[20px] z-50 bg-white"}>
                            <div className={'px-1 py-1'}>
                                <Menu.Item>
                                    {({ active }) => (
                                        <button
                                            onClick={()=>{
                                                handleDeleteItem(x.id)
                                            }}
                                            className={'flex hover:text-4B4DA4 space-x-2 items-center w-full justify-between'}> <span>{t("removeBtn")}</span>  <img src={trashIcon}/></button>
                                    )}
                                </Menu.Item>
                            </div>

                        </Menu.Items>

                </Menu>
            </div>
        </div>)}
    </div>
}
