import React, {useEffect, useState} from 'react'
import GlobalHeaderStudent from "../../commons/components/headers/GlobalHeaderStudent";
import Footer from "../../commons/components/footer/Footer";
import {useDispatch, useSelector} from "react-redux";
import {listCourse} from "../courses/courseSlice";
import SearchIcon from '../../assets/images/icons/searchColorIcon.svg'
import CourseInfo from "./CourseInfo";
import Calendar from "../../components/calendar/Calendar";
import {add, format, isSameDay, parse, parseISO, startOfToday} from "date-fns";
import {getPlannerByDate} from "./CourseStudentSlice";
import assignmentIcon from '../../assets/images/icons/assignment.svg'
import clockIcon from '../../assets/images/icons/clock.svg'
import courseIcon from '../../assets/images/icons/coursesicon.svg'
import {getCoursesInAccount} from "../dashboardStudent/DashboardStudentSlice";
import {useParams} from "react-router-dom";
import { useTranslation } from 'react-i18next';
export default function CourseMega(){
    const dispatch = useDispatch()
    const {t, i18n} = useTranslation()
    const user =useSelector(state=>state.auth.user)
    const [isSearch,setIsSearch] = useState(false)
    const [planners, setPlanners] = useState([])
    const list_planner = useSelector(state=> state.courseStudent.planners)
    let today = startOfToday()
    let [selectedDay, setSelectedDay] = useState(today)
    let [currentMonth, setCurrentMonth] = useState(format(today, 'MMM-yyyy'))
    let params = useParams()
    const [courses,setCourses]= useState([])
    const changeSelectedDay = (day)=>{
        setSelectedDay(day)
        let _planners = []
        for(let pl of list_planner){
            if(isSameDay(day,parseISO(pl.plannable_date))){
                _planners.push(pl)
            }
        }
        setPlanners(_planners)
    }



    useEffect(()=>{
        console.log(params)
        if(params.accountId){
            dispatch(getCoursesInAccount(params.accountId)).unwrap().then(data=>{
                setCourses(data)
            })
        }

    },[params])

    useEffect(()=>{
        let firstDayCurrentMonth = parse(currentMonth, 'MMM-yyyy', new Date())
        let firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 })
        dispatch(getPlannerByDate({startDate: new Date(firstDayCurrentMonth).toISOString(), endDate: new Date(firstDayNextMonth).toISOString()}))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentMonth])




    return <div className={'w-screen bg-white min-h-screen'}>
        <GlobalHeaderStudent page={'course'} />
        <div className={'bg-E9FAFF'}>
            <div className={"container mx-auto"}>
                <div  className={"py-5 px-3 md:pt-0 text-[18px] "}>
                    <span>Xin chào {user && user.name}!</span>
                </div>
                <div className={'flex flex-col md:flex-row space-x-0 md:space-x-5'}>
                    <div className={'w-full px-3 md:px-0 md:w-2/3'}>
                        <div className={'flex justify-between'}>
                            <div>
                                <span className={'text-[21px] font-bold'}>{t( "course.megacourse")}</span>
                            </div>
                        {/*    <div className="relative text-gray-400  focus-within:text-gray-600 block bg-bgcoloz  flex items-center py-1  lg:bg-white   rounded-3xl px-2 mb-2">*/}

                        {/*        <input type="text" placeholder={'search course'}*/}
                        {/*               onBlur={()=>{setIsSearch(false)}}*/}
                        {/*               onFocus={()=>{setIsSearch(true)}}*/}
                        {/*               value={""}*/}
                        {/*               onChange={(e)=>{}}*/}
                        {/*               className={` py-2 px-5   placeholder-gray-700 font-light bg-bgcoloz text-sm lg:bg-white hover:cursor-pointer  rounded rounded-3xl*/}
                        {/*appearance-none  block  focus:outline-none  ${isSearch?"w-full pr-8":"w-full lg:w-1"}`} />*/}
                        {/*        <img alt={'seachicon'} src={SearchIcon} className={'pointer-events-none w-4 h-4 absolute top-1/2  text-indigo-600 transform -translate-y-1/2 right-5'}/>*/}
                        {/*    </div>*/}

                        </div>
                        <div className={'w-full'}>
                            {courses && courses.map(course=><div className={'flex flex-col md: flex-row py-3 border-b '}>
                                <img className={'w-[200px] h-[120px] md:w-[300px] md:min-w-[300px] md:h-[170px] rounded-xl'} src={course.image_download_url} />
                                <div className={'px-3 pt-3 md:pt-0 w-full'}>
                                    <div className={'flex justify-between '}>
                                        <span className={'text-[18px] font-bold'}>
                                            {course.name}
                                        </span>
                                    </div>
                                    <div className={'mb-2'}>
                                        <CourseInfo course={course} />
                                    </div>

                                </div>
                            </div>)}
                        </div>
                    </div>
                    <div className={'w-full px-3 md:px-0 md:w-1/3'}>
                        <div className={'shadow-xl rounded-3xl bg-white '}>
                            <div className={'relative w-full z-0'} >
                                <div className={'absolute top-[-15px] w-full'}>
                                    <div className={'flex   justify-between mx-5'}>
                                        <div className={'w-3 rounded-xl h-10 bg-black'}></div>
                                        <div className={'w-3 rounded-xl h-10 bg-black'}></div>
                                    </div>
                                </div>

                                <div className={'rounded-xl bg-4B4DA4  flex justify-center py-5'}>
                                    <span className={'text-white'}>{t("course.timeTable")}</span>
                                </div>
                                <Calendar
                                    selectedDay = {selectedDay}
                                    changeSelectedDay={changeSelectedDay}
                                    today={today}
                                    isToDo={false}
                                    currentMonth={currentMonth}
                                    setCurrentMonth={setCurrentMonth}
                                >
                                </Calendar>
                            </div>


                        </div>
                        <div className={'py-5'}>
                            <div className={' pb-2'}>
                                <span>{new Date(selectedDay).toLocaleDateString()}</span>
                            </div>
                            {planners && planners.map(x=><div className={'flex mb-2'}>
                                <div className={'bg-71C16A w-[50px] rounded-l-xl flex justify-center'}>
                                    <img src={assignmentIcon} className={'w-[20px]'}/>
                                </div>
                                <div className={'w-full bg-white rounded-r-xl py-2 px-5'}>
                                    <div className={'font-semibold text-[14px] uppercase'}>{x.plannable.title}</div>
                                    <div className={'flex justify-between  w-full text-[13px] text-7A86A1'}>
                                        <div className={'flex space-x-2 flex-1'}>
                                            <img src={clockIcon} />
                                            <span>{new Date(x.plannable_date).toLocaleDateString()} {new Date(x.plannable_date).toLocaleTimeString()}</span>
                                        </div>
                                        <div className={'flex space-x-2'}>
                                            <img src={courseIcon}/>
                                            <span>{x.context_name}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>)}
                        </div>

                    </div>

                </div>
            </div>



            <Footer/>
        </div>


    </div>
}
