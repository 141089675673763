import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    getAllQuizSubmissionsAPI,
    getListQuizQuestionAPI,
    getQuizByIdAPI,
    getSubmissionHistoryAPI,
    updateQuestionAPI
} from "./QuizDetailService";
import {
    answerQuestionAPI,
    completeSubmitAPI,
    createQuizSubmissionAPI,
    getQuestionAPI
} from "../../services/quizSubmissionService";

const initState={
    quiz: null,
    status: 'idle',
    quizSubmission:null,
    questions:[],
    allSubmission:[],
    submissionHistory:null
}
export const getQuizById = createAsyncThunk('quizDetailStudent/getQuizById', async(input)=>{
    let quiz = await getQuizByIdAPI(input.courseId, input.quizId)
    return quiz.data
})


export const createQuizSubmission = createAsyncThunk('quizDetailStudent/createSubmission', async ({courseId, quizId})=>{
    let quizSubmission = await  createQuizSubmissionAPI(courseId, quizId)
    return quizSubmission.data
})

export const getQuestion = createAsyncThunk('quizDetailStudent/getQuestion',async(submissionId)=>{
    let question = await getQuestionAPI(submissionId)
    return question.data
})

export const answeringQuestion = createAsyncThunk('quizDetailStudent/answeringQuestion', async({submissionId, attempt, validateToken, quiz_question})=>{
    let question = await answerQuestionAPI(submissionId, attempt, validateToken, quiz_question)
    return question.data
})

export const completeSubmission = createAsyncThunk('quizDetailStudent/completeSubmission', async({submissionId, attempt, validateToken,courseId, quizId})=>{
    let submissions = await completeSubmitAPI(submissionId, attempt, validateToken,courseId, quizId)
    return submissions.data
})

export const getAllSubmission= createAsyncThunk('quizDetailStudent/getAllSumission', async({courseId, quizId})=>{
    let submissions = await getAllQuizSubmissionsAPI(courseId, quizId)
    return submissions.data
})

export const getSubmissionHistory= createAsyncThunk('quizDetailStudent/submissionHistory', async({submissionId, version})=>{
    let submissionHistory = await getSubmissionHistoryAPI(submissionId, version)
    return submissionHistory.data
})

const QuizDetailStudentSlice = createSlice({
    name: 'quizDetail',
    initialState: initState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getQuizById.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(getQuizById.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.quiz = action.payload
        }).addCase(getQuizById.rejected, (state,action)=>{
            state.status = 'dile'
            console.log(action.error)
        }).addCase(createQuizSubmission.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(createQuizSubmission.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(createQuizSubmission.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.quizSubmission = action.payload
        }).addCase(getQuestion.pending, (state,status)=>{
            state.status = 'pending'
        }).addCase(getQuestion.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(getQuestion.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.questions = action.payload
        }).addCase(answeringQuestion.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(answeringQuestion.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(answeringQuestion.fulfilled, (state,action)=>{
            state.status = 'idle'
            let _questions = {...state.questions}
            console.log(_questions.quiz_submission_questions)
            for(let i =0; i< _questions.quiz_submission_questions.length; i++){
                console.log(_questions.quiz_submission_questions[i].id, action.payload.quiz_submission_questions[0].id)
                if(_questions.quiz_submission_questions[i].id == action.payload.quiz_submission_questions[0].id){
                    _questions.quiz_submission_questions[i] = action.payload.quiz_submission_questions[0]
                }
            }
            state.questions= _questions
        }).addCase(completeSubmission.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(completeSubmission.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(completeSubmission.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.quizSubmission= action.payload
        }).addCase(getAllSubmission.pending, (state,action)=>{
            state.status = 'idle'
        }).addCase(getAllSubmission.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.allSubmission= action.payload
        }).addCase(getAllSubmission.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(getSubmissionHistory.pending, (state,action)=>{
            state.status = 'idle'
        }).addCase(getSubmissionHistory.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.submissionHistory= action.payload
        }).addCase(getSubmissionHistory.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        })
    }
})

export default QuizDetailStudentSlice.reducer
