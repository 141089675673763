import React, {useEffect, useState} from 'react'
import GlobalHeader from "../../commons/components/headers/GlobalHeader";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getCourseDetail} from "../courseDetail/CourseDetailSlice";
import AddIcon from '../../assets/images/icons/addIcon.svg'
import {changePublish, deletePage, getAllListPage} from "./PageSlice";
import PublishIcon from '../../assets/images/icons/publish.svg'
import UnPublishIcon from '../../assets/images/icons/unpublish.svg'
import TrashIcon from '../../assets/images/icons/trash.svg'
import Footer from "../../commons/components/footer/Footer";
import {getSubString} from "../../commons/services/utilService";
import TeacherCourseTabBar from "../../components/tabBar/TeacherCourseTabBar";
import CourseSubTab from "../../components/tabBar/CourseSubTab";
import { useTranslation } from 'react-i18next';
export default function PageTab(){
    const {t, i18n} = useTranslation()
    const params = useParams()
    const dispatch = useDispatch()
    const course = useSelector(state=>state.courseDetail.course)
    const [tabSelect,setTabSelect] = useState('2')
    const navigator = useNavigate()
    const pages = useSelector(state=> state.page.pages)
    useEffect(()=>{
        dispatch(getCourseDetail(params.courseId))
    },[params.courseId])

    useEffect(()=>{
        if(params.courseId){
            dispatch((getAllListPage(params.courseId)))
        }
    },[params.courseId])

    const handleDeletePage = (pageId)=>{
        dispatch(deletePage({
            courseId: params.courseId,
            pageId: pageId
        }))
    }


    const handleChangPublish=(pageId, publish)=>{
        dispatch(changePublish({
            courseId: params.courseId,
            pageId: pageId,
            publish: publish
        }))
    }

    return <div>
        <GlobalHeader />
        <CourseSubTab  course={course}/>
        <TeacherCourseTabBar tabSelect={tabSelect} />
        <div className={'container mx-auto min-h-[500px]'}>
           <div>
               <button
                   onClick={()=>{{navigator(`/courses/${params.courseId}/pages/create`)}}}
                   className={'bg-316AFF text-white text-[14px] rounded px-3 py-2 flex space-x-2 items-center my-5'}>
                   <img src={AddIcon}/>
                   <span>{t("page.newPageBtn")}</span>
               </button>
           </div>
            <div className={'border rounded-xl w-fit shadow-xl'}>
                <table >
                    <thead>
                        <tr className={''}>
                            <th className={'bg-E9EDF8 rounded-tl-xl px-3 text-[14px] font-semibold py-2 min-w-[600px] text-left'}>{t("page.pageTitle")}</th>
                            <th className={'bg-E9EDF8  px-3 text-[14px] font-semibold py-2 min-w-[200px] text-left'}>{t( "page.createTime")}</th>
                            <th className={'bg-E9EDF8  px-3 text-[14px] font-semibold py-2 min-w-[200px] text-left'}>{t("page.lastEditTime")}</th>
                            <th className={'bg-E9EDF8 rounded-tr-xl px-3 text-[14px] font-semibold py-2 min-w-[150px] text-left'}></th>
                        </tr>
                    </thead>
                    <tbody>
                    {pages && pages.map((page, idx)=> <tr key={page.page_id} className={'text-[14px]'}>
                        <td className={`px-3 py-2 ${idx%2==0?"bg-FAFAFA":"bg-EBEBEB "}`}><span
                            onClick={()=>{navigator(`/courses/${params.courseId}/pages/${page.page_id}/edit`)}}
                            className={'hover:cursor-pointer'}>{getSubString(128,page.title)}
                        </span></td>
                        <td className={`px-3 py-2 ${idx%2==0?"bg-FAFAFA":"bg-EBEBEB"}`}>{new Date(page.created_at).toLocaleDateString() +"-"+ new Date(page.created_at).toLocaleTimeString()}</td>
                        <td className={`px-3 py-2 ${idx%2==0?"bg-FAFAFA":"bg-EBEBEB"}`}>{new Date(page.updated_at).toLocaleDateString() +"-"+ new Date(page.updated_at).toLocaleTimeString()}</td>
                        {!page.front_page?<td className={`flex justify-end space-x-2 justify-end px-3 py-2 ${idx%2==0?"bg-FAFAFA":"bg-EBEBEB "}`}>
                            <button onClick={()=>{
                                handleChangPublish(page.page_id, !page.published)
                            }}>{page.published?<img src={PublishIcon}/>:<img src={UnPublishIcon}/>}</button>
                            <button onClick={()=>{handleDeletePage(page.page_id)}}><img src={TrashIcon}/></button>
                        </td>:<td  className={`flex justify-end space-x-2 justify-end px-3 py-2 ${idx%2==0?"bg-FAFAFA":"bg-EBEBEB "}`}>
                            <span className={'text-[12px] font-bold bg-2B96E3 text-white px-1 py-0.5 rounded'}>{t("page.FontPage")}</span>
                        </td>}
                    </tr>)}
                    </tbody>
                </table>
            </div>

        </div>
        <Footer/>

    </div>
}
