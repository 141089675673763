import React, {useEffect, useState, useTransition} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {getDefaultSchool} from "./SchoolSlice";
import {getGroupsInSchool} from "./GroupSlice";
import {getGradeInGroup} from "./GradeSlice";
import {getSubjectInGrade} from "./SubjectSlice";
import DropdownSimple from "../../commons/components/dropdowns/DropdownSimple";
import SimpleButton from "../../commons/components/buttons/SimpleButton";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {createNewCourse} from "./courseSlice";
import {data} from "autoprefixer";
import { useTranslation } from 'react-i18next';
export default function CreateCourseForm({handleClose}){
    const [courseName,setCourseName] = useState('')
    const schools = useSelector(state=>state.courseSchool.schools)
    const [schoolSelect,setSchoolSelect] = useState(null)
    const groups = useSelector(state=>state.courseGroup.groups)
    const [groupSelect, setGroupSelect]= useState(null)
    const grades = useSelector(state=>state.courseGrade.grades)
    const [gradeSelect,setGradeSelect] = useState(null)
    const subjects = useSelector(state=>state.courseSubject.subjects)
    const [subjectSelect,setSubjectSelect]= useState(null)
    const coursesStatus = useSelector(state=>state.courses.status)

    const dispatch= useDispatch()
    useEffect(()=>{
        dispatch(getDefaultSchool())
    },[])
    useEffect(()=>{
        if(schools.length){
            setSchoolSelect(schools[0])
        }else{
            setSchoolSelect(null)
        }
    },[schools])
    useEffect(()=>{
        if(schoolSelect){
            dispatch(getGroupsInSchool(schoolSelect.id))
        }
    },[schoolSelect])
    useEffect(()=>{
        if(groups && groups.length){
            setGroupSelect(groups[0])
        }else {
            setGroupSelect(null)
        }
    },[groups])
    useEffect(()=>{
        if(groupSelect){
            dispatch(getGradeInGroup(groupSelect.id))
        }
    },[groupSelect])
    useEffect(()=>{
        if(grades && grades.length){
            setGradeSelect(grades[0])
        }else{
            setGradeSelect(null)
        }
    },[grades])
    useEffect(()=>{
        if(gradeSelect){
            dispatch(getSubjectInGrade(gradeSelect.id))
        }
    },[gradeSelect])
    useEffect(()=>{
        if(subjects && subjects.length> 0){
            setSubjectSelect(subjects[0])
        }else{
            setSubjectSelect(null)
        }
    },[subjects])

    const handleSchoolSelect=(school)=>{
        setSchoolSelect(school)
    }
    const handleGroupSelect = (group)=>{
        setGroupSelect(group)
    }
    const handleGradeSelect = (grade)=>{
        setGradeSelect(grade)
    }
    const handleSubjectSelect = (subject)=>{
        setSubjectSelect(subject)
    }
    const handleCreateNewCourse=()=>{
        if(courseName.trim() == ''){
            return toast.error('Course name can\'t empty')
        }
        if(subjectSelect == null){
            return toast.error('Please select subject')
        }
        dispatch(createNewCourse({
            name:courseName,
            subjectId: subjectSelect.id
        })).unwrap().then(data=>{
            handleClose()
            toast.success('A new course have been created')
        }).catch(err=>{
            toast.error(err.message)
        })

    }
    const {t, i18n} = useTranslation()
    return <div className={'px-2 py-2 w-full'}>
        <input className={'rounded-xl border w-full p-2 bg-f8f8f8'} placeholder={t('courseName')} value={courseName} onChange={e=>{setCourseName(e.target.value)}}/>
        <div className={'grid grid-cols-2 gap-2 pt-3'}>
            <DropdownSimple selectData={handleSchoolSelect} background={'bg-f8f8f8'} listData={schools}/>
            <DropdownSimple selectData={handleGroupSelect} background={'bg-f8f8f8'} listData={groups}/>
            <DropdownSimple selectData={handleGradeSelect} background={'bg-f8f8f8'} listData={grades}/>
            <DropdownSimple selectData={handleSubjectSelect} background={'bg-f8f8f8'} listData={subjects}/>
        </div>
        <div className={'flex justify-center space-x-3'}>
            <SimpleButton name={'Huỷ'} handleClick={handleClose} bgColor={'bg-f8f8f8'} textColor={'text-black'} textSize={'text-[14px]'} />
            <SimpleButton
                name={'Tạo'}
                bgColor={'bg-6149CD'}
                textColor={'text-white'}
                textSize={'text-[14px]'}
                disable={coursesStatus!='idle'}
                handleClick={handleCreateNewCourse}
            />
        </div>
        <ToastContainer position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"/>
    </div>
}
