import React, {useEffect, useState} from 'react'
import {useDispatch} from "react-redux";
import {getCourseAvgProgress} from "../courses/courseSlice";

export default function ProgressBar({course}){
    const [percentProgress, setPercentProgress] = useState(0)
    const dispatch = useDispatch()
    useEffect(()=>{
        if(course){
            dispatch(getCourseAvgProgress(course.id)).unwrap().then(payload=>{
                setPercentProgress((parseInt(payload.avg.completed) / payload.avg.total * 100).toFixed(2))
            })
        }
    },[])


    return <div className="w-full bg-F6EFFF rounded-full h-4 mt-2 ">

        <div  className="h-4 rounded-full   flex tr-justify-center item-center" style={{width:`${percentProgress}%`, background:'#F9B035'}}>
            <span className={'text-[11px] text-white '}>{percentProgress} %</span>
        </div>
    </div>
}
