import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getCoursesInMegaAPI, listGradeMegaAPI} from "../../services/accountService";
import {getCourseActionNumberAPI} from "../../services/courseService";

const initState = {
    status : 'idle'
}

export const getGradeMega = createAsyncThunk('dashboardStudent/listGradeMega',async ()=>{
    let grades = await listGradeMegaAPI()
    return grades.data
})

export const getCoursesInAccount = createAsyncThunk('dashboardStudent/coursesInAccount',async(accountId)=>{
    let courses = await getCoursesInMegaAPI(accountId)
    return courses.data
})

export const getCourseActionNumber = createAsyncThunk('dashboardStudent/courseActionNumber', async(courseId)=>{
    let courseActionNumber= await getCourseActionNumberAPI(courseId)
    return courseActionNumber.data
})

const dashboardStudentSlice = createSlice({
    name:'dashboardStudent',
    initialState: initState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getGradeMega.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(getGradeMega.rejected, (state,action)=>{
            state.status= 'idle'
            console.log(action.error)
        }).addCase(getGradeMega.fulfilled, (state,action)=>{
            state.status = 'idle'
        }).addCase(getCoursesInAccount.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(getCoursesInAccount.rejected, (state,action)=>{
            state.status= 'idle'
            console.log(action.error)
        }).addCase(getCoursesInAccount.fulfilled, (state,action)=>{
            state.status = 'idle'
        }).addCase(getCourseActionNumber.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(getCourseActionNumber.rejected,(state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(getCourseActionNumber.fulfilled,(state,action)=>{
            state.status = 'idle'
            console.log(action.payload)
        })
    }
})

export default dashboardStudentSlice.reducer
