import React, {useEffect} from 'react'
import trashIcon from '../../assets/images/icons/transh2.svg'
import { useTranslation } from 'react-i18next'
export default function TrueFalseQuestion({answers, handleSaveAnswer, index}){
    const {t, i18n} = useTranslation()
    const changeTextAnswer = (text, id)=>{
        let ans = [...answers]
        ans[id].answer_text = text
        handleSaveAnswer(index, ans)
    }


    const changeCorrectAnswer = (id)=>{
        let ans = [...answers]
        for(let i =0; i< ans.length; i++){
            if(i == id){
                ans[i].answer_weight = 100
            }else{
                ans[i].answer_weight = 0
            }
        }
        handleSaveAnswer(index, ans)
    }

    const handleDeleteAns=(id)=>{
        let ans = [...answers]
        if(ans.length ==1){
            return
        }
        if(ans[id].answer_weight == 100){
            ans.splice(id, 1)
            ans[0].answer_weight=100
        }else{
            ans.splice(id, 1)
        }
        handleSaveAnswer(index, ans)
    }


    return <div>
        <p className={'text-[14px] font-semibold'}>{t("quizQuestion.answer")}:</p>
        {answers && answers.map((x,i)=><div className={'flex justify-between space-x-3 py-2'}>
            <div className={'flex space-x-3'}>
                <button onClick={()=>{changeCorrectAnswer(i)}} className={`text-[14px] ${x.answer_weight == 100?"text-009A17 font-semibold":"text-black"}`}>{x.answer_weight == 100?t("quizQuestion.correctAns"):t("quizQuestion.otherAnswerCan")}</button>
                <input disabled={true} onChange={(e)=>{changeTextAnswer(e.target.value, i)}} value={x.answer_text} className={'bg-F0F0F0 h-[40px] rounded min-w-[300px] px-2'}  />
            </div>
            <button onClick={()=>{handleDeleteAns(i)}}><img src={trashIcon}/></button>
        </div>)}

    </div>
}
