import {axiosPrivate} from "../../commons/services/axiosService";

export const getAssignmentAPI=(courseId, assignmentId)=>{
    return axiosPrivate({
        method:'GET',
        url:`/assignment/getAssignment?courseId=${courseId}&assignmentId=${assignmentId}`
    })
}

export const getGroupAssignmentAPI=(courseId, groupId)=>{
    return axiosPrivate({
        method:"GET",
        url:`/assignment/assignmentGroups/${courseId}/${groupId}`
    })
}

export const updateAssignmentAPI = (courseId, assignmentInfo, assignmentId)=>{
    if(assignmentInfo.assignment_overrides.length > 0) {
        let assignment_overrides = []
        console.log(assignmentInfo.assignment_overrides)
        for (let i = 0; i < assignmentInfo.assignment_overrides.length; i++) {

            if (assignmentInfo.assignment_overrides[i].sectionSelects != null && assignmentInfo.assignment_overrides[i].sectionSelects.length > 0) {
                console.log(assignmentInfo.assignment_overrides[i])
                for (let section of assignmentInfo.assignment_overrides[i].sectionSelects) {
                    assignment_overrides.push({
                        course_section_id: section.value,
                        due_at: assignmentInfo.assignment_overrides[i].dueDate,
                        unlock_at: assignmentInfo.assignment_overrides[i].startDate,
                        lock_at: assignmentInfo.assignment_overrides[i].endDate
                    })
                }
            } else {
                if (assignmentInfo.assignment_overrides[i].startDate) {
                    assignmentInfo.unlock_at = assignmentInfo.assignment_overrides[i].startDate
                }
                if (assignmentInfo.assignment_overrides[i].endDate) {
                    assignmentInfo.lock_at = assignmentInfo.assignment_overrides[i].endDate
                }
                if (assignmentInfo.assignment_overrides[i].dueDate) {
                    assignmentInfo.due_at = assignmentInfo.assignment_overrides[i].dueDate
                }
            }
        }
        assignmentInfo.assignment_overrides = assignment_overrides
        return axiosPrivate({
            method: "PUT",
            url: `/assignment/updateAssignment`,
            data: {
                courseId: courseId,
                assignment: assignmentInfo,
                assignmentId: assignmentId
            }
        })
    }
}

