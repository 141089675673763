import React, {useEffect, useState} from 'react'
import GlobalHeader from "../../commons/components/headers/GlobalHeader";
import publishIcon from "../../assets/images/icons/publish.svg";
import unPublishIcon from "../../assets/images/icons/unpublish.svg";
import PendIcon from "../../assets/images/icons/edit.svg";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getCourseDetail} from "../courseDetail/CourseDetailSlice";
import {changeQuizState, getQuizById} from "./QuizDetailSlice";
import {getAssignmentDetail} from "../assignmentDetail/AssignmentDetailSlice";
import Footer from "../../commons/components/footer/Footer";
import {toast} from "react-toastify";
import {getPageSessionTokenAPI} from "../../services/authService";
import TeacherCourseTabBar from "../../components/tabBar/TeacherCourseTabBar";
import CourseSubTab from "../../components/tabBar/CourseSubTab";
import { useTranslation } from 'react-i18next';

export default  function QuizDetail(){
    const {t, i18n} = useTranslation()
    const params = useParams()
    const dispatch = useDispatch()
    const course = useSelector(state=>state.courseDetail.course)
    const [tabSelect,setTabSelect] = useState('3')
    const navigate = useNavigate()
    const quiz = useSelector(state=> state.quizDetail.quiz)
   const [quizUri,setQuizUri] = useState(null)
    useEffect(()=>{
        dispatch(getCourseDetail(params.courseId))
    },[params.courseId])

    useEffect(()=>{
        if(params.courseId && params.quizId){
            dispatch(getQuizById({
                courseId: params.courseId,
                quizId: params.quizId
            }))
        }
    },[params.courseId, params.quizId])


    useEffect(()=>{
        if(course && quiz){
            console.log(quiz)
            dispatch(getAssignmentDetail({
                courseId: course.id,
                assignmentId: quiz.assignment_id
            }))
        }

    },[quiz, course])

    useEffect(()=>{
        if(course && quiz){
            getPageSessionTokenAPI(quiz.mobile_url).then(pageUrl => {
                console.log(pageUrl.data)
                setQuizUri(pageUrl.data.session_url + "&persist_headless=1")
            }).catch(err => {
                console.log(err)
            })
        }

    },[quiz, course])

    const handleChangeQuizState=(state)=>{
        dispatch(changeQuizState({
            courseId: params.courseId,
            quizId: params.quizId,
            publish: state
        })).unwrap().catch(err=>{
            toast.error(t("assignment.cannotunpublicmessage"))
        })
    }

    return <div>
        <GlobalHeader />
        <CourseSubTab course={course} />
        <TeacherCourseTabBar tabSelect={tabSelect} />
        <div className={'container mx-auto py-3 min-h-[500px]'}>
            {course && tabSelect==3 && <div className={'flex w-full'}>
                {quiz && <div className={'flex w-full'}>
                    <div className={'w-full border-r px-2 '}>
                        <div className={'flex space-x-3 w-full justify-end items-center'}>
                            { quiz.published ? <div className={'flex  space-x-2'}>
                                <button onClick={()=>{handleChangeQuizState(false)}}><img src={publishIcon} /></button>
                                <span className={'text-[14px] text-09BD3C'}>{t( "published")}</span>
                            </div>:<div className={'flex space-x-2'}>
                                <button onClick={()=>{handleChangeQuizState(true)}}><img src={unPublishIcon} /></button>
                                <span className={'text-[14px] text-09BD3C'}>{t("unpublish")}</span>
                            </div>}
                            <button
                                onClick={()=>{
                                    navigate(`/courses/${course.id}/quizzes/${quiz.id}/edit`)
                                }}
                                className={'flex space-x-2 px-3 py-2 bg-F0F0F0 rounded'}>
                                <img src={PendIcon}/> <span className={'text-[14px]'}>{t("editBtn")}</span>
                            </button>
                        </div>
                        <div>
                            {quizUri ? <div className={'p-3 h-screen'}>
                                <iframe src={quizUri} className={'w-full h-full border-0'} ></iframe>
                            </div>:<div className={'h-screen p-3'}> </div>}

                        </div>
                    </div>
                </div>}

            </div>}

        </div>
        <Footer />
    </div>
}
