import {axiosPrivate} from "../../commons/services/axiosService";


export const createNewQuizApi = (courseId, quiz)=>{
    return axiosPrivate({
        method: 'POST',
        url:'quizzes/create',
        data:{
            courseId: courseId,
            quiz: quiz
        }
    })
}

export const editQuizAPI = (courseId, quizId, quiz)=>{
    return axiosPrivate({
        method: "PUT",
        url:'quizzes/edit',
        data:{
            courseId: courseId,
            quizId: quizId,
            quiz:quiz
        }
    })
}

export const createQuizQuestionsAPI = (courseId, quizId, questions)=>{
    return axiosPrivate({
        method:"POST",
        url:'quizQuestion/create',
        data:{
            courseId: courseId,
            quizId: quizId,
            questions:questions
        }
    })
}
