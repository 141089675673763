import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {addNoteAPI, getNoteAPI, translateApi} from "../../services/toolService";

const initState = {
    status:'idle',
    note: null
}


export const translate = createAsyncThunk('tool/translate', async({text, to})=>{
    let result = await translateApi(text,to)
    return result.data
})

export const getNote = createAsyncThunk('tool/getNote', async({courseId, pageId})=>{
    let note = await getNoteAPI(courseId, pageId)
    return note.data
})

export const addNote = createAsyncThunk('tool/addNote', async({courseId, pageId, text})=>{
    let note = await addNoteAPI(courseId, pageId, text)
    return note.data
})

const toolSlice = createSlice({
    initialState: initState,
    name: 'tool',
    reducers:{},
    extraReducers:builder => {
        builder.addCase(translate.pending,(state,action)=>{
            state.status ='loading'
        }).addCase(translate.fulfilled, (state,action)=>{
            state.status = 'idle'
        }).addCase(translate.rejected,(state,action)=>{
            state.status = 'ilde'
            console.log(action.error)
        }).addCase(getNote.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(getNote.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.note = action.payload
        }).addCase(getNote.rejected,(state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(addNote.pending,(state,action)=>{
            state.status='loading'
        }).addCase(addNote.fulfilled, (state,action)=>{
            state.status = 'idle'
        }).addCase(addNote.rejected,(state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        })
    }
})

export default toolSlice.reducer
