import {axiosPrivate} from "../commons/services/axiosService";

export const getCourseActionNumberAPI = (courseId)=>{
    return axiosPrivate({
        method:'GET',
        url:`course/${courseId}/getCourseActiveNumber`
    })
}


export const checkCourseIsMegaContentAPI=(courseId)=>{
    return axiosPrivate({
        method:"GET",
        url:`course/${courseId}/checkCourseIsMegaContent`
    })
}

export const getCourseReportSummaryAPI=(courseId)=>{
    return axiosPrivate({
        method:"GET",
        url:`course/${courseId}/summaryReport`
    })
}
