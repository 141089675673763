import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getAllAssignmentAPI} from "./moduleAssignmentService";

const initState = {
    status: 'idle',
    assignments:[]
}


export const getAllAssignment= createAsyncThunk('moduelAssignment/getAllAssignment', async(courseId)=>{
    let assignments = await getAllAssignmentAPI(courseId)
    return assignments.data
})

const moduleAssignmentSlice = createSlice({
    name: 'moduleAssignment',
    initialState: initState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getAllAssignment.pending, (state,action)=>{
            state.status ='pending'
        }).addCase(getAllAssignment.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.assignments = action.payload
        }).addCase(getAllAssignment.rejected, (state, action)=>{
            state.status= 'idle'
            console.log(action.error)
        })
    }
})
export default  moduleAssignmentSlice.reducer
