import React,{useEffect, useState} from 'react'
import GlobalHeader from "../../commons/components/headers/GlobalHeader";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getCourseDetail} from "../courseDetail/CourseDetailSlice";
import {getAssignmentDetail, updateAssState} from "./AssignmentDetailSlice";
import SpeedGradeIcon from '../../assets/images/icons/speed-grader.svg'
import publishIcon from '../../assets/images/icons/publish.svg'
import unPublishIcon from '../../assets/images/icons/unpublish.svg'
import PendIcon from '../../assets/images/icons/edit.svg'
import Footer from "../../commons/components/footer/Footer";
import {changeAssignmentState} from "../courseDetail/assignmentTab/AssignmentGroupSlice";
import {toast, ToastContainer} from "react-toastify";
import TeacherCourseTabBar from "../../components/tabBar/TeacherCourseTabBar";
import CourseSubTab from "../../components/tabBar/CourseSubTab";
import { useTranslation } from 'react-i18next';
export default function AssignmentDetail(){
    const params = useParams()
    const {t, i18n} = useTranslation()
    const dispatch = useDispatch()
    const course = useSelector(state=>state.courseDetail.course)
    const [tabSelect,setTabSelect] = useState('3')
    const navigate = useNavigate()
    const assignment = useSelector(state=> state.assignmentDetail.assignment)

    useEffect(()=>{
        dispatch(getCourseDetail(params.courseId))
    },[params.courseId])

    useEffect(()=>{
        if(params.courseId && params.assignmentId){
            dispatch(getAssignmentDetail({
                courseId: params.courseId,
                assignmentId: params.assignmentId
            }))
        }
    },[params.courseId, params.assignmentId])

    const handleChangeStateAssignment=(state)=>{
        console.log(params)
        dispatch(changeAssignmentState({
            assignmentId: params.assignmentId,
            courseId: params.courseId,
            published: state
        })).unwrap().then(assignment=>{
            dispatch(updateAssState(state))
        }).catch(err=>{
            console.log(err)
            toast.error(t("assignment.cannotunpublicmessage"))
        })
    }

    return <div>
        <GlobalHeader />
        <CourseSubTab  course={course}/>
        <TeacherCourseTabBar tabSelect={tabSelect} />
        <div className={'container mx-auto py-3'}>
            {course && tabSelect==3 && <div className={'flex w-full min-h-[800px]'}>
                {assignment && <div className={'flex w-full'}>
                    <div className={'w-10/12 border-r px-2 '}>
                        <div className={'flex space-x-3 w-full justify-end items-center'}>
                        { assignment.published ? <div className={'flex  space-x-2'}>
                            <button onClick={()=>{handleChangeStateAssignment(false)}}><img src={publishIcon} /></button>
                            <span className={'text-[14px] text-09BD3C'}>{t("published")}</span>
                        </div>:<div className={'flex space-x-2'}>
                            <button onClick={()=>{handleChangeStateAssignment(true)}}><img src={unPublishIcon} /></button>
                            <span className={'text-[14px] text-09BD3C'}>{t("unpublish")}</span>
                        </div>}
                        <button
                            onClick={()=>{
                                navigate(`/courses/${course.id}/assignments/${assignment.id}/edit`)
                            }}
                            className={'flex space-x-2 px-3 py-2 bg-F0F0F0 rounded'}>
                            <img src={PendIcon}/> <span className={'text-[14px]'}>{t("editBtn")}</span>
                        </button>
                    </div>
                        <div>
                            <p className={'text-[24px] font-semibold py-5'}>{assignment.name}</p>
                            <div className={'grid grid-cols-2 gap-2 sm:w-full md:w-1/2'}>
                                <span className={'text-[14px] font-semibold'}>{t("Grade")}:</span>
                                <span className={'text-[14px]'}>{assignment.points_possible}</span>
                                <span className={'text-[14px] font-semibold'}>{t("assignment.timeAttempt")}:</span>
                                <span className={'text-[14px]'}>{assignment.allowed_attempts==-1?'Không giới hạn':assignment.allowed_attempts}</span>
                                <span className={'text-[14px] font-semibold'}>{t("assignment.submissionType")}:</span>
                                <span className={'text-[14px]'}>{assignment.submission_types.join()=='on_paper'?'Mega Marking':assignment.submission_types.join()}</span>
                            </div>
                            <div className={'w-full border rounded-2xl mt-5'}>
                                <table className={'w-full'}>
                                    <thead className={''}>
                                    <tr className={'text-left bg-E9EDF8 text-[14px]  '}>
                                        <th className={'py-3 px-2 rounded-tl-2xl '}>{t("assignTo.dueDate")}</th>
                                        <th className={'py-3 px-2'}>{t("assignTo")}</th>
                                        <th className={'py-3 px-2'}>{t("assignTo.avaiableDate")}</th>
                                        <th className={'py-3 px-2 rounded-tr-2xl'}>{t("assignTo.utilDate")}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {assignment.all_dates?<>
                                        {assignment.all_dates.map(x=><tr className={''}>
                                            <td className={'text-[14px] py-3 px-2'}>{x.due_at? `${new Date(x.due_at).toLocaleDateString()}-${new Date(x.due_at).toLocaleTimeString()}`:"-"}</td>
                                            <td className={'text-[14px] py-3 px-2'}>{x.title? x.title:'-'}</td>
                                            <td className={'text-[14px] py-3 px-2'}>{x.unlock_at? `${new Date(x.unlock_at).toLocaleDateString()}-${new Date(x.unlock_at).toLocaleTimeString()}`:"-"}</td>
                                            <td className={'text-[14px] py-3 px-2'}>{x.lock_at? `${new Date(x.lock_at).toLocaleDateString()}-${new Date(x.lock_at).toLocaleTimeString()}`:"-"}</td>
                                        </tr>)}
                                    </>:<>

                                    </>}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className={'w-2/12 px-2'}>
                        <p className={'text-[14px] font-semibold'}>
                           {t("assignment.OtherInfo")}
                        </p>
                        <button
                            onClick={()=>{
                                navigate(`/courses/${params.courseId}/assignments/${params.assignmentId}/speedGrade`)
                            }}
                            className={'flex space-x-3 py-3 text-[14px]'}>
                                <img  src={SpeedGradeIcon}/>
                                <span>{t( "assignment.speedGrade")}</span>

                        </button>
                    </div>
                </div>}

            </div>}

        </div>
        <Footer/>
        <ToastContainer />
    </div>
}
