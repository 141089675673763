import React, {useEffect} from 'react'
import userManager from "../../config/configSSO";
import {useDispatch} from "react-redux";
import {mgeAuth} from "../logins/loginSlice";
import {useNavigate} from "react-router-dom";

export default function MGECallback(){
    const navigator = useNavigate()
    const dispatch = useDispatch()
    useEffect(()=>{
        userManager.signinRedirectCallback()
            .then((user) => {
                console.log(user)
                let tokenValid = user.access_token;
                if (tokenValid != null) {
                    dispatch(mgeAuth(user.access_token)).then(user=>{
                        navigator('/')
                    }).catch(err=>{
                        navigator('/login')
                    })
                }
            }).catch(err=>{
                console.log(err)
        })

    },[])

    return <div>

    </div>
}
