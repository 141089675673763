import React from 'react'
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    changePublishAPI,
    createPageAPI,
    deletePageAPI,
    getAllPageInCourseAPI,
    showPageAPI,
    updatePageAPI
} from "./PageService";

const initState = {
    pages:[],
    status: 'idle',
    pageDetail: null
}

export const getAllListPage= createAsyncThunk('page/allPage', async(courseId)=>{
    let pages = await getAllPageInCourseAPI(courseId)
    return pages.data
})

export const changePublish = createAsyncThunk('page/changePublish', async ({courseId, pageId, publish})=>{
    let page = await changePublishAPI(courseId, pageId, publish)
    return {
        page: page.data,
        pageId: pageId
    }
})

export const deletePage = createAsyncThunk('page/delete', async({courseId, pageId})=>{
    let result = await deletePageAPI(courseId, pageId)
    return {
        result: result,
        pageId: pageId
    }
})

export const showPage= createAsyncThunk('page/show', async({courseId, pageId})=>{
    let page = await showPageAPI(courseId, pageId)
    return page.data
})

export const createPage = createAsyncThunk('page/create', async({courseId, title, content})=>{
    let page = await createPageAPI(courseId, title, content)
    return page.data
})

export const updatePage= createAsyncThunk('page/update', async({courseId, pageId, content, title,isFontPage })=>{
    let page= await  updatePageAPI(courseId, pageId, content, title,isFontPage)
    return page.data
})

const pageSlice = createSlice({
    name: 'page',
    initialState: initState,
    reducers:{},
    extraReducers: builder => {
        builder.addCase(getAllListPage.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(getAllListPage.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(getAllListPage.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.pages = action.payload
        }).addCase(changePublish.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(changePublish.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(changePublish.fulfilled, (state,action)=>{
            state.status = 'idle'
            let _pages = [...state.pages]
            for(let i =0; i< _pages.length; i++){
                if(_pages[i].page_id == action.payload.pageId){
                    _pages[i] = action.payload.page
                }
            }
            state.pages = [..._pages]
        }).addCase(deletePage.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(deletePage.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(deletePage.fulfilled, (state,action)=>{
            state.status = 'idle'
            let _pages = [...state.pages]
            for(let i =0; i< _pages.length; i++){
                if(_pages[i].page_id == action.payload.pageId){
                   _pages.splice(i, 1)
                    break
                }
            }
            state.pages = [..._pages]
        }).addCase(createPage.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(createPage.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(console.log(action.error))
        }).addCase(createPage.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.pages= [...state.pages, action.payload]
        }).addCase(showPage.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(showPage.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(showPage.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.pageDetail=  action.payload
        }).addCase(updatePage.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(updatePage.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(updatePage.fulfilled, (state,action)=>{
            state.status = 'idle'
            let _pages = [...state.pages]
            for(let i =0; i< _pages.length; i++){
                if(_pages[i].page_id == action.payload.page_id){
                    _pages[i] =action.payload
                }
            }
            state.pages = _pages
        })
    }
})

export default pageSlice.reducer
