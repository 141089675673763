import React,{useState, useEffect} from 'react'
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getCourseDetail} from "../courseDetail/CourseDetailSlice";
import {getAssignmentDetail, getGroupAssignment, updateAssignment} from "../assignmentDetail/AssignmentDetailSlice";
import {getListQuestion, getQuizById, updateListQuestion} from "../quizDetail/QuizDetailSlice";
import GlobalHeader from "../../commons/components/headers/GlobalHeader";
import TabListComponent from "../../commons/components/tabs/TabListComponent";
import TabComponent from "../../commons/components/tabs/TabComponent";
import QuizInfo from "../quizInfo/QuizInfo";
import CreateQuestion from "../createQuestions/CreateQuestion";
import {editQuiz} from "../quizInfo/QuizSlice";
import {toast} from "react-toastify";
import Footer from "../../commons/components/footer/Footer";
import TeacherCourseTabBar from "../../components/tabBar/TeacherCourseTabBar";
import CourseSubTab from "../../components/tabBar/CourseSubTab";
import { useTranslation } from 'react-i18next';

export default function QuizEdit(){
    const {t, i18n} = useTranslation()
    const params = useParams()
    const dispatch = useDispatch()
    const course = useSelector(state=>state.courseDetail.course)
    const [tabSelect2,setTabSelect2] = useState(1)
    const [tabSelect,setTabSelect] = useState('3')
    const [quizInfo, setQuizInfo] = useState({})
    const [listAssignTo, setListAssignTo] = useState([])
    const [quizQuestions, setQuizQuestions] = useState([])
    const navigate=useNavigate()
    const quiz = useSelector(state=> state.quizDetail.quiz)
    const [loading, setLoading]= useState(false)

    useEffect(()=>{
        dispatch(getCourseDetail(params.courseId))
    },[params.courseId])

    useEffect(()=>{
        if(params.courseId && params.quizId){
            dispatch(getQuizById({
                courseId: params.courseId,
                quizId: params.quizId
            }))
        }
    },[params.courseId, params.quizId])

    useEffect(()=>{
        if(params.courseId && params.quizId){
            dispatch(getListQuestion({
                courseId: params.courseId,
                quizId: params.quizId
            })).unwrap().then(questions=>{
                let _questions =JSON.parse(JSON.stringify(questions))
                for(let i = 0; i< questions.length; i++){
                    for(let j=0; j< questions[i].answers.length; j++){
                        _questions[i].answers[j].answer_text=_questions[i].answers[j].text
                        _questions[i].answers[j].answer_weight=_questions[i].answers[j].weight
                    }
                }
                console.log(JSON.parse(JSON.stringify(_questions)))
                setQuizQuestions(JSON.parse(JSON.stringify(_questions)))
            })
        }
    },[params.courseId, params.quizId])

    useEffect(()=>{
        if(quiz){
            dispatch(getGroupAssignment({
                courseId: params.courseId,
                groupId: quiz.assignment_group_id
            })).unwrap().then(group=>{
                if(quiz.assignment_id) {
                    dispatch(getAssignmentDetail({
                        courseId: params.courseId,
                        assignmentId: quiz.assignment_id
                    })).unwrap().then(assignment=>{
                        if(assignment && assignment.all_dates && assignment.all_dates.length >0){
                            let listAssto = []
                            for(let dates of assignment.all_dates){
                                if(dates.set_type =='CourseSection'){
                                    listAssto.push({
                                        sectionSelects:[{
                                            label:dates.title,
                                            value:dates.set_id
                                        }],
                                        dueDate:  dates.due_at?new Date(dates.due_at):null,
                                        startDate: dates.unlock_at?new Date(dates.unlock_at):null,
                                        endDate: dates.lock_at? new Date(dates.lock_at):null
                                    })
                                }else{
                                    listAssto.push({
                                        sectionSelects:[],
                                        dueDate:  dates.due_at?new Date(dates.due_at):null,
                                        startDate: dates.unlock_at?new Date(dates.unlock_at):null,
                                        endDate: dates.lock_at? new Date(dates.lock_at):null
                                    })
                                }
                            }
                            setListAssignTo(listAssto)
                        }
                    })
                }


            })

        }
        setQuizInfo(quiz)

    },[quiz])


    const handleEditQuiz=()=>{
        setLoading(true)
        dispatch(editQuiz({
            courseId: course.id,
            quizId: quizInfo.id,
            quiz: quizInfo
        })).unwrap().then(quiz=>{
            if (quizInfo.assignment_overrides && quizInfo.assignment_overrides.length > 0) {
                for (let override of quizInfo.assignment_overrides) {
                    if (override.dueDate) {
                        if (override.startDate) {
                            if (new Date(override.dueDate).getTime() < new Date(override.startDate).getTime()) {
                                toast.error(t("assignment.dateError1"))
                                return
                            }
                        }
                        if (override.endDate) {
                            if (new Date(override.dueDate).getTime() > new Date(override.endDate).getTime()) {
                                toast.error(t("assignment.dateError2"))
                                return
                            }
                        }

                    }
                    if (override.startDate && override.endDate) {
                        if (new Date(override.startDate).getTime() > new Date(override.endDate).getTime()) {
                            toast.error(t("assignment.dateError3"))
                            return
                        }
                    }
                }
                dispatch(updateAssignment({
                    courseId: course.id,
                    assignmentInfo: {
                        assignment_overrides: quizInfo.assignment_overrides
                    },
                    assignmentId: quiz.assignment_id
                })).unwrap().then(assignment=>{
                    dispatch(updateListQuestion({
                        courseId: course.id,
                        quizId: quizInfo.id,
                        questions: quizQuestions
                    })).unwrap().then(result=>dispatch(editQuiz({
                        courseId: course.id,
                        quizId: quizInfo.id,
                        quiz: quizInfo
                    })).unwrap().then(_quiz=>{
                        navigate(`/courses/${course.id}/quizzes/${quiz.id}`)
                        setLoading(false)
                    }))
                })
            }else{
                dispatch(updateListQuestion({
                    courseId: course.id,
                    quizId: quizInfo.id,
                    questions: quizQuestions
                })).unwrap().then(result=>{
                    dispatch(editQuiz({
                        courseId: course.id,
                        quizId: quizInfo.id,
                        quiz: quizInfo
                    })).unwrap().then(_quiz=>{
                        navigate(`/courses/${course.id}/quizzes/${quiz.id}`)
                        setLoading(false)
                    })
                })
            }
        })
    }


    return <div>
        <GlobalHeader />
        <CourseSubTab course={course}/>
        <TeacherCourseTabBar tabSelect={tabSelect} />
        <div className={'container mx-auto py-3 min-h-[500px]'}>
            {course && tabSelect==3 && <div className={'flex w-full'}>
                {quiz && <div className={'py-2 w-full'}>
                    <TabListComponent >
                        <TabComponent name={'Thông tin chi tiết'}
                                      isSelect={tabSelect2==1}
                                      handleClick={()=>{setTabSelect2(1)}}
                        />
                        <TabComponent name={'Câu hỏi'}
                                      isSelect={tabSelect2==2}
                                      handleClick={()=>{setTabSelect2(2)}}
                                      />

                    </TabListComponent>
                    {tabSelect2 == 1 && quizInfo && <QuizInfo _listAssignTo={listAssignTo} isEdit={true} quizInfo={quizInfo} setQuizInfo={setQuizInfo}  course= {course} />}
                    {tabSelect2==2 &&  <CreateQuestion  quizQuestions={quizQuestions} setQuizQuestions={setQuizQuestions} course={course} quiz={quizInfo} />}
                    <div className={'flex justify-end space-x-2'}>
                        <button onClick={()=>{
                            window.open(`/courses/${course.id}/assignments`, '_self')
                        }} className={'h-[40px] rounded bg-F0F0F0 min-w-[100px] text-[14px]'}>{t('cancelBtn')}</button>
                        <button disabled={loading} onClick={handleEditQuiz}  className={'h-[40px] rounded bg-316AFF min-w-[100px] text-[14px] text-white'}>{
                            loading? <div
                                className="inline-block h-4 w-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                role="status">
                                  <span
                                      className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                                  >{t("loading")}...</span
                                  >
                            </div>:<span>{t("saveBtn")}</span>
                        }</button>
                    </div>
                </div>}

            </div>}

        </div>
        <Footer />
    </div>
}
