import React, {Fragment, useEffect, useState} from 'react'
import {Menu, Transition} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/solid";
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function DropdownSimple2({listData, selectData, background, disable, index, valueSelected}){
    const [dataSelect,setDataSelect] = useState({})

    useEffect(()=>{
        if(listData && listData.length > 0){
            if(valueSelected){
                for(let i =0; i< listData.length; i++){
                    if(listData[i].value == valueSelected){
                        setDataSelect(listData[i])
                        break
                    }
                }

            }else{
                setDataSelect(listData[0])
            }

            selectData(listData[0])
        }else{
            setDataSelect(null)
        }
    },[listData])

    const onChange = (data)=>{
        if(index != undefined){
            selectData(index,data )
        }else{
            selectData(data)
        }
        setDataSelect({
            name:data.name,
            value: data.value
        })
    }
    return <div >
        <Menu  as="div" className="relative inline-block text-left mb-2  rounded h-[40px] min-w-[200px] ">
            <div className={' min-w-full '} >
                <Menu.Button className={`inline-flex justify-start lg:justify-center w-full rounded px-7  py-3 h-[40px]  text-sm font-light text-gray-700  focus:outline-none ${background} `}>
                    <div className={'flex justify-start w-full'}>
                        {dataSelect && <span className={""}>{dataSelect.name}</span>}
                    </div>

                    <div className={'flex'}>
                        <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5 text-indigo-700" aria-hidden="true" />
                    </div>
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="origin-top-right bg-F0F0F0 max-h-[300px] overflow-y-scroll  scrollbar-hide  max-h-[300px] rol z-50 absolute right-0 mt-2 w-full   rounded z-50 bg-F0F0F0  focus:outline-none">
                    <div className="py-1 w-full overflow-y-visible ">
                        {listData && listData.map(x=><Menu.Item disabled={disable} >
                            {({ active }) => (
                                <button
                                    onClick={()=>{onChange(x)}}
                                    className={classNames(
                                        'block px-4 py-2 text-sm hover:text-indigo-600 w-full'
                                    )}
                                >
                                    {x.name}
                                </button>
                            )}
                        </Menu.Item>)}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    </div>
}
