import {axiosPrivate} from "../commons/services/axiosService";

export const translateApi=(text, to)=>{
    return axiosPrivate({
        method:'POST',
        url:`/tool/translate`,
        data:{
            text:text,
            to:to
        }
    })
}

export const getNoteAPI=(courseId, pageId)=>{
    return axiosPrivate({
        method:"GET",
        url:`note/get?courseId=${courseId}&pageId=${pageId}`
    })
}

export const addNoteAPI=(courseId, pageId, text)=>{
    return axiosPrivate({
        method:"POST",
        url:`note/add`,
        data:{
            courseId: courseId,
            pageId: pageId,
            text:text
        }
    })
}
