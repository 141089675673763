import React, {useState, useEffect} from 'react'
import GlobalHeaderStudent from "../../commons/components/headers/GlobalHeaderStudent";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getCourseDetail} from "../courseDetail/CourseDetailSlice";
import {getCourseReportSummary, getEnrollInfo} from "./CourseReportStudentSlice";
import clockIcon from '../../assets/images/icons/clock.svg'
import Footer from "../../commons/components/footer/Footer";
import startIcon from '../../assets/images/icons/star.png'
import StudentCourseTabBar from "../../components/tabBar/StudentCourseTabBar";
import CourseSubTab from "../../components/tabBar/CourseSubTab";
import { useTranslation } from 'react-i18next';
export default function CourseReportStudent(){
    const {t, i18n} = useTranslation()
    const params = useParams()
    const dispatch = useDispatch()
    const course = useSelector(state=>state.courseDetail.course)
    const enrollInfo = useSelector(state=>state.courseReportStudent.enrollInfo)
    const courseSummaryInfo = useSelector(state=>state.courseReportStudent.courseSummary)
    const [tabSelect,setTabSelect] = useState('5')
    const [process, setProcess] = useState(0)
    const [totalActivity, setTotalActivity] = useState("")
    const [currentGrade,setCurrentGrade] = useState(0)
    useEffect(()=>{
        dispatch(getCourseDetail(params.courseId))
    },[params.courseId])

    useEffect(()=>{
        if(params.courseId){
            dispatch(getEnrollInfo(params.courseId))
        }
    },[params.courseId])

    useEffect(()=>{
        if(params.courseId){
            dispatch(getCourseReportSummary(params.courseId))
        }
    },[params.courseId])

    useEffect(()=>{
        if(course && course.course_progress){
            let p=  (course.course_progress.requirement_completed_count/course.course_progress.requirement_count*100).toFixed(2)
            setProcess(p)
        }
    },[course])

    useEffect(()=>{
        if(enrollInfo && enrollInfo.total_activity_time){
            let total = enrollInfo.total_activity_time
            let hours = (total/3600).toFixed(0)
            let minutes = ((total % 3600)/60).toFixed(0)
            setTotalActivity(`${hours}:${minutes}hr`)
        }
        if(enrollInfo && enrollInfo.grades && enrollInfo.grades.current_grade){
            setCurrentGrade(enrollInfo.grades.current_grade)
        }
    },[enrollInfo])

    const randomColor=(process)=> {
        if(process< 100){
            return '#FF7439'
        }else{
            return "#09BD3C"
        }
    }

    return <div>
        <GlobalHeaderStudent />
        <CourseSubTab  course={course}/>
        <StudentCourseTabBar  tabSelect={tabSelect}/>
        <div className={'container mx-auto'}>
            <div className={'px-3'}>
                <div className={"py-5"}>
                    <h4 className={'font-semibold my-3'}>{t("learningReport")}</h4>
                    <div className={'flex space-x-3 items-center'}>
                        <img src={clockIcon}/>
                        <span className={'text-7A86A1 text-[14px]'}>{t("course.lastAccess")}:</span>
                        <span className={'text-7A86A1 text-[14px]'}>{enrollInfo?new Date(enrollInfo.last_activity_at).toLocaleDateString()+"-"+new Date(enrollInfo.last_activity_at).toLocaleTimeString():""}</span>

                    </div>
                    <div className="w-full bg-E9EDF8 text-blue-600 rounded-full h-2.5 mt-4 ">
                        {course && course.course_progress &&  <div  className="h-2.5 rounded-full" style={{width:`${process}%`, background: randomColor(process)}}></div>}
                        <span className={'text-7A86A1 text-[12px]'}>{course && course.course_progress && <span className={'text-7A86A1 text-[12px]'}>{course.course_progress.requirement_completed_count}</span>} / {course && course.course_progress && <span>{course.course_progress.requirement_count}</span>}</span>
                    </div>
                </div>
                <div  className={"py-5"}>
                    <h4 className={'font-semibold my-3'}>{t("report.learingStatistic")}</h4>
                </div>
                <div className={'grid grid-cols-12 gap-5 w-full'}>
                    <div className={' col-span-12 md:col-span-5'}>
                        <div className={'rounded bg-F0F0F0 my-3 w-full h-[80px] px-3 py-2 my-2'}>
                            <h4 className={'text-[14px] font-semibold'}>{t("report.lastActivityTime")}</h4>
                            <span className={'text-[24px] font-semibold text-blue-600 py-5'}>{totalActivity}</span>
                        </div>
                        <div className={'rounded bg-F0F0F0 my-3 w-full h-[80px] px-3 py-2 my-2'}>
                            <h4 className={'text-[14px] font-semibold'}>{t("dashboard.avgGrade")}</h4>
                            <span className={'text-[24px] font-semibold text-blue-600 py-5'}>{currentGrade}</span>
                        </div>
                        {courseSummaryInfo && courseSummaryInfo.user.analytics &&<div className={'rounded  my-3 w-full h-[80px]  grid grid-cols-2 gap-3'}>
                            <div className={'bg-F0F0F0 rounded px-3 py-2'}>
                                <h4 className={'text-[14px] font-semibold pb-3'}>{t("report.pageView")}</h4>
                                {courseSummaryInfo.user.analytics.page_views.level ==0 && <div className={'flex space-x-3'}>
                                    <span className={'text-blue-600'}>None</span>
                                </div>}
                                {courseSummaryInfo.user.analytics.page_views.level ==1 && <div className={'flex space-x-3'}>
                                    <img className={'w-5'} src={startIcon} />
                                </div>}
                                {courseSummaryInfo.user.analytics.page_views.level ==2 && <div className={'flex space-x-3'}>
                                    <img className={'w-5'} src={startIcon} />
                                    <img className={'w-5'} src={startIcon} />
                                </div>}
                                {courseSummaryInfo.user.analytics.page_views.level ==3 && <div className={'flex space-x-3'}>
                                    <img className={'w-5'} src={startIcon} />
                                    <img className={'w-5'} src={startIcon} />
                                    <img className={'w-5'} src={startIcon} />
                                </div>}

                            </div>
                            <div className={'bg-F0F0F0 rounded px-3 py-2'}>
                                <h4 className={'text-[14px] font-semibold pb-3'}>{t("report.practice")}</h4>
                                {courseSummaryInfo.user.analytics.participations.total ==1 && <div className={'flex space-x-3'}>
                                    <img className={'w-5'} className={'w-5'} src={startIcon} />
                                </div>}
                                {courseSummaryInfo.user.analytics.participations.total ==2 && <div className={'flex space-x-3'}>
                                    <img className={'w-5'} src={startIcon} />
                                    <img className={'w-5'} src={startIcon} />
                                </div>}
                                {courseSummaryInfo.user.analytics.participations.total ==3 && <div className={'flex space-x-3'}>
                                    <img className={'w-5'} src={startIcon} />
                                    <img className={'w-5'} src={startIcon} />
                                    <img className={'w-5'} src={startIcon} />
                                </div>}
                                {courseSummaryInfo.user.analytics.participations.level ==0 && <div className={'flex space-x-3'}>
                                    <span className={'text-blue-600'}>None</span>
                                </div>}

                            </div>

                        </div>}
                    </div>
                    <div className={'col-span-12 md:col-span-7'}>
                        <div className={'rounded bg-F0F0F0 my-3 w-full px-3 py-2 my-2'}>
                            <h4 className={'text-[14px] font-semibold'}>{t("report.listAss")}</h4>
                            {courseSummaryInfo
                                && courseSummaryInfo.course
                                && courseSummaryInfo.course.submissionsConnection
                                && courseSummaryInfo.course.submissionsConnection.edges
                                && courseSummaryInfo.course.submissionsConnection.edges.map(grade=><div className={'py-2 flex space-x-2 justify-center'}>
                                    <span className={'text-[12px] '}>{grade.submission.assignment.name.length>19?grade.submission.assignment.name.substring(0,19)+"...":grade.submission.assignment.name}</span>
                                    <div className={'flex justify-center flex-1 items-center space-x-2'}>
                                        <div className="w-full bg-white text-blue-600 rounded-full h-4">
                                            <div  className="h-4 rounded-full" style={{width:`${grade.submission.score / grade.submission.assignment.points_possible*100}%`, background: randomColor(grade.submission.score / grade.submission.assignment.points_possible*100)}}>
                                            </div>
                                        </div>
                                        <span className={'text-[12px] w-[50px]'}>{grade.submission.score} / {grade.submission.assignment.points_possible}</span>
                                    </div>


                                </div>)
                            }
                        </div>
                    </div>
                </div>
            </div>


        </div>
        <Footer/>
    </div>
}
