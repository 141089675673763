import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getSubjectInGradeAPI} from "../../commons/services/subjectService";

const initState = {
    subjects: [],
    status:'idle'
}

export const getSubjectInGrade= createAsyncThunk('courseSubject/getSubjectInGrade', async(gradeId)=>{
    let subjects = await getSubjectInGradeAPI(gradeId)
    return subjects.data
})

export const CourseSubjectSlice = createSlice({
    name:'courseSubject',
    initialState:initState,
    reducers: {},
    extraReducers:builder => {
        builder.addCase(getSubjectInGrade.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(getSubjectInGrade.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.subjects = action.payload
        }).addCase(getSubjectInGrade.rejected, (state, action)=>{
            state.status= 'idle'
            console.log(action.error.message)
        })
    }
})

export default CourseSubjectSlice.reducer
