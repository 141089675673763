import { UserManager } from 'oidc-client';

const userManager = new UserManager({
    authority: process.env.REACT_APP_SS0_AUTHORITY,
    client_id: process.env.REACT_APP_SS0_CLIENT_ID,
    //client_secret: process.env.SS0_CLIENT_SECRET,
    redirect_uri: process.env.REACT_APP_SS0_REDIRECT_URI,
    response_type: process.env.REACT_APP_SS0_RESPONSE_TYPE,
    scope: process.env.REACT_APP_SS0_SCOPE,
    post_logout_redirect_uri: process.env.REACT_APP_SS0_POST_LOGOUT_REDIRECT_URI
});

export default userManager;
