import React from 'react'
import CourseCard from "./CourseCard";

export default function ListCourse({title,listCourse}){
    return <div >
        <div className={'py-5'}>
            <span className={'text-[18px] font-semibold text-7A86A1'}>{title}</span>
        </div>
        <div className={'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-2 gap-y-4'}>
            {listCourse && listCourse.map(x=>(
               <CourseCard course={x} />
            ))}
        </div>

    </div>
}
