import React, {useEffect, useState} from 'react'
import EditIcon from '../../../assets/images/icons/edit.svg'
import TrashIcon from '../../../assets/images/icons/trash.svg'
import AddIcon from "../../../assets/images/icons/add1.svg";
import {useDispatch, useSelector} from "react-redux";
import {deleteSection, updateSection} from "./SectionSlice";
import {toast, ToastContainer} from 'react-toastify';
import { useTranslation } from 'react-i18next';
export default function SectionItem({section, openModal, setSectionSelect, openViewListPeople}){
    const [onEdit, setOnEdit] = useState(false)
    const [sectionName, setSectionName] = useState("")
    const dispatch = useDispatch()
    const status = useSelector(state=>state.section.status)
    useEffect(()=>{
        if(section){
            setSectionName(section.name)
        }
    },[section])

    const handleUpdateName= ()=>{
        dispatch(updateSection({
            name: sectionName,
            sectionId: section.id
        })).unwrap().then(data=>{
            setOnEdit(false)
        })
    }

    const {t, i18n}= useTranslation()
    const handleDelete=(id)=>{
        dispatch(deleteSection(id)).unwrap().catch(err=>{
            toast.error(t('candeleteSectionMessage'))
        })
    }

    const handleOpenModal=(section)=>{
        openModal()
        setSectionSelect(section)
    }

    return <div className={'flex border-b py-2'} key={section.id}>
       <div className={'flex flex-col w-full'}>
           {onEdit ===false &&<div className={'flex items-center space-x-3'}><span onClick={()=>{openViewListPeople(section)}} className={'text-black text-[14px] items-center hover: cursor-pointer hover:text-316AFF'}>{section.name}</span> <button><img className={'w-[20px] h-[20px]'} src={EditIcon} onClick={()=>{setOnEdit(true)}}/></button> </div>}
           {onEdit ===true &&<div className={'flex items-center space-x-3'}>
               <input value={sectionName}
                      onChange={(e)=>{setSectionName(e.target.value)}}
                      className={'text-black text-[14px] items-center focus:outline-none px-2 bg-F0F0F0 rounded py-1'} />
               <button disabled={status==='loading'} onClick={handleUpdateName} className={'px-1 py-1 bg-F0F0F0 rounded text-316AFF text-[14px]'}>{t('saveBtn')}</button>
           </div>}
           <div className={'text-[12px] text-7A86A1'}>{section.total_students} {t('people')}</div>
       </div>
        <div className={'justify-end flex w-full space-x-2'}>
            <button onClick={()=>{handleOpenModal(section)}} className={'bg-F0F0F0 flex justify-center items-center px-3 space-x-3 h-[40px] rounded'}><img src={AddIcon}/><span className={'text-393939 text-[14px]'}>{t('addPeopleBtn')}</span></button>
            <button onClick={()=>{handleDelete(section.id)}} className={'bg-F0F0F0 flex justify-center items-center px-3 space-x-3 h-[40px] rounded'}><img src={TrashIcon}/></button>
        </div>
    </div>
}
