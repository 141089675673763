import React, {useEffect} from 'react'
import {useDispatch} from "react-redux";
import {getAllSchool} from "./school/AdminSchoolSlice";
import GlobalHeader from "../../commons/components/headers/GlobalHeader";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminSchool from "./school/AdminSchool";
import AdminGroup from "./group/AdminGroup";
import AdminGrade from "./grade/AdminGrade";
import AdminSubject from "./subject/AdminSubject";
export default function Admin(){
    const dispatch = useDispatch()


    useEffect(()=>{
        dispatch(getAllSchool())
    },[])


    return <div >
        <GlobalHeader />
        <div className={'container mx-auto py-5'}>
            <AdminSchool />
            <AdminGroup />
            <AdminGrade />
            <AdminSubject />
        </div>

        <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        />
    </div>
}
