import {axiosPrivate} from "../../commons/services/axiosService";

export const getAllPageInCourseAPI= (courseId)=>{
    return axiosPrivate({
        method:'GET',
        url: `pages/listInCourse?courseId=${courseId}`
    })
}

export const changePublishAPI = (courseId, pageId, publish)=>{
    return axiosPrivate({
        method:"GET",
        url:`pages/course/${courseId}/page/${pageId}/changePublish?publish=${publish}`
    })
}

export const deletePageAPI =(courseId, pageId)=>{
    return axiosPrivate({
        method:"DELETE",
        url:`pages/course/${courseId}/page/${pageId}`
    })
}

export const createPageAPI=(courseId, title, content)=>{
    return axiosPrivate({
        method:"POST",
        url:"pages/create",
        data:{
            courseId: courseId,
            pageTitle: title,
            pageContent: content
        }
    })
}

export const showPageAPI = (courseId, pageId)=>{
    return axiosPrivate({
        method:'GET',
        url:`pages/show/course/${courseId}/page/${pageId}`
    })
}

export const updatePageAPI = (courseId, pageId, content, title, isFontPage)=>{
    return axiosPrivate({
        method:"PUT",
        url:`pages/update`,
        data:{
            courseId: courseId,
            pageId: pageId,
            content: content,
            title: title,
            isFontPage: isFontPage
        }
    })
}
