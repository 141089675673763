import React, {useEffect, useState} from 'react'
import SearchIcon from '../../../assets/images/icons/search_icon.svg'
import AddIcon from '../../../assets/images/icons/addIcon.svg'
import {useDispatch, useSelector} from "react-redux";
import {createGroupAssignment, getAssignmentGroups, getAssignmentInGroup} from "./AssignmentGroupSlice";
import DisclosureSimple from "../../../commons/components/disclosures/DisclosureSimple";
import AssignmentItems from "./AssignmentItems";
import SimpleModal from "../../../commons/modals/SimpleModal";
import CreateGroupAssignmentPopup from "./CreateGroupAssignmentPopup";
import CreateAssignment from "./CreateAssignment";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Footer from "../../../commons/components/footer/Footer";
import { useTranslation } from 'react-i18next';
export default function AssignmentTab({course}){
    const user =useSelector(state=>state.auth.user)
    const [searchValue,setSearchValue] = useState('')
    const dispatch = useDispatch()
    const assignmentGroups = useSelector(state=>state.assignmentGroup.groups)
    const [createGroupAssignmentModal, setCreateGroupAssignmentModal] = useState(false)
    const {t, i18n} = useTranslation()
    const closeCreateGroupAssignmentModal= ()=>{
        setCreateGroupAssignmentModal(false)
    }

    const [componentDisplay, setComponentDisplay] = useState("list")

    useEffect(()=>{
        if(course){
            dispatch(getAssignmentGroups(course.id)).unwrap().then(groups=>{
                for(let group of groups){
                    dispatch(getAssignmentInGroup({
                        courseId: course.id,
                        groupId: group.id
                    }))
                }

            })
        }
    },[course])


    const createGroup= (groupName)=>{
        dispatch(createGroupAssignment({
            courseId: course.id,
            groupName: groupName
        })).unwrap().then(group=>{
            closeCreateGroupAssignmentModal()
        })
    }

    return <div>
        {componentDisplay== 'list' && <div className={'py-3 px-3 md:px-0'}>
            { user && user.viewOption == 1 && <div className={'flex py-2'}>
            <div className={'flex space-x-2 bg-F0F0F0 h-[40px] px-2 min-w-[320px] items-center rounded-lg'}>
                <img src={SearchIcon} alt={'searchIcon'} className={'w-[16px] h-[16px]'} />
                <input className={'focus:outline-none bg-F0F0F0'}  placeholder={t('assignment.searchAssignment')} value={searchValue} onChange={(e)=>{setSearchValue(e.target.value)}} />
            </div>
                { <div className={'flex flex-1 space-x-2 justify-end '}>
                <button
                    onClick={()=>{
                        setCreateGroupAssignmentModal(true)
                    }}
                    className={'justify-center flex items-center space-x-2 text-white bg-316AFF h-[40px] w-[120px] rounded-lg'}>
                    <img src={AddIcon}/>
                    <span>{t('assignment.groupBtn')}</span>
                </button>
                <button
                    onClick={()=>{setComponentDisplay('create')}}
                    className={'justify-center flex items-center space-x-2 text-white bg-316AFF h-[40px] w-[120px] rounded-lg'}>
                    <img src={AddIcon}/>
                    <span>{t('assingment.assignmentBtn')}</span>
                </button>
            </div>}
        </div>}
            {assignmentGroups && assignmentGroups.length && assignmentGroups.map(x=><DisclosureSimple title={x.name}>
                <AssignmentItems assignments={x.assignments} courseId={course.id} />
            </DisclosureSimple>)}
            <SimpleModal maxWidth={'max-w-md'} title={t('assignment.addGroupAssignmentTitle')} isOpen={createGroupAssignmentModal} closeModal={closeCreateGroupAssignmentModal} >
                <CreateGroupAssignmentPopup  closeModal={closeCreateGroupAssignmentModal} createGroup={createGroup} />
            </SimpleModal>
        </div>}

        {componentDisplay=='create' && <CreateAssignment assignmentGroups={assignmentGroups} course={course} />}
        <ToastContainer  position="top-center"
                         autoClose={5000}
                         hideProgressBar={false}
                         newestOnTop={false}
                         closeOnClick
                         rtl={false}
                         pauseOnFocusLoss
                         draggable
                         pauseOnHover
                         theme="light"/>

    </div>
}
