import React, {useEffect, useState} from 'react'
import {useSelector} from "react-redux";
import {isSameDay,  parseISO} from 'date-fns'
export default function PlannerBadger({day}){
    const [count, setCount] = useState(0)
    const list_planner = useSelector(state=> state.courseStudent.planners)
    useEffect(()=>{
        let c = 0
        for(let planner of list_planner){
            if(isSameDay(day,parseISO(planner.plannable_date))){
                c+=1
            }
        }
        setCount(c)
    }, [day, list_planner])
    return <>
        {count!==0 && <span
            className="absolute top-3 right-4 inline-flex items-center justify-center px-1.5 py-0.5 font-light text-sm leading-none text-white transform translate-x-1/2 -translate-y-1/2  rounded-full bg-8B9D07">{count}</span>}
    </>
}
