import {axiosPrivate} from "../../../commons/services/axiosService";


export const createGroupAPI=(groupName,schoolId)=> {
    return axiosPrivate({
        url: '/group/create',
        method: 'POST',
        data: {
            group: {
                name: groupName
            },
            schoolId: schoolId
        },
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        },
    })
}


export const deleteGroupAPI=(groupId)=>{
    return axiosPrivate({
        url:`/group/${groupId}`,
        method:"DELETE"
    })
}

