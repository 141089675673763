import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getAllSubmissionAPI, getSubmissionByStudentAPI, submitAssignmentAPI} from "../../services/SubmissionService";

const initState={
    status : 'idle',
    submission: null
}


export const getAssSubmissionStudent= createAsyncThunk('assDetailStd/submissionDetail',async({courseId, assignmentId})=>{
     let submission = await getSubmissionByStudentAPI(courseId, assignmentId)
    return submission.data
})

export const submitAssignment = createAsyncThunk('assDetailStd/submitAssignment',async({
    courseId, assignmentId, submissionType, url, body
                                                                                       })=>{
    let submission = await submitAssignmentAPI(courseId,assignmentId, submissionType, url, body)
    return submission.data
})

export const getListSubmission=createAsyncThunk('assDetailStd/listSubmission', async({courseId, assignmentId})=>{
    let submissions = await getAllSubmissionAPI(assignmentId, courseId)
    return submissions.data
})

const AssignmentDetailStudentSlice = createSlice({
    name:'assDetailStd',
    initialState: initState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getAssSubmissionStudent.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(getAssSubmissionStudent.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.submission = action.payload
        }).addCase(getAssSubmissionStudent.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(submitAssignment.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(submitAssignment.fulfilled, (state,action)=>{
            state.status = 'idle'
            console.log(action.payload)
        }).addCase(submitAssignment.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        }).addCase(getListSubmission.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(getListSubmission.fulfilled, (state,action)=>{
            state.status = 'idle'
            console.log(action.payload)
        }).addCase(getListSubmission.rejected, (state,action)=>{
            state.status = 'idle'
            console.log(action.error)
        })
    }
})

export default AssignmentDetailStudentSlice.reducer
