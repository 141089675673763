import React from 'react'
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import { useTranslation } from 'react-i18next';

export default function MenuItem({icon, name, url, isActive}){
    const {t, i18n} = useTranslation()
    const user =useSelector(state=>state.auth.user)
    const navigate = useNavigate()
    const handleClick = ()=>{
        window.open(url, "_self")

    }



    return <div className={`h-full ${isActive?'border-b-2 border-b-6149CD':''}`}>
        <button className={'flex justify-center items-center  px-3 h-full space-x-2'} onClick={handleClick}>
            <img src={icon} alt={'icon'}/>
            <span className={`text-[14px] ${user && user.viewOption==3?"text-26276E":""}`}>{name}</span>
        </button>

    </div>
}
