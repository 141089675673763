import React, {useRef} from 'react'
import CalendarIcon  from '../../../assets/images/icons/calendar.svg'

import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
export default function DateTimePickerSimple({dateTime, setDateTime}){
    const refComp = useRef(null);
    const handle_click = ()=>{
        refComp.current.flatpickr.open()
    }
    return <div className={'flex space-x-3  bg-F0F0F0 h-[40px] py-2 rounded px-2 justify-center w-fit' }>
        <img src={CalendarIcon} className={'hover:cursor-pointer'} onClick={handle_click}/>
        <Flatpickr
            ref={refComp}
            className={' text-[14px] bg-F0F0F0 focus:outline-none'}
            data-enable-time
            value={dateTime}
            onChange={([date]) => {
                setDateTime(date)
            }}
        />

    </div>
}
