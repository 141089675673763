import {axiosPrivate} from "../commons/services/axiosService";

export const listGradeMegaAPI = ()=>{
    return axiosPrivate({
        method:'GET',
        url:'account/listGradeMega'
    })
}

export const getCoursesInMegaAPI=(accountId)=>{
    return axiosPrivate({
        method: 'GET',
        url:`account/listCourseMega/${accountId}`
    })
}
