import React, {useEffect, useRef, useState} from 'react'
import CourseNameIcon from '../../../assets/images/icons/courseNameIcon.svg'
import DatePickerComponent from "../../../commons/components/datetimes/DatePickerComponent";
import {useDispatch, useSelector} from "react-redux";
import {
    createUploadUrl,
    getCourseSetting,
    publicCourse,
    updateCourse,
    updateCourseState,
    uploadImgFile
} from "../CourseDetailSlice";
import UnCheckIcon from '../../../assets/images/icons/unCheckIcon.svg'
import CheckIcon from '../../../assets/images/icons/checkIcon.svg'
import unPublicIcon from '../../../assets/images/icons/unpublish.svg'
import publicIcon from '../../../assets/images/icons/complete.svg'
import PhotoEditButton from '../../../assets/images/icons/button_edit_photo.svg'
import DeleteIcon from '../../../assets/images/icons/deleteIcon.svg'
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useTranslation} from 'react-i18next'
export default function CourseSettingTab({course}){
    const [courseName, setCourseName] = useState('')
    const [publicDesc, setPublicDesc] = useState('')
    const [courseColor, setCourseColor] = useState('')
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [studentFutureView, setStudentFutureView] = useState(false)
    const [studentPastView, setStudentPastView] = useState(false)
    const courseStatus = useSelector(state=>state.courseDetail.course)
    const courseSetting = useSelector(state=>state.courseDetail.courseSetting)
    const [courseImgUrl, setCourseImgUrl] = useState(null)
    const [courseBannerImgUrl, setCourseBannerImgUrl] = useState(null)
    const imageRef = useRef(null)
    const bannerRef = useRef(null)
    const dispatch = useDispatch()

    const verifyColor = (color)=>{
        let reg=/^#([0-9a-f]{3}){1,2}$/i;
        return reg.test(color)
    }

    const updateCourseStatus = (event)=>{
        dispatch(updateCourseState({
            courseId: course.id,
            event: event
        })).unwrap().then(res=>{
            toast.success(t('courseStatusUpdateMessage'))
        }).catch(err=>{
            toast.error(t('courseStatusUnChange'))
        })
    }

    const handleSelectImage = (event)=>{
        if(event.target.files.length > 0){
            dispatch(createUploadUrl(
                {courseId:course.id,
                    name:event.target.files[0].name})).unwrap().then(url=>{
                dispatch(uploadImgFile({
                    params:url.upload_params,
                    file:event.target.files[0],
                    url:url.upload_url
                })).unwrap().then(res=>{
                    setCourseImgUrl(res.url)
                }).catch(err=>{
                    toast.error(err.message)
                })
            }).catch(err=>{
                toast.error(err.message)
            })

        }
    }

    const handleSelectBanner = (event)=>{
        if(event.target.files.length > 0){
            dispatch(createUploadUrl(
                {courseId:course.id,
                    name:event.target.files[0].name})).unwrap().then(url=>{
                dispatch(uploadImgFile({
                    params:url.upload_params,
                    file:event.target.files[0],
                    url:url.upload_url
                })).unwrap().then(res=>{
                    setCourseBannerImgUrl(res.url)
                }).catch(err=>{
                    toast.error(err.message)
                })
            }).catch(err=>{
                toast.error(err.message)
            })

        }
    }

    const handleUpdateCourse = ()=>{
        if(courseName === ''){
            toast.error(t('courseNameCannotEmpty'))
            return
        }
        if(!verifyColor(courseColor)){
            toast.error(t('colorCodeInvalid'))
            return
        }
        if(startDate && endDate){
            console.log(startDate, endDate)
            if(new Date(startDate).getTime() - new Date(endDate).getTime() >=0){
                toast.error(t('dateSettingInvalid1'))
                return
            }
        }

        let updateData = {
            id: course.id,
            name: courseName,
            public_description: publicDesc,
            course_code: courseName,
            start_at: startDate,
            end_at: endDate,
            image_url: courseImgUrl,
            banner_image_url: courseBannerImgUrl,
            course_color: courseColor,
            remove_image: false,
            remove_banner_image:false,
            restrict_student_past_view: studentPastView,
            restrict_student_future_view: studentFutureView
        }
        if(courseImgUrl == null){
            updateData.remove_image = true
        }
        if(courseBannerImgUrl == null){
            updateData.remove_banner_image = true
        }

        dispatch(updateCourse(updateData)).unwrap().then(res=>{
            toast.success(t('courseHaveBeenUpdate'))
        }).catch(err=>{
            toast.error(err.message)
        })

    }

    useEffect(()=>{
        if(course){
            setCourseName(course.name)
            setPublicDesc(course.public_description)
            setCourseColor(course.course_color)
            setStartDate(course.start_at)
            setEndDate(course.end_at)
            dispatch(getCourseSetting(course.id))
        }
    },[course])
    useEffect(()=>{
        if(courseSetting){
            setStudentPastView(courseSetting.restrict_student_past_view)
            setStudentFutureView(courseSetting.restrict_student_future_view)
            setCourseImgUrl(courseSetting.image_url)
            setCourseBannerImgUrl(courseSetting.banner_image_url)
        }
    },[courseSetting])
    const {t, i18n} = useTranslation()
    return <div className={'w-full flex'}>
        <div className={'w-10/12 border-r pt-5 px-2'}>
            <h1 className={'text-[30px] text-black mb-5'}>{courseName}</h1>

            <div>
                <h3 className={'text-7A86A1 text-[16px] font-semibold'}>{t('courseSetting.DetailCourse')}</h3>
            </div>
            <div className={'w-full flex'}>
                <div className={'w-1/3 flex flex-col items-center'}>
                    <div className={'pt-6'}><span>{t('courseSetting.coursePic')}</span></div>
                    <div className={'relative w-[100px] h-[100px] items-center mt-5 bg-fef5f3 rounded-3xl'}>
                        {courseImgUrl && <img className={'w-[100px] h-[100px] rounded-3xl'} src={courseImgUrl} /> }
                        <img
                            onClick={()=>{imageRef.current.click()}}
                            src={PhotoEditButton} className={'absolute bottom-0 right-0 hover:cursor-pointer'}/>
                        <input onChange={handleSelectImage} ref={imageRef} accept="image/png, image/gif, image/jpeg"  type='file' className="hidden"/>
                    </div>
                    <button
                        onClick={()=>{setCourseImgUrl(null)}}
                        className={'flex justify-center items-center space-x-2 pt-3'}>
                        <img src={DeleteIcon}/>
                        <span className={'text-7A86A1 text-[14px]'}>{t('courseSEtting.remove')}</span>
                    </button>


                    <div className={'pt-6'}><span>{t('courseSetting.courseCoverPic')}</span></div>
                    <div className={'relative w-[200px] h-[100px] items-center mt-5 rounded-3xl bg-fef5f3'}>
                        {courseBannerImgUrl && <img className={'w-[200px] h-[100px] rounded-3xl'} src={courseBannerImgUrl} /> }
                        <img onClick={()=>{bannerRef.current.click()}}
                            src={PhotoEditButton} className={'absolute bottom-0 right-0 hover:cursor-pointer'}/>
                        <input onChange={handleSelectBanner} ref={bannerRef} accept="image/png, image/gif, image/jpeg"  type='file' className="hidden"/>
                    </div>
                    <button
                        onClick={()=>{setCourseBannerImgUrl(null)}}
                        className={'flex justify-center items-center space-x-2 pt-3'}>
                        <img src={DeleteIcon}/>
                        <span className={'text-7A86A1 text-[14px]'}>Xoá</span>
                    </button>


                </div>

                <div className={'w-2/3'}>
                    <span className={'text-[14px]'}>{t('courseName')}</span>
                    <div className={'flex rounded-xl px-2 py-3 bg-F0F0F0 mt-2 mb-5'}>
                        <img src={CourseNameIcon}  className={'px-2'}/>
                        <input className={'bg-F0F0F0 w-full focus:outline-none'} value={courseName} onChange={(e)=>{setCourseName(e.target.value)}} />
                    </div>
                    <span className={'text-[14px]'}>{t('courseSetting.courseDescription')}</span>
                    <textarea value={publicDesc} onChange={(e)=>{setPublicDesc(e.target.value)}} className={'w-full bg-F0F0F0 rounded-xl  h-[300px] focus:outline-none px-2 py-2 mb-5'}></textarea>
                    <span className={'text-[14px]'}>{t('courseSetting.courseColor')}</span>
                    <div className={'flex space-x-1 mt-2 mb-5'}>
                        <button
                            onClick={()=>{setCourseColor('#BB3B00')}}
                            className={'rounded-full p-[19px] bg-BB3B00 border border-EBEBEB'}></button>
                        <button
                            onClick={()=>{setCourseColor('#FD2200')}}
                            className={'rounded-full p-[19px] bg-FD2200 border border-EBEBEB'}></button>
                        <button
                            onClick={()=>{setCourseColor('#E50D5E')}}
                            className={'rounded-full p-[19px] bg-E50D5E border border-EBEBEB'}></button>
                        <button
                            onClick={()=>{setCourseColor('#903296')}}
                            className={'rounded-full p-[19px] bg-903296 border border-EBEBEB'}></button>
                        <button
                            onClick={()=>{setCourseColor('#68419C')}}
                            className={'rounded-full p-[19px] bg-68419C border border-EBEBEB'}></button>
                        <button
                            onClick={()=>{setCourseColor('#4B4DA4')}}
                            className={'rounded-full p-[19px] bg-4B4DA4 border border-EBEBEB'}></button>
                        <button
                            onClick={()=>{setCourseColor('#2B96E3')}}
                            className={'rounded-full p-[19px] bg-2B96E3 border border-EBEBEB'}></button>
                        <button
                            onClick={()=>{setCourseColor('#139889')}}
                            className={'rounded-full p-[19px] bg-139889 border border-EBEBEB'}></button>
                        <button
                            onClick={()=>{setCourseColor('#009A17')}}
                            className={'rounded-full p-[19px] bg-009A17 border border-EBEBEB'}></button>
                        <button
                            onClick={()=>{setCourseColor('#8B9D07')}}
                            className={'rounded-full p-[19px] bg-8B9D07 border border-EBEBEB'}></button>
                        <button
                            onClick={()=>{setCourseColor('#D67B00')}}
                            className={'rounded-full p-[19px] bg-D67B00 border border-EBEBEB'}></button>
                        <button
                            onClick={()=>{setCourseColor('#FA5D00')}}
                            className={'rounded-full p-[19px] bg-FA5D00 border border-EBEBEB'}></button>
                        <button
                            onClick={()=>{setCourseColor('#EE5C8E')}}
                            className={'rounded-full p-[19px] bg-EE5C8E border border-EBEBEB'}></button>
                    </div>
                    <div className={'mb-5'}>
                        <input className={'bg-F0F0F0 w-1/2 focus:outline-none rounded-xl px-2 py-3'} placeholder={'#D67B00'} value={courseColor} onChange={(e)=>{setCourseColor(e.target.value)}} />
                    </div>
                    <div className={'flex w-full space-x-5 mb-5'}>
                        <div className={'w-1/3'}>
                            <span className={'text-[14px]'}>{t('courseSetting.StartDate')}</span>
                            <DatePickerComponent dateSelect={startDate} handleSelect={setStartDate} />
                        </div>
                        <div className={'w-1/3'}>
                            <span className={'text-[14px]'}>{t('courseSetting.EndDate')}</span>
                            <DatePickerComponent dateSelect={endDate} handleSelect={setEndDate} />
                        </div>
                    </div>
                    <div className={'flex mb-5'}>
                        <img className={'mr-5 hover:cursor-pointer'} onClick={()=>{setStudentFutureView(!studentFutureView)}} src={studentFutureView?CheckIcon:UnCheckIcon}/>
                        <span className={'text-[14px]'}>{t('courseSetting.retrictStdStartDate')}</span>
                    </div>
                    <div className={'flex mb-5'}>
                        <img className={'mr-5 hover:cursor-pointer'} onClick={()=>{setStudentPastView(!studentPastView)}} src={studentPastView?CheckIcon:UnCheckIcon}/>
                        <span className={'text-[14px]'}>{t('courseSetting.retrictStdStartDate')}</span>
                    </div>
                    <div className={'flex justify-end space-x-2 mb-5'}>
                        <button onClick={handleUpdateCourse}
                                className={'rounded bg-6149CD text-white text-[14px] w-[120px] h-[30px]'}>
                            {t('saveBtn')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div className={'w-2/12 px-3'}>
            <div className={'mb-5'}>
                <span className={'text-[14px] text-7A86A1'}>{t('courseSetting.courseStatus')}</span>
            </div>

            {course.workflow_state ==='unpublished' && <div className={'flex mb-5'}>
                <img src={unPublicIcon} />
                <span className={'ml-2 text-F96767 text-[14px]'}>{t('courseSetting.unpublish')}</span>
            </div>}
            {course.workflow_state ==='available' && <div className={'flex mb-5'}>
                <img src={publicIcon} />
                <span className={'ml-2 text-F96767 text-[14px]'}>{t('courseSetting.publish')}</span>
            </div>}
            {course.workflow_state ==='completed' && <div className={'flex mb-5'}>
                <img src={publicIcon} />
                <span className={'ml-2 text-F96767 text-[14px]'}>{t('courseStting.conclude')}</span>
            </div>}
            {(course.workflow_state ==='unpublished') &&<div className={'mb-5'}>
                <button
                    onClick={()=>{updateCourseStatus('offer')}}
                    disabled={courseStatus =='loading'}
                    className={'rounded bg-6149CD text-white text-[14px] w-[120px] h-[30px]'}>
                    {t('btnPublish')}
                </button>
            </div>}
            {(course.workflow_state ==='available') &&<div className={'mb-5'}>
                <button
                    onClick={()=>{updateCourseStatus('claim')}}
                    disabled={courseStatus =='loading'}
                    className={'rounded bg-6149CD text-white text-[14px] w-[120px] h-[30px]'}>
                   {t('btnUnPublish')}
                </button>
            </div>}
            {(course.workflow_state ==='completed') &&<div className={'mb-5'}>
                <button
                    onClick={()=>{updateCourseStatus('offer')}}
                    disabled={courseStatus =='loading'}
                    className={'rounded bg-6149CD text-white text-[14px] w-[120px] h-[30px]'}>
                    {t('btnUnConclude')}
                </button>
            </div>}
            {course.workflow_state !=='completed' && <div className={'mb-5'}>
                <button
                    onClick={()=>{updateCourseStatus('conclude')}}
                    className={'rounded bg-F0F0F0 text-black text-[14px] w-[120px] h-[30px]'}>
                    {t('btnConclude')}
                </button>
            </div>}
            <hr className={'mb-5'}/>
            <div className={'mb-5'}>
                <span className={'text-[14px] text-7A86A1'}>{t('courseSetting.people')}</span>
            </div>
            <div className={'mb-2'}>
                <span className={'text-[14px] font-semibold text-7A86A1'}>{t('courseSetting.student')}:</span>
            </div>
            <div className={'mb-5'}>
                <span className={'text-[14px]  text-7A86A1'}>{course.total_students?course.total_students:t('courseSetting.Emtpy')}</span>
            </div>
            <div className={'mb-2'}>
                <span className={'text-[14px] font-semibold text-7A86A1'}>{t('courseSetting.teacher')}:</span>
            </div>
            <div className={'mb-5'}>
                <span className={'text-[14px]  text-7A86A1'}>{(course.teachers && course.teachers.length)?course.teachers.length:t('courseSetting.Emtpy')}</span>
            </div>
        </div>
        <ToastContainer />
    </div>
}
