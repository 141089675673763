import {axiosPrivate} from "../../../commons/services/axiosService";

export const createSubjectAPI=(name, gradeId)=>{
    return axiosPrivate({
        url:`/subject/create`,
        method:'POST',
        data:{
            name: name,
            gradeId:gradeId
        }
    })
}


export const deleteSubjectAPI=(subjectId)=>{
    return axiosPrivate({
        url:`subject/${subjectId}`,
        method:'DELETE'
    })
}
