import React, {useState} from 'react'
import DropdownSimple2 from "../../../commons/components/dropdowns/DropdownSimple2";
import CheckIcon from "../../../assets/images/icons/checkIcon.svg";
import UnCheckIcon from "../../../assets/images/icons/unCheckIcon.svg";
import SimpleButton from "../../../commons/components/buttons/SimpleButton";
import {useDispatch} from "react-redux";
import {inviteUser, listAllEnrollInSection} from "./EnrollmentSlice";
import {toast} from "react-toastify";
import { useTranslation } from 'react-i18next';


export default function AddPeopleForm({course,section, handleClose}){


    const [role, setRole] = useState( {name: 'Học sinh', value :"StudentEnrollment"})
    const [emailRaw, setEmailRaw] = useState("")
    const dispatch = useDispatch()
    const handleSelectRole=(role)=>{
        setRole(role)
    }
    const {t, i18n} = useTranslation()

    const listRole = [
        {name: t('student'), value :"StudentEnrollment"},
        {name:t('teacher'), value:"TeacherEnrollment"}
    ]
    const [selectLimit, setSelectLimit] = useState(false)

    const handleEnroll= ()=>{
        console.log(emailRaw)
        let emails = []
        let listEmail = emailRaw.trim().replace( /\n/g, " " ).split(' ')
        for(let email of listEmail){
            emails.push(email.trim())
        }
        console.log(emails)
        dispatch(inviteUser({
            emails: emails,
            limit: selectLimit,
            sectionId: section.id,
            type: role.value
        })).unwrap().then(enrollment=>{
            handleClose()
            dispatch(listAllEnrollInSection({
                sectionId: section.id,
                courseId: course.id
            }))
        })
    }

    return <div className={'w-full'}>
        <div className={'my-2'}>
            <span className={'text-[14px] text-black '}>{t('class')}</span>
        </div>
        <div>
            <input  readOnly={true} value={section.name} className={'text-[14px] text-393939 w-full rounded bg-F0F0F0 focus:outline-none px-2 h-[40px]'}/>
        </div>
        <div className={'my-2'}>
            <span className={'text-[14px] text-black '}>{t('addPeopleForm.studentEmailAddress')}</span>
        </div>
        <div>
            <textarea
                value={emailRaw} onChange={(e)=>{setEmailRaw(e.target.value)}}
                className={'bg-F0F0F0 w-full min-h-[200px] rounded focus:outline-none px-2 text-393939 text-[14px]'} placeholder={'abc@gmail.com xyz@gmail.com'}>

            </textarea>
        </div>
        <div className={'my-2'}>
            <span className={'text-[14px] text-black '}>{t('addPeopleForm.role')}</span>
        </div>
        <div>
            <DropdownSimple2 background={'bg-F0F0F0'} selectData={setRole} listData={listRole}/>
        </div>
        <div className={'flex mb-5'}>
            <img className={'mr-3 hover:cursor-pointer'} onClick={()=>{setSelectLimit(!selectLimit)}} src={selectLimit?CheckIcon:UnCheckIcon}/>
            <span className={'text-[14px]'}>{t('addpeopleForm.retricActWithOtherSection')}</span>
        </div>
        <div className={'flex justify-center space-x-3'}>
            <button onClick={handleClose} className={'rounded bg-F0F0F0 h-[40px] text-[14px] min-w-[100px]'}>{t('cancelBtn')}</button>
            <button onClick={handleEnroll} className={'rounded bg-6149CD h-[40px] text-white text-[14px] min-w-[100px]'}>{t('addBtn')}</button>

        </div>
    </div>
}
