import {axiosPrivate} from "../../../commons/services/axiosService";


export const listSectionInCourseAPI=(courseId)=>{
    return axiosPrivate({
        method:'GET',
        url:`section/${courseId}/listSection`,
    })
}

export const updateSectionAPI= (sectionId, name)=>{
    return axiosPrivate({
        method:"PUT",
        url:`section/update`,
        data:{
            sectionId: sectionId,
            name:name
        }
    })
}

export const createSectionAPI=(courseId, name)=>{
    return axiosPrivate({
        method:"POST",
        url:`section/create`,
        data:{
            courseId: courseId,
            name:name
        }
    })
}


export const deleteSectionAPI = (sectionId)=>{
    return axiosPrivate({
        method:"DELETE",
        url:`section/${sectionId}`
    })
}
