import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import GlobalHeader from "../../commons/components/headers/GlobalHeader";
import {listCourse} from "../courses/courseSlice";
import Carousel from "react-multi-carousel";
import {useTranslation} from "react-i18next";
import "react-multi-carousel/lib/styles.css";
import defaultCourseImg  from '../../assets/images/logos/logo.svg'
import ProgressBar from "./ProgressBar";
import DropdownSimple2 from "../../commons/components/dropdowns/DropdownSimple2";
import {listSectionInCourse} from "../courseDetail/peopleTab/SectionSlice";
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {countSubmittion, getCountPublishAssignment, getSectionProgress} from "./DashboardSlice";
import {listAllEnrollInSection, listEnrollInvited} from "../courseDetail/peopleTab/EnrollmentSlice";
import GoldIcon from '../../assets/images/icons/gold.svg'
import SilverIcon from '../../assets/images/icons/silver.svg'
import BronzeIcon from '../../assets/images/icons/bronze.svg'
import PieChartCompomentGrade from "./PieChartCompomentGrade";
import Footer from "../../commons/components/footer/Footer";
import InviteEnroll from "../../components/InviteEnroll";
export default function Dashboard(){
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };
    const {t, i18n}= useTranslation()
    const user =useSelector(state=>state.auth.user)
    const dispatch  = useDispatch()
    const navigate = useNavigate()
    const [publishCourse, setPublishCourse] = useState([])
    const [unPublishCourse, setUnPublishCourse] = useState([])
    const courses = useSelector(state=>state.courses.courses)
    const sections = useSelector(state=>state.section.sections)
    const [listCourses, setListCourses] = useState([])
    const [courseSelect, setCourseSelect] = useState()
    const [listSections, setListSections] = useState([])
    const [sectionSelect, setSectionSelect] = useState()
    const numberPublishAssignment = useSelector(state=>state.dashboard.numberPublishAssignment)
    const sectionProgress = useSelector(state=>state.dashboard.sectionProgress)
    const submission = useSelector(state=> state.dashboard.submission)
    const enrollInvited = useSelector(state=> state.enrollment.enrollInvited)
    const [students, setStudents] = useState([])
    const [currentScoreData, setCurrentScoreData] = useState([])
    const [finalScoreData, setFinalScoreData] = useState([])
    useEffect(()=>{
        if(user && user.viewOption == null){
            navigate('/changeview')
        }
    },[user, navigate])
    useEffect(()=>{
        if(user){
            dispatch(listCourse())
        }
    },[user])



    useEffect(()=>{
        if(courses.length){
            let publics=[]
            let unPublics=[]
            for(let course of courses){
                if(course.workflow_state=='available'){
                    publics.push(course)
                }else if(course.workflow_state=='unpublished'){
                    unPublics.push(course)
                }
            }
            setPublishCourse(publics)
            setUnPublishCourse(unPublics)
        }else{
            setUnPublishCourse([])
            setPublishCourse([])
        }
    },[courses])

    useEffect(()=>{
        let _courses= []
        if(publishCourse){
            for(let course of publishCourse){
                _courses.push({
                    name: course.name,
                    value: course
                })
            }
            setListCourses(_courses)
        }
    },[publishCourse])

    useEffect(()=>{
        if(courseSelect && courseSelect.value){
            dispatch(listSectionInCourse(courseSelect.value.id))
        }
    },[courseSelect])

    useEffect(()=>{
        let _sections= []
        if(sections){
            for(let section of sections){
                _sections.push({
                    name: section.name,
                    value: section
                })
            }
            setListSections(_sections)
    }},[sections])

    useEffect(()=>{
        if(listSections && listSections.length>0){
            setSectionSelect(listSections[0])
        }
    },[listSections])

    useEffect(()=>{
        if(sectionSelect){
            dispatch(getSectionProgress({
                courseId: sectionSelect.value.course_id,
                sectionId: sectionSelect.value.id
            }))
        }
    },[sectionSelect])
    useEffect(()=>{
        if(sectionSelect){
            dispatch(getCountPublishAssignment({
                courseId: sectionSelect.value.course_id,
                sectionId: sectionSelect.value.id
            }))
        }
    },[sectionSelect])

    useEffect(()=>{
        if(sectionSelect){
            dispatch(countSubmittion({
                courseId: sectionSelect.value.course_id,
                sectionId: sectionSelect.value.id
            }))
        }
    },[sectionSelect])

    useEffect(()=>{
        if(sectionSelect){
            setCurrentScoreData([
                { name: '< 60%', value: 0 },
                { name: '60% < 80%', value: 0 },
                { name: '> 80%', value: 0 },
            ])
            setFinalScoreData([
                { name: '< 60%', value: 0 },
                { name: '60% < 80%', value: 0 },
                { name: '> 80%', value: 0 },
            ])
            dispatch(listAllEnrollInSection({
                courseId: sectionSelect.value.course_id,
                sectionId: sectionSelect.value.id
            })).unwrap().then(_users=>{
                let users = JSON.parse(JSON.stringify(_users))
                if(users.length){
                    users.sort(function (x, y){
                        let xMax = 0
                        let yMax =0
                        for(let enrollment of x.enrollments){
                            if(enrollment.type=='StudentEnrollment'){
                                if( enrollment.grades && enrollment.grades.final_score >= xMax){
                                    xMax= enrollment.grades.final_score
                                }
                            }
                        }
                        for(let enrollment of y.enrollments){
                            if(enrollment.type=='StudentEnrollment' && enrollment.course_section_id==sectionSelect.value.id){
                                if(enrollment.grades && enrollment.grades.final_score >= yMax){
                                    yMax= enrollment.grades.final_score
                                }
                            }
                        }
                        if (xMax < yMax) {
                            return 1;
                        }
                        if (xMax > yMax) {
                            return -1;
                        }
                        return 0;

                    })
                    let scoreGroup1=0
                    let scoreGroup2 =0
                    let scoreGroup3 = 0
                    let scoreGroup4 =0
                    let scoreGroup5=0
                    let scoreGroup6 =0
                    for(let i =0; i<users.length; i++){
                        for(let enrollment of users[i].enrollments){
                            if(enrollment.type=='StudentEnrollment' && enrollment.course_section_id==sectionSelect.value.id){
                                if(enrollment.grades && enrollment.grades.final_score ){
                                    if(enrollment.grades.final_score <60){
                                        scoreGroup4+=1
                                    }else if(enrollment.grades.final_score >=60 && enrollment.grades.final_score<80){
                                        scoreGroup5 +=1
                                    }else{
                                        scoreGroup6 +=1
                                    }
                                  users[i].final_score= enrollment.grades.final_score
                                }else{
                                    scoreGroup4+=1
                                    users[i].final_score=0
                                }

                                if(enrollment.grades && enrollment.grades.current_score ){
                                    if(enrollment.grades.current_score <60){
                                        scoreGroup1+=1
                                    }else if(enrollment.grades.current_score >=60 && enrollment.grades.current_score<80){
                                        scoreGroup2 +=1
                                    }else{
                                        scoreGroup3 +=1
                                    }
                                    users[i].current_score= enrollment.grades.current_score
                                }else{
                                    scoreGroup1+=1
                                    users[i].current_score=0
                                }
                            }
                        }
                    }
                    setCurrentScoreData([
                        { name: '< 60%', value: scoreGroup1 },
                        { name: '60% < 80%', value: scoreGroup2 },
                        { name: '> 80%', value: scoreGroup3 },
                    ])
                    setFinalScoreData([
                        { name: '< 60%', value: scoreGroup4 },
                        { name: '60% < 80%', value: scoreGroup5 },
                        { name: '> 80%', value: scoreGroup6 },
                    ])
                    setStudents(users)
                }

            })
        }
    },[sectionSelect])

    useEffect(()=>{
        dispatch(listEnrollInvited())
    },[])

    return <div className={'w-screen bg-white min-h-screen'}>
        <GlobalHeader  page={'home'}/>
        <div className={'container mx-auto '} >
            <div className={'py-3 border-b'}>
                <span className={'text-7A86A1 text-[14px]'}>{t('home')}</span>
            </div>
            {enrollInvited && enrollInvited.map(x=><InviteEnroll enrollment={x}/>)}
            <div className={'w-full  p-[10px] bg-white '}>
                <div className={'flex space-x-3 mb-3'}>
                    <h3 className={'text-[18px] text-7A86A1 font-semibold'}>{t('learningReport')}</h3>
                </div>
                <div className={' bg-white rounded shadow-2xl p-[10px] '}>
                    <div className={'grid grid-cols-12 gap-5'}>
                        <div className={'col-span-12 md:col-span-6'}>
                            <span className={'text-[14px] text-7A86A1 '}>{t('dashboard.selectCourse')}</span>
                            <DropdownSimple2 background={'bg-F0F0F0'} selectData={setCourseSelect} listData={listCourses}  />
                        </div>
                        <div className={'col-span-12 md:col-span-6'}>
                            <span className={'text-[14px] text-7A86A1 '}>{t('dashboard.selectSection')}</span>
                            <DropdownSimple2 background={'bg-F0F0F0'} selectData={setSectionSelect} listData={listSections}  />
                        </div>
                    </div>
                    <div>
                        <span className={'text-[14px] font-semibold text-black'}>{t('dashboard.GeneralReport')}</span>
                        <div className={'grid grid-cols-12 gap-2 mt-3 '}>
                            <div className={'flex justify-between items-center col-span-6 md:col-span-3 border rounded-3xl p-[10px] bg-edfce5'}>
                                <span className={'text-[14px] text-7A86A1'}>{t('dashboard.moduleHaveBeenDone')}</span>
                                <div className={'w-[60px] h-[60px]'}>
                                    {sectionProgress && <CircularProgressbar
                                        styles={buildStyles({
                                            pathColor:'red'
                                        })}
                                        value={sectionProgress.total ?(sectionProgress.completed/sectionProgress.total *100).toFixed(2):0}
                                        text={sectionProgress.total ?(sectionProgress.completed/sectionProgress.total *100).toFixed(0) +"%": '0%'}
                                        strokeWidth={10}
                                    />}
                                </div>
                            </div>
                            <div className={'flex justify-between items-center col-span-6 md:col-span-3 border rounded-3xl p-[10px]  bg-d6d3fa'}>
                                <span className={'text-[14px] text-7A86A1'}>{t('dashboard.assignmentAssigned')}</span>
                                <div className={'w-[60px] h-[60px]'}>
                                    {numberPublishAssignment && <CircularProgressbar
                                        styles={buildStyles({
                                            pathColor:'blue'
                                        })}
                                        value={numberPublishAssignment.total?(numberPublishAssignment.published/numberPublishAssignment.total *100).toFixed(2):0}
                                        text={numberPublishAssignment.total?(numberPublishAssignment.published/numberPublishAssignment.total *100).toFixed(0) +"%":"0%"}
                                        strokeWidth={10}
                                    />}
                                </div>`

                            </div>
                            <div className={'flex justify-between items-center col-span-6 md:col-span-3 border rounded-3xl p-[10px] bg-ffeee3'}>
                                <span className={'text-[14px] text-7A86A1'}>{t('dashboard.AssignmentDone')}</span>
                                <div className={'w-[60px] h-[60px]'}>
                                    {submission && <CircularProgressbar
                                        styles={buildStyles({
                                            pathColor:'red'
                                        })}
                                        value={submission.total ?(submission.submitted/submission.total *100).toFixed(2):0}
                                        text={submission.total ?(submission.submitted/submission.total *100).toFixed(2) +"%":"0%"}
                                        strokeWidth={10}
                                    />}
                                </div>

                            </div>
                            <div className={'flex justify-between items-center col-span-6 md:col-span-3 border rounded-3xl p-[10px] bg-dffaff'}>
                                <span className={'text-[14px] text-7A86A1'}>{t('dashboard.AssignmentGraded')}</span>
                                <div className={'w-[60px] h-[60px]'}>
                                    {submission && <CircularProgressbar
                                        styles={buildStyles({
                                            pathColor:'blue'
                                        })}
                                        value={submission.total?(submission.graded/submission.total *100).toFixed(2):0}
                                        text={submission.total?(submission.graded/submission.total *100).toFixed(2) +"%":"0%"}
                                        strokeWidth={10}
                                    />}
                                </div>

                            </div>

                        </div>

                    </div>
                    <div className={'grid grid-cols-12 gap-5 py-5'}>
                        <div className={'col-span-12'}>
                            <div className={'text-[18px] font-semibold text-black pb-5'}>{t('dashboard.gradeReport')}</div>
                            <div className={'grid grid-cols-12 gap-5  rounded pb-5 '}>
                                <div className={'col-span-12 lg:col-span-6 h-[350px] text-center'}>
                                    <PieChartCompomentGrade data={currentScoreData} />
                                    <h3 className={'font-semibold text-center '}>{t('dashboard.currentScore')}</h3>

                                </div>
                                <div className={'col-span-12 lg:col-span-6 h-[350px] text-center'}>
                                    <PieChartCompomentGrade data={finalScoreData} />
                                    <h3 className={'font-semibold text-center '}>{t('dashboard.avgGrade')}</h3>
                                </div>
                            </div>
                        </div>
                        <div className={'col-span-12' }>
                            <div className={'text-[18px] font-semibold text-black pb-5'}>{t('dashboard.rate')}</div>
                            <div className={'border rounded max-h-[375px]  overflow-y-scroll scrollbar-hide '}>
                                {students && students.map((x,i)=><div className={`flex justify-between items-center p-4 ${i%2==0?'bg-FAFAFA':''}`} key={x.id}>
                                    <div className={'flex space-x-2 items-center'}>
                                        {i==0 && <img className={'min-w-[25px]'} src={GoldIcon} />}
                                        {i==1 && <img className={'min-w-[25px]'} src={SilverIcon} />}
                                        {i==2 && <img className={'min-w-[25px]'} src={BronzeIcon} />}
                                        {i!=0 && i!=1 && i!=2 && <span className={'min-w-[25px] text-7A86A1 text-[14px] text-center'}>{i+1}</span>}
                                        <img className={'w-[38px] h-[38px] rounded-full'} src={x.avatar_url} />
                                        <span className={'text-[14px]'}>{x.name}</span>
                                    </div>

                                    <span className={'text-[14px]'}>{x.final_score+"%"}</span>
                                </div>)}
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className={'w-full rounded-3xl p-[10px] my-3'}>
                <div className={'flex space-x-3 mb-3'}>
                    <h3 className={'text-[18px] text-7A86A1 font-semibold'}>{t('dashboard.publicCourse')}</h3>
                    <button onClick={()=>{navigate('/courses')}} className={'text-316AFF text-[14px]'}>{t('dashboard.viewAll')}</button>
                </div>
                <Carousel
                    swipeable={false}
                    draggable={true}
                    showDots={false}
                    responsive={responsive}
                    infinite={true}
                    autoPlaySpeed={1000}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={1000}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                >
                    {
                        publishCourse && publishCourse.map(x=><div key={x.id} onClick={()=>{navigate(`/courses/${x.id}`)}} className={'mx-3 py-3 shadow-md hover:cursor-pointer rounded bg-white'}>
                            <img src={x.image_download_url?x.image_download_url:defaultCourseImg} className={'h-[222px] w-full rounded'} />
                            <div className={'px-3 py-2'}>
                                <h3 className={'text-black text-[14px] font-semibold'}>{x.name}</h3>
                                <ProgressBar course={x}  />
                            </div>

                        </div>)
                    }
                </Carousel>
            </div>

            <div className={'w-full rounded-3xl p-[10px]'}>
                <div className={'flex space-x-3 mb-3'}>
                    <h3 className={'text-[18px] text-7A86A1 font-semibold'}>{t('dashbaord.unpublishCourse')}</h3>
                    <button onClick={()=>{navigate('/courses')}} className={'text-316AFF text-[14px]'}>{t('dashboard.viewAll')}</button>
                </div>
                <Carousel
                    swipeable={false}
                    draggable={true}
                    showDots={false}
                    responsive={responsive}
                    infinite={true}
                    autoPlaySpeed={1000}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={1000}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                >
                    {
                        unPublishCourse && unPublishCourse.map(x=><div onClick={()=>{navigate(`/courses/${x.id}`)}} key={x.id} className={'mx-3 my-3 rounded shadow-md hover:cursor-pointer bg-white'}>
                            <img src={x.image_download_url?x.image_download_url:defaultCourseImg} className={'h-[222px] w-full rounded'} />
                            <div className={'px-3 py-2'}>
                                <h3 className={'text-black text-[14px] font-semibold'}>{x.name}</h3>
                            </div>

                        </div>)
                    }
                </Carousel>
            </div>

        </div>

        <Footer />
    </div>
}
